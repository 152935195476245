import { BASE_PATH, MODULE_NAME, MODULE_NAME_DEFAULT } from "./constants";

export const pathToModuleName = (path: string) => {
  if (!path.includes(BASE_PATH)) return undefined;

  const pathKeys = path.split("/");
  const pathEnd = pathKeys.pop();

  switch (pathEnd) {
    case "deposit-limits":
      return MODULE_NAME.DEPOSIT_LIMIT;
    case "funding-controls":
      return MODULE_NAME.FUNDING_CONTROLS;
    case "timeout":
      return MODULE_NAME.TIMEOUT;
    case "self-exclude":
      return MODULE_NAME.SELF_EXCLUSION;
    case "suspend-account":
      return MODULE_NAME.SUSPEND;
    default:
      return MODULE_NAME_DEFAULT;
  }
};
