import React, { Component } from "react";
import PropTypes from "prop-types";
import ListItem from "@tvg/atomic-ui/_molecule/ListItem/index";
import style from "./style.css";

export default class SubMenuItem extends Component {
  subMenuList() {
    const { subMenus } = this.props;
    return subMenus.map((subMenu) => {
      const item = {
        title: subMenu.name,
        description: subMenu.description,
        url: subMenu.route,
        id: subMenu.subQaLabel,
        icon: subMenu.icon,
        isNew: subMenu.newIcon,
        promptClick: (event) => {
          event.preventDefault();
          event.stopPropagation();
          this.props.openMenuMethod(
            event,
            subMenu.route,
            subMenu.name,
            this.props.menuName,
            subMenu.options
          );
        }
      };
      return (
        <ListItem
          device="desktop"
          item={item}
          showGoArrow={false}
          isNewTagSLim
        />
      );
    });
  }

  render() {
    const menuFirstName = this.props.menuName.split(" ")[0];
    return (
      <ul
        className={`${this.props.className} ${style.subMenuList}`}
        data-qa-label={`${menuFirstName}SubMenuItem`}
      >
        {this.subMenuList()}
      </ul>
    );
  }
}

SubMenuItem.propTypes = {
  menuName: PropTypes.string,
  subMenus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      route: PropTypes.string,
      newIcon: PropTypes.bool
    })
  ),
  openMenuMethod: PropTypes.func,
  className: PropTypes.string
};

SubMenuItem.defaultProps = {
  subMenus: [],
  openMenuMethod: false,
  className: "",
  menuName: ""
};
