import { BaseEventProps } from "../../types";

export enum ModuleEventProps {}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  CLOSE_SOCIAL_MODAL = "CLOSE_SOCIAL_SHARE_MODAL",
  OPEN_SOCIAL_MODAL = "OPEN_SOCIAL_SHARE_MODAL",
  SHARE_BET = "SHARE_BET_THROUGH_SOCIAL_MEDIA"
}

export interface SocialShareType {
  type: MediatorEventType;
  payload: {
    isMyBets: boolean;
    isActiveBet: boolean;
  };
}
