import AccountSummary from "./modules/accountSummary";
import PicksAlerts from "./modules/picksAlerts";
import BetConfirmation from "./modules/betConfirmation";
import BetSlip from "./modules/betSlip";
import Alchemer from "./modules/alchemer";
import Paws from "./modules/paws";
import BetConfirmJourney from "./modules/betConfirmJourney";
import BetCancel from "./modules/betCancel";
import BetPreferences from "./modules/betPreferences";
import Braze from "./modules/braze";
import Footer from "./modules/footer";
import ForgotCredentials from "./modules/forgotCredentials";
import GridFooter from "./modules/gridFooter";
import HomepageTrackList from "./modules/homepageTrackList";
import Deposits from "./modules/deposits";
import MyBets from "./modules/myBets";
import BetSlipUndo from "./modules/betSlipUndo";
import IOS from "./modules/ios";
import Navigation from "./modules/navigation";
import PastPerformance from "./modules/pastPerformance";
import MorePage from "./modules/morePage";
import Onboarding from "./modules/onboarding";
import Handicapping from "./modules/handicapping";
import PendingWithdrawals from "./modules/pendingWithdrawals";
import PromosStoryblokMain from "./modules/promosStoryblokMain";
import PromoWidgetCalendar from "./modules/promoWidgetCalendar";
import Promos from "./modules/promos";
import ResponsibleGaming from "./modules/responsibleGaming";
import RepeatBets from "./modules/repeatBets";
import RaceTracks from "./modules/raceTracks";
import Registration from "./modules/registration";
import QuickLinks from "./modules/quickLinks";
import ReferFriendMain from "./modules/referFriendMain";
import TalentPicks from "./modules/talentPicks";
import Main from "./modules/main";
import PromoOnboarding from "./modules/promoOnboarding";
import ProgramPage from "./modules/programPage";
import TopRaces from "./modules/topRaces";
import TopTracks from "./modules/topTracks";
import TopPools from "./modules/topPools";
import Video from "./modules/video";
import UpcomingRaces from "./modules/upcomingRaces";
import RaceAlert from "./modules/raceAlert";
import RacePageView from "./modules/racePageView";
import RaceResults from "./modules/raceResults";
import ResultedTabInteractions from "./modules/resultedTabInteractions";
import Selection from "./modules/selection";
import SocialShare from "./modules/socialShare";
import PreviousWinners from "./modules/previousWinners";
import Tracks from "./modules/tracks";
import WatchLivePage from "./modules/watchLivePage";
import TutorialsFdr from "./modules/tutorialsFdr";
import Tutorials from "./modules/tutorials";
import WagerPad from "./modules/wagerPad";
import WagerRewards from "./modules/wagerRewards";
import PageView from "./modules/promos/pageView";
import ReferFriend from "./modules/promos/referFriend";
import LoginModal from "./modules/loginModal";
import RaceCell from "./modules/raceCell";
import HomePage from "./modules/homePage";
import GlobalWallet from "./modules/globalWallet";
import Derby from "./modules/derby";
import CrossProductNavigation from "./modules/crossProductNavigation";
import EducationalHub from "./modules/educationalHub";
import VideoPlayer from "./modules/videoPlayer";
import PickBetsOnBoarding from "./modules/pickBetsOnBoarding";
import RacePage from "./modules/racePage";
import Header from "./modules/header";

export const init = () => {
  AccountSummary();
  Alchemer();
  BetCancel();
  BetConfirmation();
  BetConfirmJourney();
  BetPreferences();
  BetSlip();
  BetSlipUndo();
  Braze();
  Deposits();
  Footer();
  ForgotCredentials();
  GridFooter();
  HomepageTrackList();
  IOS();
  Navigation();
  PastPerformance();
  PicksAlerts();
  PreviousWinners();
  MorePage();
  Onboarding();
  Handicapping();
  PromoWidgetCalendar();
  PendingWithdrawals();
  PromosStoryblokMain();
  Promos();
  QuickLinks();
  PromosStoryblokMain();
  MyBets();
  Paws();
  RaceTracks();
  RaceAlert();
  RacePageView();
  RaceResults();
  Registration();
  ResponsibleGaming();
  RepeatBets();
  ReferFriendMain();
  TalentPicks();
  Main();
  PageView();
  PromoOnboarding();
  ProgramPage();
  TopRaces();
  TopTracks();
  TopPools();
  Video();
  UpcomingRaces();
  ReferFriend();
  ResultedTabInteractions();
  Selection();
  SocialShare();
  Tracks();
  WatchLivePage();
  TutorialsFdr();
  Tutorials();
  WagerPad();
  WagerRewards();
  LoginModal();
  RaceCell();
  HomePage();
  GlobalWallet();
  Derby();
  CrossProductNavigation();
  EducationalHub();
  VideoPlayer();
  PickBetsOnBoarding();
  RacePage();
  Header();
};
