import { TopTrackRace } from "@tvg/ts-types/Track";
import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  SCROLL_INTERACTION = "TOP_TRACKS_SCROLL_INTERACTION",
  FAVORITES_INTERACTION = "TOP_TRACKS_FAVORITES_INTERACTION",
  GO_TO_RACE = "TOP_TRACKS:GO_TO_RACE"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    direction: string;
    module: string;
    isGreyhound: boolean;
    trackName: string;
    isAdding: boolean;
    url: string;
    race: TopTrackRace;
  };
}
