import { BaseEventProps } from "../../types";

export enum ModuleEventProps {}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  USER_ACTIONS = "PICKS_ALERTS:USER_ACTIONS",
  TOGGLE_CLICK = "PICKS_ALERTS:TOGGLE_CLICK"
}

export interface PicksAlerts {
  payload: {
    action: "Confirm" | "Cancel" | "Track Alerts" | "Talent Alerts" | "Close";
    toggleStatus: "On" | "Off";
    entityType: "Track" | "Talent";
    name: string;
  };
}
