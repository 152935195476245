import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { BaseEventProps } from "../../types";
import { MODULE, FILTER, LINK_TEXT } from "./constants";

export enum ModuleEventProps {
  PLACE_TYPE = "Place Type",
  TALENT_NAME = "Talent Name",
  POSITION = "Position"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  TPPAGE_EDIT_BET = "TP_TPPAGE_EDIT_BET",
  PROGRAMPAGE_EDIT_BET = "TP_PROGRAMPAGE_EDIT_BET",
  TPPAGE_BET = "TP_TPPAGE_BET",
  PROGRAMPAGE_BET = "TP_PROGRAMPAGE_BET",
  PROGRAMPAGE_BET_SUCCESS = "TP_PROGRAMPAGE_BET_SUCCESS",
  PROGRAMPAGE_BET_ERROR = "TP_PROGRAMPAGE_BET_ERROR",
  PROGRAMPAGE_TPTAB = "TP_PROGRAMPAGE_TPTAB",
  HOMEPAGE_BANNER = "TP_HOMEPAGE_BANNER",
  PROGRAMPAGE_CONFIRM_BET_OK = "TP_PROGRAMPAGE_CONFIRM_BET_OK",
  PROGRAMPAGE_CONFIRM_BET_CLOSE = "TP_PROGRAMPAGE_CONFIRM_BET_CLOSE",
  PROGRAMPAGE_CONFIRM_PHRASE = "TP_PROGRAMPAGE_CONFIRM_PHRASE",
  TALENT_CLICK = "TP_TALENT_CLICK",
  ALERTS_CLICK = "TP_ALERTS_CLICK",
  HP_TP_VIEW_RACE = "HP_TP_VIEW_RACE",
  SEE_NEXT_RACES = "TALENT_PICKS_SEE_NEXT_RACES",
  SEE_PREVIOUS_RACES = "TALENT_PICKS_SEE_PREVIOUS_RACES",
  CARD_CLICKED = "TALENT_PICKS_CARD_CLICKED",
  ADD_TO_BETSLIP = "TALENT_PICKS_ADD_TO_BETSLIP",
  FILTERS_APPLIED = "TALENT_PICKS_FILTERS_APPLIED",
  EMPTY_STATE_VIEWED = "TALENT_PICKS_EMPTY_STATE_VIEWED",
  CTA_CLICKED = "TALENT_PICKS_CTA_CLICKED"
}

export type TPEventData = {
  type:
    | MediatorEventType.PROGRAMPAGE_TPTAB
    | MediatorEventType.HOMEPAGE_BANNER
    | MediatorEventType.PROGRAMPAGE_CONFIRM_BET_CLOSE;
  payload: {
    label: string;
    action?: string;
    tag?: string;
    url?: string;
  };
};

export type TPBetEventData = {
  type:
    | MediatorEventType.TPPAGE_EDIT_BET
    | MediatorEventType.PROGRAMPAGE_EDIT_BET
    | MediatorEventType.TPPAGE_BET
    | MediatorEventType.PROGRAMPAGE_BET
    | MediatorEventType.PROGRAMPAGE_BET_SUCCESS
    | MediatorEventType.PROGRAMPAGE_BET_ERROR
    | MediatorEventType.PROGRAMPAGE_CONFIRM_PHRASE
    | MediatorEventType.PROGRAMPAGE_CONFIRM_BET_OK;
  payload: {
    tag?: string;
    action?: string;
    label?: string;
    race?: {
      mtp?: number;
      raceNumber: string;
      track: {
        trackName: string;
      };
      type: {
        code: string;
      };
    };
    betType?: string;
    betAmount?: number;
    url?: string;
    betId?: string | number;
    talentPickOrigin?: "pickspage" | "programpage";
  };
};

export type TPViewRaceEventData = {
  type: MediatorEventType.HP_TP_VIEW_RACE;
  payload: {
    clickLocation: string;
    url: string;
    talentPick: {
      trackName: string;
      race: string;
      mtp: string;
      raceType: string;
      wagerType: { abbreviation: string };
      talent: { name: string };
    };
  };
};

export type TPTalentEventData = {
  type: MediatorEventType.TALENT_CLICK;
  payload: {
    talentFilterState: "Toggle ON" | "Toggle Off";
    raceURL: string;
    talentName: string;
  };
};

export type TPTModule = (typeof MODULE)[keyof typeof MODULE];

export type TPTCardClickedData = {
  type: MediatorEventType.CARD_CLICKED;
  payload: {
    module: TPTModule;
    raceURL: string;
    linkText: (typeof LINK_TEXT)[keyof typeof LINK_TEXT];
    talentName: string;
    trackName: string;
    raceNumber: number;
    wagerTypeCode: WagerTypeCodesEnum;
    cardPosition: number;
  };
};

export type TPTAddBetslipData = {
  type: MediatorEventType.ADD_TO_BETSLIP;
  payload: {
    module: TPTModule;
    talentName: string;
    trackName: string;
    raceNumber: number;
    wagerTypeCode: WagerTypeCodesEnum;
    cardPosition: number;
    raceURL: string;
    betTotal: string;
  };
};

export type TPTFilterApplied = {
  type: MediatorEventType.FILTERS_APPLIED;
  payload: {
    filterName: (typeof FILTER)[keyof typeof FILTER];
    filterLabel: string;
  };
};
