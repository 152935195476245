import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  NavigateData,
  OpenData,
  LoadData,
  NextData,
  CloseData,
  ModuleEventProps
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(MediatorEventType.NAVIGATE, (data: NavigateData) => {
    // When a user clicks a link to the tutorials content from the Quick Link tile
    // When a user clicks a link to the tutorials content from the Other Links menu in the Account section
    const { destinationUrl, isFromQuickLinks } = data.payload;
    track<EventProps>(EVENT_NAME.NAVIGATE, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: "Tutorials",
      [BaseEventProps.MODULE]: isFromQuickLinks ? "Quick Links" : "Other Links",
      [BaseEventProps.LINK_URL]: destinationUrl // link url path
    });
  });

  // When a user selects a tutorial
  mediator.base.subscribe(MediatorEventType.OPEN, (data: OpenData) => {
    const { name, tutorialCategory } = data.payload;
    track<EventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: "Tutorials",
      [BaseEventProps.EVENT_LABEL]: name, // e.g "Live Streaming"
      [BaseEventProps.MODULE]: "Tutorials",
      [ModuleEventProps.TUTORIAL_CATEGORY]: tutorialCategory // e.g "Live Streaming"
    });
  });

  // When a tutorial screen loads
  mediator.base.subscribe(MediatorEventType.LOAD, (data: LoadData) => {
    const { title, tutorialCategory, pageNumber, totalPages } = data.payload;
    track<EventProps>(EVENT_NAME.LOAD, {
      [BaseEventProps.EVENT_CATEGORY]: "Education",
      [BaseEventProps.EVENT_LABEL]: title, // e.g "Racing Odds"
      [ModuleEventProps.TUTORIAL_CATEGORY]: tutorialCategory, // e.g "How to win"
      [BaseEventProps.MODULE]: "Tutorials",
      [ModuleEventProps.PAGINATION_NUMBER]: pageNumber, // n of page active
      [ModuleEventProps.TOTAL_PAGES]: totalPages, // total number of pages in tutorial
      [ModuleEventProps.ARTICLE_NAME]: title
    });
  });

  // When a user clicks the "Next" CTA
  mediator.base.subscribe(MediatorEventType.NEXT, (data: NextData) => {
    const { buttonText, linkText, tutorialCategory, pageNumber, totalPages } =
      data.payload;
    track<EventProps>(EVENT_NAME.NEXT, {
      [BaseEventProps.EVENT_CATEGORY]: "Tutorials",
      [BaseEventProps.EVENT_LABEL]: buttonText, // e.g "Next"
      [BaseEventProps.LINK_TEXT]: linkText, // e.g "Next"
      [ModuleEventProps.TUTORIAL_CATEGORY]: tutorialCategory, // e.g "How to win"
      [BaseEventProps.MODULE]: "Tutorials",
      [ModuleEventProps.PAGINATION_NUMBER]: pageNumber, // n of page active
      [ModuleEventProps.TOTAL_PAGES]: totalPages // total number of pages in tutorial
    });
  });

  // When a user clicks the x button to close the tutorial
  mediator.base.subscribe(MediatorEventType.CLOSE, (data: CloseData) => {
    const { title, tutorialCategory, pageNumber, totalPages } = data.payload;
    track<EventProps>(EVENT_NAME.CLOSE, {
      [BaseEventProps.EVENT_CATEGORY]: "Tutorials",
      [BaseEventProps.EVENT_LABEL]: title, // e.g "Racing Odds"
      [ModuleEventProps.TUTORIAL_CATEGORY]: tutorialCategory, // e.g "How to win"
      [BaseEventProps.MODULE]: "Tutorials",
      [ModuleEventProps.PAGINATION_NUMBER]: pageNumber, // n of page active
      [ModuleEventProps.TOTAL_PAGES]: totalPages // total number of pages in tutorial
    });
  });
};
