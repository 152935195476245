// @flow

import styled from "styled-components";

import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
`;

export const IndividualSelectWrapper = styled.section`
  position: relative;
  flex: ${({ isYear }) => (isYear ? "3" : "2")};
  margin-right: 8px;
  &:last-of-type {
    margin-right: 0;
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

export const SelectLabel = styled.label`
  font-family: ${fontMedium};
  font-weight: 500;
  font-size: 14px;
  color: ${buildColor("grey", "900")};
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

export const StyledSelect = styled.select.attrs(({ qaLabel }) => ({
  "data-qa-label": qaLabel
}))`
  width: 100%;
  background-color: transparent;
  appearance: none;
  position: relative;
  margin-bottom: 2px;
  outline: none;
  height: 42px;
  border-radius: 2px;
  font-family: ${fontNormal};
  font-size: 14px;
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
  color: ${buildColor("grey", "700")};
  padding: 0 12px;
  padding-right: 45px;
  ${({ error }) =>
    error &&
    `
    box-shadow: none;
    border: 2px solid ${buildColor("orange", "600")};
  `}
  &:focus {
    border: 2px solid ${buildColor("blue_accent", "500")};
  }
  &.selected-item {
    color: ${buildColor("grey", "900")};
  }
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  outline: none;
`;
