import { BaseEventProps } from "../../types";

export enum ModuleEventProps {}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  CHANGE_TAB_RESULTS = "CHANGE_TAB_RESULTS"
}

export interface ResultsChangeTabType {
  type: MediatorEventType;
  payload: {
    textLabel: string;
    raceNumber: number;
    trackName?: string;
  };
}
