import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontMedium } from "../../_static/Typography";

// eslint-disable-next-line
export const Container = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${buildColor("white", "100")};
  overflow-y: scroll;
  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 0 12px 12px;
        `
      : css`
          padding: 20px;
        `}
  ${(props) =>
    props.device === "tablet" &&
    css`
      height: 0;
      min-height: 515px;
    `};
  ${(props) =>
    props.device === "desktop" &&
    css`
      height: 0;
      min-height: 450px;
    `};
`;

Container.Header = styled.div`
  margin-bottom: 20px;
  ${({ hasPadding, device }) =>
    hasPadding &&
    device === "mobile" &&
    css`
      padding-top: 16px;
    `};
  .message-warning {
    ${({ device }) =>
      device === "mobile" &&
      css`
        margin-left: -12px;
        margin-right: -12px;
      `};
    .attempts {
      font-family: ${fontMedium};
      vertical-align: baseline;
    }
  }
`;

Container.Main = styled.div`
  ${({ device }) =>
    device !== "mobile" &&
    css`
      flex: 1;
      displa: flex;
      flex-direction: column;
      .email-recovery-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        button {
          margin-top: auto;
        }
      }
    `}
  ${({ isDateOfBirthPlaceholder }) =>
    isDateOfBirthPlaceholder &&
    css`
      input#dateOfBirth {
        color: ${buildColor("grey", "700")} !important;
      }
    `}
`;

export const GoToHomeLink = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  font-family: ${fontMedium};
`;

export const FooterError = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;
