import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  VIDEO_POSITION = "Video Position",
  VIDEO_PROVIDER = "Video Provider",
  VIDEO_TYPE = "Video Type",
  METHOD = "Method"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  OPEN = "VIDEO:OPEN",
  CLOSE = "VIDEO:CLOSE",
  MUTE = "VIDEO:MUTE",
  PLAY_VIDEO = "VIDEO:PLAY_VIDEO",
  REPLAY_VIDEO = "VIDEO:REPLAY_VIDEO",
  STOP_VIDEO = "VIDEO:STOP_VIDEO",
  SUCCESS = "VIDEO:SUCCESS",
  ERROR = "VIDEO:ERROR",
  SWITCH_STREAMS = "VIDEO:SWITCH_STREAMS"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    error?: string;
    trackName: string;
    raceNumber: string;
    runnerType: string;
    videoType: string; // e.g 'Live Stream' or 'Live Video' or 'Race Replay'
    videoProvider: string; // e.g 'RCN'
    module: string;
    fullscreen: boolean;
    tilt: boolean;
    switchToLive: boolean;
    forward: boolean;
    method?: "click" | "auto_play";
    mtp?: number;
  };
}
