import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PRE_CHECKED = "preChecked"
}

export type EventProps = BaseEventProps & ModuleEventProps;
export enum MediatorEventType {
  WAR_OPTIN = "WAR_OPTIN",
  WAR_OPTIN_SUCCESS = "WAR_OPTIN_SUCCESS",
  WAR_OPTIN_ERROR = "WAR_OPTIN_ERROR",
  WAR_FIND_RACE = "WAR_FIND_RACE",
  WAR_CASH_IN = "WAR_CASH_IN",
  WAR_FILTER = "WAR_FILTER",
  WAR_SEE_ALL_HISTORY = "WAR_SEE_ALL_HISTORY"
}
