import { get, isNumber } from "lodash";

const calcModalContainerOffset = () => {
  let width;
  if (typeof window !== "undefined") {
    width = get(window, "innerWidth");
  }

  return isNumber(width) ? (width - 376) / 2 : 100;
};

export default calcModalContainerOffset;
