import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { MediatorEventType, EventProps, OpenQuickData } from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.OPEN_QUICK,
    (data: OpenQuickData) => {
      track<EventProps>(EVENT_NAME.OPEN_QUICK, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: "Quick Deposit Modal",
        [BaseEventProps.MODULE]: data.payload.module
      });
    }
  );
};
