import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  Banner,
  EventProps,
  ModuleEventProps,
  MediatorEventType,
  BannerCarouselViewedEvent,
  BannerCarouselClickEvent,
  HPTutorialsLearnMoreEvent
} from "./types";
import { EVENTS, EVENT_CATEGORY, MODULE } from "./constants";

const subscribeBannerEvents = () => {
  const getEventPropsDefault = (payload: Banner) => ({
    [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
    [BaseEventProps.MODULE]: MODULE,
    [BaseEventProps.EVENT_LABEL]: payload.bannerName,
    [ModuleEventProps.BANNER_TYPE]: payload.bannerType,
    [ModuleEventProps.BANNER_ID]: payload.bannerId,
    [ModuleEventProps.BANNER_NAME]: payload.bannerName
  });

  mediator.base.subscribe(
    MediatorEventType.BANNER_CAROUSEL_VIEWED,
    (data: BannerCarouselViewedEvent) =>
      track<EventProps>(EVENTS.BANNER_CAROUSEL_VIEWED, {
        ...getEventPropsDefault(data.payload)
      })
  );

  mediator.base.subscribe(
    MediatorEventType.BANNER_CAROUSEL_CLICKED,
    (data: BannerCarouselClickEvent) =>
      track<EventProps>(EVENTS.BANNER_CAROUSEL_CLICKED, {
        ...getEventPropsDefault(data.payload),
        [BaseEventProps.LINK_URL]: data.payload.linkUrl,
        [BaseEventProps.LINK_TEXT]: data.payload.linkText
      })
  );
};

export default () => {
  subscribeBannerEvents();

  mediator.base.subscribe(
    MediatorEventType.HOMEPAGE_TUTORIALS_LEARN_MORE,
    (data: HPTutorialsLearnMoreEvent) =>
      track<EventProps>(EVENTS.EDUCATION_ARTICLE_SELECTED, {
        [BaseEventProps.MODULE]: data.payload.subheading,
        [ModuleEventProps.EDUCATION_CATEGORY]: data.payload.subheading,
        [ModuleEventProps.ARTICLE_NAME]: data.payload.article
      })
  );
};
