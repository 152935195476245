import _ from "lodash";
import * as mediator from "@tvg/mediator-classic/src";

export function loginHandler(payload) {
  if (_.has(payload, "data")) {
    mediator.dispatch("USER_ACTION", {
      userAction: "Login",
      module: "Header"
    });
    mediator.dispatch("APP_LOGIN", payload.data);
    return payload.data;
  }
  if (_.has(payload, "user")) {
    return payload.user;
  }
  return {};
}

export function logoutHandler() {
  mediator.dispatch("HEADER_DESKTOP_LOGOUT_SUCCESS", {});
  mediator.dispatch("APP_LOGOUT", null);
}

export function loginHandlerRedirect(payload) {
  if (_.has("redirectUrl", payload)) {
    mediator.dispatch("APP_REDIRECT", payload);
  }
}

export function userTransformHandler(user) {
  return {
    username: user.userName || user.UserName,
    accountNumber: user.AccountNumber || user.accountNumber,
    emailAddress: user.EmailAddress || user.emailAddress,
    profile: user.profile || user.profileName,
    accountRoles: user.accountRoles || user.AccountRoles
  };
}
