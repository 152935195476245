import styled, { css } from "styled-components";
import {
  fontBold,
  fontCondensedNormal,
  fontMedium
} from "@tvg/atomic-ui/_static/Typography";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const UserInfoContainer = styled.div`
  padding: 15px 20px 20px 15px;
`;

export const Name = styled.p<{ isLightTheme?: boolean }>`
  text-align: center;
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 19px;
  color: ${({ isLightTheme }) =>
    isLightTheme ? buildColor("grey", "900") : buildColor("white", "100")};
`;

export const BalanceContainer = styled.div<{
  isSubWalletsAvailable?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  ${({ isSubWalletsAvailable }) =>
    isSubWalletsAvailable &&
    css`
      margin-bottom: 32px;
    `}
`;

export const Balance = styled.h1<{ isLightTheme?: boolean }>`
  font-family: ${fontBold};
  font-size: 32px;
  line-height: 37px;
  color: ${({ isLightTheme }) =>
    isLightTheme ? buildColor("grey", "900") : buildColor("white", "100")};
`;

export const HideButton = styled.button<{ isLightTheme?: boolean }>`
  border: none;
  background-color: transparent;
  outline: none;
  text-transform: uppercase;
  font-family: ${fontCondensedNormal};
  font-size: 14px;
  line-height: 18px;
  color: ${({ isLightTheme }) =>
    isLightTheme
      ? buildColor("blue", "500")
      : buildColor("blue_accent", "300")};
`;

export const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const HideBalanceButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 12px;
  height: 37px;
`;
