export default {
  // REDUCERS CONSTANTS
  SET_SESSION_SUCCESS: "SET_SESSION_SUCCESS",
  SET_SESSION_ERROR: "SET_SESSION_ERROR",
  CLEAR_SESSION: "CLEAR_SESSION",
  SET_USER: "SET_USER",
  CMS_LOGIN_MESSAGES: "CMS_LOGIN_MESSAGES",

  // MEDIATOR CONSTANS
  "TVG_LOGIN:DO_LOGIN": "TVG_LOGIN:DO_LOGIN",
  "TVG_LOGIN:DO_LOGIN_FORM": "TVG_LOGIN:DO_LOGIN_FORM",
  "TVG_LOGIN:DO_LOGOUT": "TVG_LOGIN:DO_LOGOUT",
  "TVG_LOGIN:USER_SESSION_UPDATE": "TVG_LOGIN:USER_SESSION_UPDATE",
  "TVG_LOGIN:OPEN_LOGIN_MODAL": "TVG_LOGIN:OPEN_LOGIN_MODAL",
  "TVG_LOGIN:CLOSE_LOGIN_MODAL": "TVG_LOGIN:CLOSE_LOGIN_MODAL",
  "TVG_LOGIN:VALIDATE_SESSION": "TVG_LOGIN:VALIDATE_SESSION",

  // EXTERNAL REDUCERS
  UPDATE_USER_DATA: "UPDATE_USER_DATA",

  // METRICS
  METRIC_USER_LOGIN: "user_login"
};
