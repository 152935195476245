// @flow

import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium } from "../../_static/Typography";
import { Label as LabelInputV2 } from "../InputV2/styled-components";

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ align }) => (align === "left" && "start") || align};
  align-items: initial;
  width: 100%;
  height: ${({ size }) => (size === "large" ? "58px" : "42px")};
  border: 1px solid ${buildColor("blue", "100")};
  border-radius: 2px;
  box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
  padding: ${({ size }) => {
    if (size === "large") return "12px 16px";
    return "0 12px;";
  }};
  ${({ error }) =>
    error &&
    `
    box-shadow: none;
    border: 2px solid ${buildColor("orange", "600")}
  `}
  ${({ isFocused }) =>
    isFocused &&
    `
    box-shadow: none;
    border: 2px solid ${buildColor("blue_accent", "500")}
  `};
`;

export const InputValue = styled.input`
  font-family: ${fontNormal};
  border: 0;
  width: ${({ size }) => (size === "large" ? "18px" : "10px")};
  padding: 0;
  ${(props) => props.partBreak && `padding-left: 12px`};
  outline: none;
  font-size: ${({ size }) => (size === "large" ? "28px" : "14px")};
  color: ${buildColor("grey", "900")};

  /*
    This makes the password dots bigger in the default size
    However the caret is also affected, making it bigger
    Disabled in ios devices
   */
  ${({ isIOS }) =>
    !isIOS &&
    css`
      &[type="password"] {
        font-size: 24px;
        font-weight: bold;
      }
    `}

  & ::placeholder {
    color: ${({ size }) =>
      size === "large" ? buildColor("grey", "300") : buildColor("grey", "700")};
    font-size: ${({ size }) => (size === "large" ? "28px" : "14px")};

    ${({ size }) =>
      size !== "large" &&
      css`
        font-weight: normal;
      `}
  }
`;

export const IconWrapper = styled.button`
  position: absolute;
  ${({ size }) => {
    if (size === "large")
      return css`
        top: 17px;
      `;
    return css`
      top: 50%;
      transform: translateY(-50%);
    `;
  }}
  right: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  outline: none;
  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
    border-radius: 50%;
  }
`;

export const Label = styled(LabelInputV2)`
  margin-bottom: 12px;
  flex-direction: column;
  align-items: start;
`;

export const LabelDescription = styled.span`
  color: ${buildColor("grey", "700")};
  padding-top: 5px;
`;

export const Dash = styled.div`
  margin: auto 12px;
  width: 8px;
  border-radius: 1px;
  border: 1px solid ${buildColor("grey", "700")};
`;

export const SectionTitle = styled.label`
  font-family: ${fontMedium};
  color: ${buildColor("grey", "900")};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 12px;
`;
