import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { buildModule } from "../../utils";
import { MediatorEventType, SocialShareType } from "./types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.CLOSE_SOCIAL_MODAL,
    (data: SocialShareType) =>
      track<BaseEventProps>(EVENT_NAME.SOCIAL_SHARE_MODAL_CLOSED, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.MODULE]: buildModule(
          get(data, "payload.isMyBets", false),
          get(data, "payload.isActiveBet", false)
        )
      })
  );

  mediator.base.subscribe(
    MediatorEventType.SHARE_BET,
    (data: SocialShareType) =>
      track<BaseEventProps>(EVENT_NAME.SHARE_BET_CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: "Share Bet",
        [BaseEventProps.MODULE]: buildModule(
          get(data, "payload.isMyBets", false),
          get(data, "payload.isActiveBet", false)
        )
      })
  );

  mediator.base.subscribe(
    MediatorEventType.OPEN_SOCIAL_MODAL,
    (data: SocialShareType) =>
      track<BaseEventProps>(EVENT_NAME.SOCIAL_SHARE_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.MODULE]: buildModule(
          get(data, "payload.isMyBets", false),
          get(data, "payload.isActiveBet", false)
        )
      })
  );
};
