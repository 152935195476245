import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK = "RG_HOME_CLICK",
  OPTION_SELECT = "RG_OPTION_SELECT_HANDLE",
  BACK = "HEADER_GO_BACK",
  MODAL_OPEN = "RG_BLOCK_MODAL_OPEN",
  MODAL_CLICK = "RG_MODAL_CLICK_HANDLE",
  TIMEOUT = "RG_TIMEOUT_CLICK",
  DEPOSIT_LIMIT_CLICK = "RG_DEPOSIT_LIMIT_CLICK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    gaEventLabel: string;
    locationPath: string;
    destinationUrl: string;
    module: string;
  };
}
