export const EVENT_NAME = {
  FILTER: {
    OPEN: "filter",
    APPLY: "select filter",
    CLEAR: "clear filter"
  },
  DOWNLOAD: {
    CLICK: "download",
    CONFIRM: "download as"
  },
  SELECTION: {
    TIME_FRAME: "select filter",
    DATE_RANGE: "select filter"
  },
  PAGINATION: {
    CLICK: "dropdown",
    NEXT: "next",
    PREV: "previous"
  },
  HEADER_BACK: "return to menu"
};

export const BASE_PATH = "my-account-summary";
