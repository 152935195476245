import { get } from "lodash";
import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  EventProps,
  ModuleEventProps,
  MediatorEventType,
  PageViewData
} from "./types";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.PAGE_VIEW,
    (data: PageViewData): void => {
      track<EventProps>(COMMON_EVENT_NAMES.PAGE_VIEWED, {
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber", ""),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName", ""),
        [ModuleEventProps.RACE_STATUS]: get(data, "payload.raceStatus", ""),
        [ModuleEventProps.MTP]: get(data, "payload.mtp", ""),
        [ModuleEventProps.FDTV_TYPE]: get(data, "payload.fdtvType", "non_fdtv")
      });
    }
  );
};
