export const EVENT_NAME = {
  BET: {
    ERROR: "Bet Error",
    CONFIRM: "Bet Confirm",
    RETRY: "Retry Bet",
    SUCCESS: "Bet Success",
    TIMEOUT: "Bet Time out"
  },
  CLOSE_MODAL: "Closed",
  RETAIN_RUNNERS: "Add To Betslip"
};

export const MODULE = "Bet Modal";

export const LABELS = {
  CONFIRM_MODAL: "Confirm Modal",
  BET_SUCCESS: "Bet Success Modal",
  BET_AMOUNT: "Bet Amount Modal"
};
