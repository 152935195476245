// @flow
import type { Message } from "@tvg/types/Messages";

type RemoveMessageAction = {
  type: "MESSENGER_REMOVE_MESSAGE",
  payload: {
    key: number
  }
};

export type AddMessageAction = {
  type: "MESSENGER_ADD_MESSAGE",
  payload: {
    message: Message
  }
};

export type RemoveAllMessagesAction = {
  type: "MESSENGER_REMOVE_ALL_MESSAGES"
};

export type Actions =
  | AddMessageAction
  | RemoveMessageAction
  | RemoveAllMessagesAction;

export const addMessage = (message: Message): AddMessageAction => ({
  type: "MESSENGER_ADD_MESSAGE",
  payload: { message }
});

export const removeMessage = (key: number): RemoveMessageAction => ({
  type: "MESSENGER_REMOVE_MESSAGE",
  payload: { key }
});

export const removeAllMessages = (): RemoveAllMessagesAction => ({
  type: "MESSENGER_REMOVE_ALL_MESSAGES"
});
