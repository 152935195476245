import axios from "axios";
import React from "react";
import _ from "lodash";
import ServiceUrl from "../../factories/serviceUrl";
import GeoComplySvc from "../GeoComplySvc/GeoComplySvc";

const geoComply = new GeoComplySvc();

/* eslint-disable jsx-a11y/anchor-has-content */
const errors = {
  ACCOUNT_DISABLED: "Your account is disable",
  ACCOUNT_LOCKED: "Your account is locked",
  ACCOUNT_UPDATE_REQUIRED: "Your account require an update",
  BLOCKED_SERVICE: "Fail to login",
  BLOCKED_SOFTWARE: "Fail to login",
  GEO_SERVICE_FAILURE: "Fail to detect your location",
  INVALID_CREDENTIALS: "Your Username/Password are wrong",
  INVALID_GEO: "Invalid location",
  INVALID_GEOPACKET: "Fail to login",
  INVALID_WAGERING_STATE: (
    <span>
      It seems you are trying to access the application from a non legal state.
      Please call <a href="tel://1-888-752-9884">1-888-PLAY-TVG (752-9884)</a>{" "}
      for assistance.
    </span>
  ),
  LOGIN_FAILED: "Fail to login",
  LOGIN_REDIRECT: "Fail to login",
  MISSING_TOKEN: "Fail to login",
  NON_LEGAL_STATE: "Wager not is allowed in your state",
  OUT_OF_BOUNDARY: "Fail to login",
  SESSION_NOT_FOUND: "Fail to login",
  SESSION_TIMEOUT: "Fail to login",
  UNCONFIRMED_BOUNDARY: "Fail to login",
  USER_SESSION_LIMIT_REACHED: "Fail to login"
};

const loginErrorHandle = (error) => {
  if (error.status > 500) {
    return { message: "Login request fail" };
  }
  const errorMessage = _.get(error, "response.data.exception");
  const redirectUrl = _.get(error, "response.data.redirectUrl");
  return { message: errors[errorMessage] || "Fail to login", redirectUrl };
};

const makeLoginRequest = (userData) => {
  const requestOptions = {
    method: "post",
    url: `${ServiceUrl.getServiceUrl("service.usa")}/login`,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": ServiceUrl.getContext()
    },
    data: userData,
    withCredentials: true
  };

  return axios(requestOptions);
};

const makeValidateSessionRequest = () => {
  const requestOptions = {
    method: "get",
    url: `${ServiceUrl.getServiceUrl("service.uam")}/profile`,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": ServiceUrl.getContext(),
      "x-requested-with": "XMLHttpRequest", // we need this some the request works on old request
      accept: "application/json"
    },
    withCredentials: true
  };

  return new Promise((resolve, reject) => {
    axios(requestOptions)
      .then((response) => {
        if (_.has(response, "status")) {
          resolve(response);
        } else {
          reject({ message: "Invalid Session" });
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyPriorLogin = () =>
  typeof window === "object" &&
  window.document.cookie
    .split(";")
    .some((item) => item.includes("hasLoginOnce=true"));

export default {
  /*
   * Login user
   * {object} userData
   * return {Promise} service response
   */
  userLogin: (userData) => {
    if (ServiceUrl.getBrand() === "4njbets") {
      /* set up geoComply instance */
      return new Promise((resolve, reject) => {
        /* Request geo packet */
        geoComply
          .getGeoPacket(userData.username)
          .then((pack) => {
            const newUserData = { ...userData, geo: pack };
            /* Make login with geo packet */
            makeLoginRequest(newUserData)
              .then(() => makeValidateSessionRequest())
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(loginErrorHandle(error));
              });
          })
          .catch((error) => {
            reject(loginErrorHandle(error));
          });
      });
    }
    /* if not 4njbets make login without geoPacket */
    return new Promise((resolve, reject) => {
      makeLoginRequest(userData)
        .then(() => makeValidateSessionRequest())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(loginErrorHandle(error));
        });
    });
  },
  /* Validate user session */
  validateSession: () => makeValidateSessionRequest()
};
