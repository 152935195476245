import mediator from "@tvg/mediator";
import { MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.FEEDBACK_OPEN, () => {
    track<BaseEventProps>(EVENT_NAME.FEEDBACK_WIDGET_OPENED, {
      [BaseEventProps.EVENT_CATEGORY]: "Beta Onboarding",
      [BaseEventProps.EVENT_LABEL]: "Program Page",
      [BaseEventProps.MODULE]: "Program Page Feedback Widget"
    });
  });

  mediator.base.subscribe(MediatorEventType.FEEDBACK_SUBMIT, () => {
    track<BaseEventProps>(EVENT_NAME.FEEDBACK_WIDGET_SUBMITTED, {
      [BaseEventProps.EVENT_CATEGORY]: "Beta Onboarding",
      [BaseEventProps.EVENT_LABEL]: "Program Page",
      [BaseEventProps.MODULE]: "Program Page Feedback Widget"
    });
  });
};
