import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import style from "./style.css";

export default class SubMenu extends Component {
  constructor() {
    super();
    _.bindAll(this, ["subMenuList"]);
  }

  subMenuList() {
    const { subMenus } = this.props;
    return subMenus.map((subMenu) => {
      const classesString = () => `${style.subMenuItem}${subMenu.classes}`;
      return (
        <li className={classesString()} key={`subMenu${subMenu.name}`}>
          <a
            href={subMenu.route}
            className={style.subMenuLink}
            data-qa-label={subMenu.subQaLabel}
            onClick={(event) =>
              this.props.openMenuMethod(
                event,
                subMenu.route,
                subMenu.name,
                this.props.menuName,
                subMenu.options
              )
            }
          >
            {subMenu.name}
            {subMenu.newIcon && (
              <span className={style.newSubMenuLabel}>New</span>
            )}
          </a>
        </li>
      );
    });
  }

  render() {
    const menuFirstName = this.props.menuName.split(" ")[0];
    return (
      <ul
        className={`${this.props.className} ${style.subMenuList}`}
        data-qa-label={`${menuFirstName}SubMenu`}
      >
        {this.subMenuList()}
      </ul>
    );
  }
}

SubMenu.propTypes = {
  menuName: PropTypes.string,
  subMenus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      route: PropTypes.string
    })
  ),
  openMenuMethod: PropTypes.func,
  className: PropTypes.string
};

SubMenu.defaultProps = {
  subMenus: [],
  openMenuMethod: false,
  className: "",
  menuName: ""
};
