import tvgConf from "@tvg/conf";
import { get } from "lodash";
import { EnvKey, KeysList } from "./types";

const KEYS_LIST: KeysList = {
  qa: {
    fdr_fe: {
      web: "client-sJeHwbwoOdpgTjlausdjWW5szEQK3PMG"
    },
    tvg: {
      web: "client-jnKlEobFN5VznDxIalCa79Cxx11b03PZ"
    },
    sbk: {
      ios: "client-hGUIVcs9WJyi9qBb65hmoxoNWgcwcJdg",
      android: "client-em33htTASmGwBq2PuooW9BGrBaHPL8v3",
      web: "client-YA2sGyaXthgkCAsBCEbrx4OD6Oy02ItY"
    }
  },
  staging: {
    tvg: {
      web: "client-DybuPFka20yiegOqJwv7R3t4TZBFgvzd"
    },
    fdr_fe: {
      web: "client-WGddpVy4j4U8ouXQajgXL5qwQNrjiM0v"
    },
    sbk: {
      ios: "client-hGUIVcs9WJyi9qBb65hmoxoNWgcwcJdg",
      android: "client-em33htTASmGwBq2PuooW9BGrBaHPL8v3",
      web: "client-YA2sGyaXthgkCAsBCEbrx4OD6Oy02ItY"
    }
  },
  production: {
    fdr_fe: {
      web: "client-UxgLTJk47Cc6ce5aUb7gcwUvH1tCcDSp"
    },
    tvg: {
      web: "client-QWuoGcQ3o0cUyK9IvZLiB3BZZ2eSa6QN"
    },
    sbk: {
      ios: "client-25U3VqPyrxX2F1Nc4FmbKBcXTLCXkAi3",
      android: "client-qENtN9i7TECUlin6ww2zU9th6496lKQi",
      web: "client-5xlIVGL8e7Nfnt2H6wIgLm7p8Ked2VTH"
    }
  }
};

const { environment = "production" } = tvgConf();

export const ENV_KEYS: EnvKey = get(KEYS_LIST, environment, KEYS_LIST.qa);
