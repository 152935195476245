import tvgConf from "@tvg/conf";
import { GetAlertsResponse } from "@tvg/ts-types/Alerts";
import { FavoriteTopTracksSuccessResponse } from "@tvg/ts-types/Track";
import getProtocol from "../protocolSetter";
import requester from "../requester";
import { serviceResponse } from "../types";

const ufcService: string = "service.ufc";
const getUfcServiceUrl = (): string => `${tvgConf().config(ufcService)}`;

const getUserFavoriteTracks = (accountId: number): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/tracks`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const setUserFavoriteTrack = (
  accountId: number,
  trackCode: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/tracks/${trackCode}`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const deleteUserFavoriteTrack = (
  accountId: number,
  trackCode: string
): Promise<serviceResponse> =>
  requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/tracks/${trackCode}`,
    method: "delete",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getUserAlerts = (
  accountId: string,
  entityType: string = "race"
): Promise<{ data: GetAlertsResponse; status: number }> =>
  requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/favorites`,
    method: "get",
    params: {
      entityTypes: entityType
    },
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const deleteUserAlert = (
  accountId: string,
  ids: Array<number>
): Promise<{ status: number }> => {
  const idsString = ids
    .reduce((currentValue, accumulator) => `${currentValue}${accumulator},`, "")
    .slice(0, -1);

  return requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/favorites`,
    method: "delete",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    params: { ids: idsString }
  });
};

const setUserAlerts = (
  accountId: string,
  entityNames: string[],
  entityType: string = "race",
  eventTypes: string[] = ["raceResults"]
): Promise<{ status: number }> => {
  const subscriptions = eventTypes.map((subscription) => ({
    eventType: subscription,
    channels: [
      {
        type: "push"
      }
    ]
  }));

  const newAlerts = entityNames.map((entityName) => ({
    entityType,
    entityName,
    subscriptions
  }));

  return requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/favorites`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: newAlerts
  });
};

export const addFavorite = (
  accountId: string,
  entityType: string,
  entityNames: string[]
): Promise<FavoriteTopTracksSuccessResponse> => {
  const newFavorites = entityNames.map((entityName) => ({
    accountId,
    entityType,
    entityName
  }));
  return requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/favorites`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: newFavorites
  });
};

const removeFavorite = (
  accountId: string,
  favoriteId: number | null
): Promise<{ status: number }> =>
  requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/favorites/${favoriteId}`,
    method: "delete",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const addRunnerToStable = (
  accountId: string,
  entityType: string,
  entityIds: string[],
  entityDob: number,
  entityName: string,
  eventTypes: string[] = ["raceResults", "raceDay", "timeToRace"]
): Promise<FavoriteTopTracksSuccessResponse> => {
  const subscriptions = eventTypes.map((subscription) => ({
    eventType: subscription,
    channels: [
      {
        type: "email"
      }
    ]
  }));

  const newFavorites = entityIds.map((entityRunnerId) => ({
    entityType,
    entityRunnerId,
    entityName,
    entityDob,
    subscriptions
  }));
  return requester()({
    url: `${getProtocol()}${getUfcServiceUrl()}/users/${accountId}/favorites`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: newFavorites
  });
};

const UFC_SERVICE = {
  getUserFavoriteTracks,
  setUserFavoriteTrack,
  deleteUserFavoriteTrack,
  getUserAlerts,
  deleteUserAlert,
  setUserAlerts,
  addFavorite,
  removeFavorite,
  addRunnerToStable
};

export default UFC_SERVICE;
