export const EVENT_NAME = {
  OPEN: "Open",
  CONFIRM: "Bet Confirm",
  SUCCESS: "Set Repeats Success",
  BACK: "Back Arrow Clicked",
  SET_MAX: "Set To Max Repeat Applied",
  MODAL_OPEN: "Modal Opened"
};

export const MODULE = "Repeat Bet Modal";
