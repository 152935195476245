import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  URP_RUNNER_SELECTION: "Add To Betslip",
  RESET_ALL_RUNNERS: "Remove Selection From Betslip",
  SELECT_BET_TYPE: "Program Bet Type Selected",
  BET_TIMEOUT: "Bet Time out",
  BET_NOW: "Bet Submit",
  DELETE_BET_TICKET: "All Selections Removed",
  OPEN_RACE_NAVIGATION: "Open",
  HANDICAP_STORE_PRODUCT: "Select",
  HANDICAP_STORE_SEE_ALL: COMMON_EVENT_NAMES.NAVIGATED,
  RUNNER_MODIFIER_FILTER_APPLIED: "Program Filter Applied",
  RUNNER_MODIFIER_SORT_APPLIED: "Program Sort Applied",
  WILL_PAYS_SELECT_OPTION: "Will Pays Option Selected",
  PROBABLES_SELECT_OPTION: "Probables Option Selected",
  BETSLIP_BET_AMOUNT_SELECTED: "Betslip Bet Amount Selected",
  PROGRAM_RUNNER_INFO_MODAL_OPENED: "Program Runner Info Modal Opened",
  PROGRAM_RUNNER_INFO_MODAL_CLOSED: "Program Runner Info Modal Closed",
  FAVORITED: "Track Favorited",
  UNFAVORITED: "Track Unfavorited",
  POOLS_AND_INFO_MODAL_OPENED: "Program Pools And Info Modal Opened",
  POOLS_AND_INFO_TAB_OPENED: "Program Pools And Info Tab Opened",
  HANDICAPPING_TAB_OPENED: "Program Handicapping Tab Opened",
  RUNNER_TAGS_EXPANDED: "Program Runner Tags Expanded",
  PROGRAM_FREE_PP_DOWNLOAD: "Program Free PP Downloaded",
  DROPDOWN_OPENED: "Program Race Selector Opened",
  DROPDOWN_RACE_SELECTED: "Program Race Selected"
};
