import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PLACE_TYPE = "Place Type"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  BET_ERROR = "BET_CONFIRMATION:BET_ERROR",
  BET_CONFIRM = "BET_CONFIRMATION:CONFIRM_BET",
  BET_RETRY = "BET_CONFIRMATION:RETRY_BET",
  BET_SUCCESS = "BET_CONFIRMATION:BET_SUCCESS",
  BET_TIMEOUT = "BET_CONFIRMATION:BET_TIMEOUT",
  CLOSE_MODAL = "BET_CONFIRMATION:CLOSE_CONFIRM_MODAL",
  RETAIN_RUNNERS = "BET_CONFIRMATION:RETAIN_RUNNERS"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    error?: string;
    selections?: string;
    trackName?: string;
    raceNumber?: string;
    betAmount?: number;
    runnerAmount?: number;
    confirmMode?: boolean;
    betType?: string;
    runnerType?: "Horse Racing" | "Greyhound Racing";
    repeatBet?: string;
    module?: string;
  };
}
