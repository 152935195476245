import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import _, { get } from "lodash";
import classNames from "classnames";
import BalanceButton from "@tvg/atomic-ui/_atom/BalanceButton/index";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import Button from "@tvg/ui-bootstrap/components/Buttons/";
import buttons from "@tvg/ui-bootstrap/components/Buttons/index.css";
import Plus from "@tvg/ui-bootstrap/assets/svg/plus.svg";
import PlusGreen from "@tvg/ui-bootstrap/assets/svg/plus-green.svg";
import style from "./style.css";
import BalancePoller from "../../services/BalancePoller/BalancePoller";
import userBalance from "../../actions/balance";
import LoginButtonComp from "../LoginButton/index";
import BetslipButtonComp from "./BetslipButton/index";
import TVGContactSection from "../TVGContactSection/index";
import MyAccountButton from "../MyAccountButton/index";
import WatchLiveButton from "../WatchLiveButton/index";
import ContentCards from "../ContentCards/index";
import { getIsNewQuickDepositButton } from "../../../selectors/header";
import { PreferencesService } from "../../services/Preferences/Preferences";

const signUp = () => (event) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  let rfr = "";

  const searchParams = new URLSearchParams(window.location.search); // eslint-disable-line

  const rfrValue = searchParams.get("rfr");

  if (typeof window !== "undefined" && !!rfrValue) {
    rfr = `?rfr=${rfrValue}`;
  }

  window.location.assign(`/registration${rfr}`);
};

const getBalanceLabel = (balance, showBalanceDeposit) => {
  if (showBalanceDeposit) {
    return (
      <span className={style.depositLabelNum}>
        {balance === undefined || balance === null ? (
          "-"
        ) : (
          <NumberFormat
            value={balance}
            displayType="text"
            thousandSeparator
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
          />
        )}
      </span>
    );
  }

  return <span className={style.depositLabelText}>Quick Deposit</span>;
};

export class ButtonSection extends Component {
  requestBalance = _.throttle(
    () => {
      this.props.dispatch(userBalance(this.props.user));
    },
    2000,
    { trailing: false }
  );

  constructor() {
    super();
    _.bindAll(this, [
      "changeBalanceVisibility",
      "requestBalance",
      "updateBalanceRequest",
      "openQuickDeposit"
    ]);
  }

  componentDidMount() {
    this.updateBalanceRequest();

    mediatorClassic.subscribe(
      "BALANCE_VISIBILITY_CHANGED",
      this.changeBalanceVisibility
    );

    mediatorClassic.subscribe(
      "BALANCE_UPDATE_FORCE",
      this.updateBalanceRequest
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.balancePollerTimer !== this.props.balancePollerTimer) {
      this.updateBalanceRequest(true);
    } else {
      this.updateBalanceRequest();
    }
    mediatorClassic.dispatch("BALANCE_UPDATE", this.props.balance);
  }

  updateBalanceRequest(force = false) {
    if (!get(this.props, "user.accountNumber", "")) {
      BalancePoller.stop();
    } else if (!BalancePoller.alive() || force) {
      BalancePoller.start(
        this.requestBalance,
        this.props.balancePollerTimer * 1000
      );
    }
  }

  changeBalanceVisibility(
    balanceVisibility,
    doRequest = true,
    dispatchEvent = {}
  ) {
    const isLogged = !!get(this.props, "user.accountNumber", "");

    if (doRequest) {
      if (isLogged) {
        this.props.dispatch(
          PreferencesService.setUserPref(
            "balance_is_shown",
            balanceVisibility,
            get(this.props, "user.accountNumber", "")
          )
        );
      }
    } else {
      this.props.dispatch(dispatchEvent);
    }
  }

  getDepositUrl = () => {
    if (typeof window !== "undefined") {
      return `${window.location.pathname}${window.location.search}`;
    }
    return "";
  };

  openQuickDeposit(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.props.enablePawsQuickDeposits) {
      mediator.base.dispatch({ type: "OPEN_QUICK_DEPOSIT" });
      return;
    }

    mediatorClassic.dispatch("HEADER_DESKTOP_OPEN_SECTION", {
      section: "Quick Deposit"
    });
    mediatorClassic.dispatch("OPEN_QUICK_DEPOSIT", {});
  }

  render() {
    const isLogged = !!get(this.props, "user.accountNumber", "");

    const balanceButtonUrl =
      typeof window !== "undefined"
        ? `${window.location.pathname}${window.location.search}`
        : "";

    return (
      <div className={classNames(style.buttonSection, this.props.className)}>
        {this.props.newHeaderExperience &&
          (isLogged ? (
            <Fragment>
              {!this.props.tvgHeaderV2FeatureToggle && (
                <div
                  className={
                    this.props.newQuickDepositButton
                      ? classNames(style.balanceButton)
                      : classNames(style.newBalanceButton)
                  }
                >
                  <BalanceButton
                    balanceAmount={this.props.balance}
                    isBalanceShown={this.props.showBalanceDeposit}
                    onBalanceClick={this.openQuickDeposit}
                    url={balanceButtonUrl}
                    device="desktop"
                    tvgHeaderV2FeatureToggle={
                      this.props.tvgHeaderV2FeatureToggle
                    }
                  />
                </div>
              )}
              {this.props.headerContentCardsInbox && (
                <ContentCards
                  device="desktop"
                  tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
                />
              )}
              <MyAccountButton
                className={style.myAccountButton}
                newHeaderExperience
                tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
              />
              <TVGContactSection
                supportLink={this.props.supportLink}
                supportMessage={this.props.supportMessage}
                newHeaderExperience
                tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
              />
              {this.props.tvgHeaderV2FeatureToggle && (
                <WatchLiveButton
                  tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
                />
              )}

              {this.props.tvgHeaderV2FeatureToggle && (
                <div
                  className={
                    this.props.newQuickDepositButton
                      ? classNames(style.newBalanceButton)
                      : classNames(style.balanceButton)
                  }
                >
                  <BalanceButton
                    url={this.getDepositUrl()}
                    balanceAmount={this.props.balance}
                    isBalanceShown={this.props.showBalanceDeposit}
                    onBalanceClick={this.openQuickDeposit}
                    device="desktop"
                    tvgHeaderV2FeatureToggle={
                      this.props.tvgHeaderV2FeatureToggle
                    }
                  />
                </div>
              )}
              <BetslipButtonComp
                tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
              />
            </Fragment>
          ) : (
            <Fragment>
              <TVGContactSection
                supportLink={this.props.supportLink}
                supportMessage={this.props.supportMessage}
                newHeaderExperience
              />
              <LoginButtonComp />
              <Button
                type="legacy"
                onClick={signUp()}
                content="Sign up"
                qaLabel="signUp"
                className={style.signupBtn}
              />
            </Fragment>
          ))}
        {
          /* TODO: Remove WatchLiveButton component when toggle is no more needed */
          !this.props.newHeaderExperience && <WatchLiveButton />
        }
        {!this.props.newHeaderExperience &&
          (isLogged ? (
            <Fragment>
              <MyAccountButton
                className={style.myAccountButton}
                tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
              />
              <button
                className={classNames(
                  buttons.tvgBtnSecondaryAlt,
                  style.depositButton
                )}
                onClick={this.openQuickDeposit}
              >
                <div className={style.innerFlexWrapper}>
                  <div className={style.balanceContainer}>
                    {getBalanceLabel(
                      this.props.balance,
                      this.props.showBalanceDeposit
                    )}
                    {this.props.showBalanceDeposit && (
                      <span
                        className={
                          this.props.showBalanceDeposit
                            ? style.quickDepositLabel
                            : style.depositLabel
                        }
                      >
                        Quick Deposit
                      </span>
                    )}
                  </div>
                  {this.props.showBalanceDeposit &&
                  !isNaN(this.props.balance) &&
                  this.props.balance < 20 ? (
                    <PlusGreen width="16" height="16" />
                  ) : (
                    <Plus width="16" height="16" />
                  )}
                </div>
              </button>
              <BetslipButtonComp
                tvgHeaderV2FeatureToggle={this.props.tvgHeaderV2FeatureToggle}
              />
            </Fragment>
          ) : (
            <Fragment>
              <LoginButtonComp />
              <Button
                type="legacy"
                onClick={signUp()}
                content="Sign up"
                qaLabel="signUp"
                className={style.signupBtn}
              />
            </Fragment>
          ))}
      </div>
    );
  }
}

ButtonSection.propTypes = {
  dispatch: PropTypes.func,
  showBalanceDeposit: PropTypes.bool,
  balance: PropTypes.string,
  user: PropTypes.shape({}),
  className: PropTypes.string,
  balancePollerTimer: PropTypes.number,
  supportLink: PropTypes.string,
  supportMessage: PropTypes.string
};

ButtonSection.defaultProps = {
  dispatch: () => null,
  showBalanceDeposit: false,
  className: "",
  balance: "",
  balancePollerTimer: 120,
  user: {},
  supportLink: "https://support.tvg.com",
  supportMessage: "help"
};

export default connect((store) => ({
  user: store.userData.user,
  balance: store.balance.balance,
  balancePollerTimer: store.balance.balancePollerTimer,
  showBalanceDeposit: store.header.showBalanceDeposit,
  supportLink: store.header.supportLink,
  newQuickDepositButton: getIsNewQuickDepositButton(store),
  supportMessage: store.header.supportMessage,
  enablePawsQuickDeposits: get(store, "header.features.pawsQuickDeposit", false)
}))(ButtonSection);
