export type BrazeContentCard = {
  id: string;
  title: string;
  description: string;
  imageUrl?: string | null;
  linkText: string;
  url: string;
  clicked: boolean;
  dismissed: boolean;
  dismissible: boolean;
  pinned: boolean;
  viewed: boolean;
  sc?: string;
};

export type SetBrazeContentCardsAction = {
  type: "SET_BRAZE_CONTENT_CARDS";
  payload: {
    unviewed: number;
    totalCards: number;
    cards: BrazeContentCard[];
  };
};

export type DismissBrazeContentCardAction = {
  type: "DISMISS_BRAZE_CONTENT_CARD";
  payload: BrazeContentCard;
};

export type SuccessBrazePollerRequestAction = {
  type: "BRAZE_TIMER_POLLER";
  payload: number;
};

export const setBrazeContentCards = (contentCards: {
  unviewed: number;
  totalCards: number;
  cards: BrazeContentCard[];
}): SetBrazeContentCardsAction => ({
  type: "SET_BRAZE_CONTENT_CARDS",
  payload: contentCards
});

export const dismissBrazeContentcard = (
  card: BrazeContentCard
): DismissBrazeContentCardAction => ({
  type: "DISMISS_BRAZE_CONTENT_CARD",
  payload: card
});

export const successBrazePollerRequest = (
  brazePoller: number
): SuccessBrazePollerRequestAction => ({
  type: "BRAZE_TIMER_POLLER",
  payload: brazePoller
});

export type BrazeActions =
  | SetBrazeContentCardsAction
  | DismissBrazeContentCardAction
  | SuccessBrazePollerRequestAction;
