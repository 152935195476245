// @flow

import React, { PureComponent, Fragment, type Node } from "react";
import type { NullaryFn, UnaryFn, BinaryFn } from "@tvg/types/Functional";
import noop from "lodash";
import type { Device } from "@tvg/types/Device";
import {
  GoogleText,
  MessageBoxContainer,
  LoginFormComponent,
  AsideLabelLink,
  SelectWarnText,
  GoogleTextLink
} from "./styled-components";
import Input, { type LoginFieldStatus } from "../../_molecule/LoginInput";
import Select, { type OptionsType } from "../../_molecule/LoginSelect";
import MessageBox from "../../_molecule/MessageBoxV2";

type Props = {
  passwordPlaceholder?: string,
  noStateSelector: boolean,
  usernameStatus: LoginFieldStatus,
  usernameCallback: BinaryFn<string, string, void>,
  username: string,
  passwordStatus: LoginFieldStatus,
  passwordCallback: BinaryFn<string, string, void>,
  password: string,
  selectCallback: BinaryFn<string, string, void>,
  states: Array<OptionsType>,
  onBlurHandler: UnaryFn<string, void>,
  onEnterKeyHandler: UnaryFn<SyntheticKeyboardEvent<*>, void>,
  stateSelectorStatus: LoginFieldStatus,
  isLoginError: boolean,
  errorTitle: string,
  errorMessage: Node,
  closeModal: NullaryFn<void>,
  recoverLink: string,
  redirectToPRF: UnaryFn<Event, void>,
  mobile: boolean,
  device: Device
};
// $FlowFixMe
const CustomLabel = React.memo(({ text, children }) => (
  <Fragment>
    <span>{text}</span>
    {children}
  </Fragment>
));
export default class LoginForm extends PureComponent<Props> {
  static defaultProps = {
    redirectToPRF: noop,
    isLoginError: false,
    errorTitle: "",
    errorMessage: null,
    usernameStatus: null,
    usernameCallback: noop,
    username: "",
    passwordStatus: null,
    passwordCallback: noop,
    password: "",
    selectCallback: noop,
    stateSelectorStatus: null,
    signUpCallback: noop,
    onBlurHandler: () => {},
    onEnterKeyHandler: noop,
    states: [],
    isProcessing: false,
    noStateSelector: false,
    closeModal: noop,
    recoverLink: "",
    awareMessage:
      "AWARE - Always Wager Responsible - National Program Gamblers Helpline: 1-800-522-4700. Age restrictions apply and you must live in a location where TVG offers wagering services. Void where prohibited. Other restrictions apply.",
    mobile: false,
    device: "mobile"
  };

  usernameBlurHandler = () => this.props.onBlurHandler("username");

  passwordBlurHandler = () => this.props.onBlurHandler("password");

  selBlurHandler = () => this.props.onBlurHandler("state");

  render() {
    return (
      <Fragment>
        {this.props.isLoginError && (
          <MessageBoxContainer device={this.props.device}>
            <MessageBox
              type="error"
              subtype={this.props.device === "mobile" ? "fullWith" : "floating"}
              title={this.props.errorTitle || "Login Failed"}
              message={this.props.errorMessage}
            />
          </MessageBoxContainer>
        )}
        <LoginFormComponent
          name="loginForm"
          action=""
          device={this.props.device}
        >
          <Input
            status={this.props.usernameStatus}
            onChangeHandler={this.props.usernameCallback}
            onBlurHandler={this.usernameBlurHandler}
            value={this.props.username}
            placeholder="Username/Email or Account Number"
            qaLabel="username"
            label="Username/Email or Account Number"
            enterKeyHandler={(e) => this.props.onEnterKeyHandler(e)}
          />
          <Input
            status={this.props.passwordStatus}
            onChangeHandler={this.props.passwordCallback}
            onBlurHandler={this.passwordBlurHandler}
            value={this.props.password}
            type="password"
            placeholder={this.props.passwordPlaceholder}
            qaLabel="password"
            label={
              <CustomLabel
                text={this.props.passwordPlaceholder || "Password or PIN"}
              >
                <AsideLabelLink
                  data-qa-label="forgotCredentialsBtn"
                  to={this.props.recoverLink}
                  onClick={this.props.redirectToPRF}
                >
                  Forgot Credentials?
                </AsideLabelLink>
              </CustomLabel>
            }
            isButtonShown
            enterKeyHandler={(e) => this.props.onEnterKeyHandler(e)}
          />
          {!this.props.noStateSelector && (
            <Select
              options={this.props.states}
              callback={this.props.selectCallback}
              status={this.props.stateSelectorStatus}
              qaLabel="stateSelector"
              label={
                <CustomLabel text="State">
                  <SelectWarnText qaLabel="select-state-warn">
                    By entering this site, I certify that I am currently located
                    in the state selected below.
                  </SelectWarnText>
                </CustomLabel>
              }
              onBlurHandler={this.selBlurHandler}
              enterKeyHandler={(e) => this.props.onEnterKeyHandler(e)}
            />
          )}
          {typeof window !== "undefined" && window.grecaptcha && (
            <GoogleText>
              This site is protected by reCAPTCHA and the Google
              <GoogleTextLink
                href="https://policies.google.com/privacy"
                target="_blank"
              >
                Privacy Policy
              </GoogleTextLink>{" "}
              and
              <GoogleTextLink
                href="https://policies.google.com/terms"
                target="_blank"
              >
                Terms of Service
              </GoogleTextLink>{" "}
              apply.
            </GoogleText>
          )}
        </LoginFormComponent>
      </Fragment>
    );
  }
}
