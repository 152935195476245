import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { parseModule, processTrackName } from "../../utils";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.FAVORITES_INTERACTION,
    (data: EventData) =>
      track<EventProps>(
        get(data, "payload.isAdding")
          ? EVENT_NAME.FAVORITED
          : EVENT_NAME.UNFAVORITED,
        {
          [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
          [BaseEventProps.EVENT_LABEL]: "favourite",
          [BaseEventProps.MODULE]: parseModule(
            get(data, "payload.module", "Tracks")
          )
        }
      )
  );

  mediator.base.subscribe(MediatorEventType.GO_TO_RACE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: `${processTrackName(
        get(data, "payload.trackName")
      )} - R${get(data, "payload.race.number")} - ${get(
        data,
        "payload.race.mtp"
      )}`,
      [BaseEventProps.MODULE]: parseModule(get(data, "payload.module")),
      [BaseEventProps.LINK_URL]: get(data, "payload.url", "-")
    })
  );
};
