import axios from "axios";
import TVGConf from "@tvg/conf";
import _ from "lodash";
import * as mediator from "@tvg/mediator-classic/src";
import ServiceUrl from "../factories/serviceUrl";

export const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest"
});

export function updateUserBalance(balance) {
  return {
    type: "UPDATE_USER_BALANCE",
    payload: balance
  };
}

export function rspCatch(response) {
  if (
    _.get(response, "response.status") === 401 ||
    _.get(response, "response.status") === 403
  ) {
    mediator.dispatch("TVG_LOGIN:DO_LOGOUT", { sessionExpired: true });
  }
  return {
    data: { balance: null }
  };
}

export default function userBalance(user) {
  const url = `${ServiceUrl.getServiceUrl("service.uwt")}/users/${
    user && user.accountNumber
  }/balance?isPoller=true`;
  const requestOptions = {
    method: "get",
    url,
    headers: tvgHeaders(),
    withCredentials: true
  };
  return {
    type: "USER_BALANCE",
    payload: axios(requestOptions).catch((response) => rspCatch(response))
  };
}

export function userBalancePollerTime() {
  const url = `${ServiceUrl.getServiceUrl(
    "service.capi"
  )}/configs/balance-polling-time`;
  const requestOptions = {
    method: "get",
    url,
    headers: tvgHeaders(),
    withCredentials: true
  };
  return {
    type: "USER_BALANCE_POLLER",
    payload: axios(requestOptions)
  };
}
