export enum VariantEnum {
  CONTROL = "control",
  TREATMENT = "treatment",
  OFF = "off"
}

export interface AutoPlayConfig {
  autoPlay: boolean;
  mtpThreshold: number;
}
