import React, { PropsWithChildren } from "react";
import { useTvg5 } from "..";
import { Props } from "../types";

const InitWrapper = ({ children, accountNumber }: PropsWithChildren<Props>) => {
  if (typeof window !== "undefined") {
    useTvg5(accountNumber);
  }

  return <>{children}</>;
};

export default InitWrapper;
