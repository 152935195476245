import { BaseEventProps } from "../../types";

export enum ModuleEventProps {}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  PLACE_BET = "BETSLIP:PLACE_BET",
  SELECT_BET_AMOUNT = "BETSLIP:SELECT_BET_AMOUNT",
  REMOVE_ALL_SELECTIONS = "BETSLIP:REMOVE_ALL_SELECTIONS"
}

// Payload type data
export interface SelectBetAmount {
  type: MediatorEventType;
  payload: {
    amountSelected: string;
  };
}

export interface PlaceBetData {
  type: MediatorEventType;
  payload: {
    raceNumber?: string;
    trackName?: string;
    betType?: string;
    betAmount: number;
    selectionSource?: string;
    selectionRaceType?: string;
    module?: string;
  };
}
