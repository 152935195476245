import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { processTrackName } from "../../utils";

export default () => {
  mediator.base.subscribe(MediatorEventType.SELECT_RUNNER, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: `${processTrackName(
        get(data, "payload.race.trackName")
      )} - R${get(data, "payload.race.raceNumber")} - ${get(
        data,
        "payload.race.mtp"
      )}`,
      [BaseEventProps.MODULE]: "Top races - runner",
      [BaseEventProps.LINK_URL]: get(data, "payload.url")
    })
  );

  mediator.base.subscribe(MediatorEventType.GO_TO_RACE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: `${processTrackName(
        get(data, "payload.race.trackName")
      )} - R${get(data, "payload.race.raceNumber")} - ${get(
        data,
        "payload.race.mtp"
      )}`,
      [BaseEventProps.MODULE]: `Top races - ${get(data, "payload.section")}`,
      [BaseEventProps.LINK_URL]: get(data, "payload.url")
    })
  );
};
