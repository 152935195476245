import React, { Component } from "react";
import _, { get } from "lodash";
import PropTypes from "prop-types";
import LocationRevalidator from "@tvg/revalidate-location";
import { Tvg5InitWrapper } from "@urp/amplitude-experiments";
import LoginController from "@tvg/login-controller";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import requester from "@tvg/api/requester";
import RESPONSE_CODES from "@tvg/utils/responseCodesUtils";
import mediator from "@tvg/mediator";
import conf from "@tvg/conf";
import Poller from "@tvg/poller";
import classNames from "classnames";
import { connect } from "react-redux";
import withRouter from "@tvg/utils/withCustomRouter";
import axios from "axios";
import {
  brazeInitialize,
  brazeChangeUser,
  isBrazeInitialized
} from "@tvg/braze";
import Logo from "@tvg/atomic-ui/_static/Logos";
import { createConversation, events as AlchemerEvents } from "@urp/alchemer";
import { amplitudeInit } from "@urp/amplitude";
import LinkFactory from "../../factories/linkFactory";
import serviceUrl from "../../factories/serviceUrl";
import {
  toggleSideMenu,
  successCmsRequest,
  failCmsRequest,
  successFeaturesRequest,
  failFeaturesRequest
} from "../../actions/header";
import { updateSession, userLogout } from "../../actions/login";
import MenuItems from "../../config/menu-items";
import MenuNavComp from "../../components/MenuNav/index";
import ButtonSectionComp from "../../components/ButtonSection/index";
import TVGContactSection from "../../components/TVGContactSection/index";
import style from "./style.css";
import {
  getRace,
  getNextRace
} from "../../services/ServiceNextRace/serviceNextRace";
import RacesPoller from "../../services/RacesPoller/RacesPoller";
import { userBalancePollerTime } from "../../actions/balance";
import { verifyPriorLogin } from "../../services/Login/Login";
import {
  trackingInit,
  getAmplitudeGlobalProperties,
  amplitudeSessionStart,
  amplitudeSessionEnd
} from "./utils/amplitude";

const getLogoImage = () => {
  switch (serviceUrl.getBrand()) {
    case "4njbets":
      return style.njLogo;
    case "pabets":
      return style.paLogo;
    case "iowa":
      return style.iaLogo;
    default:
      return style.baseLogo;
  }
};
const goHome = (e, route) => {
  e.preventDefault();
  e.stopPropagation();
  if (typeof window === "undefined") {
    return;
  }
  mediatorClassic.dispatch("HEADER_DESKTOP_NAVIGATION", {
    url: `${_.get(window, "location.hostname")}${route}`,
    event: "TVG Home Page"
  });
  mediatorClassic.dispatch("TVG4_NAVIGATION", { route });
  AlchemerEvents.selectTVGLogo();
};

const RefreshPoller = new Poller();

const reloadApp = () => {
  if (typeof window === "undefined") {
    return;
  }
  // reload current location with force fetch from the server
  window.location.reload(true);
};

/* eslint-disable */
const renderContactHeader = (supportLink, supportMessage) => (
  <div className={style.tvgHeaderBase}>
    <div className={style.header}>
      <div className={style.tvgLogoContact}>
        <a
          className={getLogoImage()}
          href="/"
          onClick={(event) => goHome(event, "/")}
        />
      </div>
      <TVGContactSection
        supportLink={supportLink}
        supportMessage={supportMessage}
      />
    </div>
  </div>
);
/* eslint-enable */

const revalidateLocationMessages = `{
  "warning":"We have detected that you are attempting to wager from outside the State of New Jersey.This conduct is in violation of New Jersey state law N.J.S.A 5:12-95.23a.<br /><br />You should immediately cease and desist from attempting to wager from outside of New Jersey.<br /><br />We have retained your user information and future attempts could result in an enforcement action.",
  "failure":"We are unable to confirm your location after repeated attempts and have terminated your session.<br /><br />Please contact customer services at 1-888-752-9884 so we can help you resolve this problem."
}`;

const lhnInHamburgerBtn = (showLHNInHamburgerBtn) =>
  showLHNInHamburgerBtn ? style.default : style.noLHNInHamburgerBtn;

export class Header extends Component {
  constructor(props, context) {
    super(props, context);
    _.bindAll(this, [
      "toggleSideMenu",
      "subscribeNavigation",
      "requestCmsMessages",
      "requestBalanceTimer",
      "loadNextRacePoller",
      "loginFunc"
    ]);
    this.conf = conf();
    mediatorClassic.subscribe("UPDATE_ROUTER", this.subscribeNavigation);
    this.requestCmsMessages();
    this.doLogout = _.throttle(
      () => {
        if (get(this.props, "userData.user.accountNumber", "")) {
          this.props.dispatch(userLogout());
          amplitudeSessionEnd();
        }
      },
      2000,
      { trailing: false }
    );
  }

  conversationCreated = false;

  componentDidMount() {
    const self = this;
    this.handleUnauthorizedRequest();

    this.shouldOpenLoginModal();

    this.loadNextRacePoller();
    mediatorClassic.subscribe("CLOSE_HAMBURGUER", () => {
      this.props.dispatch(toggleSideMenu(false));
      mediatorClassic.dispatch("OPEN_SIDE_MENU", { open: false });
    });

    if (verifyPriorLogin() && !isBrazeInitialized()) {
      brazeInitialize();
    }

    this.requestActiveFeatures();

    // this will poll the /refresh endpoint to check if the app should be reloaded
    // if not ,this will cause a hard refresh on the next page transition
    RefreshPoller.start(
      () =>
        axios
          .get(`${this.conf.config("service.capi")}/devices/desktop`)
          .then(({ data }) => {
            if (data && data.refreshTimestamp) {
              const newRefreshTimestamp = new Date(
                data.refreshTimestamp
              ).getTime();
              if (
                newRefreshTimestamp &&
                newRefreshTimestamp > self.refreshTimestamp
              ) {
                self.hardReload = true;
              }
              self.refreshTimestamp = newRefreshTimestamp;
            }
          }),
      60000
    );

    mediator.base.subscribe("TVG_LOGIN:LOGIN_SUCCESS", ({ userData }) => {
      if (userData) {
        const { accountNumber, homeAddress } = userData;
        createConversation(userData);
        AlchemerEvents.login();
        amplitudeSessionStart({ accountNumber, homeState: homeAddress?.state });
      }
    });

    // This Amplitude instance is not intended to formally collect analytics
    // It is used only to identify TVG4 users and to ease the TVG5 rollout using experiments
    if (this.props.enableAmplitude) {
      amplitudeInit({
        getGlobalProperties: () => getAmplitudeGlobalProperties(this.props)
      });
      trackingInit();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (typeof window === "undefined") {
      return;
    }

    if (
      this.props.userData.logged === nextProps.userData.logged &&
      nextProps.userData.user?.accountNumber !== "" &&
      this.props.userData.user?.accountNumber !==
        nextProps.userData.user?.accountNumber
    ) {
      amplitudeSessionStart(nextProps.userData.user);
    }
  }

  componentDidUpdate(prevProps) {
    const userAccount = get(this.props, "userData.user.accountNumber", "");
    const oldUserAccount = get(this.props, "userData.user.accountNumber");
    this.loadNextRacePoller(prevProps);

    if (!!userAccount && userAccount !== oldUserAccount) {
      brazeChangeUser(this.props.userData.user);
    }

    if (!this.conversationCreated && this.props.userData.logged) {
      this.conversationCreated = true;
      createConversation(this.props.userData.user);
    }
  }

  componentWillUnmount() {
    mediatorClassic.unsubscribe("UPDATE_ROUTER", this.subscribeNavigation);
  }

  getRouteWithDomain(route = "") {
    // eslint-disable-next-line no-template-curly-in-string
    return route.replace("${domain}", this.conf.config("domain.desktop"));
  }

  // SMALL WORKAROUND TO PREVENT A LOT OF CHANGES ON MODALS
  shouldOpenLoginModal = () => {
    if (typeof window !== "undefined") {
      const shouldOpen = sessionStorage.getItem("open_login_modal");

      if (shouldOpen) {
        setTimeout(() => {
          mediatorClassic.dispatch("HEADER_DESKTOP_OPEN_SECTION", {
            section: "Login"
          });

          mediator.base.dispatch({ type: "TVG_LOGIN:OPEN_LOGIN_MODAL" });
        }, 2000);
        sessionStorage.removeItem("open_login_modal");
      }
    }
  };

  sessionVerification = (error) => {
    if (
      this.props.userData.logged &&
      error.response?.status === RESPONSE_CODES.AUTHENTICATION_ERROR &&
      error?.response?.data &&
      error?.response?.data?.code === 100001
    ) {
      mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT" });
      mediatorClassic.dispatch("TVG4_NAVIGATION", {
        route: "/"
      });

      if (typeof window !== "undefined") {
        sessionStorage.setItem("open_login_modal", true);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

      amplitudeSessionEnd();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  };

  getRenderedNavItems(menuItemsConfig = [], menus = []) {
    const newMenus = menus.map((menu) => {
      let newSubmenus = [];
      let newMenuByName = null;

      if (Array.isArray(menuItemsConfig)) {
        newMenuByName = (menuItemsConfig || []).find(
          (newMenu) => newMenu.name.toUpperCase() === menu.name.toUpperCase()
        );
      }

      if (newMenuByName) {
        newSubmenus = (newMenuByName.subMenus || [])
          .map((subMenu) => ({
            ...subMenu,
            route: this.getRouteWithDomain(subMenu.route)
          }))
          .filter((subMenu) => {
            const profileType = _.get(this.props, "userData.user.profile");
            return (
              !_.get(subMenu, "options.brandsException", []).includes(
                this.conf.brand
              ) &&
              !_.get(subMenu, "options.profileTypeException", []).includes(
                profileType
              )
            );
          });
        return { ...menu, subMenus: newSubmenus };
      }

      return menu;
    });
    return newMenus;
  }

  refreshTimestamp;

  hardReload = false;

  requestBalanceTimer() {
    return this.props.dispatch(userBalancePollerTime());
  }

  requestCmsMessages() {
    this.conf
      .getMessages(["Global", "Login"])
      .then((response) => {
        this.props.dispatch(successCmsRequest(response));
      })
      .catch((err) => {
        this.props.dispatch(failCmsRequest(err));
      });
  }

  requestActiveFeatures() {
    this.conf
      .getFeatures()
      .then((response) => {
        this.props.dispatch(successFeaturesRequest(response));
      })
      .catch((err) => {
        this.props.dispatch(failFeaturesRequest(err));
      });
  }

  loginFunc(data) {
    if (data && data.logged) {
      brazeInitialize();
      // ignore every login without profile
      if (_.get(data, "userData.user.profile")) {
        this.props.dispatch(updateSession(data.user));
      }
    } else {
      this.doLogout();
    }
  }

  subscribeNavigation() {
    if (typeof window === "undefined") {
      return;
    }
    const pathWithParameters = window.location.href.replace(
      window.location.origin,
      ""
    );
    if (_.get(this, "url") !== pathWithParameters) {
      // detect when we must hard reload because new build is available
      if (this.hardReload) {
        reloadApp();
      } else {
        this.props.history.replace(pathWithParameters);
      }
    }
  }

  toggleSideMenu() {
    this.props.dispatch(toggleSideMenu(!this.props.sideMenuToggled));
    mediatorClassic.dispatch("OPEN_SIDE_MENU", {
      open: !this.props.sideMenuToggled
    });
  }

  sideMenuToggled() {
    return this.props.sideMenuToggled ? style.crossed : style.normal;
  }

  NextRaceCallBack(race) {
    return () => this.props.dispatch(getRace(race));
  }

  handleUnauthorizedRequest = () => {
    requester().interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => this.sessionVerification(error)
    );

    axios.interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => this.sessionVerification(error)
    );
  };

  loadNextRacePoller(prevProps) {
    if (get(this.props, "userData.user.accountNumber", "")) {
      const newPath = _.get(this.props, "location.pathname", "");
      const isPP =
        newPath.indexOf("/racetracks") > -1 ||
        newPath.indexOf("/greyhounds") > -1;
      const oldPath = _.get(prevProps, "location.pathname", "");
      const wasPP =
        oldPath.indexOf("/racetracks") > -1 ||
        oldPath.indexOf("/greyhounds") > -1;
      const newParams = _.get(this.props, "location.search");
      const oldParams = _.get(prevProps, "location.search");
      if (
        isPP &&
        (!wasPP ||
          newPath !== oldPath ||
          newParams !== oldParams ||
          !RacesPoller.alive())
      ) {
        const trackAbbr = _.get(newPath.split("/"), "[2]");
        RacesPoller.start(
          this.NextRaceCallBack({
            trackAbbr,
            raceNumber: newParams.replace("?race=", "")
          }),
          30000
        );
      } else if (!RacesPoller.alive() || (wasPP && !isPP)) {
        RacesPoller.start(this.NextRaceCallBack(), 30000);
      }
    } else {
      RacesPoller.stop();
    }
  }

  renderStandard(showLHNInHamburgerBtn = true) {
    let renderedNavItems = MenuItems(
      this.conf.config("domain.desktop"),
      this.conf.brand
    );

    if (this.props.features.newHeaderExperienceMenus) {
      renderedNavItems = this.getRenderedNavItems(
        this.props.menuItemsConfig,
        renderedNavItems
      );
    }

    if (this.props.features.newHeaderExperience) {
      renderedNavItems = renderedNavItems.filter(
        (menu) => menu.qaLabel !== "helpButton"
      );
    } else {
      renderedNavItems = renderedNavItems.filter(
        (menu) => menu.qaLabel !== "watchLiveButton"
      );
    }

    // TODO: verify if this should be removed for good!
    if (this.props.features) {
      if (!this.props.features.showAppFAQLink) {
        renderedNavItems = renderedNavItems.map((menu) => {
          if (menu.name !== "Help") {
            return menu;
          }

          return {
            ...menu,
            subMenus: menu.subMenus.filter(
              (subMenu) => subMenu.name !== "TVG FAQ"
            )
          };
        });
      }

      if (this.props.features.enablePromosStoryblok) {
        renderedNavItems = renderedNavItems.map((menu) => {
          if (menu.name === "Promotions") {
            /* eslint-disable-next-line no-param-reassign */
            menu.route = "/promos";
            /* eslint-disable-next-line no-param-reassign */
            menu.options = {};

            /* eslint-disable-next-line no-param-reassign */
            menu.subMenus[0].route = "/promos";
            /* eslint-disable-next-line no-param-reassign */
            menu.subMenus[0].options = "";
          }
          return menu;
        });
      }
    }

    return (
      <div
        className={classNames({
          [style.tvgHeaderBase]: true,
          [style.headerShadow]: this.props.sideMenuToggled
        })}
      >
        <div className={style.header}>
          <div className={classNames(style.tvgLogo, style.tvgLogoStandard)}>
            <button
              className={classNames(
                style.hamburgerBtn,
                this.sideMenuToggled(),
                lhnInHamburgerBtn(showLHNInHamburgerBtn),
                { [style.hamburgerBtnBackground]: this.props.sideMenuToggled }
              )}
              onClick={this.toggleSideMenu}
            >
              <span />
              <span />
              <span />
            </button>
            <a
              data-qa-label="tvgHomeButton"
              href="/"
              onClick={(event) => goHome(event, "/")}
            >
              <Logo
                brand={
                  serviceUrl.getBrand() === "tvg"
                    ? "tvgPoweredByFanduel"
                    : serviceUrl.getBrand()
                }
              />
            </a>
          </div>
          <MenuNavComp
            menuItems={renderedNavItems}
            newHeaderExperienceMenus={
              this.props.features.newHeaderExperienceMenus
            }
            tvgHeaderV2FeatureToggle={
              this.props.features.tvgHeaderV2FeatureToggle
            }
          />
          <ButtonSectionComp
            newHeaderExperience={this.props.features.newHeaderExperience}
            headerContentCardsInbox={
              this.props.features.headerContentCardsInbox
            }
            tvgHeaderV2FeatureToggle={
              this.props.features.tvgHeaderV2FeatureToggle
            }
            className={style.buttonSection}
            history={this.context?.router?.history}
          />
        </div>
      </div>
    );
  }

  renderNextRace() {
    if (typeof window !== "undefined") {
      getNextRace((nextBetableRace) => {
        try {
          window.location = LinkFactory.buildHorseLink(
            nextBetableRace.nextRace[0]
          );
        } catch (e) {
          window.location = "/";
        }
      });
    }

    return this.renderStandard(true);
  }

  renderHeader = () => {
    const mode = _.get(this.props, "mode");
    switch (mode) {
      case "signup":
        return renderContactHeader(
          this.props.supportLink,
          this.props.supportMessage
        );
      case "recoverPassword": {
        return this.renderStandard(true);
      }
      case "nextRace":
        return this.renderNextRace();
      case "noLHNInHamburgerBtn":
        return this.renderStandard(false);
      case "hidden":
        return null;
      default:
        return this.renderStandard(true);
    }
  };

  render() {
    return (
      <Tvg5InitWrapper
        accountNumber={get(this.props, "userData.user.accountNumber", "")}
      >
        {this.renderHeader()}

        <LocationRevalidator
          device="desktop"
          messages={revalidateLocationMessages}
        />

        <LoginController history={this.props.history} />
      </Tvg5InitWrapper>
    );
  }
}

/* eslint-disable */
Header.contextTypes = {
  router: PropTypes.object
};
/* eslint-enable */

Header.propTypes = {
  dispatch: PropTypes.func,
  sideMenuToggled: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.string),
  features: PropTypes.shape({
    showAppFAQLink: PropTypes.bool,
    wagerRewardsApp: PropTypes.bool
  })
};

Header.defaultProps = {
  dispatch: () => {},
  sideMenuToggled: false,
  mode: "standart",
  location: {
    pathname: "",
    search: ""
  },
  user: {},
  features: {
    showAppFAQLink: false
  }
};

export default withRouter(
  connect((store, props) => ({
    mode: props.mode,
    location: props.location,
    userData: store.userData,
    sideMenuToggled: store.header.sideMenuToggled,
    balancePollerTimer: store.balance.balancePollerTimer,
    greyhoundsProfiles: store.header.greyhoundsProfiles,
    features: store.header.features,
    supportEmail: store.header.supportEmail,
    supportLink: store.header.supportLink,
    supportMessage: store.header.supportMessage,
    menuItemsConfig: _.attempt(JSON.parse, [store.header.menuItemsConfig]),
    tvgHeaderV2FeatureToggle: get(
      store,
      "header.features.tvgHeaderV2FeatureToggle"
    ),
    enableAmplitude: get(store, "header.features.useAmplitude", false)
  }))(Header)
);
