import { get } from "lodash";

import mediator from "@tvg/mediator";
import { MediatorEventType, MediatorEventData } from "./types";
import { track } from "../../../amplitude";
import { BaseEventProps } from "../../../types";
import { EVENTS } from "./constants";

export default () => {
  const getDefaultProps = (data: MediatorEventData) => ({
    [BaseEventProps.EVENT_CATEGORY]: "Navigation",
    [BaseEventProps.LINK_TEXT]: get(data, "payload.gaEventLabel", undefined), // pass parameters for promos(promo id/code), watch TVG ('Watch TVG') footer (anchor text - eg 'Deposit Options' or 'Tutorial Videos' or 'Wager Rewards' or 'Watch TVG' or 'Apple App Store' or 'Andriod App Store'), funds ('Deposit Now')
    [BaseEventProps.MODULE]: get(data, "payload.module", undefined), // pass parameters for header ('Promos Header'), footer ('Promos Footer'), funds ('Deposit Options'), watch TVG (set as either 'Promos Header' or 'Promos Footer')
    // or 'promo hub landing', 'promo offer page', 'promo modal'
    [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", undefined) // destination Url
  });

  mediator.base.subscribe(
    MediatorEventType.PROMOS_FOOTER_ICON_CLICK,
    (data: MediatorEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATE_TO, getDefaultProps(data));
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PROMOS_SLOT_PROMO_CLICK,
    (data: MediatorEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATE_TO, getDefaultProps(data));
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PROMOS_CTA_CLICK,
    (data: MediatorEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATE_TO, getDefaultProps(data));
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CTA_NAVIGATION_CLICK,
    (data: MediatorEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATE_TO, getDefaultProps(data));
    }
  );
};
