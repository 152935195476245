// @flow
import React, { Component } from "react";
import classNames from "classnames";
import * as mediator from "@tvg/mediator-classic/src";
import _ from "lodash";
import Notification from "@tvg/ui-bootstrap/components/Notifications";
import cookie from "react-cookie";
import type { ResponseObject } from "../../Types.js.flow";
import style from "./style.css";

import LoginHeader from "./LoginHeader/index.jsx";
import LoginFooter from "./LoginFooter/index.jsx";
import LoginDisclaimer from "./Disclaimer/index.jsx";
import CustomModal from "./CustomModal/index.jsx";
import Username from "./Username/index.jsx";
import Password from "./Password/index.jsx";
import StateSelector from "./StateSelector/index.jsx";
import Utils from "../../utils.jsx";
import CONSTANTS from "../../constants";

type Props = {
  mobile?: boolean,
  dropdown?: boolean,
  cmsMsgs?: Object,
  triggerLogin: (par1: Object, par2?: boolean) => Promise<ResponseObject>,
  closeModal?: () => void,
  isLoadingHandler: (par: boolean) => void,
  callback?: () => void,
  closeDropdownCallback?: () => void
};

class LoginBlock extends Component {
  static defaultProps = {
    mobile: false,
    dropdown: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isFormValid: true,
      errorTitle: "",
      errorMessage: "",
      usernameValue: "",
      passwordValue: "",
      stateValue: "",
      isUsernameValid: true,
      isPasswordValid: true,
      isStateValid: true,
      isUsernameCorrected: false,
      isPasswordCorrected: false,
      isStateCorrected: false,
      isHumanChallengeEnabled:false,
      isLogging: false
    };

    _.bindAll(this, [
      "updateMainState",
      "validateForm",
      "tryToLogin",
      "firstTouchForGTM",
      "onFieldBlur",
      "openRedirectModal",
      "toCloseModal",
      "stateSelect",
      "keyIsPressed"
    ]);

    mediator.subscribe(CONSTANTS["TVG_LOGIN:DO_LOGIN_FORM"], this.tryToLogin);
  }

  state: {
    isFormValid: boolean,
    errorTitle: string,
    errorMessage: string,
    usernameValue: string,
    passwordValue: string,
    stateValue: string,
    isUsernameValid: boolean,
    isPasswordValid: boolean,
    isStateValid: boolean,
    isUsernameCorrected: boolean,
    isPasswordCorrected: boolean,
    isStateCorrected: boolean,
    isHumanChallengeEnabled: boolean,
    isLogging: boolean
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("passedHC", () => {
        this.tryToLogin();
        this.setState({isHumanChallengeEnabled:false});
      });
      window.addEventListener("enableHC", () => {
        this.setState({ isHumanChallengeEnabled: true });
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      isFormValid: true,
      errorTitle: "",
      errorMessage: "",
      usernameValue: "",
      passwordValue: "",
      stateValue: "",
      isUsernameValid: true,
      isPasswordValid: true,
      isStateValid: true,
      isUsernameCorrected: false,
      isPasswordCorrected: false,
      isStateCorrected: false,
      isHumanChallengeEnabled: false
    });

    if (typeof window !== "undefined") {
      window.removeEventListener("passedHC", () => {
        this.setState({ isHumanChallengeEnabled: false });
      });
      window.removeEventListener("enableHC", () => {
        this.setState({ isHumanChallengeEnabled: true });
      });
    }
  }

  updateMainState: () => void;
  validateForm: () => void;
  tryToLogin: () => void;

  updateMainState(myObj: Object) {
    const newState = Object.assign({}, this.state, myObj);
    this.setState({
      ...newState
    });
  }

  toCloseModal(): void {

    this.props.closeModal && this.props.closeModal();

    if (this.props.closeDropdownCallback) {
      this.props.closeDropdownCallback();
    }
  }

  keyIsPressed(e: SyntheticEvent<>): void {
    if (e.charCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.validateForm();
    }
  }

  firstTouchForGTM: () => void;

  firstTouchForGTM() {
    let isFirstTouch = sessionStorage.getItem("isFirstTouch");

    if (!isFirstTouch) {
      this.props.dropdown
        ? mediator.dispatch("HEADER_DESKTOP_LOGIN_START", {})
        : mediator.dispatch("LOGIN_MODAL_START", {});
      isFirstTouch = sessionStorage.setItem("isFirstTouch", "true");
    }
  }

  modalRedirect: Object;

  openRedirectModal(domain: ?string): void {
    this.modalRedirect = Utils.openModal(
      "TVG_LOGIN_REDIRECT",
      <CustomModal targetDomain={domain} type="redirect" />,
      true
    );

    if (this.modalRedirect && this.modalRedirect.open) {
      this.modalRedirect.open();
    }
  }

  tryToLogin() {
    const dataForm = {
      account: this.state.usernameValue,
      pin: this.state.passwordValue,
      stateAbbr: this.state.stateValue
    };

    this.props.isLoadingHandler(true);
    this.setState({isLogging: true});
    this.props
      .triggerLogin(
        { ...dataForm, callback: this.props.callback },
        this.props.dropdown
      )
      .then(
        (response: ResponseObject) => {
          if (response) {
            if (response.error) {
              if (response.error.redirectUrl) {
                this.props.isLoadingHandler(false);
                this.setState({isLogging: false});
                this.toCloseModal();
                this.openRedirectModal(_.get(response.error, "redirectUrl"));
                return;
              }

              this.setState({
                isFormValid: false,
                errorTitle: response.error.title,
                errorMessage: response.error.message,
                isUsernameCorrected: false,
                isPasswordCorrected: false,
                isStateCorrected: false
              });
              this.props.dropdown
                ? mediator.dispatch("HEADER_DESKTOP_LOGIN_ERROR", {
                    error: _.get(response.error, "message"), errorTitle: _.get(response.error, "title")
                  })
                : mediator.dispatch("LOGIN_MODAL_LOGIN_ERROR", {
                    error: _.get(response.error, "message")
                  });
              this.props.isLoadingHandler(false);
              this.setState({isLogging: false});
            }

            if (response.success) {
              if (!this.props.dropdown) {
                this.setState({
                  isFormValid: true,
                  errorMessage: ""
                });
              }

              this.props.isLoadingHandler(false);
              this.setState({isLogging: false});

              this.toCloseModal();

              const profile = _.get(
                response.success,
                "data.userDetails.profile"
              );
              const domain = window.location.hostname;
              const domainForCookie = domain.substring(domain.indexOf("."));

              cookie.load("wp", { domain: domainForCookie }) &&
                cookie.remove("wp", { domain: domainForCookie });
              cookie.save("wp", profile, { domain: domainForCookie });

              if(typeof window !== "undefined"){
                window._pxParam5= _.get(
                  response.success,
                  "data.userDetails.accountNumber"
                );
              }

              this.props.dropdown
                ? mediator.dispatch("HEADER_DESKTOP_LOGIN_SUCCESS", {
                    payload: {
                      accountId: _.get(
                        response.success,
                        "data.userDetails.accountNumber"
                      )
                    }
                  })
                : mediator.dispatch("LOGIN_MODAL_SUCCESS", {
                    accountId: _.get(
                      response.success,
                      "data.userDetails.accountNumber"
                    )
                  });
            }
          }
        },
        // handle error
        response => {
          this.props.isLoadingHandler(false);
          this.setState({isLogging: false});
          if (response && response.redirectUrl) {
            this.toCloseModal();
            this.openRedirectModal(response.redirectUrl);
            return;
          }

          this.setState({
            isFormValid: false,
            errorMessage: response.error ? response.error.message : ""
          });
        }
      );
  }

  checkPasswordPattern: () => boolean;
  checkPasswordPattern(password: string) {
    return (
      password.length > 0 && password.length < 21 && /^[\\S]*/.test(password)
    );
  }

  checkPattern: () => boolean;
  checkPattern(testString: string) {
    return /^([A-Za-z0-9]+){1,20}/.test(testString);
  }

  validateForm() {
    this.props.dropdown
      ? mediator.dispatch("HEADER_DESKTOP_LOGIN_ATTEMPT", {})
      : mediator.dispatch("LOGIN_MODAL_LOGIN_ATTEMPT", {});

    const objectWithValidation = {
      isUsernameValid: this.checkPattern(this.state.usernameValue),
      isPasswordValid: this.checkPasswordPattern(this.state.passwordValue),
      isStateValid: this.state.stateValue !== ""
    };

    this.setState({
      ...objectWithValidation
    });

    const allInputsCorrect =
      objectWithValidation.isUsernameValid &&
      objectWithValidation.isPasswordValid &&
      objectWithValidation.isStateValid;

    if (allInputsCorrect) {
      this.tryToLogin();
    } else {
      this.props.dropdown
        ? mediator.dispatch("HEADER_DESKTOP_LOGIN_ERROR", {
            error: "Login form is not filled correctly"
          })
        : mediator.dispatch("LOGIN_MODAL_LOGIN_ERROR", {
            error: "Login form is not filled correctly"
          });
    }
  }

  onFieldBlur: () => void;
  onFieldBlur(field: string, value: string) {
    if (value !== "") {
      this.props.dropdown
        ? mediator.dispatch("HEADER_DESKTOP_FIELD_COMPLETE", { field })
        : mediator.dispatch("LOGIN_MODAL_FIELD_COMPLETE", { field });
    }
  }

  stateSelect: () => void;
  stateSelect(state: string) {
    this.props.dropdown
      ? mediator.dispatch("HEADER_DESKTOP_SELECT_STATE", { state })
      : mediator.dispatch("LOGIN_MODAL_SELECT_STATE", { state });
  }

  render() {
    const loginStyle = classNames({
      [style.loginBlock]: true,
      [style.loginBlockMobile]: this.props.mobile,
      [style.loginBlockDropdown]: this.props.dropdown
    });

    const mobileFormStyle = classNames({
      [style.mobileFormStyle]: this.props.mobile,
      [style.formStyle]: true
    });

    const errMsg = this.state.errorMessage
      ? ""
      : 'An error occurred and we could not log you in at this time. Please <a target="_blank" href="redirectengine?type=messageus" style="color:inherit; text-decoration: underline; font-weight:bold;">message our customer service</a> for assistance.';

    const notificationTitle = this.state.errorTitle;

    return (
      <div className={loginStyle}>
        {!this.state.isFormValid && !this.state.isHumanChallengeEnabled && !this.state.isLogging ? (
          <Notification
            type="error"
            icon
            title={notificationTitle}
            message={`${this.state.errorMessage}${errMsg}`}
            show
          />
        ) : null}
        <div className={mobileFormStyle}>
          {this.props.mobile || this.props.dropdown ? null : <LoginHeader />}
          <form name="loginForm" action="">
            <div className={style.loginBlockContainer}>
              <Username
                isUsernameValid={this.state.isUsernameValid}
                usernameValue={this.state.usernameValue}
                updateMainState={this.updateMainState}
                firstTouchForGTM={this.firstTouchForGTM}
                onFieldBlur={this.onFieldBlur}
                isUsernameCorrected={this.state.isUsernameCorrected}
                keyIsPressed={this.keyIsPressed}
              />
              <Password
                isPasswordValid={this.state.isPasswordValid}
                passwordValue={this.state.passwordValue}
                updateMainState={this.updateMainState}
                firstTouchForGTM={this.firstTouchForGTM}
                onFieldBlur={this.onFieldBlur}
                isPasswordCorrected={this.state.isPasswordCorrected}
                keyIsPressed={this.keyIsPressed}
              />
              <StateSelector
                isStateValid={this.state.isStateValid}
                stateValue={this.state.stateValue}
                updateMainState={this.updateMainState}
                firstTouchForGTM={this.firstTouchForGTM}
                isStateCorrected={this.state.isStateCorrected}
                stateSelect={this.stateSelect}
                keyIsPressed={this.keyIsPressed}
                dropdown={this.props.dropdown}
              />
            </div>
            {this.props.mobile && (
              <LoginDisclaimer cmsMsgs={this.props.cmsMsgs} />
            )}
          </form>
        </div>
        <LoginFooter
          isHumanChallengeEnabled={this.state.isHumanChallengeEnabled}
          cmsMsgs={this.props.cmsMsgs}
          validateForm={this.validateForm}
          mobile={this.props.mobile}
          closeModal={this.props.closeModal}
          closeDropdownCallback={this.props.closeDropdownCallback}
        />
      </div>
    );
  }
}

export default LoginBlock;
