import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.FIND_MORE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.FIND_MORE, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: data.payload.tutorialName
    })
  );

  mediator.base.subscribe(MediatorEventType.NAVIGATE, (data: EventData) =>
    track<EventProps>(get(data, "payload.orientation"), {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.LINK_TEXT]: undefined,
      [BaseEventProps.MODULE]: data.payload.tutorialName,
      [BaseEventProps.TAG]: data.payload.currentSlide
    })
  );

  mediator.base.subscribe(MediatorEventType.COMPLETE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: data.payload.destination,
      [BaseEventProps.MODULE]: data.payload.tutorialName,
      [BaseEventProps.LINK_URL]: data.payload.destination
    })
  );
};
