import { get } from "lodash";
import { Props, State } from "@tvg/ts-types/Login";
import { initPromoOnboardingPoller } from "@tvg/sh-lib-promos-onboarding/utils/initPromoOnboardingPoller";
import { UnaryFn } from "@tvg/ts-types/Functional";

export const userPromoOnboarding = (
  props: Props,
  prevProps: Props,
  state: State,
  prevState: State,
  setState: UnaryFn<unknown, void>
) => {
  if (props.promoOnboardingToggle) {
    initPromoOnboardingPoller(
      {
        isLogged: get(props, "user.isLogged", false),
        accountNumber: get(props, "user.accountNumber"),
        promoEligible: get(state, "promoEligible"),
        promoOnboardingPollerTime: get(props, "promoOnboardingPollerTime")
      },
      {
        isLogged: get(prevProps, "user.isLogged", false),
        accountNumber: get(prevProps, "user.accountNumber"),
        promoEligible: get(prevState, "promoEligible"),
        promoOnboardingPollerTime: get(prevProps, "promoOnboardingPollerTime")
      },
      setState,
      props.dispatch
    );
  }
};

export default userPromoOnboarding;
