import axios from "axios";
import _ from "lodash";
import cookie from "react-cookie";
import ServiceUrl from "../../factories/serviceUrl";
import {
  nextRacePending,
  nextRaceFulFilled,
  greyhoundRaceFulFilled,
  nextGreyhoundRace,
  nextRaceRejected,
  noNextGreyhounds,
  noNextRaces
} from "../../actions/nextRace";

const DEFAULT_WP = "PORT-Generic";

const getSelectedRace = (races, selectedRace) => {
  const result = _.filter(
    races,
    (possibleRace) => possibleRace.number === selectedRace
  );
  if (result.length) {
    return result[0];
  }
  return races[0];
};

const getWagerProfileFromBrand = () => {
  switch (ServiceUrl.getBrand()) {
    case "4njbets":
      return "PORT-NJ";
    case "pabets":
      return "PORT-PA";
    case "iowa":
      return "PORT-IA";
    default:
      return DEFAULT_WP;
  }
};

const getRaceOptions = (race) => {
  const variables = {
    wagerProfile: cookie.load("wp") || getWagerProfileFromBrand(),
    trackAbbr: race && race.trackAbbr ? race.trackAbbr : "WO",
    raceOpen: true,
    greyhoundRace: true,
    postTimeSortAsc: { byPostTime: "ASC" },
    postTimeSortDesc: { byPostTime: "DESC" },
    pageResults: {
      current: 0,
      results: 1
    }
  };

  const nextRaceQuery = `query getNextRace(
  $wagerProfile: String,
  $trackAbbr: [String],
  $raceOpen: Boolean,
  $greyhoundRace: Boolean,
  $postTimeSortAsc: RaceListSort,
  $postTimeSortDesc: RaceListSort,
  $pageResults: Pagination
  ) {
    selectedRace: tracks (profile: $wagerProfile, filter: {code: $trackAbbr}){
      races (filter:{isOpen: true}, sort: $postTimeSortAsc){
        number
        trackCode
        trackName
        track{
          perfAbbr
        }
        type {
          code
        }
      }
    }
    nextRace: races (profile: $wagerProfile, page: $pageResults, sort: $postTimeSortAsc, filter:{isOpen:$raceOpen}){
      number
      trackCode
      trackName
      track{
        perfAbbr
      }
      type {
        code
      }
    }
    selectedGreyhoundRace: tracks (profile: $wagerProfile, filter: {code: $trackAbbr, isGreyhound:$greyhoundRace}){
      races (filter:{isOpen: $raceOpen, isGreyhound: $greyhoundRace}, sort: $postTimeSortAsc){
        number
        trackCode
        trackName
        track{
          perfAbbr
        }
        type {
          code
        }
      }
    }
    nextGreyHoundRace: races (profile: $wagerProfile, page: $pageResults, sort: $postTimeSortAsc, filter:{isGreyhound:$greyhoundRace, isOpen:$raceOpen}){
      number
      trackCode
      trackName
      track{
        perfAbbr
      }
      type {
        code
      }
    }
    lastGreyHoundRace: races (profile: $wagerProfile, page: $pageResults, sort: $postTimeSortDesc, filter:{isGreyhound:$greyhoundRace}){
      number
      trackCode
      trackName
      track{
        perfAbbr
      }
      type {
        code
      }
    }
  }`;

  return {
    data: {
      operationName: "getNextRace",
      query: nextRaceQuery,
      variables
    },
    url: ServiceUrl.getServiceUrl("service.graph"),
    method: "POST",
    widthCredentials: true
  };
};

const handleNextRace = (dispatch, response, raceNumber) => {
  if (_.get(response, "data.data.selectedRace[0].races.length")) {
    const result = getSelectedRace(
      response.data.data.selectedRace[0].races,
      raceNumber
    );
    dispatch(nextRaceFulFilled(result));
  } else if (_.get(response, "data.data.nextRace.length")) {
    dispatch(nextRaceFulFilled(response.data.data.nextRace[0]));
  } else {
    dispatch(noNextRaces("No race available"));
  }
};

const handleGreyhound = (dispatch, response, raceNumber) => {
  if (_.get(response, "data.data.selectedGreyhoundRace[0].races.length")) {
    const result = getSelectedRace(
      response.data.data.selectedGreyhoundRace[0].races,
      raceNumber
    );
    dispatch(greyhoundRaceFulFilled(result));
  } else if (_.get(response, "data.data.nextGreyHoundRace.length")) {
    dispatch(greyhoundRaceFulFilled(response.data.data.nextGreyHoundRace[0]));
  } else if (_.get(response, "data.data.lastGreyHoundRace.length")) {
    dispatch(greyhoundRaceFulFilled(response.data.data.lastGreyHoundRace[0]));
  } else {
    dispatch(noNextGreyhounds("No greyhounds available"));
  }
};

const handleNextBetableGreyhoundRace = (dispatch, response) => {
  if (_.get(response, "data.data.nextGreyHoundRace.length")) {
    dispatch(nextGreyhoundRace(response.data.data.nextGreyHoundRace[0]));
  } else if (_.get(response, "data.data.lastGreyHoundRace.length")) {
    dispatch(nextGreyhoundRace(response.data.data.lastGreyHoundRace[0]));
  }
};

export function getRace(race) {
  const raceNumber = race ? race.raceNumber : "";
  const options = getRaceOptions(race);

  return (dispatch) => {
    dispatch(nextRacePending());
    return axios(options)
      .then((response) => {
        handleNextRace(dispatch, response, raceNumber);
        handleGreyhound(dispatch, response, raceNumber);
        handleNextBetableGreyhoundRace(dispatch, response);
      })
      .catch(() => {
        dispatch(nextRaceRejected("Fail fetch next race"));
      });
  };
}

export function getNextRace(callback) {
  const options = getRaceOptions();

  return axios(options)
    .then((response) => {
      let nextBetableRace;

      if (_.get(response, "data.data.nextRace.length")) {
        nextBetableRace = response.data.data;
      }

      callback(nextBetableRace);
    })
    .catch(() => {
      callback();
    });
}
