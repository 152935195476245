import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK_HOME = "FOOTER_CLICK_HOME",
  CLICK_TRACKS = "FOOTER_CLICK_TRACKS",
  CLICK_LIVE = "FOOTER_CLICK_LIVE",
  CLICK_PROMOS = "FOOTER_CLICK_PROMOS",
  CLICK_BETS = "FOOTER_CLICK_BETS",
  CLICK_MORE = "FOOTER_CLICK_MORE",
  CLICK_ACCOUNT = "FOOTER_CLICK_ACCOUNT",
  CLICK_PICKS = "FOOTER_CLICK_PICKS",
  CLICK_LINKS = "FOOTER_CLICK_LINKS"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    destinationUrl: string;
    linkName: string;
  };
}
