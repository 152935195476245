import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CANCEL = "MYBETS:BET_CANCEL",
  SUBMIT = "BET:BET_CANCEL_SUBMIT",
  ERROR = "BET:BET_CANCEL_ERROR",
  SUCCESS = "BET:BET_CANCEL_SUCCESS",
  KEEP_BET = "MYBETS:BET_CANCEL_KEEP_BET",
  MODAL_CLOSED = "MYBETS:CANCEL_BET_MODAL_CLOSED"
}

export interface SuccessAndSumbitData {
  type: MediatorEventType.SUCCESS | MediatorEventType.SUBMIT;
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
    betType: string;
  };
}

export interface ErrorData {
  type: MediatorEventType.ERROR;
  payload: {
    raceNumber?: string;
    trackName?: string;
    betAmount: number;
    betType: string;
    error: string;
  };
}
