import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { MediatorEventType, SelectBetAmount, PlaceBetData } from "./types";
import { EVENT_NAME, MODULE } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(MediatorEventType.REMOVE_ALL_SELECTIONS, () => {
    track<BaseEventProps>(EVENT_NAME.ALL_SELECTION_REMOVED, {
      [BaseEventProps.EVENT_CATEGORY]: "Betslip",
      [BaseEventProps.MODULE]: MODULE
    });
  });

  mediator.base.subscribe(
    MediatorEventType.SELECT_BET_AMOUNT,
    (data: SelectBetAmount): void => {
      track<BaseEventProps>(EVENT_NAME.BET_AMOUNT_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Betslip",
        [BaseEventProps.EVENT_LABEL]: data.payload.amountSelected,
        [BaseEventProps.MODULE]: MODULE
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PLACE_BET,
    (data: PlaceBetData): void => {
      track<BaseEventProps>(EVENT_NAME.BET_SUBMIT, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.MODULE]: data.payload.module || MODULE,
        [BaseEventProps.SELECTION_SOURCE]: data.payload.selectionSource,
        [BaseEventProps.SELECT_RACE_TYPE]: data.payload.selectionRaceType,
        [BaseEventProps.BET_AMOUNT]: data.payload.betAmount,
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.BET_TYPE]: data.payload.betType
      });
    }
  );
};
