import mediator from "@tvg/mediator";
import {
  EventData,
  EventProps,
  MediatorEventType,
  ModuleEventProps
} from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.IMPRESSION,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.IMPRESSION, {
        [BaseEventProps.EVENT_CATEGORY]: "Content Cards",
        [BaseEventProps.EVENT_LABEL]: data?.payload?.id || "",
        [ModuleEventProps.POSITION]: data?.payload?.position || 0,
        [BaseEventProps.TAG]: data?.payload?.pinned ? "Pinned" : "Unpinned",
        [BaseEventProps.MODULE]: "Content Cards"
      })
  );

  mediator.base.subscribe(MediatorEventType.CLICK, (data: EventData): void =>
    track<EventProps>(EVENT_NAME.CLICK, {
      [BaseEventProps.EVENT_CATEGORY]: "Content Cards",
      [BaseEventProps.EVENT_LABEL]: data?.payload?.id || "",
      [ModuleEventProps.POSITION]: data?.payload?.position || 0,
      [BaseEventProps.TAG]: data?.payload?.pinned ? "Pinned" : "Unpinned",
      [BaseEventProps.MODULE]: "Content Cards"
    })
  );

  mediator.base.subscribe(MediatorEventType.DISMISS, (data: EventData): void =>
    track<EventProps>(EVENT_NAME.DISMISS, {
      [BaseEventProps.EVENT_CATEGORY]: "Content Cards",
      [BaseEventProps.EVENT_LABEL]: data?.payload?.id || "",
      [ModuleEventProps.POSITION]: data?.payload?.position || 0,
      [BaseEventProps.TAG]: data?.payload?.pinned ? "Pinned" : "Unpinned",
      [BaseEventProps.MODULE]: "Content Cards"
    })
  );
};
