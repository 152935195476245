import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  EVENT_NAME,
  MODULE_NAME,
  MODULE_NAME_DEFAULT,
  TIMEOUT_AND_EXCLUSIONS
} from "./constants";
import { pathToModuleName } from "./utils";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.CLICK, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: get(data, "payload.gaEventLabel", ""),
      [BaseEventProps.MODULE]: MODULE_NAME_DEFAULT,
      [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
    })
  );

  mediator.base.subscribe(
    MediatorEventType.OPTION_SELECT,
    (data: EventData) => {
      const moduleKey = get(data, "payload.module", "");
      const gaEventLabel = get(MODULE_NAME, moduleKey, "");
      return track<EventProps>(EVENT_NAME.SELECT, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: gaEventLabel,
        [BaseEventProps.MODULE]: MODULE_NAME_DEFAULT
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.BACK, (data: EventData) => {
    const locationPath: string = get(data, "payload.locationPathname", "");
    const module = pathToModuleName(locationPath);

    return track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: get(data, "payload.gaEventLabel", ""),
      [BaseEventProps.MODULE]: module,
      [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
    });
  });

  mediator.base.subscribe(MediatorEventType.MODAL_OPEN, (data: EventData) =>
    track<EventProps>(EVENT_NAME.BLOCK, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.gaEventLabel", ""),
      [BaseEventProps.MODULE]: MODULE_NAME.FUNDING_CONTROLS
    })
  );

  mediator.base.subscribe(MediatorEventType.MODAL_CLICK, (data: EventData) => {
    const moduleKey = get(data, "payload.module", "");
    const module = get(TIMEOUT_AND_EXCLUSIONS, moduleKey)
      ? TIMEOUT_AND_EXCLUSIONS.TO_DEFAULT
      : get(MODULE_NAME, moduleKey);
    return track<EventProps>(EVENT_NAME.SELECT, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.gaEventLabel", ""),
      [BaseEventProps.MODULE]: module
    });
  });

  mediator.base.subscribe(MediatorEventType.TIMEOUT, (data: EventData) =>
    track<EventProps>(EVENT_NAME.REQUEST, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.gaEventLabel", ""),
      [BaseEventProps.MODULE]: MODULE_NAME.TO_DEFAULT
    })
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_LIMIT_CLICK,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.APPLY_LIMIT, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.gaEventLabel", ""),
        [BaseEventProps.MODULE]: MODULE_NAME.DEPOSIT_LIMIT
      })
  );
};
