export interface Variant<V, P> {
  value: V | undefined;
  payload: P | null;
}

export interface EnvKey {
  fdr_fe: {
    web: string;
  };
  tvg: {
    web: string;
  };
  sbk: {
    ios: string;
    android: string;
    web: string;
  };
}

export interface KeysList {
  qa: EnvKey;
  staging: EnvKey;
  production: EnvKey;
}

export enum VariantEnum {
  CONTROL = "control",
  TREATMENT = "treatment"
}
