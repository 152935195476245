export const EVENT_NAME = {
  TRY_NOW_MODAL_VIEWED: "Try Now Modal Viewed",
  TRY_MODAL_CTA_CLICKED: "CTA Clicked",
  EXIT_MODAL_VIEWED: "Exit Modal Viewed",
  EXIT_MODAL_CTA_CLICKED: "CTA Clicked",
  WHATS_NEW_MODAL_VIEWED: "Whats New Modal Viewed",
  WHATS_NEW_MODAL_CTA_CLICKED: "CTA Clicked",
  FEEDBACK_WIDGET_OPENED: "Feedback Widget Opened",
  FEEDBACK_WIDGET_SUBMITTED: "Feedback Widget Submitted",
  ACCOUNT_PANEL_LINK_CLICKED: "Account Panel Link Clicked",
  WELCOME_MODAL_VIEWED: "Welcome Modal Viewed",
  WELCOME_MODAL_CTA_CLICKED: "CTA Clicked"
};
