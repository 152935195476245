import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { MediatorEventType, EventProps } from "./types";
import { EVENT_NAME, MODULE } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(MediatorEventType.UNDO, () => {
    track<EventProps>(EVENT_NAME.UNDO, {
      [BaseEventProps.EVENT_CATEGORY]: "Betslip",
      [BaseEventProps.MODULE]: MODULE
    });
  });
};
