import mediator from "@tvg/mediator";
import { get, capitalize } from "lodash";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  ModuleEventProps,
  SelectPaymentTypeOrMethod,
  NewPaymentTypeSelected,
  DepositWithdrawOptionsModal,
  NavigateDepositWithdrawOptions,
  DepositSuccess,
  DepositNewPaymentMethod,
  WithdrawAddressModal,
  DepositAddressModal,
  WithdrawAddressModalCS,
  OtherPaymentMethodsOpenTab,
  OtherPaymentMethods,
  PaymentMethodSelector,
  PaymentMethodSelectorChange,
  DeletePaymentMethod,
  FirstDepositInteraction,
  WalletPageView,
  UserAccountWalletIconsClick
} from "./types";
import { CUSTOMER_STATUS, EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_VALIDATION_ERROR,
    (data: SelectPaymentTypeOrMethod) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_ERROR, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.errorMessage", ""),
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType", ""),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Withdrawal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_AUTHORIZE_TRANSACTION,
    (data: SelectPaymentTypeOrMethod) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_ATTEMPT, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType", ""),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Withdrawal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SELECT_PAYMENT_TYPE_OR_METHOD,

    (data: SelectPaymentTypeOrMethod) => {
      track<BaseEventProps>(
        get(data, "payload.isNewCard", false)
          ? EVENT_NAME.NEW_PAYMENT_TYPE_SELECTED
          : EVENT_NAME.SAVED_PAYMENT_TYPE_SELECTED,
        {
          [BaseEventProps.EVENT_CATEGORY]: "Wallet",
          [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType", ""),
          [BaseEventProps.MODULE]: "Wallet"
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.NEW_PAYMENT_TYPE_SELECTED,
    (data: NewPaymentTypeSelected) => {
      track<BaseEventProps>(EVENT_NAME.NEW_PAYMENT_TYPE_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.OPEN_PAYMENT_TYPE_MODAL,
    (data: SelectPaymentTypeOrMethod) => {
      track<BaseEventProps>(EVENT_NAME.WALLET_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: "Deposit",
        [BaseEventProps.MODULE]: get(data, "payload.paymentType")
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.WALLET_GO_BACK, () => {
    track<BaseEventProps>(EVENT_NAME.BACK_ARROW_CLICKED, {
      [BaseEventProps.EVENT_CATEGORY]: "Wallet",
      [BaseEventProps.MODULE]: "Wallet"
    });
  });

  // Open or Close deposit/withdraw options modal
  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_WITHDRAW_OPTIONS_MODAL,
    (data: DepositWithdrawOptionsModal) => {
      track<BaseEventProps>(
        get(data, "payload.isOpen")
          ? EVENT_NAME.PAYMENT_DRAW_OPENED
          : EVENT_NAME.PAYMENT_DRAW_CLOSED,
        {
          [BaseEventProps.EVENT_CATEGORY]: "Wallet",
          [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType", ""),
          [BaseEventProps.MODULE]: "Wallet"
        }
      );
    }
  );

  // When user navigates to deposit or withdraw from the wallet
  mediator.base.subscribe(
    MediatorEventType.NAVIGATE_DEPOSIT_WITHDRAW_OPTIONS,
    (data: NavigateDepositWithdrawOptions) => {
      track<BaseEventProps>(EVENT_NAME.NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.LINK_TEXT]: capitalize(
          get(data, "payload.selectedOption") // "Deposit" or "Withdraw"
        ),
        [BaseEventProps.MODULE]: get(data, "payload.paymentType", ""),
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ATTEMPT,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_ATTEMPT, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.CUSTOMER_STATUS]: get(data, "payload.isFirstDeposit")
          ? CUSTOMER_STATUS.NEW
          : CUSTOMER_STATUS.RETURNING, // pass depending on first deposit or not
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Deposit"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_VALIDATION_MESSAGE,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_ERROR, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.validationMessage"),
        [BaseEventProps.CUSTOMER_STATUS]: get(data, "payload.isFirstDeposit")
          ? CUSTOMER_STATUS.NEW
          : CUSTOMER_STATUS.RETURNING, // pass depending on first deposit or not
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Deposit"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ERROR_MESSAGE,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_FAILED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.errorMessage"),
        [BaseEventProps.CUSTOMER_STATUS]: get(data, "payload.isFirstDeposit")
          ? CUSTOMER_STATUS.NEW
          : CUSTOMER_STATUS.RETURNING, // pass depending on first deposit or not
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Deposit"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_SUCCESS,
    (data: DepositSuccess) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.CUSTOMER_STATUS]: get(data, "payload.isFirstDeposit")
          ? CUSTOMER_STATUS.NEW
          : CUSTOMER_STATUS.RETURNING, // pass depending on first deposit or not
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "Deposit",
        [ModuleEventProps.DEPOSIT_AMOUNT]: get(data, "payload.amount"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_CLOSE,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_MODAL_CLOSED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: "Deposit Modal",
        [BaseEventProps.MODULE]: get(data, "payload.isCreate")
          ? "Create"
          : "Deposit",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_CREATION_RETURN,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_BACK_ARROW_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: "Return to creation modal",
        [BaseEventProps.MODULE]: "Deposit",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_DEFAULT_SELECT,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.DEFAULT_AMOUNT_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: "Deposit",
        [BaseEventProps.MODULE]: "Deposit",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_NEW_PAYMENT_METHOD,
    (data: DepositNewPaymentMethod) => {
      track<BaseEventProps>(EVENT_NAME.NEW_PAYMENT_METHOD_SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "wallet"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_CLOSE,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_MODAL_CLOSED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: "Withdrawal",
        [BaseEventProps.MODULE]: "Withdrawal",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_SUCCESS,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "Withdrawal",
        [ModuleEventProps.DEPOSIT_AMOUNT]: get(data, "payload.amount"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_ERROR_MESSAGE,
    (data: NavigateDepositWithdrawOptions) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_FAILED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.errorMessage"),
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Withdrawal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_ADDRESS_MODAL_VIEW,
    (data: WithdrawAddressModal) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: "Address Confirmation",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: get(data, "payload.paymentType")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_ADDRESS_MODAL_USER_SELECTS_ADDRESS,
    (data: WithdrawAddressModal) => {
      track<EventProps>(EVENT_NAME.WITHDRAWAL_ADDRESS_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS,
    (data: WithdrawAddressModal) => {
      track<EventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: "Confirm",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_ADDRESS_MODAL_USER_CLICKS_CANCEL,
    (data: WithdrawAddressModal) => {
      track<EventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: "Cancel",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  // Deposit Addrress gtm events
  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ADDRESS_MODAL_VIEW,
    (data: DepositAddressModal) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: "Address Confirmation",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: get(data, "payload.paymentType")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ADDRESS_MODAL_USER_SELECTS_ADDRESS,
    (data: WithdrawAddressModal) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_ADDRESS_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS,
    (data: DepositAddressModal) => {
      track<EventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: "Confirm",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ADDRESS_MODAL_USER_CLICKS_CANCEL,
    (data: DepositAddressModal) => {
      track<EventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: "Cancel",
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE,
    (data: WithdrawAddressModalCS) => {
      track<EventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.linkText"),
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE,
    (data: WithdrawAddressModalCS) => {
      track<EventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.linkText"),
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountID"),
        [BaseEventProps.MODULE]: "Address Confirmation Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.OTHER_PAYMENT_METHODS_OPEN_TAB,
    (data: OtherPaymentMethodsOpenTab) => {
      track<BaseEventProps>(EVENT_NAME.TAB_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "Other Payment Methods"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.OTHER_PAYMENT_METHODS_CTA_CLICK,
    (data: OtherPaymentMethods) => {
      track<BaseEventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.linkText"),
        [BaseEventProps.MODULE]: "Other Payment Methods"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.OTHER_PAYMENT_METHODS_PDF_DOWNLOAD,
    (data: OtherPaymentMethods) => {
      track<BaseEventProps>(EVENT_NAME.PDF_DOWNLOADED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.linkText"),
        [BaseEventProps.MODULE]: "Other Payment Methods"
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.ADD_PAYMENT_METHOD, () => {
    track<BaseEventProps>(EVENT_NAME.CTA_CLICKED, {
      [BaseEventProps.EVENT_CATEGORY]: "Wallet",
      [BaseEventProps.EVENT_LABEL]: "Add Payment Method",
      [BaseEventProps.MODULE]: "Wallet"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.PAYMENT_SELECTOR_OPEN,
    (data: PaymentMethodSelector) => {
      track<BaseEventProps>(EVENT_NAME.WALLET_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: "Change Payment Method",
        [BaseEventProps.MODULE]: get(data, "payload.currentPaymentType", "")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PAYMENT_SELECTOR_ADD_METHOD,
    (data: PaymentMethodSelector) => {
      track<BaseEventProps>(EVENT_NAME.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: "Add Payment Method",
        [BaseEventProps.MODULE]: get(data, "payload.currentPaymentType", "")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PAYMENT_SELECTOR_CHANGE_METHOD,
    (data: PaymentMethodSelectorChange) => {
      track<BaseEventProps>(EVENT_NAME.CHANGE_PAYMENT_METHOD_SELECTED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(
          data,
          "payload.selectedPaymentType",
          ""
        ),
        [BaseEventProps.MODULE]: get(data, "payload.currentPaymentType", "")
      });
    }
  );

  // When a user clicks the delete payment method button:
  mediator.base.subscribe(
    MediatorEventType.DELETE_PAYMENT_METHOD_BUTTON,
    (data: DeletePaymentMethod) => {
      track<BaseEventProps>(EVENT_NAME.DELETE_PAYMENT_METHOD_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "Wallet"
      });
    }
  );

  // When a user successfully deletes a payment method:
  mediator.base.subscribe(
    MediatorEventType.DELETE_PAYMENT_METHOD_SUCCESS,
    (data: DeletePaymentMethod) => {
      track<BaseEventProps>(EVENT_NAME.PAYMENT_METHOD_DELETED, {
        [BaseEventProps.EVENT_CATEGORY]: "Wallet",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "Wallet"
      });
    }
  );

  // // On first click interaction on a deposit modal when it opens:
  mediator.base.subscribe(
    MediatorEventType.DEPOSIT_FIRST_INTERACTION,
    (data: FirstDepositInteraction) => {
      track<EventProps>(EVENT_NAME.DEPOSIT_START, {
        [BaseEventProps.EVENT_CATEGORY]: "Deposit",
        [BaseEventProps.CUSTOMER_STATUS]: get(data, "payload.isFirstDeposit")
          ? CUSTOMER_STATUS.NEW
          : CUSTOMER_STATUS.RETURNING, // pass depending on first deposit or not
        [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.paymentType"),
        [BaseEventProps.MODULE]: "Deposit",
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId")
      });
    }
  );

  // When a user loads the wallet
  mediator.base.subscribe(
    MediatorEventType.WALLET_PAGEVIEW,
    (data: WalletPageView) => {
      track<BaseEventProps>(EVENT_NAME.WALLET_PAGE_VIEWED, {
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
        [BaseEventProps.RESIDENCE_STATE]: get(data, "payload.residenceState")
      });
    }
  );

  // When a user clicks either on Deposit, Withdraw or Wallet icons on the Account Pannel
  mediator.base.subscribe(
    MediatorEventType.USER_ACCOUNT_WALLET_ICONS_CLICK,
    (data: UserAccountWalletIconsClick) => {
      track<EventProps>(EVENT_NAME.NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.iconText"),
        [BaseEventProps.MODULE]: "Header",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      });
    }
  );
};
