import React, { Component } from "react";
import PropTypes from "prop-types";
import * as mediator from "@tvg/mediator-classic/src";
import { connect } from "react-redux";
import _ from "lodash";
import classNames from "classnames";
import buttons from "@tvg/ui-bootstrap/components/Buttons/index.css";
import { buildColor } from "@tvg/design-system/src/_static/colorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { betTicket } from "@tvg/atomic-ui/_static/Icons/icons";
import Betticket from "@tvg/ui-bootstrap/assets/svg/betticket.svg";
import style from "./style.css";

const windowConfig = {
  toolbar: 0,
  location: 0,
  status: 0,
  menubar: 0,
  scrollbars: 1,
  resizable: 0,
  top: 0,
  left: 0,
  width: "480px",
  height: "800px"
};

export class BetslipButton extends Component {
  constructor() {
    super();
    _.bindAll(this, ["nextRaceName", "openBetSlipWindow"]);

    this.windowConfig = Object.keys(windowConfig)
      .map((key) => `${key}=${windowConfig[key]}`)
      .join(",");
  }

  nextRaceName() {
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      const isGreyhounds = path.includes("greyhounds");
      if (isGreyhounds) {
        if (
          _.get(this.props, "nextRace.greyhoundRace.trackCode") &&
          _.get(this.props, "nextRace.error") === null
        ) {
          return `(${this.props.nextRace.greyhoundRace.trackCode})`;
        }
      } else if (_.get(this.props, "nextRace.nextRace.trackCode")) {
        return `(${this.props.nextRace.nextRace.trackCode})`;
      }
    }
    return "";
  }

  openBetSlipWindow() {
    if (typeof window !== "undefined") {
      const path = window.location.pathname;
      const isGreyhounds = path.includes("greyhounds");
      const link = isGreyhounds
        ? this.props.nextRace.betslipGreyhoundRaceLink
        : this.props.nextRace.nextBetslipRaceLink;

      mediator.dispatch("HEADER_DESKTOP_NAVIGATION", {
        url: `${_.get(window, "location.hostname")}${link}`,
        event: "Create bet"
      });

      window.open(link, "_blank", this.windowConfig);
    }
  }

  render() {
    const nextRaceAbbr = this.nextRaceName();
    return (
      <button
        className={
          this.props.tvgHeaderV2FeatureToggle
            ? classNames(
                buttons.tvgBtnMarketing,
                style.betslipButtonV2,
                buttons.iconLeft
              )
            : classNames(
                buttons.tvgBtnMarketing,
                style.betslipButton,
                buttons.iconLeft
              )
        }
        onClick={this.openBetSlipWindow}
        disabled={nextRaceAbbr === ""}
      >
        <div className={style.innerFlexWrapper}>
          {this.props.tvgHeaderV2FeatureToggle ? (
            <React.Fragment>
              <Icon
                icon={betTicket}
                color="transparent"
                size={16}
                viewBoxSize={16}
              />
              <span className={style.betButtonLabel}>Create bet</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Betticket width="16" height="16" fill="#333333" />{" "}
              <span className={style.betButtonLabel}>Create a bet</span>
            </React.Fragment>
          )}
        </div>
      </button>
    );
  }
}

BetslipButton.propTypes = {
  nextRace: PropTypes.shape({
    trackName: PropTypes.string,
    nextRace: PropTypes.shape({
      trackCode: PropTypes.string
    }),
    nextBetslipRaceLink: PropTypes.string,
    greyhoundRace: PropTypes.shape({
      trackCode: PropTypes.string
    }),
    betslipGreyhoundRaceLink: PropTypes.string
  })
};

BetslipButton.defaultProps = {
  nextRace: {}
};

export default connect((store) => ({ nextRace: store.nextRace }))(
  BetslipButton
);
