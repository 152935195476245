import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import * as mediator from "@tvg/mediator-classic/src";
import { connect } from "react-redux";
import classNames from "classnames";
import enhanceWithClickOutside from "react-click-outside";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import DownArrow from "@tvg/ui-bootstrap/assets/svg/down-arrow.svg";
import Icon from "@tvg/atomic-ui/_static/Icons";
import Account from "@tvg/ui-bootstrap/assets/svg/account.svg";
import { userOutline } from "@tvg/atomic-ui/_static/Icons/icons";
import MyAccountButtonOverlayComp from "./MyAccountButtonOverlay/index";
import style from "./style.css";
import {
  triggerOverlayChange,
  closeOverlay
} from "../../actions/accountButton";
import { toggleSideMenu } from "../../actions/header";

class MyAccountButton extends Component {
  constructor() {
    super();
    this.state = { isOpened: false };
    _.bindAll(this, ["handleClickOutside", "toggleOverlay"]);
  }

  handleClickOutside() {
    this.props.dispatch(closeOverlay());
  }

  toggleOverlay(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.props.overlayOpen) {
      mediator.dispatch("HEADER_DESKTOP_OPEN_SECTION", {
        section: "My Account"
      });
    }
    this.props.dispatch(toggleSideMenu(false));
    mediator.dispatch("OPEN_SIDE_MENU", { open: false });
    this.props.dispatch(triggerOverlayChange());
  }

  showOverlayArea() {
    return this.props.overlayOpen ? style.showOverlay : style.hideOverlay;
  }

  render() {
    const buttonStyle = classNames({
      [style.activeButton]: this.props.overlayOpen
    });

    return this.props.newHeaderExperience ? (
      <button
        type="button"
        onClick={(event) => this.toggleOverlay(event)}
        className={classNames(style.myAccountIconButton, this.props.className)}
      >
        <div
          className={classNames(style.accountIcon)}
          data-qa-label="myAccountButton"
        >
          <i className={buttonStyle}>
            {this.props.tvgHeaderV2FeatureToggle ? (
              <Icon
                className={classNames(style.userOutlineIcon)}
                icon={userOutline}
                color="transparent"
                size={20}
                viewBoxSize={20}
              />
            ) : (
              <Account
                height="24"
                width="24"
                fill={buildColor("blue", "100")}
              />
            )}
            <span>{this.props.tooltip}</span>
          </i>
        </div>
        <MyAccountButtonOverlayComp
          className={classNames(style.overlayArea, this.showOverlayArea())}
        />
      </button>
    ) : (
      <button
        type="button"
        onClick={(event) => this.toggleOverlay(event)}
        className={classNames(style.myAccountButton, this.props.className)}
      >
        <div
          className={classNames(style.accountLabel)}
          data-qa-label="myAccountButton"
        >
          <i className={!this.props.tvgHeaderV2FeatureToggle && "account"}>
            {this.props.tvgHeaderV2FeatureToggle ? (
              <Icon
                className={classNames(style.userOutlineIcon)}
                icon={userOutline}
                color="transparent"
                size={20}
                viewBoxSize={20}
              />
            ) : (
              <Account height="16" width="16" fill="#fff" />
            )}
            <span>{this.props.tooltip}</span>
          </i>
          <span>Account</span>
          <i className="arrow">
            <DownArrow width="8" height="8" fill="#fff" />
          </i>
        </div>
        <MyAccountButtonOverlayComp
          className={classNames(style.overlayArea, this.showOverlayArea())}
        />
      </button>
    );
  }
}

MyAccountButton.propTypes = {
  dispatch: PropTypes.func,
  overlayOpen: PropTypes.bool,
  tooltip: PropTypes.string,
  className: PropTypes.string
};

MyAccountButton.defaultProps = {
  dispatch: () => null,
  overlayOpen: false,
  tooltip: "Your Account",
  className: "",
  tvgHeaderV2FeatureToggle: false
};

export default connect((store) => ({
  overlayOpen: store.accountButton.overlayOpen,
  sideMenuToggled: store.header.sideMenuToggled
}))(enhanceWithClickOutside(MyAccountButton));
