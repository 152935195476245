import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME, MODULE } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.LINK_CLICK, (data: EventData) =>
    track<EventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "Repeat Bet",
      [BaseEventProps.MODULE]: get(data, "payload.module"),
      [BaseEventProps.TAG]: get(data, "payload.tag")
    })
  );

  mediator.base.subscribe(MediatorEventType.CONFIRM, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CONFIRM, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: MODULE,
      [BaseEventProps.RUNNER_SELECTION_LIST]: get(data, "payload.selections"),
      [BaseEventProps.RACE_TRACK_SELECTION]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER_SELECTION]: get(data, "payload.raceNumber"),
      [BaseEventProps.BET_AMOUNT]: get(data, "payload.betAmount"),
      [BaseEventProps.BET_TYPE]: get(data, "payload.betType"),
      [BaseEventProps.REPEAT_BET]: get(data, "payload.repeatBet")
    })
  );

  mediator.base.subscribe(MediatorEventType.SET_BET, (data: EventData) =>
    track<EventProps>(EVENT_NAME.SUCCESS, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.repeatBet", "1"),
      [BaseEventProps.MODULE]: MODULE
    })
  );

  mediator.base.subscribe(MediatorEventType.BACK, () =>
    track<EventProps>(EVENT_NAME.BACK, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.EVENT_LABEL]: "Return to Confirm Bet Modal",
      [BaseEventProps.MODULE]: MODULE
    })
  );

  mediator.base.subscribe(MediatorEventType.SET_MAX, () =>
    track<EventProps>(EVENT_NAME.SET_MAX, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: MODULE
    })
  );

  mediator.base.subscribe(MediatorEventType.SHOW, () =>
    track<EventProps>(EVENT_NAME.MODAL_OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.EVENT_LABEL]: "Repeat Bet",
      [BaseEventProps.MODULE]: "Bet Placed Modal"
    })
  );
};
