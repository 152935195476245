import { ProgramPageMediatorEventType } from "@tvg/ts-types/Amplitude";
import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  FILTER_TYPE = "Filter Type",
  PLACE_TYPE = "Place Type",
  SORT_TYPE = "Sort Type",
  SORT_NAME = "Sort Name",
  SORT_OPERATION = "Sort Operation"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export interface ProgramPageData {
  type: ProgramPageMediatorEventType;
  payload: {
    add?: boolean;
    tag?: string;
    action?: string;
    selections?: string;
    betAmount?: string;
    runnerType?: string;
    label?: string;
    error?: string;
    trackName?: string;
    raceNumber?: string;
    betType?: string;
    primaryType?: string;
    secondaryType?: string;
    product?: string;
    destinationUrl?: string;
    race?: {
      raceNumber: string;
      track: {
        trackName: string;
      };
      type: {
        code: string;
      };
    };
    isCustomAmount?: boolean;
    module?: string;
    isFavorite?: boolean;
    filter?: string;
    sortOperation?: string;
    sortValue?: string;
    repeatBet?: string;
    runnerTagsVisible?: string;
    runnerName?: string;
  };
}
