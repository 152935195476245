export enum MediatorEventType {
  WAGERPAD_CLICK = "HEADER:WAGERPAD_CLICK",
  WAGERPAD_CTA_CLICKED = "WAGERPAD:CTA_CLICKED",
  WAGERPAD_PAGE_VIEWED = "WAGERPAD:PAGE_VIEWED",
  WAGERPAD_DROPDOWN_SELECTED = "WAGERPAD:DROPDOWN_SELECTED"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    trackName: string;
    raceNumber: string;
    fieldName?: string;
  };
}
