import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  EventProps,
  ModuleEventProps,
  MediatorEventType,
  DerbyHeaderClickEventData,
  DerbyHeaderViewedEventData
} from "./types";
import { EVENTS, EVENT_CATEGORY, MODULE } from "./constants";

export default () => {
  const eventPropsDefault = {
    [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
    [BaseEventProps.MODULE]: MODULE,
    [ModuleEventProps.BANNER_TYPE]: "derby",
    [ModuleEventProps.BANNER_ID]: "homepage/homepage-config"
  };

  mediator.base.subscribe(
    MediatorEventType.HEADER_CLICK,
    (data: DerbyHeaderClickEventData) =>
      track<EventProps>(EVENTS.BANNER_LINK_CLICKED, {
        ...eventPropsDefault,
        [BaseEventProps.EVENT_LABEL]: data.payload.linkText,
        [BaseEventProps.LINK_URL]: data.payload.linkUrl,
        [ModuleEventProps.BANNER_NAME]: data.payload.bannerName,
        [BaseEventProps.LINK_TEXT]: data.payload.linkText
      })
  );

  mediator.base.subscribe(
    MediatorEventType.HEADER_SELECTED_RACE,
    (data: DerbyHeaderClickEventData) =>
      track<EventProps>(EVENTS.BANNER_LINK_CLICKED, {
        ...eventPropsDefault,
        [BaseEventProps.EVENT_LABEL]: "race_selected",
        [BaseEventProps.LINK_URL]: data.payload.linkUrl,
        [ModuleEventProps.BANNER_NAME]: data.payload.bannerName,
        [BaseEventProps.LINK_TEXT]: "race_selected"
      })
  );

  mediator.base.subscribe(
    MediatorEventType.HEADER_VIEWED,
    (data: DerbyHeaderViewedEventData) =>
      track<EventProps>(EVENTS.BANNER_VIEWED, {
        ...eventPropsDefault,
        [BaseEventProps.EVENT_LABEL]: "derby_banner",
        [ModuleEventProps.BANNER_NAME]: data.payload.bannerName
      })
  );
};
