export default function reducer(
  state = {
    balance: null,
    loading: false,
    balancePollerTimer: 120
  },
  action
) {
  switch (action.type) {
    case "USER_BALANCE_PENDING": {
      return { ...state, loading: true };
    }
    case "USER_BALANCE_FULFILLED": {
      return { ...state, balance: action.payload.data.balance, loading: false };
    }
    case "USER_BALANCE_REJECTED": {
      return { ...state, balance: null, loading: false };
    }
    case "UPDATE_USER_BALANCE": {
      return { ...state, balance: action.payload, loading: false };
    }
    case "USER_BALANCE_POLLER_FULFILLED": {
      return { ...state, balancePollerTimer: +action.payload.data.value };
    }
    case "USER_BALANCE_POLLER_REJECTED": {
      return state;
    }
    default: {
      return state;
    }
  }
}
