import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  STARTUP: "Startup",
  OPENED: "Opened",
  CLOSED: "Closed",
  CLEARED_FILTER_LIST: "Cleared Filter List",
  SELECTED_FILTER: "Selected Filter",
  SELECTED_FILTER_DATE: "Selected Filter",
  CLEARED_ALL_FILTERS: "Cleared All Filters",
  CLICK_NAVIGATED_TO: COMMON_EVENT_NAMES.NAVIGATED,
  TIMEFRAME_SELECTED_FILTER: "Selected Filter",
  OPEN_FOOTER: "Open",
  KEEP_BET: "Keep Bet",
  CANCEL_BET_SUCCESS: "Cancel Bet Success",
  CANCEL_BET_ERROR: "Cancel Bet Error",
  OPEN_DELETE_BET: "Open",
  DELETE_BET_ERROR: "Delete Bet Error",
  OPENED_SHOW: "My Bets Show Details Opened",
  HIDE: "My Bets Show Details Closed",
  OPENED_SHOW_HIDE: "Opened Show/Hide",
  CLOSED_SHOW_HIDE: "Closed Show/Hide",
  CANCEL_BET_OPEN_MODAL: "Opened Modal",
  RETURN_BETTING_NAVIGATE_TO: COMMON_EVENT_NAMES.NAVIGATED,
  CLOSED_SCREEN: "Closed Screen",
  TRACK_RULES_OPENED_MODAL: "Opened Modal",
  SAW_MESSAGE: "Cancel Bet Modal Viewed",
  WATCH_REPLAY_NAVIGATE_TO: COMMON_EVENT_NAMES.NAVIGATED,
  LIVE_BUTTON_NAVIGATE_TO: COMMON_EVENT_NAMES.NAVIGATED,
  CANCEL_BET_OPENED_MODAL: "Opened Modal",
  TAB_SELECT_OPEN_TAB: "My Bets Tab Opened",
  GA_EVENT_ACTION: "Event Action",
  CREATE_BET: "Create Bet",
  PAYOUT_OPENED_MODAL: "Opened Modal",
  BET_SUCCESS: "Bet Successful",
  TRACK_NAVIGATED_TO: COMMON_EVENT_NAMES.NAVIGATED,
  REPEAT_BET: "Add To Betslip",
  OPEN: "Opened",
  CLOSE: "Closed",
  DELETED: "Deleted",
  NAVIGATED_TO: COMMON_EVENT_NAMES.NAVIGATED
};

export const LABELS = {
  DELETE: "Delete Bet",
  BETSLIP: "Betslip",
  RACE_CARD: "Race Card Active Bets",
  BET: "Bet",
  MY_BETS_TAB: "My Bets Tab"
};
