import * as mediator from "@tvg/mediator-classic/src";
import loginService from "../services/Login/Login";

export function userLoginFulFilled(response) {
  return {
    type: "USER_LOGIN_FULFILLED",
    payload: response
  };
}

export function userLoginRejected(err) {
  return {
    type: "USER_LOGIN_REJECTED",
    payload: err
  };
}

export function userLoginPending() {
  return {
    type: "USER_LOGIN_PENDING"
  };
}

export function userLoginFormError(err) {
  return {
    type: "USER_LOGIN_FORM_ERROR",
    payload: err
  };
}

export function userLoginSaveFormData(formData) {
  return {
    type: "USER_LOGIN_SAVE_FORM_DATA",
    payload: formData
  };
}

export function userLogout() {
  return {
    type: "USER_LOGOUT_FULFILLED"
  };
}

export function sessionReject() {
  return {
    type: "SESSION_REJECTED"
  };
}

export function updateSession(user) {
  return {
    type: "UPDATE_SESSION",
    payload: user
  };
}

export function validateSession() {
  return (dispatch) => {
    loginService
      .validateSession()
      .then((data) => dispatch(updateSession(data)))
      .catch(() => dispatch(sessionReject()));
  };
}

export function login(userData) {
  return (dispatch) => {
    dispatch(userLoginPending());
    return loginService
      .userLogin(userData)
      .then((response) => {
        mediator.dispatch("HEADER_DESKTOP_LOGIN_SUCCESS", {
          module: "Header"
        });
        dispatch(userLoginFulFilled(response));
      })
      .catch((err) => {
        mediator.dispatch("HEADER_DESKTOP_LOGIN_ERROR", {
          error: err
        });
        dispatch(userLoginRejected(err));
      });
  };
}
