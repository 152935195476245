import tvgConf from "@tvg/conf";

const { NODE_ENV = "production" } = process.env;
const isDev = NODE_ENV === "development";

const conf = tvgConf();

const { gcs } = conf.config("google");
const { environment } = conf;

const getPublicPath = (publicPath?: string) =>
  (environment === "staging" || environment === "production") && !isDev
    ? `${gcs.url}/${gcs.tvgStatic}/static/hdr/assets/`
    : publicPath;

export default getPublicPath;
