// @flow
import CONSTANTS from "./constants";
import type { User } from "./Types.js.flow";

type State = {
  logging: boolean,
  logged: boolean,
  error: ?string,
  user: ?User,
  userLogin: boolean,
  userLogout: boolean
};

export const initialState: State = {
  logging: false,
  logged: false,
  error: null,
  user: null,
  userLogin: false,
  userLogout: false
};

const reducers = {
  [CONSTANTS.UPDATE_USER_DATA]: (state: State, payload: Object): State =>
    payload
};

const reducer = (
  state: State = initialState,
  action: { type: string, payload?: * }
): State => {
  const validAction = action || { type: "" };

  return validAction.type && reducers[validAction.type]
    ? reducers[validAction.type](state, validAction.payload)
    : state;
};

export default { userData: reducer };
