import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  NAVIGATION = "LIVE_PAGE_NAVIGATION",
  MORE_NAVIGATION = "MORE_LIVE_PAGE_CLICK"
}

export interface LivePageData {
  type: MediatorEventType;
  payload: {
    channel: "TVG" | "TVG2";
    destinationUrl?: string;
  };
}
