import { get } from "lodash";
import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  ResultType,
  ResultChangeTrackNextRace,
  ResultRaceViewed,
  EventProps,
  ModuleEventProps
} from "./types";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.SELECT_TAB, (data: ResultType) => {
    track<BaseEventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.tabName"),
      [BaseEventProps.MODULE]: get(data, "payload.raceResults")
        ? "pp result bets closed"
        : "pp result after off"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.CHANGE_TRACK,
    (data: ResultChangeTrackNextRace) => {
      track<BaseEventProps>(EVENT_NAME.NAVIGATE_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: data.payload.actionName,
        [BaseEventProps.MODULE]: "Next Race",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.VIEW_RACE_RESULTS,
    (data: ResultRaceViewed) => {
      track<EventProps>(EVENT_NAME.RACE_RERSULTS_VIEWED, {
        [BaseEventProps.MODULE]: "tracks_list",
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
        [BaseEventProps.LINK_URL]: get(data, "payload.linkUrl"),
        [BaseEventProps.TRACK_COUNTRY]: get(data, "payload.trackCountry"),
        [BaseEventProps.TAB]: "Results",
        [ModuleEventProps.HIDE_RESULTS_SPOILER]: get(
          data,
          "payload.hideResultsSpoiler"
        )
      });
    }
  );
};
