export enum MediatorEventType {
  TAB_SELECT = "MYBETS_TAB_SELECT",
  STARTUP = "MYBETS_STARTUP",
  FILTERS_HANDLING = "MYBETS_FILTERS_HANDLING",
  CLEAR_FILTER_DROPDOWN = "CLEAR_MYBETS_FILTER_DROPDOWN",
  SELECT_FILTER_TYPE = "SELECT_MYBETS_FILTER_TYPE",
  SELECT_CUSTOM_DATE = "SELECT_MYBETS_CUSTOM_DATE",
  CLEAR_ALL_FILTERS = "CLEAR_ALL_MYBETS_FILTERS",
  GO_TO_TRACK = "MYBETS_GO_TO_TRACK",
  TIMEFRAME_SELECTED = "MYBETS_TIMEFRAME_SELECTED",
  OPEN_FROM_FOOTER = "MYBETS_OPEN_FROM_FOOTER",
  KEEP_BET = "MYBETS_KEEP_BET",
  CONFIRM_DELETE_BET = "MYBETS_CONFIRM_DELETE_BET",
  ERROR_DELETE_BET = "MYBETS_ERROR_DELETE_BET",
  DELETE_BET = "MYBETS_DELETE_BET",
  DELETE_BET_ERROR = "MYBETS_DELETE_BET_ERROR",
  SHOW_DETAILS = "MYBETS_SHOW_DETAILS",
  SHOW_LEGS = "HIDE-SHOW-LEGS-GTM",
  CANCEL_BET = "MYBETS_CANCEL_BET",
  RETURN_TO_BETTING = "MYBETS_RETURN_TO_BETTING",
  CLICKS_X_BUTTON = "MYBETS_CLICKS_X_BUTTON",
  OPEN_TRACK_RULES = "MYBETS_OPEN_TRACK_RULES",
  USER_SAW_WARNING_MESSAGE = "MYBETS_USER_SAW_WARNING_MESSAGE",
  USER_CLICKS_WATCH_REPLAY = "MYBETS_USER_CLICKS_WATCH_REPLAY",
  USER_CLICKS_VIDEO_LIVE_BUTTON = "MYBETS_USER_CLICKS_VIDEO_LIVE_BUTTON",
  CLICKS_SEE_RULES_ON_CANCELLED_BET = "MYBETS_USER_CLICKS_SEE_RULES_ON_CANCELLED_BET",
  TAB_CLICK = "MYBETS_TAB_CLICK",
  EXPAND_COLLAPSE_INDIVIDUAL_LEG = "MYBETS_EXPAND_COLLAPSE_INDIVIDUAL_LEG",
  PICKBET_CREATE_BET = "MYBETS_PICKBET_CREATE_BET",
  POTENTIAL_PAYOUT_CLICK = "MYBETS_POTENTIAL_PAYOUT_CLICK",
  REPEAT_BET = "MYBETS_REPEAT_BET",
  NAVIGATE_TO_TRACK = "MYBETS_NAVIGATE_TO_TRACK",
  ADD_TO_BETSLIP = "MYBETS_ADD_TO_BETSLIP",
  EMPTY_MESSAGE = "MYBETS_EMPTY_MESSAGE",
  MODAL_CLOSE_FDR = "MYBETS_MODAL_CLOSE_FDR",
  CONFIRM_DELETE_BET_FDR = "MYBETS_CONFIRM_DELETE_BET_FDR",
  TAB_SELECT_FDR = "MYBETS_TAB_SELECT_FDR",
  OPEN_FROM_FOOTER_FDR = "MYBETS_OPEN_FROM_FOOTER_FDR",
  OPEN_LIVE_VIDEO = "MYBETS_OPEN_LIVE_VIDEO",
  RACE_ACTIVE_BETS_MODAL_CLOSE = "RACE_ACTIVE_BETS_MODAL_CLOSE",
  RACE_ACTIVE_BETS_MODAL_OPEN = "RACE_ACTIVE_BETS_MODAL_OPEN",
  RACE_ACTIVE_BETS_MODAL_RETURN = "RACE_ACTIVE_BETS_MODAL_RETURN"
}

// Payload type data
type MyBetsEventsType =
  | "MYBETS_STARTUP"
  | "MYBETS_TAB_SELECT"
  | "MYBETS_TIMEFRAME_SELECTED"
  | "MYBETS_OPEN_FROM_FOOTER"
  | "MYBETS_MODAL_CLOSE"
  | "MYBETS_GO_TO_TRACK"
  | "MYBETS_DELETE_BET"
  | "MYBETS_DELETE_BET_ERROR"
  | "MYBETS_OPEN_TRACK_RULES"
  | "MYBETS_TAB_CLICK"
  | "MYBETS_EXPAND_COLLAPSE_INDIVIDUAL_LEG"
  | "MYBETS_REPEAT_BET"
  | "MYBETS_NAVIGATE_TO_TRACK"
  | "MYBETS_FILTERS_HANDLING";

export interface MyBets {
  type: MyBetsEventsType;
  payload: {
    tab?: string;
    timeframe?: string;
    url?: string;
    number?: number;
    mtp?: number;
    trackName?: string;
  };
}

export interface MyBetsStartup {
  type: MyBetsEventsType;
  payload: {
    activeBets?: string;
    settledBets?: string;
  };
}

export interface MyBetsFilterHandling {
  type: MyBetsEventsType;
  payload: {
    filter?: string;
    tab?: string;
    settledTab?: string;
    activeBets?: number;
    settledBets?: number;
    isOpening?: boolean;
  };
}

export interface MyBetsDelete {
  type: MyBetsEventsType;
  payload: {
    wagerSerialNumber?: number;
    runnerAmount?: string;
    trackName?: string;
    betAmount?: string;
    betType?: string;
    repeatBet?: string;
    selections?: number[];
  };
}

export interface MyBetsDeleteBetError {
  type: MyBetsEventsType;
  payload: {
    errorId: number;
    runnerSelection: string;
    trackName: string;
    raceNumber: number;
    betId: number;
    betAmount: number;
    betType: string;
    repeatBet?: string;
  };
}

export interface MyBetsBase {
  payload: {
    selectedTab?: string;
    selectedSettledTab?: string;
    show?: boolean;
    activeBetsCount?: number;
    settledBetsCount?: number;
    futureBetsCount?: number;
    gaEventAction?: string;
    legNumber?: number;
  };
}

export interface MultiLegCreateBet {
  runnerAmount: number;
  trackName: string;
  betAmount: number;
  betType: number;
  repeatBet: number;
  runnerSelectionList: string;
  raceNumber: number;
}

export interface RepeatBetEventData extends MultiLegCreateBet {
  gaEventAction: string;
  gaEventLabel?: string;
  module?: string;
  betId?: string;
  betTotal?: number;
  selectionSource?: string;
}

export type EmptyMessageEventData = {
  type: MediatorEventType.EMPTY_MESSAGE;
  payload: {
    buttonText: string;
    module: string;
    destinationUrl: string;
  };
};

export type TabSelectEventData = {
  type: MediatorEventType.TAB_SELECT_FDR;
  payload: {
    selectedTab: string;
    activeTab: string;
  };
};

export type LiveVideoEventData = {
  type: MediatorEventType.OPEN_LIVE_VIDEO;
  payload: {
    tag: string;
    raceUrl: string;
  };
};

export type RaceActiveBetsEventData = {
  type: MediatorEventType.RACE_ACTIVE_BETS_MODAL_RETURN;
  payload: {
    trackName: string;
    raceNumber: string;
    mtp: number;
    destinationUrl: string;
  };
};
