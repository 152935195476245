import mediator from "@tvg/mediator";
import { COMMON_EVENT_NAMES } from "../../constants";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { MediatorEventType } from "./types";

export default () => {
  mediator.base.subscribe(MediatorEventType, () => {
    track<BaseEventProps>(EVENT_NAME, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "horse_details",
      [BaseEventProps.MODULE]: "horse_details"
    });
  });
};
