import _ from "lodash";

const getBetslipLink = (race) => {
  if (race && race.trackCode && race.number && _.get(race, "track.perfAbbr")) {
    return `/betslip/${race.trackCode}/${race.track.perfAbbr}/${race.number}${
      _.get(race, "type.code") === "G" ? "?greyhounds" : ""
    }`;
  }

  return "/betslip";
};

const getRaceLink = (race) => {
  const prefix = _.get(race, "type.code") === "G" ? "greyhounds" : "racetracks";
  if (race && race.trackCode && race.number && race.trackName) {
    const track = race.trackName
      .trim()
      .replace(/\s/g, "-")
      .replace(/-+/g, "-")
      .toLowerCase();
    return `/${prefix}/${race.trackCode}/${track}?race=${race.number}`;
  }

  return "/";
};

export default class LinkFactory {
  static buildBetSlipLink(race) {
    return getBetslipLink(race);
  }

  static buildHorseLink(race) {
    return getRaceLink(race);
  }

  static buildGreyhoundLink(race) {
    return getRaceLink(race);
  }

  static buildBetSlipGreyhoundLink(race) {
    return getBetslipLink(race);
  }
}
