import React, { useCallback } from "react";
import { Icon, Paragraph } from "@tvg/design-system";
import formatCurrency from "@tvg/formatter/currency";
import { computeBalanceDisplay } from "@tvg/utils/balance";
import {
  UserInfoContainer,
  Name,
  BalanceContainer,
  Balance,
  HideButton,
  BalanceWrapper,
  HideBalanceButton
} from "./styled-components";

export interface Props {
  accountNumber: string;
  accountName: string;
  balance: number;
  onClickHideBalance: () => void;
  hideBalance: boolean;
  showUserInfo?: boolean;
  showBalanceInfo?: boolean;
  isSubWalletsAvailable?: boolean;
  onExpand: () => void;
  isExpended: boolean;
  isLightTheme: boolean;
}

const AccountHeader = ({
  accountNumber,
  accountName,
  balance,
  onClickHideBalance,
  hideBalance,
  showUserInfo,
  showBalanceInfo,
  isSubWalletsAvailable = false,
  onExpand,
  isExpended,
  isLightTheme = false
}: Props) => {
  const onRenderBalance = useCallback(() => {
    if (isSubWalletsAvailable) {
      return (
        <BalanceContainer isSubWalletsAvailable>
          <BalanceWrapper
            data-qa-label="expand-balance-button"
            role="button"
            onClick={onExpand}
          >
            <Balance
              data-qa-label="account-balance"
              isLightTheme={isLightTheme}
            >
              {computeBalanceDisplay({ isBalanceHidden: hideBalance, balance })}
            </Balance>
            <Icon
              ml="space-2"
              lineColor={isLightTheme ? "blue_accent.500" : "blue_accent.200"}
              name={isExpended ? "chevronUp" : "chevronDown"}
              size="s"
              qaLabel="tooltip-icon"
            />
          </BalanceWrapper>
          <Paragraph
            fontFamily="condensedRegular"
            textTransform="uppercase"
            color={isLightTheme ? "grey.900" : "blue_accent.200"}
            qaLabel="sub-wallet-text"
          >
            Playable Balance
          </Paragraph>
          <HideBalanceButton
            data-qa-label="account-hide-balance-button"
            onClick={onClickHideBalance}
          >
            <Icon
              mr="space-1"
              lineColor={isLightTheme ? "blue_accent.500" : "blue_accent.200"}
              name={!hideBalance ? "eyeHide" : "eyeShow"}
              size="s"
              qaLabel="tooltip-icon"
            />
            <Paragraph
              fontFamily="condensedRegular"
              textTransform="uppercase"
              color={isLightTheme ? "blue_accent.500" : "blue_accent.200"}
              qaLabel="sub-wallet-text"
            >
              {!hideBalance ? "hide" : "show"}
            </Paragraph>
          </HideBalanceButton>
        </BalanceContainer>
      );
    }
    // TODO remove this when SubWallets become officially available
    return (
      <BalanceContainer>
        <Balance data-qa-label="account-balance" isLightTheme={isLightTheme}>
          {hideBalance ? "••••" : formatCurrency(balance)}
        </Balance>

        <HideButton
          data-qa-label="account-hide-balance-button"
          onClick={onClickHideBalance}
          isLightTheme={isLightTheme}
        >
          {hideBalance ? "show balance" : "hide balance"}
        </HideButton>
      </BalanceContainer>
    );
  }, [isSubWalletsAvailable, hideBalance, isLightTheme, isExpended, balance]);

  return (
    <>
      {showUserInfo && (
        <UserInfoContainer data-qa-label="account-number-name">
          <Name
            isLightTheme={isLightTheme}
          >{`#${accountNumber} • ${accountName}`}</Name>
        </UserInfoContainer>
      )}

      {showBalanceInfo && onRenderBalance()}
    </>
  );
};

export default AccountHeader;
