import type { InfoScreenGtm, PageViewGtm } from "@tvg/ts-types/Registration";
import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  REGISTRATION_VERSION = "Registration Version"
}

export type EventsProps = BaseEventProps | ModuleEventProps;

export enum AuthModalType {
  PROMO_TO_ADD = "Promo To Be Added",
  PRE_APPLIED_PROMO = "Pre-Applied Promo",
  REFERRAL = "Referral"
}

// authentication
export enum SuccessAuthMediatorEventType {
  SUCCESS_AUTHENTICATION = "REGISTRATION:SUCCESS_AUTHENTICATION",
  SUCCESS_AUTHENTICATION_CTA_CLICK = "REGISTRATION:SUCCESS_AUTHENTICATION_CTA_CLICK"
}

export type SuccessAuthentication = {
  type: SuccessAuthMediatorEventType;
  payload: {
    gaEventLabel?: string;
    module?: AuthModalType;
  };
};

// form completion
export enum FormCompletionMediatorEventType {
  CLICK_CONTINUE_BUTTON = "REGISTRATION:CLICK_CONTINUE_BUTTON",
  FORM_STEP_SUCCESS = "REGISTRATION:FORM_STEP_SUCCESS",
  REGISTRATION_ATTEMPT = "REGISTRATION:REGISTRATION_ATTEMPT",
  REGISTRATION_COMPLETE = "REGISTRATION:REGISTRATION_COMPLETE",
  REGISTRATION_FAILURE = "REGISTRATION:REGISTRATION_FAILURE",
  FORM_SIGN_UP_START = "REGISTRATION:FORM_SIGN_UP_START",
  REGISTRATION_MODAL_OPEN = "REGISTRATION:MODAL_OPEN"
}

export type FormCompletion = {
  type: FormCompletionMediatorEventType;
  payload: {
    module?: string;
    verificationStatus?: boolean;
    accountId?: number;
    loginStatus?: boolean;
    residenceState?: string;
    promoCode?: string;
    errorCode?: number;
  };
};

// info screen

export enum InfoScreenMediatorEventType {
  INFO_SCREEN_ERROR = "REGISTRATION:INFO_SCREEN_ERROR",
  INFO_SCREEN_CTA_CLICK = "REGISTRATION:INFO_SCREEN_CTA_CLICK"
}

export type InfoScreenEventData = {
  type: InfoScreenMediatorEventType;
  payload: InfoScreenGtm;
};

// input related

export enum InputRelatedMediatorEventType {
  FIELD_FILL_AND_VALIDATED = "REGISTRATION:FIELD_FILL_AND_VALIDATED",
  FIELD_ERROR = "REGISTRATION:FIELD_ERROR"
}

export type InputRelatedEventData = {
  type: InputRelatedMediatorEventType;
  payload: {
    module?: string;
    label?: string;
    isEditing?: boolean;
  };
};

// other Interactions

export enum OtherInteractionsMediatorEventType {
  CLICK_BACK_BUTTON = "REGISTRATION:CLICK_BACK_BUTTON",
  CLICK_EDIT = "REGISTRATION:CLICK_EDIT",
  CLICK_LINK_AWAY_FROM_FORM = "REGISTRATION:CLICK_LINK_AWAY_FROM_FORM"
}

export type OtherInteractionsEventData = {
  type: OtherInteractionsMediatorEventType;
  payload: {
    module?: string;
    formSection?: string;
    linkText?: string;
    destinationUrl?: string;
  };
};

// page view

export enum PageViewMediatorEventType {
  PAGE_VIEW = "REGISTRATION:PAGE_VIEW",
  VIRTUAL_PAGE_VIEW = "REGISTRATION:VIRTUAL_PAGE_VIEW"
}

export type PageViewEventData = {
  type: PageViewMediatorEventType;
  payload: PageViewGtm;
};

// promo code

export enum PromoCodeMediatorEventType {
  ENTER_PROMO_CODE = "REGISTRATION:PROMO_CODE_ENTER_PROMO_CODE",
  APPLY = "REGISTRATION:PROMO_CODE_APPLY",
  APPLIED = "REGISTRATION:PROMO_CODE_APPLIED",
  APPLIED_AUTOMATICALLY = "REGISTRATION:PROMO_CODE_APPLIED_AUTOMATICALLY",
  REMOVED = "REGISTRATION:PROMO_CODE_REMOVED",
  EDITED = "REGISTRATION:PROMO_CODE_EDITED",
  MODAL_CLOSED = "REGISTRATION:PROMO_CODE_MODAL_CLOSED"
}

export type PromoCodeEventData = {
  type: PromoCodeMediatorEventType;
  payload: {
    providerName?: string;
    module?: string;
    promoCode?: string;
    eventLabel?: string;
  };
};

// TVG Provider

export enum TVGProviderMediatorEventType {
  SELECT_PROVIDER = "REGISTRATION:TVG_PROVIDER_SELECT_PROVIDER",
  APPLIED_PROVIDER = "REGISTRATION:TVG_PROVIDER_APPLIED_PROVIDER",
  REMOVE_PROVIDER = "REGISTRATION:TVG_PROVIDER_REMOVE_PROVIDER",
  CLOSE_MODAL = "REGISTRATION:TVG_PROVIDER_CLOSE_MODAL"
}

export type TVGProviderEventData = {
  type: TVGProviderMediatorEventType;
  payload: {
    providerName?: string;
    module?: string;
  };
};
