// @flow

import styled from "styled-components";
import { buildText } from "../Text";

type HighlightTypeMapItem = {
  bgColor: string,
  textColor: string
};

const createTextHighlightComponent = (
  type: HighlightTypeMapItem,
  fontSize: number,
  bold: boolean,
  uppercase: boolean,
  qaLabel?: string
) => styled(
  buildText({ fontSize, color: type.textColor, bold, uppercase, qaLabel })
)`
  background-color: ${type.bgColor};
  padding: 0 2px;
  border-radius: 30px;
  display: inline-flex;

  @media (pointer: coarse) {
    padding: 0 6px;
  }
`;

export default createTextHighlightComponent;
