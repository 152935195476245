import mediator from "@tvg/mediator";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.CLICK_HOME,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_HOME, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "Home",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_TRACKS,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_TRACKS, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "Tracks",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_LIVE,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_LIVE, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "TVGTV",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_PROMOS,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_PROMOS, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "Promos Modal",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_BETS,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_BETS, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "My Bets Modal",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_MORE,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_MORE, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "More",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_ACCOUNT,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_ACCOUNT, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "Account",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_PICKS,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_PICKS, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "Talent Picks",
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );

  mediator.base.subscribe(
    MediatorEventType.CLICK_LINKS,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_LINKS, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: data.payload.linkName,
        [BaseEventProps.MODULE]: "Footer",
        [BaseEventProps.LINK_URL]: data.payload.destinationUrl
      })
  );
};
