import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { BASE_PATH, EVENT_NAME } from "./constants";
import { EventData, EventProps, MediatorEventType } from "./types";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.FILTER_OPEN, () =>
    track<EventProps>(EVENT_NAME.FILTER.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: undefined,
      [BaseEventProps.MODULE]: "account summary activity list"
    })
  );

  mediator.base.subscribe(MediatorEventType.FILTER_APPLY, (data: EventData) =>
    track<EventProps>(EVENT_NAME.FILTER.APPLY, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.filter", []).toString(),
      [BaseEventProps.MODULE]: "account summary filters"
    })
  );

  mediator.base.subscribe(MediatorEventType.FILTER_CLEAR, (data: EventData) =>
    track<EventProps>(EVENT_NAME.FILTER.CLEAR, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.filter", []).toString(),
      [BaseEventProps.MODULE]: "account summary activity list"
    })
  );

  mediator.base.subscribe(
    MediatorEventType.SELECTION_TIME_FRAME,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.SELECTION.TIME_FRAME, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.timeFrame"),
        [BaseEventProps.MODULE]: "account summary header"
      })
  );

  mediator.base.subscribe(
    MediatorEventType.SELECTION_DATE_RANGE,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.SELECTION.DATE_RANGE, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.gaEventLabel"),
        [BaseEventProps.MODULE]: "account summary date selector"
      })
  );

  mediator.base.subscribe(MediatorEventType.DOWNLOAD_CLICK, () =>
    track<EventProps>(EVENT_NAME.DOWNLOAD.CLICK, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: undefined,
      [BaseEventProps.MODULE]: "account summary header"
    })
  );

  mediator.base.subscribe(
    MediatorEventType.DOWNLOAD_CONFIRM,
    (data: EventData) =>
      track<EventProps>(
        `${EVENT_NAME.DOWNLOAD.CONFIRM} ${get(data, "payload.fileType")}`,
        {
          [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
          [BaseEventProps.EVENT_LABEL]: undefined,
          [BaseEventProps.MODULE]: "download transactions"
        }
      )
  );

  mediator.base.subscribe(
    MediatorEventType.PAGINATION_CLICK,
    (data: EventData) => {
      const currentPage = get(data, "payload.currentPage", 0);
      const newPage = get(data, "payload.newPage", 0);
      let action: string = EVENT_NAME.PAGINATION.CLICK;

      if (newPage === currentPage - 1) action = EVENT_NAME.PAGINATION.PREV;
      if (newPage === currentPage + 1) action = EVENT_NAME.PAGINATION.NEXT;

      return track<EventProps>(action, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: undefined,
        [BaseEventProps.MODULE]: "account summary activity list"
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.HEADER_BACK, (data: EventData) => {
    const locationPath = get(data, "payload.locationPathname", "");

    if (locationPath.includes(BASE_PATH))
      return track<EventProps>(EVENT_NAME.HEADER_BACK, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: undefined,
        [BaseEventProps.MODULE]: "account summary"
      });

    return null;
  });
};
