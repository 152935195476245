import { PaymentType } from "@tvg/wallet/src/types";
import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  DEPOSIT_AMOUNT = "Deposit Amount",
  DEPOSIT_TYPE = "Deposit Type",
  MENU = "menu"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  WITHDRAW_VALIDATION_ERROR = "PAWS:WITHDRAW_VALIDATION_ERROR",
  WITHDRAW_AUTHORIZE_TRANSACTION = "PAWS:WITHDRAW_AUTHORIZE_TRANSACTION",
  WITHDRAW_CLOSE = "PAWS:WITHDRAW_CLOSE",
  WITHDRAW_SUCCESS = "PAWS:WITHDRAW_SUCCESS",
  WITHDRAW_ERROR_MESSAGE = "PAWS:WITHDRAW_ERROR_MESSAGE",
  WITHDRAW_ADDRESS_MODAL_VIEW = "PAWS:WITHDRAW_ADDRESS_MODAL_VIEW",
  WITHDRAW_ADDRESS_MODAL_USER_SELECTS_ADDRESS = "PAWS:WITHDRAW_ADDRESS_MODAL_USER_SELECTS_ADDRESS",
  WITHDRAW_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS = "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS",
  WITHDRAW_ADDRESS_MODAL_USER_CLICKS_CANCEL = "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CLICKS_CANCEL",
  WITHDRAW_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE = "PAWS:WITHDRAW_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE",
  NAVIGATE_DEPOSIT_WITHDRAW_OPTIONS = "PAWS:NAVIGATE_DEPOSIT_WITHDRAW_OPTIONS",
  DEPOSIT_WITHDRAW_OPTIONS_MODAL = "PAWS:DEPOSIT_WITHDRAW_OPTIONS_MODAL",
  DEPOSIT_ATTEMPT = "PAWS:DEPOSIT_ATTEMPT",
  DEPOSIT_VALIDATION_MESSAGE = "PAWS:DEPOSIT_VALIDATION_MESSAGE",
  DEPOSIT_ERROR_MESSAGE = "PAWS:DEPOSIT_ERROR_MESSAGE",
  DEPOSIT_SUCCESS = "PAWS:DEPOSIT_SUCCESS",
  DEPOSIT_CLOSE = "PAWS:DEPOSIT_CLOSE",
  DEPOSIT_CREATION_RETURN = "PAWS:DEPOSIT_CREATION_RETURN",
  DEPOSIT_DEFAULT_SELECT = "PAWS:DEPOSIT_DEFAULT_SELECT",
  DEPOSIT_NEW_PAYMENT_METHOD = "PAWS:DEPOSIT_NEW_PAYMENT_METHOD",
  DEPOSIT_ADDRESS_MODAL_VIEW = "PAWS:DEPOSIT_ADDRESS_MODAL_VIEW",
  DEPOSIT_ADDRESS_MODAL_USER_SELECTS_ADDRESS = "PAWS:DEPOSIT_ADDRESS_MODAL_USER_SELECTS_ADDRESS",
  DEPOSIT_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS = "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CONFIRMS_ADDRESS",
  DEPOSIT_ADDRESS_MODAL_USER_CLICKS_CANCEL = "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CLICKS_CANCEL",
  DEPOSIT_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE = "PAWS:DEPOSIT_ADDRESS_MODAL_USER_CLICKS_MESSAGE_CUSTOMER_SERVICE",
  DEPOSIT_FIRST_INTERACTION = "PAWS:DEPOSIT_FIRST_INTERACTION",
  OTHER_PAYMENT_METHODS_OPEN_TAB = "PAWS:OTHER_PAYMENT_METHODS_OPEN_TAB",
  OTHER_PAYMENT_METHODS_CTA_CLICK = "PAWS:OTHER_PAYMENT_METHODS_CTA_CLICK",
  OTHER_PAYMENT_METHODS_PDF_DOWNLOAD = "PAWS:OTHER_PAYMENT_METHODS_PDF_DOWNLOAD",
  PAYMENT_SELECTOR_OPEN = "PAWS:PAYMENT_SELECTOR_OPEN",
  PAYMENT_SELECTOR_ADD_METHOD = "PAWS:PAYMENT_SELECTOR_ADD_METHOD",
  PAYMENT_SELECTOR_CHANGE_METHOD = "PAWS:PAYMENT_SELECTOR_CHANGE_METHOD",
  DELETE_PAYMENT_METHOD_BUTTON = "PAWS:DELETE_PAYMENT_METHOD_BUTTON",
  SELECT_PAYMENT_TYPE_OR_METHOD = "PAWS:SELECT_PAYMENT_TYPE_OR_METHOD",
  NEW_PAYMENT_TYPE_SELECTED = "PAWS:NEW_PAYMENT_TYPE_SELECTED",
  OPEN_PAYMENT_TYPE_MODAL = "PAWS:OPEN_PAYMENT_TYPE_MODAL",
  ADD_PAYMENT_METHOD = "PAWS:ADD_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD_SUCCESS = "PAWS:DELETE_PAYMENT_METHOD_SUCCESS",
  WALLET_GO_BACK = "PAWS:WALLET_GO_BACK",
  WALLET_PAGEVIEW = "PAWS:WALLET_PAGEVIEW",
  USER_ACCOUNT_WALLET_ICONS_CLICK = "PAWS:USER_ACCOUNT_WALLET_ICONS_CLICK"
}

// Payload type data
export interface SelectPaymentTypeOrMethod {
  payload: {
    isNewCard?: boolean;
    paymentType: string;
  };
}

export interface NewPaymentTypeSelected {
  payload: {
    module: string;
    paymentType: string;
  };
}

export interface DepositWithdrawOptionsModal {
  payload: {
    isOpen: boolean;
    paymentType: string;
  };
}

export interface NavigateDepositWithdrawOptions {
  payload: {
    selectedOption: string;
    paymentType: string;
    destinationUrl: string;
  };
}

export interface DepositSuccess {
  isFirstDeposit: boolean;
  paymentType: string;
  accountID: string;
  amount: string;
}

export interface DepositNewPaymentMethod {
  payload: {
    paymentType: string;
  };
}

export interface WithdrawAddressModal {
  payload: {
    paymentType: string;
    accountID: string;
  };
}

export interface DepositAddressModal {
  payload: {
    paymentType: string;
    accountID: string;
  };
}

export interface WithdrawAddressModalCS {
  payload: {
    paymentType: string;
    accountID: string;
    linkText: string;
  };
}

export interface OtherPaymentMethodsOpenTab {
  payload: {
    paymentType: string;
  };
}

export interface OtherPaymentMethods {
  payload: {
    linkText: string;
  };
}

export interface PaymentMethodSelector {
  payload: {
    currentPaymentType: PaymentType;
  };
}

export interface PaymentMethodSelectorChange {
  payload: {
    currentPaymentType: PaymentType;
    selectedPaymentType: PaymentType;
  };
}

export interface DeletePaymentMethod {
  payload: {
    paymentType: string;
  };
}

export interface FirstDepositInteraction {
  payload: {
    accountId: string;
    paymentType: string;
    isFirstDeposit: boolean;
  };
}

export interface WalletPageView {
  accountId: string;
  customerStatus: "New" | "Returning";
  privateBrowser: "Yes" | "No";
  productVersion: string;
  registrationStatus: "Registered";
  residenceState: string;
  siteVersion: string;
}

export interface UserAccountWalletIconsClick {
  destinationUrl: string;
  iconText: string;
}
