import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  OtherInteractionsEventData,
  OtherInteractionsMediatorEventType
} from "./types";
import { EVENT_CATEGORY, OTHER_INTERACTIONS_EVENTS } from "./constants";

export default () => {
  // When a user clicks a back arrow
  mediator.base.subscribe(
    OtherInteractionsMediatorEventType.CLICK_BACK_BUTTON,
    (data: OtherInteractionsEventData) => {
      track<BaseEventProps>(OTHER_INTERACTIONS_EVENTS.BACK_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  // When a user clicks edit in the Review part of the form
  mediator.base.subscribe(
    OtherInteractionsMediatorEventType.CLICK_EDIT,
    (data: OtherInteractionsEventData) => {
      track<BaseEventProps>(OTHER_INTERACTIONS_EVENTS.EDIT_FORM_OPEN, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.formSection"),
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  // When a user clicks a link that takes them away from the form (another URL)
  mediator.base.subscribe(
    OtherInteractionsMediatorEventType.CLICK_LINK_AWAY_FROM_FORM,
    (data: OtherInteractionsEventData) => {
      track<BaseEventProps>(OTHER_INTERACTIONS_EVENTS.NAVIGATE, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.LINK_TEXT]: get(data, "payload.linkText"),
        [BaseEventProps.MODULE]: get(data, "payload.module"),
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      });
    }
  );
};
