// @flow
import { find, get } from "lodash";
import type { MenusItems } from "@tvg/types/WagerRewards";

export const hasWagerProfileException = (
  tvgMenus: MenusItems[],
  userProfileType: string
) => {
  try {
    let wagerMenu;
    tvgMenus.forEach((menu) => {
      if (!wagerMenu) {
        wagerMenu = find(menu.subMenus, { subQaLabel: "wagerRewardsButton" });
      }
    });

    const profileTypeException = get(
      wagerMenu,
      "options.profileTypeException",
      []
    );
    return profileTypeException.includes(userProfileType);
  } catch (error) {
    return false;
  }
};

export default hasWagerProfileException;
