export default function reducer(
  state = {
    sideMenuToggled: false,
    showBalanceDeposit: false,
    showBalanceRequested: false,
    greyhoundsProfiles: [],
    features: {},
    brazeContentCardsOpen: false,
    abTesting: {},
    pawsSubWalletsContent: []
  },
  action
) {
  switch (action.type) {
    case "TOGGLE_SIDE_MENU": {
      return { ...state, sideMenuToggled: action.payload };
    }
    case "SHOW_BALANCE_DEPOSIT": {
      return { ...state, showBalanceDeposit: true, showBalanceRequested: true };
    }
    case "HIDE_BALANCE_DEPOSIT": {
      return {
        ...state,
        showBalanceDeposit: false,
        showBalanceRequested: true
      };
    }
    case "CMS_REQUEST_SUCCESS": {
      return {
        ...state,
        greyhoundsProfiles: action.payload.profiles,
        supportLink: action.payload.supportLink,
        supportMessage: action.payload.supportMessage,
        menuItemsConfig: action.payload.menuItemsConfig,
        accountMenuTitles: action.payload.accountMenuTitles,
        brazeMessages: action.payload.brazeMessages,
        loginErrorTitles: action.payload.loginErrorTitles,
        robotMessage: action.payload.robotMessage,
        credentialsRecoveryModal: action.payload.credentialsRecoveryModal,
        emailRecoveryModal: action.payload.emailRecoveryModal,
        pawsSubWalletsContent: action.payload.pawsSubWalletsContent
      };
    }
    case "CMS_REQUEST_FAIL": {
      return { ...state, greyhoundsProfiles: [] };
    }
    case "FEATURES_REQUEST_SUCCESS": {
      return { ...state, features: action.payload.features };
    }
    case "FEATURES_REQUEST_FAIL": {
      return { ...state, features: {} };
    }
    case "TOGGLE_CONTENT_CARDS_OPEN": {
      return {
        ...state,
        brazeContentCardsOpen: action.payload.brazeContentCardsOpen
      };
    }
    case "SET_ABTESTING_TOGGLE": {
      return {
        ...state,
        abTesting: {
          ...state.abTesting,
          [action.payload.featureName]: action.payload.enabled
        }
      };
    }
    default: {
      return state;
    }
  }
}
