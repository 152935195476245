import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";
import buttons from "@tvg/ui-bootstrap/components/Buttons/index.css";
import style from "./style.css";

const logout = () => {
  mediatorClassic.dispatch("TVG_LOGIN:DO_LOGOUT", {});
  mediator.base.dispatch({ type: "TVG_LOGIN:DO_LOGOUT", payload: {} });
  mediator.base.dispatch({
    type: "PAGE_VIEW",
    payload: {
      siteVersion: tvgConf().gaConfig().siteVersion,
      productVersion: tvgConf().gaConfig().productVersion,
      logged: "Logged Out",
      page: window.location.pathname
    }
  });
};

const MyAccountButtonLogoutOverlay = (props) => {
  const containerClassnames = classNames(
    style.logoutSection,
    style.centered,
    props.className
  );

  return (
    <div className={containerClassnames}>
      <button
        className={classNames(
          buttons.tvgBtnTertiary,
          buttons.big,
          style.logoutButton
        )}
        data-qa-label="logoutButton"
        onClick={logout}
      >
        Log out
      </button>
    </div>
  );
};

MyAccountButtonLogoutOverlay.propTypes = {
  className: PropTypes.string,
  supportEmail: PropTypes.string,
  sendFeedBack: PropTypes.func
};

MyAccountButtonLogoutOverlay.defaultProps = {
  className: "",
  supportEmail: "",
  sendFeedBack: () => {}
};

export default MyAccountButtonLogoutOverlay;
