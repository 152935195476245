import { get } from "lodash";

import mediatorChannels from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  AuthModalType,
  SuccessAuthMediatorEventType,
  SuccessAuthentication
} from "./types";
import { EVENT_CATEGORY, AUTH_EVENTS } from "./constants";

export default () => {
  mediatorChannels.base.subscribe(
    SuccessAuthMediatorEventType.SUCCESS_AUTHENTICATION,
    (data: SuccessAuthentication) => {
      track<BaseEventProps>(AUTH_EVENTS.MODAL.LOAD, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(
          data,
          "payload.module",
          AuthModalType.PROMO_TO_ADD
        )
      });
    }
  );

  mediatorChannels.base.subscribe(
    SuccessAuthMediatorEventType.SUCCESS_AUTHENTICATION_CTA_CLICK,
    (data: SuccessAuthentication) => {
      track<BaseEventProps>(AUTH_EVENTS.MODAL.CTA_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(
          data,
          "payload.module",
          AuthModalType.PROMO_TO_ADD
        )
      });
    }
  );
};
