// @flow
import React, { Component } from "react";
import RecoverCredentials, {
  type CredentialsRecoveryMessages,
  messagesDefault
} from "@tvg/atomic-ui/_templates/RecoverCredentials";
import queryString from "query-string";
import { get, noop } from "lodash";
import type { RouterHistory } from "react-router-dom";
import { connect } from "react-redux";
import parseCapiMessage from "@tvg/utils/capiUtils";
import {
  type OnResetCallback,
  type ValueType
} from "@tvg/atomic-ui/_organism/RecoverCredentialsForm";
import mediator from "@tvg/mediator";
import type { NullaryFn } from "@tvg/types/Functional";
import { postCredentialsRecovery } from "@tvg/api/crf";

import {
  LOGIN_ACTIVE_FLOWS,
  LOGIN_ACTIVE_FLOWS_STATUS
} from "@tvg/sh-lib-account-actions/src/reducers/modalReducer";
import tvgConf from "@tvg/conf";

const ATTEMPTS_MAX = 3;

type Props = {
  mobile: boolean,
  initialValue?: string | null,
  history: RouterHistory,
  messages: CredentialsRecoveryMessages,
  onCloseModalCallback: NullaryFn<void>
};

type State = {
  isLoading: boolean,
  hasSentEmail: boolean,
  hasResentEmail: boolean,
  hasError: boolean,
  value: ValueType,
  attempts: number
};

export class CredentialsRecovery extends Component<Props, State> {
  static defaultProps = {
    mobile: false,
    initialValue: null,
    messages: messagesDefault,
    onCloseModalCallback: noop
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      hasSentEmail: false,
      hasResentEmail: false,
      hasError: false,
      value: {
        userId: ""
      },
      attempts: 0
    };
  }

  componentDidUpdate() {
    // removes modal back action on success
    if (this.state.hasSentEmail && !this.state.hasError) {
      mediator.base.dispatch({
        type: "OPEN_LOGIN_FLOW",
        payload: {
          loginActiveFlow: LOGIN_ACTIVE_FLOWS["forgot-credentials"],
          loginActiveFlowStatus: LOGIN_ACTIVE_FLOWS_STATUS.success
        }
      });
    }

    // removes modal back action on failure
    if (this.state.hasError) {
      mediator.base.dispatch({
        type: "OPEN_LOGIN_FLOW",
        payload: {
          loginActiveFlow: LOGIN_ACTIVE_FLOWS["forgot-credentials"],
          loginActiveFlowStatus: LOGIN_ACTIVE_FLOWS_STATUS.failure
        }
      });
    }
  }

  componentWillUnmount() {
    // removes user query string used in initial value
    if (window !== undefined) {
      const path = get(window, "location.pathname");
      const { user, ...rest } = queryString.parse(
        window.location.search.toLowerCase()
      );
      const parameters = queryString.stringify(rest);
      const nextPath = `${path}?${parameters}`;
      this.props.history.push(nextPath);
    }
  }

  resendRecoveryEmail = () => {
    this.setState({
      isLoading: true,
      hasResentEmail: false,
      hasError: false
    });
    if (this.state.value.userId === "") {
      this.setState({ isLoading: false, hasError: true });
      return;
    }
    // do request
    postCredentialsRecovery(this.state.value.userId)
      .then(() => {
        this.setState((prevState: State) => ({
          isLoading: false,
          hasResentEmail: true,
          hasError: false,
          attempts: prevState.attempts + 1
        }));
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          hasResentEmail: false,
          hasError: true
        });
      });
  };

  resetCredentials: OnResetCallback = (value) => {
    this.setState({
      isLoading: true,
      hasSentEmail: false,
      hasError: false,
      value: { userId: "" }
    });
    // do request
    postCredentialsRecovery(value.userId)
      .then(() => {
        mediator.base.dispatch({ type: "FORGOT_CREDENTIALS_CHECK_SCREEN" });

        this.setState(() => ({
          isLoading: false,
          hasSentEmail: true,
          hasError: false,
          value
        }));
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          hasSentEmail: false,
          hasError: true,
          value: {
            userId: ""
          }
        });

        mediator.base.dispatch({
          type: "FORGOT_CREDENTIALS_RESET_SUBMIT_ERROR",
          payload: { field: value.userId }
        });
      });
  };

  redirectToRecoverDetails = (e: Event) => {
    mediator.base.dispatch({
      type: "FORGOT_CREDENTIALS_FORGOT_DETAILS"
    });

    if (tvgConf().device === "desktop") {
      e.preventDefault();
      e.stopPropagation();
      mediator.base.dispatch({
        type: "OPEN_LOGIN_FLOW",
        payload: {
          loginActiveFlow: LOGIN_ACTIVE_FLOWS["recover-email"]
        }
      });
    }
  };

  handleRetype = () => {
    if (this.state.attempts >= ATTEMPTS_MAX) {
      this.setState({ isLoading: false, hasError: true });
      mediator.base.dispatch({
        type: "FORGOT_CREDENTIALS_RESET_SUBMIT_ERROR",
        payload: { field: "Exceeds maximum amount of attempts" }
      });
      return;
    }

    this.setState((prevState: State) => ({
      isLoading: false,
      hasSentEmail: false,
      hasResentEmail: false,
      hasError: false,
      attempts: prevState.attempts + 1
    }));
    mediator.base.dispatch({
      type: "RESET_LOGIN_FLOW_STATUS"
    });
  };

  render() {
    return (
      <RecoverCredentials
        device={tvgConf().device}
        mobile={this.props.mobile}
        initialValue={this.state.value.userId || this.props.initialValue}
        messages={this.props.messages}
        onResetCallback={this.resetCredentials}
        isProcessing={this.state.isLoading}
        hasError={this.state.hasError}
        hasSentEmail={this.state.hasSentEmail}
        attempts={this.state.attempts}
        attemptsMax={ATTEMPTS_MAX}
        userIdValue={this.state.value.userId}
        onRetype={this.handleRetype}
        onCloseModalCallback={this.props.onCloseModalCallback}
        hasResentEmail={this.state.hasResentEmail}
        onResendCallback={this.resendRecoveryEmail}
        redirectToRecoverDetails={this.redirectToRecoverDetails}
      />
    );
  }
}

export default connect(
  (store) => ({
    messages: parseCapiMessage(
      store,
      "capi.messages.credentialsRecoveryModal",
      parseCapiMessage(
        store,
        "header.credentialsRecoveryModal",
        messagesDefault
      )
    )
  }),
  () => ({})
)(CredentialsRecovery);
