import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  RACING_ID = "Racing Id",
  APP_VERSION = "App Version",
  DEVICE = "Device",
  OS_VERSION = "OS Version"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  PAGE_VIEW = "PAGE_VIEW",
  TRACK_VIEW = "TRACK_VIEW",
  HOME_NAVIGATION = "LOGO:HOME_NAVIGATION",
  SITE_CLICK = "SITE_CLICK",
  IOS_TRACKING_PERMISSION = "IOS_TRACKING_PERMISSION"
}

export interface PageViewData {
  type: MediatorEventType.PAGE_VIEW;
  payload: {
    siteVersion: string;
    productVersion: string;
    racingId: number;
    accountId: string;
    state: string;
    logged: string;
    registrationStatus: string;
    sport: string;
    privateBrowser: "Yes" | "No";
    graphVersion: "v1" | "v2";
    appVersion: string;
    page: string;
    promoCode?: string;
    isReturningUser?: boolean;
  };
}

export interface TrackViewData {
  type: MediatorEventType.TRACK_VIEW;
  payload: {
    trackName: string;
    raceNumber: string;
  };
}

export interface SiteClickData {
  type: MediatorEventType.SITE_CLICK;
  payload: {
    action: string;
    label: string;
    module: string;
  };
}

export interface iOSTrackingPermissionData {
  type: MediatorEventType.IOS_TRACKING_PERMISSION;
  payload: {
    label: string;
    device: string;
    osVersion: string;
    appVersion: string;
  };
}
