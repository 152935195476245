import mediator from "@tvg/mediator";
import { get } from "lodash";
import { MediatorEventType, ReferFriendData } from "./types";
import { track } from "../../../amplitude";
import { BaseEventProps } from "../../../types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../../constants";
import { EVENTS_TO_EXCLUDE } from "../../../utils";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.SOCIAL_CLICK,
    (data: ReferFriendData) => {
      track<BaseEventProps>(EVENT_NAME.REFERRAL_LINK_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: "Refer A Friend",
        [BaseEventProps.EVENT_LABEL]: get(
          data,
          "payload.gaEventLabel",
          undefined
        ), // e.g 'copy link', 'twitter' etc
        [BaseEventProps.MODULE]: "Referral Share Links"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.NAVIGAGTION_CLICK,
    (data: ReferFriendData) => {
      track<BaseEventProps>(EVENT_NAME.NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(
          data,
          "payload.gaEventLabel",
          undefined
        ), // (i.e. learn more, opt in, bet now)
        [BaseEventProps.MODULE]: get(data, "payload.module", undefined), // (i.e. promo hub landing, promo offer page, promo modal, refer a friend)
        [BaseEventProps.LINK_URL]: get(
          data,
          "payload.destinationUrl",
          undefined
        ) // destination Url
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.SITE, (data: ReferFriendData) => {
    const eventAction = get(data, "payload.gaEventAction", undefined);
    if (EVENTS_TO_EXCLUDE.includes(eventAction.toLowerCase())) {
      return;
    }

    track<BaseEventProps>(eventAction, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(
        data,
        "payload.gaEventLabel",
        undefined
      ), // Button label
      [BaseEventProps.MODULE]: get(data, "payload.module", undefined)
    });
  });
};
