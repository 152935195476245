// @flow

import React, { Component, type Children } from "react";
import { findDOMNode } from "react-dom";
import classnames from "classnames";

import style from "./style.css";

type AnimationTimes = {
  regular: number,
  presentation: number,
  fullscreen: number
};

const animationTimes = {
  regular: 300,
  presentation: 300,
  fullscreen: 300
};

type Props = {
  classnames: string,
  closeModal: Function,
  children: Children,
  type: "fullscreen" | "regular" | "presentation" | "scrollContent",
  qaLabel: string
};

type State = {
  modalClass: string,
  isClosing: boolean
};

class Modal extends Component {
  static defaultProps = {
    type: "regular",
    qaLabel: ""
  };

  constructor(props: Props) {
    super(props);
    this.animationTimes = animationTimes;

    this.state = {
      modalClass: classnames({
        [style.modal]: true,
        [style.enter]: true
      }),
      isClosing: false
    };
    this.backgroundClick = this.backgroundClick.bind(this);
    this.setCloseAnimation = this.setCloseAnimation.bind(this);
  }

  state: State;

  setCloseAnimation: Function;
  setCloseAnimation(cb: Function) {
    this.setState(
      {
        modalClass: classnames({
          [style.modal]: true,
          [style.leave]: true
        }),
        isClosing: true
      },
      cb
    );

    return this;
  }

  props: Props;

  backgroundClick: Function;
  backgroundClick(e: MouseEvent): Modal {
    if (!this.state.isClosing) {
      if (e.target === findDOMNode(this.component)) {
        this.props.closeModal();
      }
    }

    return this;
  }

  animationTimes: AnimationTimes;
  component: Modal;

  render() {
    return (
      <div
        role="button"
        tabIndex={0}
        className={this.state.modalClass}
        ref={(c) => {
          this.component = c;
        }}
        onClick={this.backgroundClick}
        data-qa-label={this.props.qaLabel.concat("Modal")}
      >
        <div
          className={classnames(style[this.props.type], this.props.classnames)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Modal;
