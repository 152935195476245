// @flow
import type { RevalidateLocationErrors } from "@tvg/atomic-ui/_templates/GeolocateIn";
import type { RevalidateLocationActions } from "./actions";

export type State = {
  isOpen: boolean,
  error: ?RevalidateLocationErrors
};

export const initialState = {
  isOpen: false,
  error: null
};

const revalidateLocationReducer = (
  state: State = initialState,
  action: RevalidateLocationActions
): State => {
  switch (action.type) {
    case "OPEN_LOCATION_REVALIDATE": {
      return { isOpen: true, error: "WARNING" };
    }

    case "SET_LOCATION_REVALIDATE_FAILURE": {
      return { isOpen: true, error: "FAILURE" };
    }

    case "CLOSE_LOCATION_REVALIDATE": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default revalidateLocationReducer;
