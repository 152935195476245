import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { fontNormal, fontCondensedNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const ButtonLinkContainer = styled(Link)`
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
`;

const handleIconContainerStyles = (variant) => {
  switch (variant) {
    case "primary":
    case "primary-light":
      return css`
        background-color: ${buildColor("green", "500")};
        box-shadow: 0 2px 4px rgba(17, 43, 68, 0.1);
        &:hover {
          background-color: ${buildColor("green", "600")};
        }
        &:active {
          background-color: ${buildColor("green", "700")};
        }
      `;
    case "light":
      return css`
        background-color: ${buildColor("white", "100")};
        border: 1px solid ${buildColor("blue", "100")};
        box-shadow: 0 2px 4px ${buildColor("blue", "000")};
        &:hover {
          background-color: ${buildColor("blue_accent", "000")};
        }
        &:active {
          background-color: ${buildColor("blue_accent", "100")};
        }
      `;
    default:
      return css`
        background-color: ${buildColor("white", "10")};
        box-shadow: 0 2px 4px rgba(17, 43, 68, 0.1);
        &:hover {
          background-color: ${buildColor("white", "20")};
        }
        &:active {
          background-color: ${buildColor("white", "30")};
        }
      `;
  }
};

export const ButtonIconContainer = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  ${({ variant }) => handleIconContainerStyles(variant)};
`;

export const ButtonText = styled.span`
  text-transform: uppercase;
  font-family: ${fontNormal};
  font-size: 12px;
  line-height: 18px;
  color: ${({ variant }) =>
    variant === "light" || variant === "primary-light"
      ? buildColor("grey", "800")
      : buildColor("blue", "000")};
`;

export const Counter = styled.span`
  font-family: ${fontCondensedNormal};
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  height: 20px;
  min-width: 20px;
  border-radius: 10px;
  background-color: ${buildColor("red", "500")};
  color: ${buildColor("white", "100")};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  position: absolute;
  top: 0;
  right: 0;
`;
