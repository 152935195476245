import mediator from "@tvg/mediator";
import { has, get } from "lodash";
import {
  EventData,
  EventProps,
  PayloadEvent,
  PayloadKey,
  MediatorEventType
} from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.EVENT, (data: EventData) => {
    let base = {
      key: PayloadKey.TOUCH_ID,
      module: PayloadKey.TOUCH_ID
    };

    if (has(data.payload.gtm, PayloadKey.FACE_ID)) {
      base = {
        key: PayloadKey.FACE_ID,
        module: PayloadKey.FACE_ID
      };
    }

    switch (get(data.payload.gtm, base.key)) {
      case PayloadEvent.ATTEMPT:
        return track<EventProps>(`${base.module} ${EVENT_NAME.ATTEMPT}`, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.MODULE]: base.module
        });
      case PayloadEvent.SUCCESS:
        return track<EventProps>(`${base.module} ${EVENT_NAME.SUCCESS}`, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.MODULE]: base.module
        });
      case PayloadEvent.ERROR:
        return track<EventProps>(`${base.module} ${EVENT_NAME.ERROR}`, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.EVENT_LABEL]: data.payload.message,
          [BaseEventProps.MODULE]: base.module
        });
      case PayloadEvent.PROMPT:
        return track<EventProps>(EVENT_NAME.SAW, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.EVENT_LABEL]: `QUICK ${base.module.toUpperCase()} LOGIN`,
          [BaseEventProps.MODULE]: base.module
        });
      case PayloadEvent.YES_PROMPT:
        return track<EventProps>(EVENT_NAME.CLICKED, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.EVENT_LABEL]: data.payload.message,
          [BaseEventProps.MODULE]: base.module
        });
      case PayloadEvent.SKIP_PROMPT:
        return track<EventProps>(EVENT_NAME.SKIP, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.EVENT_LABEL]: `QUICK ${base.module.toUpperCase()} LOGIN`,
          [BaseEventProps.MODULE]: base.module
        });
      case PayloadEvent.NO_PROMPT:
        return track<EventProps>(EVENT_NAME.SKIP, {
          [BaseEventProps.EVENT_CATEGORY]: "Login",
          [BaseEventProps.EVENT_LABEL]: `QUICK ${base.module.toUpperCase()} LOGIN`,
          [BaseEventProps.MODULE]: base.module
        });
      default:
        return null;
    }
  });
};
