// @flow

import React from "react";
import style from "./style.css";

const LoginHeader = () => (
  <div className={style.loginBlockHeader}>
    <h3 className={style.loginBlockHeaderTitle}>login</h3>
  </div>
);

export default LoginHeader;
