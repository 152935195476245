import { get } from "lodash";
import mediator from "@tvg/mediator";
import { RaceTracksMediatorEventType } from "@tvg/ts-types/Amplitude";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";
import { EVENTS, MODULE } from "./constants";
import { RaceTracksEventData } from "./types";

export default () => {
  mediator.base.subscribe(
    RaceTracksMediatorEventType.TRACK_CLICK,
    (data: RaceTracksEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.trackName", ""),
        [BaseEventProps.MODULE]: "Track List",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
      });
    }
  );

  mediator.base.subscribe(
    RaceTracksMediatorEventType.RACE_CLICK,
    (data: RaceTracksEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATED_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: `${get(
          data,
          "payload.trackName",
          ""
        )} - R${get(data, "payload.raceNumber", "")} - ${get(
          data,
          "payload.mtp",
          ""
        )}`,
        [BaseEventProps.MODULE]: MODULE,
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
      });
    }
  );

  mediator.base.subscribe(
    RaceTracksMediatorEventType.ADD_FAVORITE_CLICK,
    (data: RaceTracksEventData) => {
      track<BaseEventProps>(EVENTS.ADD_TO_FAVORITES_CLICKED, {
        [BaseEventProps.MODULE]: MODULE,
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.trackName", "")
      });
    }
  );

  mediator.base.subscribe(
    RaceTracksMediatorEventType.ADD_FAVORITE_SUCCESS,
    (data: RaceTracksEventData) => {
      track<BaseEventProps>(EVENTS.ADD_TO_FAVORITES_SUCCESS, {
        [BaseEventProps.MODULE]: MODULE,
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.trackName", "")
      });
    }
  );

  mediator.base.subscribe(
    RaceTracksMediatorEventType.SEE_ALL_TODAYS_RACING,
    (data: RaceTracksEventData) => {
      track<BaseEventProps>(EVENTS.NAVIGATED_TO, {
        [BaseEventProps.MODULE]: MODULE,
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "See All Todays Racing",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
      });
    }
  );

  mediator.base.subscribe(
    RaceTracksMediatorEventType.RETURN_TO_RACETRACKS,
    () => {
      track<BaseEventProps>(EVENTS.BACK_ARROW_CLICKED, {
        [BaseEventProps.MODULE]: MODULE,
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: "Return To Track List"
      });
    }
  );
};
