// @flow

import styled, { css } from "styled-components";
import { fontMedium, fontCondensedNormal } from "../../_static/Typography";

export const TagLabel = styled.span`
  margin-left: ${({ flagPadding }) => (flagPadding ? "6px" : "4px")};
  color: ${({ fontColor }) => fontColor};
  font-family: ${({ isCondensed }) =>
    isCondensed ? fontCondensedNormal : fontMedium};
  font-size: ${({ textSize }) => `${textSize}px`};
  font-weight: 500;
  ${({ uppercase }) =>
    uppercase &&
    css`
      text-transform: uppercase;
    `}
`;

export const TagContainer = styled.div`
  background-color: ${({ bgColor }) => bgColor};
  height: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  width: max-content;
  border-radius: 12px;

  & > svg {
    padding-left: 6px;
    ${({ isCollapsed }) =>
      isCollapsed &&
      css`
        padding-right: 6px;
      `}
  }

  & > ${TagLabel} {
    padding-right: 6px;
  }
`;
