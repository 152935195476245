import React from "react";
import classNames from "classnames";
import * as mediator from "@tvg/mediator-classic/src";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { question, questionNewExp } from "@tvg/atomic-ui/_static/Icons/icons";
import style from "./style.css";

const sendUserAction = (type) => {
  mediator.dispatch("USER_ACTION", {
    userAction: "Contact",
    type,
    module: "Header"
  });
};

const TVGContactSection = ({
  supportLink,
  supportMessage,
  newHeaderExperience,
  tvgHeaderV2FeatureToggle
}) =>
  newHeaderExperience ? (
    <div className={classNames(style.tvgContactIconButton)}>
      <a
        className={style.tvgContactIcon}
        href={supportLink}
        onClick={(event) => sendUserAction(event)}
        rel="noopener noreferrer"
        target="_blank"
      >
        <i>
          <Icon
            className={tvgHeaderV2FeatureToggle && style.questionIcon}
            icon={tvgHeaderV2FeatureToggle ? questionNewExp : question}
            color={buildColor("blue", "100")}
            size={tvgHeaderV2FeatureToggle ? 20 : 24}
            viewBoxSize={tvgHeaderV2FeatureToggle ? 20 : 1024}
          />
          <span>{supportMessage}</span>
        </i>
      </a>
    </div>
  ) : (
    <div className={classNames(style.tvgContactSection)}>
      <div className={style.contactSubWrapper}>
        <a
          className={style.tvgMailContact}
          href={supportLink}
          onClick={(event) => sendUserAction(event)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon
            icon={tvgHeaderV2FeatureToggle ? questionNewExp : question}
            color={buildColor("white", "100")}
            size={10}
          />
          {supportMessage}
        </a>
      </div>
    </div>
  );

export default TVGContactSection;
