import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { EVENT_NAME, MODULE, EVENT_CATEGORY } from "./constants";
import {
  EventData,
  ErrorPayload,
  SuccessPayload,
  MediatorEventType
} from "./types";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(MediatorEventType.CONFIRM, (data: EventData<{}>) =>
    track<BaseEventProps>(EVENT_NAME.CONFIRM, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.MODULE]: data.payload.module || MODULE,
      [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
      [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
      [BaseEventProps.BET_TYPE]: data.payload.betType,
      [BaseEventProps.SELECTION_SOURCE]: data.payload.selectionSource,
      [BaseEventProps.SELECT_RACE_TYPE]: data.payload.raceType
    })
  );

  mediator.base.subscribe(
    MediatorEventType.ERROR,
    (data: EventData<ErrorPayload>) =>
      track<BaseEventProps>(EVENT_NAME.ERROR, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.ERROR_TYPE]: data.payload.error,
        [BaseEventProps.MODULE]: data.payload.module || MODULE,
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.RUNNER_SELECTION_LIST]: data.payload.selections,
        [BaseEventProps.RUNNER_AMOUNT]: data.payload.runnerAmount,
        [BaseEventProps.BET_TYPE]: data.payload.betType,
        [BaseEventProps.BET_AMOUNT]: data.payload.betAmount
      })
  );

  mediator.base.subscribe(
    MediatorEventType.SUCCESS,
    (data: EventData<SuccessPayload>) =>
      track<BaseEventProps>(EVENT_NAME.SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: data.payload.module || MODULE,
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.REPEAT_BET]: data.payload.repeatBet,
        [BaseEventProps.RUNNER_SELECTION_LIST]: data.payload.selections,
        [BaseEventProps.RUNNER_AMOUNT]: data.payload.runnerAmount,
        [BaseEventProps.BET_ID]: data.payload.betId,
        [BaseEventProps.BET_TYPE]: data.payload.betType,
        [BaseEventProps.BET_AMOUNT]: data.payload.betAmount,
        [BaseEventProps.SELECTION_SOURCE]: data.payload.selectionSource,
        [BaseEventProps.SELECT_RACE_TYPE]: data.payload.raceType
      })
  );
};
