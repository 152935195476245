// @flow

import React, { Component, type Children } from "react";
import {
  unmountComponentAtNode,
  // eslint-disable-next-line
  unstable_renderSubtreeIntoContainer
} from "react-dom";
import Modal from "./Modal";

type Props = {
  /**
   * True if modal should open when mouted
   */
  autoOpen?: boolean,
  /**
   * True if modal should always be opened and managed through state
   */
  alwaysOpen?: boolean,
  /**
   * This string identify the model and the element where she gonna be mouted.
   * Please make sure that each modal have a unique idenfify
   */
  modalIdentifier: string,

  modalParent: HTMLElement | string,
  /**
   * Type of the modal ('fullscreen' | 'regular' | 'presentation')
   */
  type?: "fullscreen" | "regular" | "presentation" | "scrollContent",
  /**
   * Content of the modal
   */
  children: Children, // eslint-disable-line react/no-unused-prop-types
  /**
   * Adicional classNames
   */
  classnames: string,

  onClose: () => mixed,

  closeModal: () => mixed,

  /**
   * prefix to be added to data-qa-label
   */
  qaLabel: string
};

type State = {
  isModalOpen: boolean,
  isClosing: boolean
};
/**
 * TVG Modal Component
 *
 * This component Allow to show a modal with custom content.
 */
class ModalPortal extends Component {
  static defaultProps = {
    autoOpen: false,
    alwaysOpen: false,
    type: "regular",
    qaLabel: ""
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isModalOpen: props.autoOpen || props.alwaysOpen || false,
      isClosing: false
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.setHandlers = this.setHandlers.bind(this);
    this.removeHandlers = this.removeHandlers.bind(this);
    this.onEscapePress = this.onEscapePress.bind(this);
  }
  state: State;

  componentDidMount() {
    this.portal = document.getElementById(
      `REACT_MODAL_ELEMENT_${this.props.modalIdentifier}`
    );

    const modalParent =
      typeof this.props.modalParent === "string"
        ? document.querySelector(this.props.modalParent)
        : this.props.modalParent;

    this.modalParent = modalParent || document.body;

    if (!this.portal) {
      this.portal = this.createElement();

      // $FlowFixMe
      this.modalParent.appendChild(this.portal);
    }

    if (this.state.isModalOpen) {
      unstable_renderSubtreeIntoContainer(
        this,
        this.renderModal(this.props),
        this.portal
      );
    }
  }

  componentWillUpdate(nextProps: Props, nextState: State) {
    if (nextState.isModalOpen) {
      if (!this.state.isModalOpen) {
        this.setHandlers();
      }

      if (nextProps !== this.props) {
        unstable_renderSubtreeIntoContainer(
          this,
          this.renderModal(nextProps),
          this.portal
        );
      } else {
        unstable_renderSubtreeIntoContainer(
          this,
          this.renderModal(this.props),
          this.portal
        );
      }
    }

    if (this.state.isModalOpen && !nextState.isModalOpen) {
      this.removeHandlers();
      unmountComponentAtNode(this.portal);
    }
  }

  componentWillUnmount() {
    if (this.portal) {
      // $FlowFixMe
      this.modalParent.removeChild(this.portal);
    }
  }

  onEscapePress: Function;
  onEscapePress(e: KeyboardEvent): ModalPortal {
    if (e.keyCode === 27) {
      this.close();
    }

    return this;
  }

  setHandlers: Function;
  setHandlers(): ModalPortal {
    // $FlowFixMe
    window.addEventListener("keyup", this.onEscapePress);

    return this;
  }

  close: Function;
  close(): ModalPortal {
    if (
      !this.state.isClosing &&
      this.state.isModalOpen &&
      !this.props.alwaysOpen
    ) {
      // I'm sorry, this is messy.
      this.modal.setCloseAnimation(() =>
        this.setState(
          {
            isModalOpen: false,
            isClosing: false
          },
          () => this.props.onClose && this.props.onClose()
        )
      );
    }

    if (this.props.alwaysOpen && this.props.closeModal) {
      this.props.closeModal();
    }
    return this;
  }

  createElement: () => HTMLElement;
  createElement() {
    const portal = document.createElement("div");
    portal.id = `REACT_MODAL_ELEMENT_${this.props.modalIdentifier}`;
    return portal;
  }

  open: Function;
  open(): ModalPortal {
    this.setState(() => ({ isModalOpen: true }));
    return this;
  }

  props: Props;

  removeHandlers: Function;
  removeHandlers(): ModalPortal {
    // $FlowFixMe
    window.removeEventListener("keyup", this.onEscapePress);

    return this;
  }

  portal: ?HTMLElement;
  modal: Modal;

  renderModal: Function;
  renderModal(props: Props) {
    return (
      <Modal
        ref={(c) => {
          this.modal = c;
        }}
        type={this.props.type}
        closeModal={this.close}
        classnames={this.props.classnames}
        qaLabel={this.props.qaLabel}
      >
        {props.children}
      </Modal>
    );
  }

  render() {
    return null;
  }
}

export default ModalPortal;
