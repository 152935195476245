// @flow
import styled from "styled-components";

import { buildText } from "../../_atom/Text";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

// The recover credentials story container is only meant to be used by ./story.jsx
export const RecoverCredentialsStoryContainer = styled.section`
  background-color: ${buildColor("white", "100")};
  padding: 20px;
`;

export const RecoverCredentialsContainer = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ isMobile }) => !isMobile && "justify-content: space-between;"}
`;

export const InfoMessage = styled(
  buildText({
    tag: "p",
    fontSize: 14,
    color: buildColor("grey", "900")
  })
)`
  margin-bottom: 20px;
  line-height: 18px;
  white-space: pre-line;
`;

export const RecoverLoginLink = styled.div`
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  font-family: ${fontMedium};
`;
