import loginModal, { initialState as loginModalState } from "./modalReducer";
import userData, { initialState as userDataState } from "./userDataReducer";
import alerts from "./alertsReducer";
import userFavorites, {
  initialState as userFavoritesState
} from "./userFavoritesReducer";
import geolocation, {
  initialState as geolocationState
} from "./geoLocationReducer";
import ios from "./iOSEventsReducer";

export const desktopLoginControllerReducersDefaultState = {
  loginModal: loginModalState,
  userData: userDataState,
  userFavorites: userFavoritesState,
  geolocation: geolocationState
};

// Workaround to not use all reducers for desktop, this should be viewed when improving login controller
export const desktopLoginControllerReducers = {
  loginModal,
  userData,
  userFavorites,
  geolocation
};

export default {
  loginModal,
  userData,
  alerts,
  userFavorites,
  ios,
  geolocation
};
