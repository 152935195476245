import mediator from "@tvg/mediator";
import { EventProps, MediatorEventType, ModuleEventProps } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.WAR_OPTIN, () => {
    track<BaseEventProps>(EVENT_NAME.WAGER_REWARDS, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: "Wager Rewards"
    });
  });

  mediator.base.subscribe(MediatorEventType.WAR_OPTIN_SUCCESS, () => {
    track<BaseEventProps>(EVENT_NAME.SUCCESS, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "Wager Rewards",
      [BaseEventProps.MODULE]: "Wager Rewards"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.WAR_OPTIN_ERROR,
    (data: { payload: { message: string } }) => {
      track<BaseEventProps>(EVENT_NAME.ERROR, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.TAG]: data.payload.message,
        [BaseEventProps.EVENT_LABEL]: "Wager Rewards",
        [BaseEventProps.MODULE]: "Wager Rewards"
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.WAR_FIND_RACE, () => {
    track<BaseEventProps>(EVENT_NAME.FIND_A_RACE, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.MODULE]: "Wager Rewards"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.WAR_CASH_IN,
    (data: { payload: number }) => {
      track<BaseEventProps>(EVENT_NAME.CASH_IN, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.TAG]: data.payload,
        [BaseEventProps.MODULE]: "Wager Rewards"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WAR_FILTER,
    (data: { payload: { filter: string; preCheckedFilter: string } }) => {
      track<EventProps>(EVENT_NAME.SELECT_FILTER, {
        [BaseEventProps.EVENT_CATEGORY]: "Filters",
        [BaseEventProps.EVENT_LABEL]: data.payload.filter,
        [ModuleEventProps.PRE_CHECKED]: data.payload.preCheckedFilter,
        [BaseEventProps.MODULE]: "Rewards History Filter"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WAR_SEE_ALL_HISTORY,
    (data: { payload: { points: number } }) => {
      track<BaseEventProps>(EVENT_NAME.SEE_ALL_HISTORY, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.TAG]: data.payload.points,
        [BaseEventProps.MODULE]: "Wager Rewards"
      });
    }
  );
};
