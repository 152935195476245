import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  MediatorEventType,
  EventProps,
  SelectBetTypeType,
  ModuleEventProps
} from "./types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.RUNNER_SELECTION,
    (data: SelectBetTypeType): void => {
      track<EventProps>(EVENT_NAME.ADD_TO_BETSLIP, {
        [BaseEventProps.EVENT_CATEGORY]: "Selection",
        [ModuleEventProps.PLACE_TYPE]: get(data, "payload.option"),
        [BaseEventProps.MODULE]: get(data, "payload.module", "Race Card"),
        [BaseEventProps.RACE_NUMBER_SELECTION]: get(
          data,
          "payload.currentRace.raceNumber"
        ),
        [BaseEventProps.RACE_TRACK_SELECTION]: get(
          data,
          "payload.currentRace.track.trackName"
        ),
        [BaseEventProps.BET_TYPE]: get(data, "payload.betTypeCode")
      });
    }
  );
};
