import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.NAVIGATION,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.NAVIGATION, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: `${get(data, "payload.trackName")} - R${get(
          data,
          "payload.number"
        )} - ${get(data, "payload.mtp")}`,
        [BaseEventProps.MODULE]: "home page tl",
        [BaseEventProps.LINK_URL]: get(data, "payload.url")
      })
  );
};
