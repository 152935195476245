// @flow

import { throttle, get } from "lodash";
import type { Dispatch } from "redux";
import Poller from "@tvg/poller";
import uwt from "@tvg/api/uwt";
import capi from "@tvg/api/capi";
import {
  updateBalance,
  updatePromoFunds
} from "@tvg/shared-actions/UserActions";
import {
  failBalanceRequest,
  successBalancePollerRequest
} from "@tvg/sh-lib-account-actions/src/actions/login";

const BalancePoller = new Poller();

export const requestBalanceTimer = (dispatch: Dispatch<*>) =>
  capi
    .fetchBrazePollerTime()
    .then((response) =>
      dispatch(successBalancePollerRequest(+response.data.value))
    );

const requestBalance = throttle(
  (
    accountNumber: string,
    isPoller: boolean,
    showPromoFunds: boolean,
    dispatch: Dispatch<*>
  ) => {
    uwt
      .getBalance(+accountNumber, isPoller, showPromoFunds)
      .then((response) => {
        dispatch(updateBalance(response.data.balance));
        dispatch(updatePromoFunds(get(response, "data.promoFunds", 0)));
      })
      .catch((err: Error) => dispatch(failBalanceRequest(err)));
  },
  2000,
  { trailing: false }
);

export const updateBalanceRequest = (
  isLogged: boolean,
  accountNumber: string,
  balancePoller: number,
  dispatch: Dispatch<*>
) => {
  if (!isLogged) {
    BalancePoller.stop();
  } else if (accountNumber !== "") {
    BalancePoller.start(
      () => requestBalance(accountNumber, true, true, dispatch),
      balancePoller * 1000
    );
  }
};
