// @flow

import React, { PureComponent, type Node } from "react";
import createTextHighlightComponent from "./styled-components";
import buildColor from "../../_static/ColorPalette";

export type HighlightType =
  | "primary"
  | "secondary"
  | "bet"
  | "marketing"
  | "pending";

type HighlightTypeMapItem = {
  bgColor: string,
  textColor: string
};

type HighlightTypeMap = {
  [key: string]: HighlightTypeMapItem
};

export const TYPES: HighlightTypeMap = {
  primary: {
    bgColor: buildColor("blue_accent", "500"),
    textColor: buildColor("white", "100")
  },
  secondary: {
    bgColor: buildColor("blue", "100"),
    textColor: buildColor("grey", "900")
  },
  bet: {
    bgColor: buildColor("green", "600"),
    textColor: buildColor("white", "100")
  },
  marketing: {
    bgColor: buildColor("yellow", "500"),
    textColor: buildColor("grey", "900")
  },
  pending: {
    bgColor: buildColor("grey", "100"),
    textColor: buildColor("grey", "800")
  }
};
type Props = {
  /**
   * children of the Text component
   */
  children: Node,
  /**
   * Type "primary" | "secondary" | "bet" | "marketing" | "pending"
   */
  type: HighlightType,
  /**
   * Size of font
   */
  fontSize: number,
  /**
   * Transform text in uppercase
   */
  uppercase: boolean,
  /**
   * For bold text
   */
  bold: boolean,
  /**
   * To be able import this _atom and add new properties
   */
  className: string,
  /**
   * QA Label
   */
  qaLabel: string
};

export const getType = (type: HighlightType) => TYPES[type];

export const buildTextHighlighted = createTextHighlightComponent;

export default class TextHighlighted extends PureComponent<Props> {
  static defaultProps = {
    children: null,
    fontSize: 11,
    type: "primary",
    uppercase: true,
    bold: false,
    className: "",
    qaLabel: ""
  };

  render() {
    const ExtendedText = createTextHighlightComponent(
      getType(this.props.type),
      this.props.fontSize,
      this.props.bold,
      this.props.uppercase,
      this.props.qaLabel
    );

    return (
      <ExtendedText className={this.props.className}>
        {this.props.children}
      </ExtendedText>
    );
  }
}
