export const EVENT_CATEGORY = "Forgot Credentials";
export const MODULE_FORGOT_CREDENTIALS = "Forgot Credentials";
export const MODULE_RECOVER_EMAIL = "Recover Email Address";

export const EVENTS = {
  RESET_SUBMIT_ATTEMPTED: "Credentials For Reset Submit Attempted",
  RESET_SUBMIT_SUCCESS: "Credentials For Reset Submit Success",
  RESET_SUBMIT_ERROR: "Credentials For Reset Submit Error",
  RESET_RE_SUBMITTED: "Credentials For Reset Re-Submitted",
  CANCEL_CREDENTIALS_RESET_CLICKED: "Cancel Credentials Reset Clicked",
  NEW_CREDENTIALS_SUBMITTED: "New Credentials Submitted",
  NEW_CREDENTIALS_UPDATED_SUCCESS: "New Credentials Updated Success",
  BACK_ARROW_CLICKED: "Back Arrow Clicked",
  RESET_CREDENTIALS_EMAIL_LINK_LANDED: "Reset Credentials Email Link Landed",
  RECOVER_EMAIL_SUBMIT_ATTEMPTED:
    "Credentials For Recover Email Submit Attempted",
  RECOVER_EMAIL_SUBMIT_ERROR: "Credentials For Recover Email Submit Error",
  RECOVER_EMAIL_SUBMIT_FAILED: "Credentials For Recover Email Submit Failed",
  RECOVER_EMAIL_SUBMIT_SUCCESS: "Credentials For Recover Email Submit Success",
  FIELD_COMPLETED: "Field Completed",
  CTA_LINK_CLICKED: "CTA Link Clicked"
};
