// @flow
import React, { Component } from "react";
import _ from "lodash";
import Loading from "@tvg/ui-bootstrap/components/Loading/";
import interceptRequests from "@tvg/perimeterx/src";
import style from "./style.css";
import Header from "./Header/index.jsx";
import SignupBlock from "./SignupBlock/index.jsx";
import LoginBlock from "./LoginBlock/index.jsx";
import TabSelector from "./TabSelector/index.jsx";

import type { ResponseObject } from "../Types.js.flow";

type Props = {
  cmsMsgs?: Object,
  mobile?: boolean,
  dropdown?: boolean,
  signupToShow?: boolean,
  triggerLogin?: (par1: Object, par2?: boolean) => Promise<ResponseObject>,
  closeModal?: () => void,
  callback?: () => void,
  closeDropdownCallback?: () => void
};

class LoginSignup extends Component {
  static defaultProps = {
    mobile: false,
    dropdown: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      signupToShow: this.props.signupToShow || false,
      isLoading: false
    };

    _.bindAll(this, [
      "renderOther",
      "renderMobile",
      "changeActiveTab",
      "isLoadingHandler",
      "renderDropdownLogin"
    ]);
  }

  state: {
    signupToShow: boolean,
    isLoading: boolean
  };

  componentDidMount(): void {
    interceptRequests();
  }

  isLoadingHandler: () => void;

  isLoadingHandler(param: boolean) {
    this.setState({
      isLoading: param
    });
  }

  changeActiveTab: () => void;

  changeActiveTab() {
    this.setState({
      signupToShow: !this.state.signupToShow
    });
  }

  renderOther: () => void;

  renderOther() {
    return (
      <div className={style.loginWrapperOther}>
        <Header closeModal={this.props.closeModal} />
        <div className={style.signupWrapper}>
          <SignupBlock />
        </div>
        <LoginBlock
          cmsMsgs={this.props.cmsMsgs}
          triggerLogin={this.props.triggerLogin}
          closeModal={this.props.closeModal}
          isLoadingHandler={this.isLoadingHandler}
          callback={this.props.callback}
        />
        {this.state.isLoading && <Loading />}
      </div>
    );
  }

  renderMobile: () => void;

  renderMobile() {
    return (
      <div className={style.loginWrapperMobile}>
        <Header closeModal={this.props.closeModal} />
        <TabSelector changeActiveTab={this.changeActiveTab} />
        <div className={style.signupWrapperMobile}>
          {this.state.signupToShow ? (
            <SignupBlock mobile />
          ) : (
            <LoginBlock
              mobile
              triggerLogin={this.props.triggerLogin}
              closeModal={this.props.closeModal}
              isLoadingHandler={this.isLoadingHandler}
              callback={this.props.callback}
              cmsMsgs={this.props.cmsMsgs}
            />
          )}
        </div>
        {this.state.isLoading && <Loading />}
      </div>
    );
  }

  renderDropdownLogin: () => void;

  renderDropdownLogin() {
    return (
      <div className={style.loginTestWrapper}>
        <LoginBlock
          dropdown
          cmsMsgs={this.props.cmsMsgs}
          triggerLogin={this.props.triggerLogin}
          isLoadingHandler={this.isLoadingHandler}
          closeDropdownCallback={this.props.closeDropdownCallback}
        />
        {this.state.isLoading && <Loading />}
      </div>
    );
  }

  render() {
    if (this.props.mobile) {
      return this.renderMobile();
    } else if (this.props.dropdown) {
      return this.renderDropdownLogin();
    } else {
      return this.renderOther();
    }
  }
}

export default LoginSignup;
