// @flow
import mediator from "@tvg/mediator";
import { isEqual, get } from "lodash";
import { CONSTANTS } from "@tvg/login-service/services/static";
import type { User } from "@tvg/login-service/src/types";

type State = {
  // TODO: check this, only added hasRequest and forceSessionUpdate here because line 68 and 71
  hasRequest: boolean,
  forceSessionUpdate: boolean,
  hasRequested: boolean,
  logging: boolean,
  logged: boolean,
  error: ?string,
  wasLogin: boolean,
  user: ?User,
  userLogin: ?boolean,
  userLogout: ?boolean,
  loginPin: ?boolean,
  geolocateIn?: ?number
};
export const initialState: State = {
  hasRequest: false,
  forceSessionUpdate: false,
  hasRequested: false,
  logging: false,
  logged: false,
  error: null,
  wasLogin: false,
  userLogin: false,
  userLogout: false,
  loginPin: false,
  geolocateIn: 0,
  user: null
};

type SessionSuccessType = {
  fromLogin: boolean,
  userLogin: boolean,
  loginPin: boolean,
  data: {
    userDetails: User,
    session: {
      tvg3token: string
    },
    geoDetails?: {
      geolocateIn: number
    }
  }
};

type SessionErrorType = {
  fromLogin: boolean,
  userLogin: boolean,
  loginPin: boolean,
  message: string
};

type SetUserType = {
  data?: {
    geoDetails?: {
      geolocateIn: number
    }
  },
  user: User,
  forceSessionUpdate: boolean,
  fromLogin: boolean,
  userLogin: boolean
};

export const mediatorSessionUpdateMiddleware = (
  oldState: State,
  newState: State,
  tvg3token?: string
): State => {
  if (
    !oldState.hasRequest ||
    !isEqual(oldState.logged, newState.logged) ||
    !isEqual(oldState.user, newState.user) ||
    !!newState.forceSessionUpdate
  ) {
    mediator.base.dispatch({
      type: CONSTANTS["TVG_LOGIN:USER_SESSION_UPDATE"],
      payload: tvg3token ? { ...newState, tvg3token } : newState
    });
  }

  return newState;
};

const reducers = {
  [CONSTANTS.SET_SESSION_SUCCESS]: (
    state: State,
    payload: SessionSuccessType
  ): State =>
    mediatorSessionUpdateMiddleware(
      state,
      {
        ...state,
        ...{
          user: payload.data ? payload.data.userDetails : null,
          hasRequested: true,
          logged: true,
          logging: false,
          wasLogin: payload.fromLogin,
          userLogin: payload.userLogin,
          loginPin: payload.loginPin,
          geolocateIn: get(payload.data, "geoDetails.geolocateIn", 0),
          error: null
        }
      },
      get(payload, "data.session.tvg3token")
    ),

  [CONSTANTS.SET_SESSION_ERROR]: (
    state: State,
    payload: SessionErrorType
  ): State =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: null,
        hasRequested: true,
        logged: false,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        loginPin: payload.loginPin,
        error: payload.message
      }
    }),
  [CONSTANTS.SET_USER]: (state: State, payload: SetUserType): State =>
    mediatorSessionUpdateMiddleware(state, {
      ...state,
      ...{
        user: payload.user,
        hasRequested: true,
        forceSessionUpdate: payload.forceSessionUpdate || false,
        logged: true,
        logging: false,
        wasLogin: payload.fromLogin,
        userLogin: payload.userLogin,
        geolocateIn: get(payload.data, "geoDetails.geolocateIn", 1),
        error: null
      }
    }),
  [CONSTANTS.CLEAR_SESSION]: (
    state: State,
    payload: { userLogout: boolean }
  ): State =>
    mediatorSessionUpdateMiddleware(state, {
      ...initialState,
      ...{
        hasRequested: true,
        userLogout: payload.userLogout,
        optedInPromos: [],
        balance: undefined
      }
    })
};

export default (
  state: State = initialState,
  action: { type: string, payload?: * }
): State => {
  const validAction = action || { type: "" };

  return validAction.type && reducers[validAction.type]
    ? reducers[validAction.type](state, validAction.payload)
    : state;
};
