import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  POSITION = "Position"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  CLICK = "BRAZE:CLICK_CARD",
  IMPRESSION = "BRAZE:CARD_IMPRESSION",
  DISMISS = "BRAZE:DISMISS_CARD"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    id: string;
    position: number;
    pinned: boolean;
  };
}
