import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  CLICK_HOME: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_TRACKS: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_LIVE: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_PROMOS: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_BETS: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_MORE: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_ACCOUNT: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_PICKS: COMMON_EVENT_NAMES.NAVIGATED,
  CLICK_LINKS: COMMON_EVENT_NAMES.NAVIGATED
};
