// @flow
import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

type BrazeGtmPayload = {
  payload: {
    id: string,
    position: number,
    pinned: boolean
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "BRAZE:CARD_IMPRESSION",
    (data: BrazeGtmPayload): void => {
      pushToDataLayer({
        event: "contentCards",
        gaEventCategory: "Content Cards",
        gaEventAction: "Content Card Impression",
        gaEventLabel: data?.payload?.id || "",
        position: data?.payload?.position || 0,
        tag: data?.payload?.pinned ? "Pinned" : "Unpinned",
        module: "Content Cards"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BRAZE:CLICK_CARD",
    (data: BrazeGtmPayload): void => {
      pushToDataLayer({
        event: "contentCards",
        gaEventCategory: "Content Cards",
        gaEventAction: "Content Card Opened",
        gaEventLabel: data?.payload?.id || "",
        position: data?.payload?.position || 0,
        tag: data?.payload?.pinned ? "Pinned" : "Unpinned",
        module: "Content Cards"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BRAZE:DISMISS_CARD",
    (data: BrazeGtmPayload): void => {
      pushToDataLayer({
        event: "contentCards",
        gaEventCategory: "Content Cards",
        gaEventAction: "Content Card Dismissed",
        gaEventLabel: data?.payload?.id || "",
        position: data?.payload?.position || 0,
        tag: data?.payload?.pinned ? "Pinned" : "Unpinned",
        module: "Content Cards"
      });
    }
  );
};
