import mediator from "@tvg/mediator";

import { MediatorEventType } from "./types";
import { track } from "../../../amplitude";
import { BaseEventProps } from "../../../types";
import { COMMON_EVENT_NAMES } from "../../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.PAGEVIEW, () => {
    track<BaseEventProps>(COMMON_EVENT_NAMES.PAGE_VIEWED, {});
  });
};
