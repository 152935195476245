import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  SAVE_CHANGES: "Save Changes",
  NAVIGATE_MORE_PAGE: COMMON_EVENT_NAMES.NAVIGATED,
  PAGE_VIEW: "FDR Account Preferences Opened",
  PREFERENCE_TOGGLE: "FDR Account Preferences Toggled",
  FDR_ACCOUNT_DEFAULT_BET_TYPE_SELECTED:
    "FDR Account Default Bet Type Selected",
  FDR_ACCOUNT_DEFAULT_BET_AMOUNT: "FDR Account Default Bet Amount Selected",
  FDR_ACCOUNT_CUSTOM_BET_AMOUNT: "FDR Account Custom Bet Amount Applied"
};
