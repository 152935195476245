import LinkFactory from "../pages/factories/linkFactory";

export default function reducer(
  state = {
    loading: false,
    nextRace: null,
    nextRaceLink: null,
    nextBetslipRaceLink: "/betslip",
    greyhoundRace: null,
    greyhoundRaceLink: null,
    betslipGreyhoundRaceLink: "/betslip",
    nextGreyhoundRace: null,
    nextGreyhoundRaceLink: null,
    error: null
  },
  action
) {
  switch (action.type) {
    case "NEXT_RACE_PENDING": {
      return { ...state, loading: true };
    }
    case "NEXT_RACE_FULFILLED": {
      return {
        ...state,
        loading: false,
        nextRace: action.payload,
        nextRaceLink: LinkFactory.buildHorseLink(action.payload),
        nextBetslipRaceLink: LinkFactory.buildBetSlipLink(action.payload)
      };
    }
    case "GREYHOUND_RACE_FULFILLED": {
      return {
        ...state,
        loading: false,
        greyhoundRace: action.payload,
        greyhoundRaceLink: LinkFactory.buildGreyhoundLink(action.payload),
        betslipGreyhoundRaceLink: LinkFactory.buildBetSlipGreyhoundLink(
          action.payload
        )
      };
    }
    case "NEXT_GREYHOUND_RACE": {
      return {
        ...state,
        loading: false,
        nextGreyhoundRace: action.payload,
        nextGreyhoundRaceLink: LinkFactory.buildGreyhoundLink(action.payload)
      };
    }
    case "NO_NEXT_GREYHOUNDS": {
      return {
        ...state,
        greyhoundRace: null,
        greyhoundRaceLink: null,
        nextGreyhoundRace: null,
        nextGreyhoundRaceLink: null,
        loading: false,
        error: action.payload
      };
    }
    case "NO_NEXT_RACE": {
      return {
        ...state,
        nextRace: null,
        nextRaceLink: "/betslip",
        loading: false,
        error: action.payload
      };
    }
    case "NEXT_RACE_REJECTED": {
      return {
        ...state,
        nextRace: null,
        greyhoundRace: null,
        greyhoundRaceLink: null,
        nextGreyhoundRace: null,
        nextGreyhoundRaceLink: null,
        nextBetslipRaceLink: "/betslip",
        loading: false,
        error: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
