import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  PageViewMediatorEventType,
  PageViewEventData,
  EventsProps
} from "./types";

export default () => {
  mediator.base.subscribe(
    PageViewMediatorEventType.PAGE_VIEW,
    (data: PageViewEventData) => {
      // @TODO: confirm event action
      track<EventsProps>("Page View", {
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId", ""),
        [BaseEventProps.RESIDENCE_STATE]: get(
          data,
          "payload.residenceState",
          ""
        )
      });
    }
  );

  mediator.base.subscribe(
    PageViewMediatorEventType.VIRTUAL_PAGE_VIEW,
    (data: PageViewEventData) => {
      // @TODO: confirm event action
      track<EventsProps>("Virtual Page Load", {
        [BaseEventProps.PAGE]: `${get(
          data,
          "payload.location.pathname",
          "/registration"
        )}/${get(data, "payload.view", "")}`,
        [BaseEventProps.SCREEN_NAME]: get(data, "payload.view", "")
      });
    }
  );
};
