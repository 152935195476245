import axios from "axios";
import TVGConf from "@tvg/conf";
import errorMessages from "./errorMessages.json";

export default class GeoComplySvc {
  constructor(clientApp) {
    this.geoClient = null;
    this.geoLicense = null;
    this.clientApp = clientApp;

    this.getPack = this.getPack.bind(this);
    this.getLicence = this.getLicence.bind(this);
    this.getGeoPacket = this.getGeoPacket.bind(this);

    if (typeof window !== "undefined") {
      this.geoComplyIsPresent = window.GcHtml5;

      if (!this.geoComplyIsPresent) {
        console.log(errorMessages.missingGeo); // eslint-disable-line
      } else {
        this.geoClient = window.GcHtml5.createClient(null, null);
        this.geoClient.setReason("GeoComply login for TVG");

        // Register the onSuccess handler
        this.geoClient.events.on("engine.success", (text, xml) => {
          this.geoClient.events.emit("geoPacketAvailable", {
            success: { text, xml }
          });
        });

        // Register error handler
        this.geoClient.events.on("*.failed", (code, message) => {
          this.geoClient.events.emit("geoPacketAvailable", {
            error: { code, message }
          });
        });
      }
    }
  }

  getGeoPacket(userId) {
    if (!this.geoComplyIsPresent || TVGConf().brand !== "4njbets") {
      return undefined;
    }

    if (!this.geoClient) {
      this.geoClient = window.GcHtml5.createClient(null, null);
      this.geoClient.setReason("GeoComply login for TVG");
    }

    if (!this.geoLicense) {
      return this.getLicence(userId).then(this.getPack);
    }

    return this.getPack(userId);
  }

  getLicence(userId) {
    return axios({
      method: "get",
      url: `${TVGConf().config().service.geo}/license`,
      headers: {
        Accept: "application/json",
        "x-tvg-context": TVGConf().context()
      },
      withCredentials: true
    })
      .then((response) => {
        this.geoLicense = response.data.license;
        return userId;
      })
      .catch(() => {
        this.geoLicense = null;
        return undefined;
      });
  }

  getPack(userId) {
    if (!this.geoClient || !this.geoLicense) {
      return Promise.reject(errorMessages.missingGeo);
    }

    if (!userId) {
      return Promise.reject(errorMessages.missingUserID);
    }

    return new Promise((resolve, reject) => {
      this.geoClient.events.on("geoPacketAvailable", (data) => {
        if (data.success) {
          resolve({ geo: data.success.text });
        } else {
          reject(data.error);
        }
      });

      this.geoClient.setUserId(userId.trim().toLowerCase());
      this.geoClient.setLicense(this.geoLicense);
      this.geoClient.request();
    });
  }
}
