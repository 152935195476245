import { useEffect } from "react";
import { isTvg5 } from "@tvg/utils/generalUtils";
import useExperiments from "../../useExperiments";
import { EXPERIMENT_KEY, DEPLOYMENT_KEY } from "./constants";
import { ValueEnum } from "./types";
import {
  clearTvg5Cookie,
  setTvg5Cookie,
  shouldExecuteExperiment
} from "./utils";

export const useTvg5 = (accountNumber: string = "") => {
  const { setUserExperiment } = useExperiments(DEPLOYMENT_KEY, {
    // Disable automatic fetch on experiments when identity change
    automaticFetchOnAmplitudeIdentityChange: false
  });

  useEffect(() => {
    if (shouldExecuteExperiment(accountNumber)) {
      setUserExperiment({ user_id: accountNumber }).then((experiment) => {
        const variant = experiment?.variant(EXPERIMENT_KEY);

        // If value received is tvg5 and we are in TVG4
        if (variant?.value === ValueEnum.TVG5 && !isTvg5()) {
          setTvg5Cookie();
          // If value received is tvg4 and we are in TVG5
        } else if (variant?.value === ValueEnum.TVG4 && isTvg5()) {
          clearTvg5Cookie();
        }
      });
    }
  }, [accountNumber]);
};
