// @flow
import React from "react";
import { noop, isNaN } from "lodash";
import tvgConf from "@tvg/conf";
import type { NullaryFn } from "@tvg/types/Functional";
import type { Device } from "@tvg/types/Device";
import Icon from "../../_static/Icons";
import { envelope } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import type { IconType } from "../../_static/Icons/Types.js.flow";
import NotificationBubbleContainer from "./styled-components";

type Props = {
  counter: number,
  counterLimit?: number,
  icon?: IconType,
  to: string,
  clickHandler?: NullaryFn<void>,
  className?: string,
  qaLabel: string,
  device: Device,
  as: string,
  tvgHeaderV2FeatureToggle?: boolean,
  viewBoxSize: number | string,
  iconSize: number
};

const COUNTER_LIMIT_DEFAULT = 99;
const LOGO_SIZE = 32;
// const ICON_SIZE_M = 16;
const ICON_SIZE_L = 20;

const getCounterValue = (counter: number, counterLimit: ?number) => {
  if (counter && !isNaN(counter)) {
    return counter < (counterLimit || COUNTER_LIMIT_DEFAULT)
      ? `${counter}`
      : `+${counterLimit || COUNTER_LIMIT_DEFAULT}`;
  }
  return null;
};

const NotificationBubble = ({
  counter,
  counterLimit,
  icon,
  to,
  clickHandler,
  qaLabel,
  className,
  device,
  as,
  tvgHeaderV2FeatureToggle,
  viewBoxSize,
  iconSize
}: Props) => {
  const isDesktop = tvgConf().device === "desktop";

  return (
    <NotificationBubbleContainer
      data-qa-label={qaLabel}
      className={className}
      size={LOGO_SIZE}
      to={to}
      onClick={clickHandler}
      $isDesktop={isDesktop}
      $tvgHeaderV2FeatureToggle={tvgHeaderV2FeatureToggle}
      $isCounterShown={counter > 0}
      counter={getCounterValue(counter, counterLimit)}
      as={as}
    >
      {tvgHeaderV2FeatureToggle ? (
        <Icon
          icon={icon}
          size={isDesktop ? iconSize : 24}
          viewBoxSize={isDesktop ? viewBoxSize : 24}
          qaLabel={`${qaLabel}-icon`}
          color="transparent"
        />
      ) : (
        <Icon
          icon={icon}
          size={24}
          viewBoxSize={isDesktop ? viewBoxSize : 24}
          qaLabel={`${qaLabel}-icon`}
          color={isDesktop ? buildColor("blue", "100") : "transparent"}
        />
      )}
    </NotificationBubbleContainer>
  );
};

NotificationBubble.defaultProps = {
  counterLimit: COUNTER_LIMIT_DEFAULT,
  icon: envelope,
  to: "#content-cards",
  clickHandler: noop,
  className: "",
  device: "mobile",
  as: "",
  viewBoxSize: 1024
};

export default NotificationBubble;
