import { TopPool } from "@tvg/ts-types/Pool";
import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  SCROLL_INTERACTION = "TOP_POOLS_SCROLL_INTERACTION",
  GO_TO_POOL = "TOP_POOLS:GO_TO_POOL"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    pool: TopPool;
    url: string;
  };
}
