export enum MediatorEventType {
  TOGGLE = "RACE_ALERTS:TOGGLE"
}

// Payload type data
export interface RaceAlertsType {
  type: MediatorEventType;
  payload: {
    toggledOn: boolean; // true if being turned on, false otherwise
    module: string;
    eventLabel: string; // "Race Alerts" or "Alerts For Race"
  };
}
