import { Menu } from "../models/MenuModels/index";

// **This has been replaced by capi message of globalTVG4MenuItems

export default (domain, brand = "tvg") => {
  const menuItems = [
    new Menu("Racing", "/races", {}, "", [], "racingButton"),
    new Menu(
      "Handicapping",
      "/handicapping/handicapping-store",
      {},
      "",
      [],
      "handicappingButton"
    ),
    new Menu(
      "Promotions",
      `https://${domain}/promos/?utm_source=fullsite&utm_medium=headerlink&utm_campaign=Promos%20Header%20Link`,
      "newWindow",
      "",
      [
        {
          name: "Live Promotions",
          route: `https://${domain}/promos/?utm_source=fullsite&utm_medium=headerlink&utm_campaign=Promos%20Header%20Link`,
          subQaLabel: "livePromotionsButton",
          options: "newWindow"
        },
        brand !== "pabets" && {
          name: "Wager Rewards",
          route: "/wager-rewards",
          subQaLabel: "wagerRewardsButton"
        }
      ].filter(Boolean),
      "promotionsButton"
    ),
    new Menu(
      "Support Center",
      "https://support.tvg.com",
      "newWindow",
      "",
      [],
      "helpButton"
    ),
    new Menu(
      "Watch FDTV",
      `https://${domain}/live`,
      "newWindow",
      "sideMenuButton",
      [],
      "watchLiveButton",
      "video"
    )
  ];
  return menuItems;
};
