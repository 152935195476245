import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  BET_ID = "Bet ID"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  LINK_CLICK = "REPEAT_BETS:LINK_CLICK",
  CONFIRM = "REPEAT_BETS:CONFIRM_BET",
  SET_BET = "REPEAT_BETS:SET_BETS",
  BACK = "REPEAT_BETS:BACK",
  SET_MAX = "REPEAT_BETS:SET_TO_MAX",
  SHOW = "REPEAT_BETS:SHOW"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    error?: string;
    selections?: string;
    trackName?: string;
    raceNumber?: string;
    betAmount?: number;
    confirmMode?: boolean;
    betType?: string;
    runnerType?: "Horse Racing" | "Greyhound Racing";
    repeatBet?: string;
    module?: string;
    tag?: string;
  };
}
