// @flow
import { type Dispatch } from "redux";
import type { Message } from "@tvg/types/Messages";

import {
  type Actions,
  addMessage,
  removeMessage,
  removeAllMessages
} from "./actions";

const MESSAGE_REMOVE_TIMEOUT = 4000; // 4 seconds

class MessengerService {
  dispatcher: Dispatch<Actions>;

  messages: Message[] = [];

  setDispatcher(dispatcher: Dispatch<Actions>) {
    this.dispatcher = dispatcher;
  }

  create(message: Message) {
    const key = this.messages.length;
    const msg = { ...message, key };

    if (message.showCloseButton) {
      msg.onClose = () => this.dispatcher(removeMessage(key));
    } else {
      setTimeout(
        () => this.dispatcher(removeMessage(key)),
        MESSAGE_REMOVE_TIMEOUT
      );
    }

    this.dispatcher(addMessage(msg));

    return msg;
  }

  clearAllMessages() {
    this.dispatcher(removeAllMessages());
  }
}

const service = new MessengerService();
export default service;
