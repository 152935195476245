// @flow

import React, { Fragment } from "react";
import { noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import formatCurrency, { formatNumber } from "@tvg/formatter/currency";
import type { Device } from "@tvg/types/Device";
import ButtonLink from "@tvg/atomic-ui/_atom/Buttons/buttonLink";
import { deposit, exclamation } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import {
  AmountContainer,
  AmountButton,
  BalanceWrapperLink,
  BalanceAmount,
  BalanceDescription,
  AmountButtonTooltip,
  ExclamationIcon,
  IconMask,
  NewAmountButton,
  IconWrapper,
  ButtonLinkContainer
} from "./styled-components";

export type Props = {
  qaLabel: string,
  balanceAmount: number,
  balanceLabel?: string,
  balanceLowLabel?: string,
  balanceButtonTooltip?: string,
  balanceLowThreshold: number,
  onBalanceClick: NullaryFn<mixed>,
  isBalanceShown?: boolean,
  device: Device,
  url: string,
  newQuickDepositButton?: boolean,
  tvgHeaderV2FeatureToggle: boolean,
  amountButtonTooltipAlignment?: "left" | "center"
};

const formatBalanceAmount = (balance, headerV2) => {
  if (balance === null) {
    return "-.--";
  }
  return headerV2 ? formatNumber(balance) : formatCurrency(balance);
};

const BalanceButton = (props: Props) => {
  const {
    balanceLabel,
    balanceLowLabel,
    balanceButtonTooltip,
    balanceAmount,
    balanceLowThreshold,
    onBalanceClick,
    isBalanceShown,
    qaLabel,
    device,
    url,
    newQuickDepositButton,
    tvgHeaderV2FeatureToggle,
    amountButtonTooltipAlignment = "center"
  } = props;

  const isLowBalance: boolean = balanceAmount < balanceLowThreshold;
  const isDesktop = device === "desktop";

  return newQuickDepositButton && isDesktop ? (
    <ButtonLinkContainer>
      <ButtonLink
        type={isLowBalance ? "bet" : "secondary_alt"}
        size="bigger"
        url={url}
        qaLabel="quickDepositButton"
        onClick={onBalanceClick}
      >
        <NewAmountButton>
          <IconWrapper>
            <Icon icon={deposit} color={buildColor("white", "100")} size={17} />
          </IconWrapper>
          {balanceButtonTooltip && (
            <AmountButtonTooltip newQuickDepositButton>
              {balanceButtonTooltip}
            </AmountButtonTooltip>
          )}
          {isBalanceShown && (
            <BalanceAmount newQuickDepositButton isDesktop={isDesktop}>
              {formatCurrency(balanceAmount)}
            </BalanceAmount>
          )}
        </NewAmountButton>
      </ButtonLink>
    </ButtonLinkContainer>
  ) : (
    <AmountContainer
      tvgHeaderV2FeatureToggle={tvgHeaderV2FeatureToggle}
      isDesktop={isDesktop}
      isLowBalance={isLowBalance}
      isBalanceShown={isBalanceShown}
      tag={!isDesktop && "div"}
      size="micro"
      url={url}
      qaLabel="quickDepositButton"
    >
      {isBalanceShown && (
        <BalanceWrapperLink
          to={url}
          onClick={(e) => {
            // @TODO This is a 🔨 fixing a bug without changing too much stuff look at this later
            e.preventDefault();
          }}
        >
          <BalanceAmount
            isDesktop={isDesktop}
            tvgHeaderV2FeatureToggle={tvgHeaderV2FeatureToggle}
            data-qa-label="balance-amount"
          >
            {formatBalanceAmount(balanceAmount, tvgHeaderV2FeatureToggle)}
          </BalanceAmount>
          {!tvgHeaderV2FeatureToggle && (
            <BalanceDescription
              isDesktop={isDesktop}
              isLowBalance={isLowBalance}
            >
              {isLowBalance ? balanceLowLabel : balanceLabel}
            </BalanceDescription>
          )}
        </BalanceWrapperLink>
      )}
      <AmountButton
        tag={isDesktop && tvgHeaderV2FeatureToggle && "div"}
        tvgHeaderV2FeatureToggle={tvgHeaderV2FeatureToggle}
        isDesktop={isDesktop}
        isLowAmount={isLowBalance}
        size="micro"
        url={url}
        qaLabel="quickDepositButton"
        onClick={onBalanceClick}
      >
        {tvgHeaderV2FeatureToggle ? (
          <Icon color={buildColor("white", "100")} icon={deposit} size={16} />
        ) : (
          <Icon
            icon={deposit}
            color={buildColor("blue", "100")}
            size={isDesktop ? 24 : 16}
          />
        )}
        {isLowBalance && !tvgHeaderV2FeatureToggle && (
          <Fragment>
            <IconMask />
            <ExclamationIcon
              icon={exclamation}
              // size={isDesktop ? 18 : 10}
              size={18}
              qaLabel={`${qaLabel}-overlay-icon`}
              color={buildColor("green", "500")}
            />
          </Fragment>
        )}
        {balanceButtonTooltip && (
          <AmountButtonTooltip
            align={amountButtonTooltipAlignment}
            className="balance-tooltip"
          >
            {balanceButtonTooltip}
          </AmountButtonTooltip>
        )}
      </AmountButton>
    </AmountContainer>
  );
};

BalanceButton.defaultProps = {
  qaLabel: "balance-button",
  balanceLabel: "Balance",
  balanceLowLabel: "Deposit Now",
  balanceButtonTooltip: "Quick Deposit",
  balanceAmount: 0,
  balanceLowThreshold: 10,
  onBalanceClick: noop,
  isBalanceShown: true,
  // TODO: default should be 'mobile' (changes in tvg-hdr needed accordingly)
  device: "desktop",
  url: "",
  newQuickDepositButton: false,
  tvgHeaderV2FeatureToggle: false
};

export default BalanceButton;
