import mediator from "@tvg/mediator";
import { get } from "lodash";
import {
  EventData,
  EventProps,
  MediatorEventType,
  ModuleEventProps
} from "./types";
import { ModuleEventProps as ResultsModuleEventProps } from "../raceResults/types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.OPEN, (data: EventData) =>
    track<EventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: get(data, "payload.module"),
      [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
      [BaseEventProps.MTP]: get(data, "payload.mtp"),
      [ModuleEventProps.METHOD]: get(data, "payload.method")
    })
  );

  mediator.base.subscribe(MediatorEventType.CLOSE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CLOSE, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: get(data, "payload.module"),
      [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
    })
  );

  mediator.base.subscribe(MediatorEventType.MUTE, (data: EventData) =>
    track<EventProps>(
      get(data, "payload.muted", true) ? EVENT_NAME.MUTE : EVENT_NAME.UNMUTE,
      {
        [BaseEventProps.EVENT_CATEGORY]: "video",
        [BaseEventProps.MODULE]: "video",
        [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
        [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
      }
    )
  );

  mediator.base.subscribe(MediatorEventType.PLAY_VIDEO, (data: EventData) =>
    track<EventProps>(EVENT_NAME.PLAY, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
    })
  );

  mediator.base.subscribe(MediatorEventType.REPLAY_VIDEO, (data: EventData) =>
    track<EventProps>(EVENT_NAME.PLAY, {
      [BaseEventProps.MODULE]: "tracks_list",
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
      [BaseEventProps.LINK_URL]: get(data, "payload.linkUrl"),
      [BaseEventProps.TRACK_COUNTRY]: get(data, "payload.trackCountry"),
      [BaseEventProps.TAB]: "Results",
      [ResultsModuleEventProps.HIDE_RESULTS_SPOILER]: get(
        data,
        "payload.hideResultsSpoiler"
      ),
      [ModuleEventProps.VIDEO_TYPE]: "replay"
    })
  );

  mediator.base.subscribe(MediatorEventType.STOP_VIDEO, (data: EventData) =>
    track<EventProps>(EVENT_NAME.STOP, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
    })
  );

  mediator.base.subscribe(MediatorEventType.SUCCESS, (data: EventData) =>
    track<EventProps>(EVENT_NAME.SUCCESS, {
      [ModuleEventProps.METHOD]: get(data, "payload.method"),
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
      [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
      [BaseEventProps.MODULE]: get(data, "payload.module", "video"),
      [BaseEventProps.MTP]: get(data, "payload.mtp")
    })
  );

  mediator.base.subscribe(MediatorEventType.ERROR, (data: EventData) =>
    track<EventProps>(EVENT_NAME.ERROR, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.error"),
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
      [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
      [BaseEventProps.ERROR_TYPE]: get(data, "payload.error")
    })
  );

  mediator.base.subscribe(MediatorEventType.SWITCH_STREAMS, (data: EventData) =>
    track<EventProps>(
      get(data, "payload.switchToLive", false)
        ? EVENT_NAME.LIVE
        : EVENT_NAME.REPLAY,
      {
        [BaseEventProps.EVENT_CATEGORY]: "video",
        [BaseEventProps.MODULE]: "video",
        [ModuleEventProps.VIDEO_TYPE]: get(data, "payload.videoType"),
        [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber")
      }
    )
  );
};
