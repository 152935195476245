// @flow
// $FlowFixMe
import React, { useState } from "react";
import { noop, get } from "lodash";
import mediator from "@tvg/mediator";

import { fontNormal } from "../../_static/Typography";
import Input from "../../_molecule/LoginInput";
import Button from "../../_atom/Buttons/button";
import ButtonLink from "../../_atom/Buttons/buttonLink";

import {
  RecoverCredentialsContainer,
  RecoverLoginLink,
  InfoMessage
} from "./styled-components";

export type ValueType = { userId: string };
export type OnResetCallback = (value: ValueType) => void;

type Props = {
  initialEmail: string,
  info?: string | null,
  recover?: string,
  onResetCallback: OnResetCallback,
  isMobile: boolean,
  redirectToRecoverDetails: (e: Event) => void
};

const RecoverCredentialsForm = ({
  initialEmail,
  info,
  recover,
  onResetCallback,
  isMobile,
  redirectToRecoverDetails
}: Props) => {
  const [email, setEmail] = useState(initialEmail);
  const [shouldDispatchEvent, setShouldDispatchEvent] = useState(true);

  // this can be a user id or email (any string)
  const isValid = typeof email === "string" && email !== "";
  const emailChangeHandler = (_, value) => {
    setShouldDispatchEvent(true);
    setEmail(value);
  };

  const emailBlurHandler = () => {
    if (isValid && shouldDispatchEvent) {
      setShouldDispatchEvent(false);
      mediator.base.dispatch({ type: "FORGOT_CREDENTIALS_VALIDATED_FIELD" });
    }
  };

  return (
    <RecoverCredentialsContainer
      data-qa-label="recover-credentials-form-container"
      isMobile={isMobile}
    >
      <div>
        {info && (
          <InfoMessage data-qa-label="recover-credentials-message">
            {info}
          </InfoMessage>
        )}
        <Input
          placeholder="Email or account number"
          qaLabel="recover credentials"
          label="Email or account number"
          value={email}
          onChangeHandler={emailChangeHandler}
          onBlurHandler={emailBlurHandler}
          maxLength="254"
        />
      </div>
      <div>
        <Button
          isStretched
          size="bigger"
          qaLabel="recover-credentials-submit"
          isUppercase={false}
          isDisabled={!isValid}
          fontFamily={fontNormal}
          // $FlowFixMe
          onClick={(e: Event) => {
            e.preventDefault();
            e.stopPropagation();
            if (isValid) {
              mediator.base.dispatch({
                type: "FORGOT_CREDENTIALS_RESET_BUTTON"
              });
              onResetCallback({ userId: email });
            }
          }}
        >
          Reset credentials
        </Button>
        <RecoverLoginLink>
          <ButtonLink
            isStretched
            size="bigger"
            type="tertiary"
            url="#recover-email"
            qaLabel="forgot-login-details-button"
            onClick={redirectToRecoverDetails}
          >
            {recover}
          </ButtonLink>
        </RecoverLoginLink>
      </div>
    </RecoverCredentialsContainer>
  );
};

RecoverCredentialsForm.defaultProps = {
  initialEmail: "",
  info: null,
  recover: "",
  onResetCallback: noop,
  redirectToRecoverDetails: noop,
  isMobile: true
};

// $FlowFixMe
export default React.memo(RecoverCredentialsForm);
