import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { getXsellKeys } from "../../utils";
import { ENV_KEYS } from "../../constants";

export const EXPERIMENT_KEY =
  "qfd-542-auto-play-video-in-program-page-when-mtp-is-less-than-x-minutes";

export const DEPLOYMENT_KEY = isXSell() ? getXsellKeys() : ENV_KEYS.fdr_fe.web;

export const DEFAULT_PAYLOAD = 3;
