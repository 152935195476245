import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  AMOUNT_OPTION = "amountOption",
  DEPOSIT_TYPE = "depositType",
  DEPOSIT_AMOUNT = "depositAmount"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  OPEN = "PENDING_WITHDRAWALS_OPEN",
  CANCEL_SUCCESS = "PENDING_WITHDRAWALS_CANCEL_SUCCESS",
  CANCEL_ERROR = "PENDING_WITHDRAWALS_CANCEL_ERROR",
  CANCEL_ATTEMPT = "PENDING_WITHDRAWALS_CANCEL_ATTEMPT",
  CALL_CS = "PENDING_WITHDRAWALS_CALL_CS",
  CANCEL_CLICK = "PENDING_WITHDRAWALS_CANCEL_CLICK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    accountId: string;
    withdrawalAmount: string;
    withdrawalType: string;
  };
}
