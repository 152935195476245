import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  PAGE_VIEW = "RAF_PAGE_VIEW",
  EMAIL_REFERRAL_ACTION = "RAF_EMAIL_REFERRAL_ACTION"
}

export interface PageViewData {
  type: MediatorEventType.PAGE_VIEW;
  payload: {
    siteVersion: string;
    productVersion: string;
    accountId: string;
    state: string;
    logged: string;
    registrationStatus: string;
    page: string;
    sport: string;
    privateBrowser: "Yes" | "No";
    graphVersion: "v1" | "v2";
  };
}

export interface EmailReferralActionData {
  type: MediatorEventType.EMAIL_REFERRAL_ACTION;
  payload: {
    action: string;
    category: string;
    module: string;
    label?: string;
  };
}
