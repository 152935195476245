import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PROMO_ID = "Promo Id"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  CTA_CLICK = "PROMOS_ONBOARDING:CTA_CLICK"
}

export interface PromoOnboardingData {
  type: MediatorEventType.CTA_CLICK;
  payload: {
    link: string;
    promoId?: string;
    promoName?: string;
  };
}
