// @flow
import { get } from "lodash";

import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

type LoginModalGTMEventsType =
  | "FORGOT_CREDENTIALS_VALIDATED_FIELD"
  | "FORGOT_CREDENTIALS_RESET_BUTTON"
  | "FORGOT_CREDENTIALS_FORGOT_DETAILS"
  | "FORGOT_CREDENTIALS_CHECK_SCREEN"
  | "FORGOT_CREDENTIALS_LINK_LANDED"
  | "FORGOT_CREDENTIALS_RESEND_SCREEN"
  | "FORGOT_CREDENTIALS_LINK_EXPIRED"
  | "FORGOT_CREDENTIALS_ERROR_PAGE_CTA"
  | "FORGOT_CREDENTIALS_RETURN_TO_TVG_COM"
  | "FORGOT_CREDENTIALS_CANCEL_RESET"
  | "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL"
  | "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL_SUCCESSFULLY"
  | "FORGOT_CREDENTIALS_WRONG_EMAIL_CTA_LINK_CLICK"
  | "FORGOT_CREDENTIALS_RESET_SUBMIT_ERROR"
  | "FORGOT_DETAILS_VALIDATED_FIELD"
  | "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT"
  | "FORGOT_DETAILS_RECOVER_EMAIL_ERROR"
  | "FORGOT_DETAILS_RECOVER_EMAIL_FAILED"
  | "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT_SUCCESS"
  | "FORGOT_CREDENTIALS_CONTACT_CUSTOMER_SERVICE"
  | "FORGOT_CREDENTIALS_CLOSE_MODAL"
  | "FORGOT_CREDENTIALS_BACK_MODAL"
  | "FORGOT_CREDENTIALS_LEAVE_PAGE_CTA_CLICK";

type LoginModalGTM = {
  type: LoginModalGTMEventsType,
  payload: {
    accountId?: string,
    error?: mixed,
    field?: string,
    state?: string,
    module?: string,
    label?: string,
    message?: string
  }
};

const FORGOT_CREDENTIALS_VALIDATED_FIELD = "FORGOT_CREDENTIALS_VALIDATED_FIELD";
type LoginModalGTMEventsActionType = "Field Completed";

export const FORGOT_CREDENTIALS_ACTION: {
  [key: LoginModalGTMEventsType]: LoginModalGTMEventsActionType
} = {
  [FORGOT_CREDENTIALS_VALIDATED_FIELD]: "Field Completed"
};

export default () => {
  // When a user fills and validates the email/account number field on the Forgot Credentials screen
  mediatorChannels.base.subscribe(
    FORGOT_CREDENTIALS_VALIDATED_FIELD,
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction:
          FORGOT_CREDENTIALS_ACTION[FORGOT_CREDENTIALS_VALIDATED_FIELD],
        gaEventLabel: "Email/Account Number",
        module: "Forgot Credentials"
      });
    }
  );

  // When a user clicks the 'reset credentials' button
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_RESET_BUTTON",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Reset Submit Attempted",
        gaEventLabel: undefined,
        module: "Forgot Credentials"
      });
    }
  );

  //  When a user clicks 'i don't know my login details'
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_FORGOT_DETAILS",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "CTA Link Clicked",
        gaEventLabel: "I don't know my Login Details",
        module: "Forgot Credentials"
      });
    }
  );

  // When a user is presented with the 'please check your inbox' screen
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_CHECK_SCREEN",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Reset Submit Success",
        gaEventLabel: undefined,
        module: "Forgot Credentials"
      });
    }
  );

  // When a user clicks the validation link in their email in order to reset their password and lands on the TVG site to resume credentials journey
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_LINK_LANDED",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Reset Credentials Email Link Landed",
        gaEventLabel: "Success/Link Expired",
        module: "Forgot Credentials"
      });
    }
  );

  // When a user clicks 'resend a reset password email' on the Forgot Credentials screen
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_RESEND_SCREEN",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Reset Re-Submitted",
        gaEventLabel: undefined,
        module: "Forgot Credentials"
      });
    }
  );

  // When a user clicks 'resend a reset password email' on the Link Expired screen:
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_LINK_EXPIRED",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Reset Re-Submitted",
        gaEventLabel: undefined,
        module: "Link Expired"
      });
    }
  );

  // When a user fills and validates the New Password field on the New Password screen:
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_NEW_PWPIN",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Field Completed",
        gaEventLabel: `${get(data, "payload.field")}`,
        module: "Forgot Credentials" // pass value depending on journey
      });
    }
  );

  // 7) When a user clicks 'cancel password reset' or 'cancel pin reset' :
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_CANCEL_RESET",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Cancel Credentials Reset Clicked",
        gaEventLabel: `${get(data, "payload.field")}`,
        module: `${get(data, "payload.module")}` // pass value depending on journey
      });
    }
  );

  // When a user clicks 'update password' or 'update pin':
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "New Credentials Submitted",
        gaEventLabel: `${get(data, "payload.field")}`,
        module: "Forgot Credentials" // pass value depending on journey
      });
    }
  );

  // When a user updates their pin or password successfully:
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL_SUCCESSFULLY",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "New Credentials Updated Success",
        gaEventLabel: `${get(data, "payload.field")}`,
        module: "Forgot Credentials" // pass value depending on journey
      });
    }
  );

  // When a user clicks 'got the wrong email':
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_WRONG_EMAIL_CTA_LINK_CLICK",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "CTA Link Clicked",
        gaEventLabel: "Got The Wrong Email",
        module: "Forgot Credentials" // pass value depending on journey
      });
    }
  );

  // When a user clicks a CTA on an Error screen (e.g too many attempts) for either credentials reset or email recovery:
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_ERROR_PAGE_CTA",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "CTA Link Clicked",
        gaEventLabel: get(data, "payload.label"),
        module: get(data, "payload.module")
      });
    }
  );

  // When a user clicks 'Return to Homepage' anywhere in the journey (except leave page modal):
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_RETURN_TO_TVG_COM",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "CTA Link Clicked",
        gaEventLabel: `Return to Homepage`,
        module: get(data, "payload.module")
      });
    }
  );

  //  When a user attempts to submit their current credentials, but an error gets thrown (e.g wrong password):
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_RESET_SUBMIT_ERROR",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Reset Submit Error",
        gaEventLabel: `${get(data, "payload.field")}`, // 'Date of Birth' or 'SSN',
        module: "Forgot Credentials" // pass value depending on journey
      });
    }
  );

  // When a user completes a field on the 'recover email address' screen:
  mediatorChannels.base.subscribe(
    "FORGOT_DETAILS_VALIDATED_FIELD",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventLabel: `${get(data, "payload.field")}`,
        gaEventAction: "Field Completed",
        module: "Recover Email Address"
      });
    }
  );

  //  When a user clicks 'contact customer service' after clicking a 'resend a reset email' button:
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_CONTACT_CUSTOMER_SERVICE",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventLabel: "Contact Customer Service",
        gaEventAction: "CTA Link Clicked",
        module: "Reset Email Re-Sent"
      });
    }
  );

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen
  mediatorChannels.base.subscribe("FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT", () => {
    pushToDataLayer({
      event: "forgotCredentials",
      gaEventCategory: "Forgot Credentials",
      gaEventAction: "Credentials For Recover Email Submit Attempted",
      gaEventLabel: undefined,
      module: "Recover Email Address"
    });
  });

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen and an error gets thrown:
  mediatorChannels.base.subscribe(
    "FORGOT_DETAILS_RECOVER_EMAIL_ERROR",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Recover Email Submit Error",
        gaEventLabel: `${get(data, "payload.message")}`, // e.g wrong email
        module: "Recover Email Address"
      });
    }
  );

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen and fails (e.g too many attempts):
  mediatorChannels.base.subscribe(
    "FORGOT_DETAILS_RECOVER_EMAIL_FAILED",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Recover Email Submit Failed",
        gaEventLabel: `${get(data, "payload.message")}`, // e.g too many attempts
        module: "Recover Email Address"
      });
    }
  );

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen and is Successful:
  mediatorChannels.base.subscribe(
    "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT_SUCCESS",
    () => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Credentials For Recover Email Submit Success",
        gaEventLabel: undefined,
        module: "Recover Email Address"
      });
    }
  );

  // When a user closes a screen by clicking the 'x' button
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_CLOSE_MODAL",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: `${get(data, "payload.action")}`,
        gaEventLabel: `${get(data, "payload.field")}`,
        module: `${get(data, "payload.module")}`
      });
    }
  );

  // When a user clicks a back arrow to go back to the previous screen
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_BACK_MODAL",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Back Arrow Clicked",
        gaEventLabel: undefined,
        module: `${get(data, "payload.module")}`
      });
    }
  );

  // When a user clicks a CTA on the 'Leave Page?' Modal:

  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_LEAVE_PAGE_CTA_CLICK",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "CTA Link Clicked",
        gaEventLabel: `${get(data, "payload.field")}`,
        module: "Leave Page Modal"
      });
    }
  );

  // When a user clicks the validation link in their email in order to reset their password and lands on the TVG site to resume credentials journey:
  mediatorChannels.base.subscribe(
    "FORGOT_CREDENTIALS_EMAIL_LINK_LANDED",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "forgotCredentials",
        gaEventCategory: "Forgot Credentials",
        gaEventAction: "Reset Credentials Email Link Landed",
        gaEventLabel: `${get(data, "payload.field")}`,
        module: "Forgot Credentials"
      });
    }
  );
};
