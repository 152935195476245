import { Variant } from "../../types";
import { DEFAULT_PAYLOAD } from "./constants";
import { AutoPlayConfig, VariantEnum } from "./types";

export const parseResponse = (
  variant: Variant<VariantEnum, number> | undefined
): AutoPlayConfig => ({
  autoPlay: variant?.value === VariantEnum.TREATMENT,
  mtpThreshold:
    variant?.payload && !Number.isNaN(variant.payload)
      ? variant.payload
      : DEFAULT_PAYLOAD
});
