import tvgConf from "@tvg/conf";

let conf;

export default {
  setHost: (req) => {
    let host;
    if (req) {
      host = req.hostname;
    } else if (typeof window !== "undefined") {
      host = window.location.hostname;
    }
    conf = tvgConf(host);
  },
  getServiceUrl: (path) => (conf ? conf.config(path) : tvgConf().config(path)),
  getBrand: () => (conf ? conf.brand : tvgConf().brand),
  getContext: () => (conf ? conf.context() : tvgConf().context())
};
