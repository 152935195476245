import mediator from "@tvg/mediator";
import { get } from "lodash";
import { COMMON_EVENT_NAMES } from "../../constants";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { MediatorEventType, EventProps, PillClickedData } from "./types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.LOGIN_WALL, () => {
    track<EventProps>(EVENT_NAME.LOGIN_WALL, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: undefined,
      [BaseEventProps.MODULE]: "header"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.PILL_CLICKED,
    (data: PillClickedData) => {
      track<EventProps>(EVENT_NAME.PILL_CLICKED, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: "Navigation_Pills"
      });
    }
  );
};
