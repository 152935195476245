import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  TUTORIAL_CATEGORY = "Tutorial Category",
  PAGINATION_NUMBER = "Pagination Number",
  TOTAL_PAGES = "Total Pages",
  ARTICLE_NAME = "Article Name"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  NAVIGATE = "FDR_TUTORIALS:NAVIGATE",
  OPEN = "FDR_TUTORIALS:OPEN_TUTORIAL",
  LOAD = "FDR_TUTORIALS:LOAD_TUTORIAL",
  NEXT = "FDR_TUTORIALS:NEXT",
  CLOSE = "FDR_TUTORIALS:CLOSE"
}

export interface NavigateData {
  type: MediatorEventType.NAVIGATE;
  payload: {
    destinationUrl: string;
    isFromQuickLinks: boolean;
  };
}

export interface OpenData {
  type: MediatorEventType.OPEN;
  payload: {
    name: string;
    tutorialCategory: string;
  };
}

export interface LoadData {
  type: MediatorEventType.LOAD;
  payload: {
    title: string;
    tutorialCategory: string;
    pageNumber: number;
    totalPages: number;
  };
}

export interface NextData {
  type: MediatorEventType.NEXT;
  payload: {
    buttonText: string;
    linkText: string;
    tutorialCategory: string;
    pageNumber: number;
    totalPages: number;
  };
}

export interface CloseData {
  type: MediatorEventType.CLOSE;
  payload: {
    title: string;
    tutorialCategory: string;
    pageNumber: number;
    totalPages: number;
  };
}
