import axios from "axios";
import * as mediator from "@tvg/mediator-classic/src";
import _ from "lodash";
import TVGConf from "@tvg/conf";
import { showBalanceDeposit, hideBalanceDeposit } from "../../actions/header";

// Fetches value independently of its origin (XHR or cookie)
export const readLoadedPref = (loadedPref) => {
  const loadedPrefValue = _.isUndefined(loadedPref) ? true : loadedPref;
  // Checking if value exists and assigning it to a variable
  const valueFromXHR = _.get(loadedPrefValue, "data.preferences[0]");
  if (valueFromXHR) {
    return _.get(loadedPrefValue, "data.preferences[0].metadata");
  }
  if (!valueFromXHR && loadedPref.split("=").length > 1) {
    return loadedPref.split("=")[1].replace(/;/g, "");
  }
  return loadedPref;
};

export const actionsDispatcher = (dispatch, prefName, prefValue) => {
  const prefValueCast = !!(prefValue === "1");
  switch (prefName) {
    case "balance_is_shown":
      if (prefValueCast === true) {
        dispatch(showBalanceDeposit());
      } else {
        dispatch(hideBalanceDeposit());
      }
      break;
    default:
      break;
  }
};

const tvgHeaders = () => ({
  "content-type": "application/json",
  "x-tvg-context": TVGConf().context(),
  "x-requested-with": "XMLHttpRequest" // we need this some the request works on old request
});

// XHR methods
export const getPreferenceFromService = (prefName, userId) => {
  // GET prefs AJAX call
  const requestOptions = {
    method: "get",
    url: `${
      TVGConf().config().service.uam
    }/users/${userId}/preferences/${prefName}`,
    headers: tvgHeaders(),
    withCredentials: true
  };
  return axios(requestOptions);
};

export function rspCatch(response) {
  if (
    _.get(response, "response.status") === 401 ||
    _.get(response, "response.status") === 403
  ) {
    mediator.dispatch("TVG_LOGIN:DO_LOGOUT", { sessionExpired: true });
  }
  return response;
}

const setPreferenceOnService = (prefName, prefValue, userId) => {
  // SET prefs AJAX call
  const requestOptions = {
    method: "put",
    url: `${TVGConf().config().service.uam}/users/${userId}/preferences`,
    headers: tvgHeaders(),
    withCredentials: true,
    data: {
      preferences: [
        {
          description: prefName,
          metadata: prefValue
        }
      ]
    }
  };
  return axios(requestOptions).catch((response) => rspCatch(response));
};

export class PreferencesService {
  // Main methods

  static getUserPref(prefName, userId) {
    return (dispatch) => {
      getPreferenceFromService(prefName, userId)
        .then((loadedPref) => {
          // Fetch value abstraction, to get it from either XHR
          const prefValue = readLoadedPref(loadedPref);
          _.attempt(() => {
            if (typeof window !== "undefined") {
              window.localStorage.setItem("balance_is_shown", prefValue);
            }
          });
          // Dispatch the right action(s)
          actionsDispatcher(dispatch, prefName, prefValue);
        })
        .catch(
          // Uh-oh, we haz a problem
          () => new Error("Error getting user preferences")
        );
    };
  }

  static setUserPref(prefName, prefValue, userId) {
    return (dispatch) => {
      actionsDispatcher(dispatch, prefName, prefValue);
      _.attempt(() => {
        if (typeof window !== "undefined") {
          window.localStorage.setItem("balance_is_shown", prefValue);
        }
      });
      setPreferenceOnService(prefName, prefValue, userId);
    };
  }
}

export const PreferencesServiceSingleton = new PreferencesService();
