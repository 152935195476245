// @flow
import React from "react";
import { get, isEmpty } from "lodash";
import { type Dispatch } from "redux";
import { connect } from "react-redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import GeolocateIn, {
  type Messages
} from "@tvg/atomic-ui/_templates/GeolocateIn";
import { parseCAPIMessage } from "@tvg/utils/capiUtils";
import calcModalContainerOffset from "@tvg/utils/modalUtils";
import type { RevalidateLocationErrors } from "@tvg/atomic-ui/_templates/GeolocateIn";
import { openRedirectEngineOnIOSCallback as openRedirectEngineOnIOS } from "@tvg/support";
import { onExternalLinkCallback } from "@tvg/mobile-account";

import { closeLocationRevalidate } from "./actions";
import type { RevalidateLocationActions } from "./actions";

type Props = {
  isOpen: boolean,
  error: RevalidateLocationErrors,
  messages: Messages,
  device: string,
  phoneUrl: string,
  liveChatUrl: string,
  hasLiveChat: boolean,
  isApp: boolean,
  dispatch: Dispatch<RevalidateLocationActions>
};

export const renderTitle = (type: RevalidateLocationErrors) => {
  switch (type) {
    case "WARNING":
      return {
        title: "Geolocation Warning",
        titleType: "warning"
      };
    case "FAILURE":
      return {
        title: "Geolocation Failure",
        titleType: "error"
      };
    default:
      return null;
  }
};

export const handleCloseModal = (
  dispatch: Dispatch<RevalidateLocationActions>
) => {
  dispatch(closeLocationRevalidate());
};

export const RevalidateModalComponent = ({
  isOpen,
  error,
  messages,
  phoneUrl,
  liveChatUrl,
  hasLiveChat,
  isApp,
  device,
  dispatch
}: Props) => {
  let offsetDesktop;

  if (device !== "mobile") {
    offsetDesktop = calcModalContainerOffset();
  }
  const isMobile = device === "mobile";

  return isOpen ? (
    <ModalV2
      {...renderTitle(error)}
      isOpen={isOpen}
      animation={isMobile ? "bottom" : "bottomFloating"}
      offsetLeft={isMobile ? 0 : offsetDesktop}
      offsetRight={isMobile ? 0 : offsetDesktop}
      isFullWidth={isMobile}
      isFullHeight={false}
      onClose={() => handleCloseModal(dispatch)}
    >
      {() => (
        <GeolocateIn
          type={error}
          messages={messages}
          handleCloseModal={() => handleCloseModal(dispatch)}
          phoneUrl={phoneUrl}
          liveChatUrl={liveChatUrl}
          hasLiveChat={hasLiveChat}
          onExternalLinkCallback={onExternalLinkCallback}
          iOSMessageUsCallback={openRedirectEngineOnIOS(liveChatUrl)}
          isApp={isApp}
          isMobile={isMobile}
        />
      )}
    </ModalV2>
  ) : null;
};

RevalidateModalComponent.defaultProps = {
  phoneUrl: "tel:1-888-752-9884",
  liveChatUrl: "",
  hasLiveChat: true,
  isApp: false
};

/*
 * This has been created because we are reusing this component in HDR which also means we don't have CAPI messages yet
 */
/* istanbul ignore next */
const mapStateToProps = (store, props) => {
  const storeProps = {
    isOpen: get(store, "revalidateLocation.isOpen"),
    error: get(store, "revalidateLocation.error")
  };
  const capiMessages = parseCAPIMessage(
    store,
    "capi.messages.revalidateLocationMessages"
  );

  if (!isEmpty(capiMessages)) {
    return {
      ...storeProps,
      messages: capiMessages
    };
  }
  return {
    ...storeProps,
    messages: parseCAPIMessage(props, "messages")
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(RevalidateModalComponent);
