import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  BET_AMOUNT_OPTION = "Bet Amount Option",
  BET_CONFIRMATION = "bet_confirmation",
  BALANCE_VISIBILITY = "balance_visibility",
  ITEM_SELECTED = "Item Selected",
  SETTING = "Setting",
  FIELD_INPUT = "Field Input"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  SAVE_CHANGES = "BET_PREFERENCES:SAVE_CHANGES",
  NAVIGATE_MORE_PAGE = "BET_PREFERENCES:NAVIGATE_MORE_PAGE",
  PAGE_VIEW = "BET_PREFERENCES:PAGE_VIEW",
  TOGGLE_REQUEST_BET_CONFIRMATION = "BET_PREFERENCES:TOGGLE_REQUEST_BET_CONFIRMATION",
  DEFAULT_BET_TYPE_SELECTED = "BET_PREFERENCES:DEFAULT_BET_TYPE_SELECTED",
  TOGGLE_PREFERENCE = "BET_PREFERENCES:TOGGLE_PREFERENCE",
  DEFAULT_BET_AMOUNT = "BET_PREFERENCES:DEFAULT_BET_AMOUNT",
  CUSTOM_BET_AMOUNT = "BET_PREFERENCES:CUSTOM_BET_AMOUNT"
}

type BetAmountType = "minimum" | "custom";

export interface BetPreferenceData {
  type: MediatorEventType.SAVE_CHANGES;
  payload: {
    betType: string;
    betAmountOption: BetAmountType;
    tag?: string;
  };
}

export interface GenericEvent {
  type: MediatorEventType;
  payload: {
    module: string;
    setting?: string;
    itemSelected?: string;
  };
}

export interface PageViewPayloadData extends GenericEvent {
  type: MediatorEventType.PAGE_VIEW;
  payload: {
    module: "betting" | "account";
  };
}

export interface TogglePreferenceData extends GenericEvent {
  type: MediatorEventType.TOGGLE_PREFERENCE;
  payload: {
    module: "betting" | "account";
    setting: "betting_confirmation" | "balance_visibility";
    itemSelected: "on" | "off";
  };
}
export interface DefaultBetTypeSelectedData {
  type: MediatorEventType.DEFAULT_BET_TYPE_SELECTED;
  payload: {
    defaultBetTypeSelected: string;
  };
}
export interface DefaultBetAmountData extends GenericEvent {
  type: MediatorEventType.DEFAULT_BET_AMOUNT;
  payload: {
    module: string;
    itemSelected: "minimum_amount" | "custom_amount";
  };
}
export interface CustomBetAmountData extends GenericEvent {
  type: MediatorEventType.CUSTOM_BET_AMOUNT;
  payload: {
    module: string;
    betType: string;
    fieldInput: string;
  };
}
