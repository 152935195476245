import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  HIDE_RESULTS_SPOILER = "Hide Results Spoiler"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  SELECT_TAB = "RACE_RESULTS_SELECT_TAB",
  CHANGE_TRACK = "RACE_RESULTS_NEXT_RACE_CHANGE_TRACK",
  VIEW_RACE_RESULTS = "VIEW_RACE_RESULTS"
}

// Payload type data
export interface ResultType {
  type: MediatorEventType;
  payload: {
    tabName: string;
    raceResults: boolean;
  };
}

export interface ResultChangeTrackNextRace {
  type: MediatorEventType;
  payload: {
    actionName: "Next Race" | "Change Track";
    destinationUrl: string;
  };
}

export interface ResultRaceViewed {
  trackName: string;
  raceNumber: string;
  linkUrl: string;
  trackCountry: string;
  hideResultsSpoiler: string;
}
