import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENTS = {
  NAVIGATED_TO: COMMON_EVENT_NAMES.NAVIGATED,
  ADD_TO_FAVORITES_CLICKED: "Add To Favorites Clicked",
  ADD_TO_FAVORITES_SUCCESS: "Add To Favorites Success",
  BACK_ARROW_CLICKED: "Back Arrow Clicked"
};

export const MODULE = "Track Page";
