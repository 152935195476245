import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK = "QUICKLINKS:CLICK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    linkName: string;
    fullURL: string;
  };
}
