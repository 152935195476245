import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK = "PROMO_SIGNAGE:CLICK",
  OPEN = "PROMOS_OPEN_FROM_FOOTER"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    isOptedIn: boolean;
    module: string;
    destinationUrl: string;
  };
}
