// @flow

import React, { PureComponent } from "react";
import Icon from "../../_static/Icons";
import color from "../../_static/ColorPalette";
import { TagContainer, TagLabel } from "./styled-components";
import type { Props } from "./types";

export default class Tag extends PureComponent<Props> {
  static defaultProps = {
    name: "",
    description: "",
    icon: null,
    iconSize: 14,
    textSize: 12,
    isCollapsed: false,
    uppercase: false,
    bgColor: ["grey", "000"],
    qaLabel: "tag",
    tagType: "DefaultTag",
    className: "",
    isCondensed: false
  };

  processTagColors = () => {
    switch (this.props.tagType) {
      case "PromoTag":
        return {
          background: color("yellow", "100"),
          iconColorList: [color("yellow", "500"), color("yellow", "900")],
          text: color("yellow", "900")
        };
      case "OptedInTag":
        return {
          background: color("green", "100"),
          iconColorList: [color("green", "900"), color("green", "500")],
          text: color("green", "900")
        };
      case "RunnerFlag":
        return {
          background: color("orange", "000"),
          iconColorList: [color("orange", "500"), color("orange", "700")],
          text: color("orange", "700")
        };
      case "RepeatBet":
        return {
          background: color("blue_accent", "100"),
          text: color("blue_accent", "500"),
          iconColorList: [
            color("blue_accent", "500"),
            color("blue_accent", "700")
          ]
        };
      default:
        return {
          background: color("blue", "000"),
          iconColorList: [color("grey", "800"), color("grey", "800")],
          text: color("grey", "800")
        };
    }
  };

  render() {
    const {
      icon,
      iconSize,
      isCollapsed,
      name,
      uppercase,
      qaLabel,
      tagType,
      isCondensed
    } = this.props;
    const tagColors = this.processTagColors();
    return (
      (!!icon || !isCollapsed) && (
        <TagContainer
          className={this.props.className}
          data-qa-label={`${qaLabel}Container${tagType}`}
          isCollapsed={isCollapsed}
          bgColor={tagColors.background}
        >
          {!!icon && (
            <Icon
              data-qa-label={`${qaLabel}Icon`}
              icon={icon}
              colorList={tagColors.iconColorList}
              size={iconSize}
            />
          )}
          {!isCollapsed && (
            <TagLabel
              flagPadding={this.props.tagType === "RunnerFlag"}
              data-qa-label={`${qaLabel}Label`}
              uppercase={uppercase}
              isCondensed={isCondensed}
              fontColor={tagColors.text}
              textSize={this.props.textSize}
            >
              {name}
            </TagLabel>
          )}
        </TagContainer>
      )
    );
  }
}
