import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { processTrackName } from "../../utils";

export default () => {
  mediator.base.subscribe(MediatorEventType.GO_TO_POOL, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: `${processTrackName(
        get(data, "payload.pool.track")
      )} - R${get(data, "payload.pool.race.raceNumber")} - ${get(
        data,
        "payload.pool.race.mtp"
      )} - ${get(data, "payload.pool.amount")}`,
      [BaseEventProps.MODULE]: "Top Pools",
      [BaseEventProps.LINK_URL]: get(data, "payload.url")
    })
  );
};
