export const EVENT_NAME = {
  OPEN: "Racing Video Opened",
  CLOSE: "Racing Video Closed",
  MUTE: "Racing Video Muted",
  UNMUTE: "Racing Video Unmuted",
  PLAY: "Racing Video Play Clicked",
  STOP: "Racing Video Stopped",
  SUCCESS: "Racing Video Play Successful",
  ERROR: "Racing Video Play Error",
  LIVE: "Racing Video Replay To Live Switched",
  REPLAY: "Racing Video Live To Replay Switched"
};
