// @flow
import React, { type Ref, type ElementType } from "react";

import type { UnaryFn } from "@tvg/types/Functional";
import type { IconType } from "../../_static/Icons/Types.js.flow";
import Icon from "../../_static/Icons";
import { trash } from "../../_static/Icons/icons";

import { Dismiss } from "./styled-components";

const { min, abs } = Math;
const getClient = (event: TouchEvent) => {
  // $FlowFixMe
  const { clientX, clientY } = event.changedTouches
    ? event.changedTouches[0]
    : event;
  return [clientX, clientY];
};
let isOpen = false;

export const getTouchEvents = (
  targetId: string,
  targetRef: Ref<ElementType>,
  dismissible: boolean,
  fnDismiss: UnaryFn<any, void>,
  targetWidth: number,
  threshold: number = targetWidth / 2,
  showDismiss: boolean
) => {
  let startPointX = 0;
  let startPointY = 0;
  // $FlowFixMe
  const getEl = () => targetRef;
  const { current } = getEl();

  const toggleCardDismiss = (value) => {
    if (dismissible) {
      fnDismiss(value);
    }
  };
  const handleTouchStart = (event: TouchEvent) => {
    [startPointX, startPointY] = getClient(event);
  };
  const handleTouchMove = (event: TouchEvent) => {
    if (dismissible) {
      const [currentPointX, currentPointY] = getClient(event);
      const difX = currentPointX - startPointX;
      const difY = currentPointY - startPointY;
      if (currentPointX < startPointX) {
        const offset = abs(difX) > targetWidth ? -targetWidth : difX;
        // $FlowFixMe
        current.style.setProperty("transform", `translate(${offset}px)`);
        isOpen = true;
      } else if (showDismiss && difY * -1 < difX && isOpen) {
        const dist = min(abs(targetWidth - difX), 59);
        const offset = dist > threshold ? dist : 0;
        isOpen = !!offset;
        // $FlowFixMe
        current.style.setProperty("transform", `translate(-${offset}px)`);
      }
    }
  };
  const handleTouchEnd = (event: TouchEvent) => {
    const [endPoint] = getClient(event);
    const showButton = startPointX > endPoint + threshold;

    if (showButton) {
      toggleCardDismiss(targetId);
      // $FlowFixMe
      current.style.setProperty("transform", `translate(-${targetWidth}px)`);
    } else {
      if (showDismiss) {
        toggleCardDismiss("");
      }
      // $FlowFixMe
      current.style.setProperty("transform", "translate(0px)");
    }
  };

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove,
    onTouchEnd: handleTouchEnd
  };
};

type Props = {
  dismissBtnWidth: number,
  dismissIconSize: number,
  dismissIconColor: string,
  callbackClick: UnaryFn<Event, void>,
  iconBtn: IconType
};

const SwipeButton = ({
  dismissBtnWidth = 59,
  dismissIconSize = 16,
  dismissIconColor,
  callbackClick,
  iconBtn = trash
}: Props) => {
  const handleClick = (event: Event) => {
    event.stopPropagation();
    callbackClick(event);
  };

  return (
    <Dismiss onClick={handleClick} width={dismissBtnWidth}>
      <Icon icon={iconBtn} size={dismissIconSize} color={dismissIconColor} />
    </Dismiss>
  );
};
// $FlowFixMe
export default React.memo(SwipeButton);
