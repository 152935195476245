import { RaceProgram } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PLACE_TYPE = "Place Type"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  RUNNER_SELECTION = "PP_RUNNER_SELECTION"
}

export interface SelectBetTypeType {
  type: MediatorEventType;
  payload: {
    eventLabel: string;
    currentRace: RaceProgram;
    betTypeCode: WagerTypeCodesEnum | undefined;
  };
}
