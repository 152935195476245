import tvgConf from "@tvg/conf";
import getProtocol from "../protocolSetter";

const perimeterXService: string = "service.pxp";

const getPerimeterXInitJs = (appId: string) =>
  `${getProtocol()}${tvgConf().config(
    perimeterXService
  )}/${appId}/init.js`.replace("http", "https");
const PXP_SERVICE = { getPerimeterXInitJs };

export default PXP_SERVICE;
