// @flow
import geocomplyReducer, {
  initialState as geoComplyInitalState,
  type State as GeoComplyState
} from "@tvg/sh-geolocation/src/redux/reducers";
import type { GeoActions } from "../actions/geo";

export type State = {
  location: {
    latitude: ?number,
    longitude: ?number
  },
  regions: {
    description: string,
    id: number,
    name: string,
    type: string
  }[],
  state: string
} & GeoComplyState;

export const initialState = {
  location: {
    latitude: null,
    longitude: null
  },
  regions: [
    {
      description: "",
      id: 0,
      name: "Default",
      type: "Default"
    }
  ],
  state: "",
  ...geoComplyInitalState
};

const geoLocationReducer = (
  state: State = initialState,
  action: GeoActions
) => {
  switch (action.type) {
    case "GEO_LOCATION_GET": {
      return { ...state, ...action.payload };
    }
    case "GEO_LOCATION_SET_STATE": {
      return { ...state, ...action.payload };
    }
    case "GEO_LOCATION_RESET": {
      return { ...initialState };
    }
    case "GEO_SHOW_STATE_SELECTOR": {
      return { ...state, showStateSelector: action.payload };
    }
    default: {
      // Please use GeoComplyReducer directly when the old geo location when it is removed.
      return geocomplyReducer(state, action);
    }
  }
};

export default geoLocationReducer;
