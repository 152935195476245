import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  NAVIGATED: COMMON_EVENT_NAMES.NAVIGATED,
  SELECT: "Select",
  BLOCK: "block",
  REQUEST: "submit request",
  APPLY_LIMIT: "apply limit"
};

export const TIMEOUT_AND_EXCLUSIONS = {
  TIMEOUT: "Timeout",
  SELF_EXCLUSION: "Self-Exclude",
  SUSPEND: "Suspend Account",
  TO_DEFAULT: "Timeout Exclusions Suspensions"
};
export const MODULE_NAME = {
  DEPOSIT_LIMIT: "Deposit Limits",
  FUNDING_CONTROLS: "Funding Controls",
  ...TIMEOUT_AND_EXCLUSIONS
};
export const MODULE_NAME_DEFAULT = "Responsible Gambling";
export const BASE_PATH = "responsible-gaming";
