// @flow
import type { UnaryFn, NullaryFn } from "@tvg/types/Functional";
import type { RegionData } from "@tvg/geolocation";

export type LocationOpenSplashAction = {
  type: "OPEN_LOCATION_SPLASH"
};
export type LocationCloseSplashAction = { type: "CLOSE_LOCATION_SPLASH" };

export type SetStateRequiresLocation = {
  type: "SET_LOCATION_REQUIRED",
  payload: boolean
};

export type SetLocationLoading = {
  type: "SET_LOCATION_LOADING",
  payload: boolean
};

export type SetLocationDenied = {
  type: "SET_LOCATION_DENIED"
};

export type SetLocationUnsupported = {
  type: "SET_LOCATION_UNSUPPORTED"
};

export type SetLocationUnknownError = {
  type: "SET_LOCATION_UNKNOWN_ERROR",
  payload: boolean
};

export type SetLocationUnavailable = {
  type: "SET_LOCATION_UNAVAILABLE"
};

export type SetLocationUnauthorized = {
  type: "SET_LOCATION_UNAUTHORIZED"
};

export type SetLocationTimedOut = {
  type: "SET_LOCATION_TIMED_OUT"
};

export type GeoLocationGetAction = {
  type: "GEO_LOCATION_GET",
  payload: { state: string } & RegionData
};

export type SetOnLocationGet = {
  type: "SET_ON_LOCATION_GET",
  payload: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>)
};

export type RGTimeoutExclusionType =
  | "TIMEOUT"
  | "SELFEXCLUSION"
  | "SELF_EXCLUSION"
  | "SUSPEND";

export type RGTimeoutExclusionSplash = {
  type: "RG_TIMEOUT_EXCLUSION_SPLASH",
  payload: {
    type: RGTimeoutExclusionType,
    endDate: string
  }
};

export type SetBlockedCountry = {
  type: "SET_BLOCKED_COUNTRY",
  payload: boolean
};

export type SetAndroidBlockedState = {
  type: "ANDROID_BLOCKED_STATE"
};

export type SetEmptyErrorState = {
  type: "CLEAR_ERROR_SPLASH"
};

export type LocationSplashActions =
  | SetBlockedCountry
  | LocationOpenSplashAction
  | LocationCloseSplashAction
  | SetStateRequiresLocation
  | SetLocationLoading
  | SetLocationDenied
  | SetLocationUnsupported
  | SetLocationUnknownError
  | SetLocationUnavailable
  | SetLocationTimedOut
  | SetLocationUnauthorized
  | GeoLocationGetAction
  | SetOnLocationGet
  | RGTimeoutExclusionSplash
  | SetAndroidBlockedState
  | SetEmptyErrorState;

export const openLocationSplash = (): LocationOpenSplashAction => ({
  type: "OPEN_LOCATION_SPLASH"
});

export const clearSplashErrors = (): SetEmptyErrorState => ({
  type: "CLEAR_ERROR_SPLASH"
});

export const setBlockedCountry = (
  isLocationRequired: boolean
): SetBlockedCountry => ({
  type: "SET_BLOCKED_COUNTRY",
  payload: isLocationRequired
});

export const setAndroidBlockedState = (): SetAndroidBlockedState => ({
  type: "ANDROID_BLOCKED_STATE"
});

export const closeLocationSplash = (): LocationCloseSplashAction => ({
  type: "CLOSE_LOCATION_SPLASH"
});

export const setLocationRequired = (
  locationRequired: boolean
): SetStateRequiresLocation => ({
  type: "SET_LOCATION_REQUIRED",
  payload: locationRequired
});

export const setLocationLoading = (loading: boolean): SetLocationLoading => ({
  type: "SET_LOCATION_LOADING",
  payload: loading
});

export const setLocationDenied = (): SetLocationDenied => ({
  type: "SET_LOCATION_DENIED"
});

export const setLocationUnsupported = (): SetLocationUnsupported => ({
  type: "SET_LOCATION_UNSUPPORTED"
});

export const setLocationUnknownError = (
  showClose: boolean
): SetLocationUnknownError => ({
  type: "SET_LOCATION_UNKNOWN_ERROR",
  payload: showClose
});

export const setLocationUnavailable = (): SetLocationUnavailable => ({
  type: "SET_LOCATION_UNAVAILABLE"
});

export const setLocationTimedOut = (): SetLocationTimedOut => ({
  type: "SET_LOCATION_TIMED_OUT"
});

export const setLocationUnauthorized = (): SetLocationUnauthorized => ({
  type: "SET_LOCATION_UNAUTHORIZED"
});

export const getGeoLocation = (
  locationData: RegionData
): GeoLocationGetAction => ({
  type: "GEO_LOCATION_GET",
  payload: locationData
});

export const setOnLocationGet = (
  callback: ?(UnaryFn<RegionData, mixed> | NullaryFn<mixed>)
): SetOnLocationGet => ({
  type: "SET_ON_LOCATION_GET",
  payload: callback
});

export const rgTimeoutExclusionSplash = (
  type: RGTimeoutExclusionType,
  endDate: string
): RGTimeoutExclusionSplash => ({
  type: "RG_TIMEOUT_EXCLUSION_SPLASH",
  payload: {
    type,
    endDate
  }
});
