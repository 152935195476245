// @flow
// $FlowFixMe
import React, { useMemo } from "react";
import { noop } from "lodash";
import type { BinaryFn, NullaryFn } from "@tvg/types/Functional";
import {
  GeolocateInContainer,
  TextWrapper,
  ButtonsWrapper
} from "./styled-components";
import Button from "../../_atom/Buttons/button";
import ButtonLink from "../../_atom/Buttons/buttonLink";

export type RevalidateLocationErrors = "WARNING" | "FAILURE";

export type Messages = {
  warning: string,
  failure: string
};

export type Props = {
  type: RevalidateLocationErrors,
  messages: Messages,
  handleCloseModal: NullaryFn<void>,
  phoneUrl: string,
  liveChatUrl: string,
  hasLiveChat: boolean,
  onExternalLinkCallback: BinaryFn<string, Event, void>,
  iOSMessageUsCallback: NullaryFn<string>,
  isApp: boolean,
  isMobile: boolean
};

export const renderText = (
  type: RevalidateLocationErrors,
  messages: Messages
) => {
  switch (type) {
    case "WARNING":
      return (
        <TextWrapper
          data-qa-label="geolocate-description"
          dangerouslySetInnerHTML={{ __html: messages.warning }}
        />
      );
    case "FAILURE":
      return (
        <TextWrapper
          data-qa-label="geolocate-description"
          dangerouslySetInnerHTML={{ __html: messages.failure }}
        />
      );
    default:
      return null;
  }
};

export const renderButtons = (props: Props) => {
  let linkOnClick = props.iOSMessageUsCallback;
  if (!props.isApp) {
    linkOnClick = (e: Event) => {
      if (props.liveChatUrl !== "") {
        props.onExternalLinkCallback(props.liveChatUrl, e);
      }
    };
  }

  switch (props.type) {
    case "WARNING":
      return (
        <Button
          size="big"
          qaLabel="geolocate-ok-btn"
          isStretched
          onClick={props.handleCloseModal}
        >
          OK
        </Button>
      );
    case "FAILURE":
      return props.isMobile ? (
        <ButtonsWrapper>
          <ButtonLink
            qaLabel="geolocate-liveChat-btn"
            type="primary"
            size="big"
            url={props.liveChatUrl}
            isStretched
            onClick={linkOnClick}
          >
            Send us a Message
          </ButtonLink>
          <ButtonLink
            qaLabel="geolocate-phone-btn"
            type="tertiary"
            size="big"
            url={props.phoneUrl}
            isStretched
            onClick={(e: Event) => {
              props.onExternalLinkCallback(props.phoneUrl, e);
            }}
          >
            Call Customer Service
          </ButtonLink>
        </ButtonsWrapper>
      ) : (
        <Button
          size="big"
          qaLabel="geolocate-ok-btn"
          isStretched
          onClick={props.handleCloseModal}
        >
          OK
        </Button>
      );
    default:
      return null;
  }
};

export const GeolocateIn = (props: Props) => {
  const { type, messages } = props;

  return useMemo(
    () => (
      <GeolocateInContainer>
        {renderText(type, messages)}
        {renderButtons(props)}
      </GeolocateInContainer>
    ),
    [props]
  );
};

GeolocateIn.defaultProps = {
  type: "WARNING",
  messages: {
    warning: "",
    failure: ""
  },
  handleCloseModal: noop,
  phoneUrl: "tel:1-888-752-9884",
  liveChatUrl: "",
  hasLiveChat: true,
  onExternalLinkCallback: noop,
  iOSMessageUsCallback: noop,
  isApp: false,
  isMobile: true
};

export default GeolocateIn;
