import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  EventsProps,
  PromoCodeMediatorEventType,
  PromoCodeEventData
} from "./types";
import { EVENT_CATEGORY, PROMO_CODE_EVENTS } from "./constants";

export default () => {
  // When a user clicks "Enter Promo Code" in the registration form
  mediator.base.subscribe(
    PromoCodeMediatorEventType.ENTER_PROMO_CODE,
    (data: PromoCodeEventData) => {
      track<BaseEventProps>(PROMO_CODE_EVENTS.FIELD_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: "Promo Code",
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user closes the promo code modal
  mediator.base.subscribe(PromoCodeMediatorEventType.MODAL_CLOSED, () => {
    track<BaseEventProps>(PROMO_CODE_EVENTS.FIELD_MODAL_CLOSED, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: "Promo Code",
      [BaseEventProps.MODULE]: "Promo Code"
    });
  });

  // When a user clicks "Apply"
  mediator.base.subscribe(
    PromoCodeMediatorEventType.APPLY,
    (data: PromoCodeEventData) => {
      track<BaseEventProps>(PROMO_CODE_EVENTS.ATTEMPT, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks continue and applies a promo code to the form
  mediator.base.subscribe(
    PromoCodeMediatorEventType.APPLIED,
    (data: PromoCodeEventData) => {
      track<EventsProps>(PROMO_CODE_EVENTS.SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", ""),
        [BaseEventProps.PROMO_CODE]: get(
          data,
          "payload.promoCode",
          ""
        ).toUpperCase()
      });
    }
  );

  // When a user lands on a registration form and a promo code gets applied automatically
  mediator.base.subscribe(
    PromoCodeMediatorEventType.APPLIED_AUTOMATICALLY,
    (data: PromoCodeEventData) => {
      track<EventsProps>(PROMO_CODE_EVENTS.SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: `Deeplink - ${get(
          data,
          "payload.promoCode",
          ""
        )}`,
        [BaseEventProps.MODULE]: get(data, "payload.module", ""),
        [BaseEventProps.PROMO_CODE]: get(
          data,
          "payload.promoCode",
          ""
        ).toUpperCase()
      });
    }
  );

  // When a user clicks continue in the promo code modal with no promo code selected/validated and removes a promo code from the form
  mediator.base.subscribe(
    PromoCodeMediatorEventType.REMOVED,
    (data: PromoCodeEventData) => {
      track<EventsProps>(PROMO_CODE_EVENTS.REMOVED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", ""),
        [BaseEventProps.PROMO_CODE]: get(
          data,
          "payload.promoCode",
          ""
        ).toUpperCase()
      });
    }
  );

  // When a user changes a promo code and clicks continue in the promo code modal
  mediator.base.subscribe(
    PromoCodeMediatorEventType.EDITED,
    (data: PromoCodeEventData) => {
      track<EventsProps>(PROMO_CODE_EVENTS.EDITED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", ""),
        [BaseEventProps.PROMO_CODE]: get(
          data,
          "payload.promoCode",
          ""
        ).toUpperCase()
      });
    }
  );
};
