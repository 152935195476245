import buildColor from "../../_static/ColorPalette";
import {
  exclamationMark,
  errorRound,
  successRound,
  info
} from "../../_static/Icons/iconsDuotone";

export const handleVariants = (variant) => {
  switch (variant) {
    case "success":
      return {
        borderColor: buildColor("green", "200"),
        backgroundColor: buildColor("green", "000"),
        textColor: buildColor("green", "600"),
        leftIcon: {
          icon: successRound,
          color: buildColor("green", "100"),
          strokeColor: buildColor("green", "600")
        },
        rightIcon: {
          color: buildColor("green", "600")
        }
      };
    case "warning":
      return {
        borderColor: buildColor("yellow", "200"),
        backgroundColor: buildColor("yellow", "000"),
        textColor: buildColor("yellow", "900"),
        leftIcon: {
          icon: exclamationMark,
          color: buildColor("yellow", "200"),
          strokeColor: buildColor("yellow", "800")
        },
        rightIcon: {
          color: buildColor("yellow", "900")
        }
      };
    case "danger":
      return {
        borderColor: buildColor("red", "200"),
        backgroundColor: buildColor("red", "000"),
        textColor: buildColor("red", "600"),
        leftIcon: {
          icon: errorRound,
          color: buildColor("red", "200"),
          strokeColor: buildColor("red", "600")
        },
        rightIcon: {
          color: buildColor("red", "600")
        }
      };
    case "info":
    default:
      return {
        borderColor: buildColor("blue_accent", "200"),
        backgroundColor: buildColor("blue_accent", "000"),
        textColor: buildColor("blue_accent", "600"),
        leftIcon: {
          icon: info,
          color: buildColor("blue_accent", "200"),
          strokeColor: buildColor("blue_accent", "600")
        },
        rightIcon: {
          color: buildColor("blue_accent", "600")
        }
      };
  }
};
