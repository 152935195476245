import { BaseEventProps } from "../../types";

export enum MediatorEventType {
  FILTER_OPEN = "AS_OPEN_FILTER",
  FILTER_APPLY = "AS_APPLY_FILTER",
  FILTER_CLEAR = "AS_CLEAR_FILTER",
  DOWNLOAD_CLICK = "AS_DOWNLOAD_CLICK",
  DOWNLOAD_CONFIRM = "AS_DOWNLOAD_CONFIRM",
  SELECTION_TIME_FRAME = "AS_TIME_FRAME_SELECTION",
  SELECTION_DATE_RANGE = "AS_DATE_RANGE_SELECTION",
  PAGINATION_CLICK = "AS_PAGINATION_CLICK",
  HEADER_BACK = "HEADER_GO_BACK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    filter?: string[];
    timeFrame?: string;
    gaEventLabel?: string;
    gaEventAction?: string;
    fileType?: string;
    currentPage?: number;
    newPage?: number;
    locationPathname?: string;
  };
}

export type EventProps = BaseEventProps;
