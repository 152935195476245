// @flow
import type { Element } from "react";
import { noop } from "lodash";
import type { SetHandicappingStoreModalHeaderAction } from "@tvg/shared-actions/HandicappingStore";
import type { LoginActiveFlow, LoginActiveFlowStatus } from "@tvg/types/Login";
import type { NullaryFn } from "@tvg/types/Functional";
import type { Actions } from "../actions/modal";

export const LOGIN_ACTIVE_FLOWS: { [key: LoginActiveFlow]: LoginActiveFlow } = {
  "forgot-credentials": "forgot-credentials",
  "recover-email": "recover-email"
};

export const LOGIN_ACTIVE_FLOWS_STATUS: {
  [key: LoginActiveFlowStatus]: LoginActiveFlowStatus
} = {
  failure: "failure",
  success: "success"
};

export type State = {
  loginOpen: boolean,
  loginComponent: Element<*> | null,
  loginActiveFlow: LoginActiveFlow | null,
  loginActiveFlowStatus: LoginActiveFlowStatus | null,
  contentCardsOpen: boolean,
  isModalOpen: boolean,
  isErrorModalOpen?: boolean,
  callback: NullaryFn<void>
};

export const initialState = {
  loginOpen: false,
  loginComponent: null,
  loginActiveFlow: null,
  loginActiveFlowStatus: null,
  contentCardsOpen: false,
  callback: noop,
  isModalOpen: false,
  isErrorModalOpen: false
};

const modalReducer = (
  state: State = initialState,
  action: Actions | SetHandicappingStoreModalHeaderAction
): State => {
  switch (action.type) {
    case "OPEN_LOGIN_MODAL": {
      return {
        ...state,
        loginOpen: true,
        callback: action.payload.callback,
        loginComponent: action.payload.loginComponent,
        loginActiveFlow: action.payload.loginActiveFlow || null,
        loginActiveFlowStatus:
          // this allows us to manually overwrite to null
          action.payload.loginActiveFlowStatus === null
            ? null
            : action.payload.loginActiveFlowStatus ||
              state.loginActiveFlowStatus,
        isModalOpen: true
      };
    }
    case "CLOSE_LOGIN_MODAL": {
      return {
        ...state,
        loginOpen: false,
        isModalOpen: false
      };
    }
    case "RESET_LOGIN_FLOW": {
      return {
        ...state,
        loginActiveFlow: null,
        loginActiveFlowStatus: null
      };
    }
    case "RESET_LOGIN_FLOW_STATUS": {
      return {
        ...state,
        loginActiveFlowStatus: null
      };
    }
    case "OPEN_SIGNUP": {
      return { ...state, isModalOpen: true };
    }
    case "OPEN_CONTENT_CARDS_MODAL": {
      return { ...state, contentCardsOpen: true, isModalOpen: true };
    }
    case "CLOSE_CONTENT_CARDS_MODAL": {
      return { ...state, contentCardsOpen: false, isModalOpen: false };
    }
    case "OPEN_ERROR_MODAL": {
      return {
        ...state,
        isErrorModalOpen: !state.isErrorModalOpen,
        isModalOpen: !state.isModalOpen
      };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
