export enum MediatorEventType {
  CLICK_PP = "PAST_PERFORMANCES:CLICK_PP",
  CLICK_MORE_PAGE = "PAST_PERFORMANCES:CLICK_MORE_PAGE",
  SELECT_PP_MORE_PAGE = "PAST_PERFORMANCES:SELECT_PP_MORE_PAGE",
  CLOSE_ALL_PP = "PAST_PERFORMANCES:CLOSE_ALL_PP",
  CLOSE_SINGLE_PP = "PAST_PERFORMANCES:CLOSE_SINGLE_PP",
  TOGGLE_CONDITIONS = "PAST_PERFORMANCES:TOGGLE_CONDITIONS",
  BACK_TO_ALL = "PAST_PERFORMANCES:BACK_TO_ALL",
  HORSE_SELECTOR = "PAST_PERFORMANCES:HORSE_SELECTOR",
  SEE_ALL_RUNNERS = "PAST_PERFORMANCES:SEE_ALL_RUNNERS"
}

export type PastPerformancesEventData = {
  type: MediatorEventType;
  payload: {
    direction?: string;
    expandAction: "Expand" | "Collapse";
    runnerId: string;
    runnerName: string;
    raceNumber: string;
    trackName: string;
    toggleState: "On" | "Off";
    matchingConditions: string;
    runnerIndex: number;
  };
};
