// @flow
import type { ChannelUFC } from "@tvg/types/Alerts";
import type { SetRaceAlertsActionType } from "@tvg/shared-actions/UserActions";

export type State = {
  raceAlerts: ChannelUFC[]
};

export const initialState = {
  raceAlerts: []
};

export default function alertsReducer(
  state: State = initialState,
  action: SetRaceAlertsActionType
) {
  switch (action.type) {
    case "SET_RACE_ALERTS": {
      return { ...state, raceAlerts: action.payload.channels };
    }

    default: {
      return state;
    }
  }
}
