import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  NAVIGATE = "TUTORIALS:NAVIGATE",
  OPEN = "TUTORIALS:OPEN"
}

export interface NavigateData {
  type: MediatorEventType.NAVIGATE;
  payload: {
    destination: string;
  };
}

export interface OpenData {
  type: MediatorEventType.OPEN;
  payload: {
    tutorialName: string;
  };
}
