// @flow
import styled, { css } from "styled-components";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const SupportSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
  padding-bottom: calc(constant(safe-area-inset-bottom));
  padding-bottom: calc(env(safe-area-inset-bottom));
`;

export const SupportContainer = styled.div`
  ${({ isLiveChat }) =>
    isLiveChat &&
    css`
      position: relative;
      margin-top: 12px;
      margin-bottom: 12px;
    `}

  & > a {
    height: 48px;
    padding: 0 8px 0 12px;

    &:first-of-type {
      position: relative;
      ${({ removeBottomBorder }) =>
        !removeBottomBorder &&
        css`
          &::before {
            content: "";
            position: absolute;
            height: 1px;
            width: calc(100% - 20px);
            bottom: 0;
            background: ${buildColor("grey", "300")};
          }
        `}
    }
  }
`;

export const HeaderText = styled.div`
  letter-spacing: 1.2px;
  font-size: 14px;
  font-weight: normal;
  font-family: ${fontNormal};
  text-transform: uppercase;
  color: ${buildColor("grey", "800")};
  height: 17px;
  padding: 0 12px;
`;
