import { RacePanelLink } from "@tvg/ts-types/Race";
import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK = "UPCOMING_RACE_CLICK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    race: RacePanelLink;
    isRDA: boolean;
    url: string;
    optedInPromos: { string: boolean };
    swipeDirection: number;
  };
}
