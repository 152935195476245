// @flow
import styled, { css } from "styled-components";
import { fontMedium, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const CardContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  transition: transform 0.125s ease-out;
  background-color: inherit;
  z-index: 1;
`;

export const Content = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 12px;
`;

export const ImageContainer = styled.div`
  width: 42px;
  margin: 16px 0 12px 12px;
`;

export const Image = styled.img`
  display: block;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 14px;
  font-family: ${fontMedium};
  font-weight: 500;
  color: ${buildColor("grey", "900")};
  ${({ viewed }) =>
    !viewed &&
    css`
      color: ${buildColor("blue_accent", "600")};
    `}
`;

export const Description = styled.p`
  margin-top: 4px;
  line-height: 18px;
  font-family: ${fontNormal};
  font-size: 14px;
  font-weight: 400;
  color: ${buildColor("grey", "700")};
`;

export const Link = styled.span`
  display: inline-block;
  margin-top: 8px;
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${buildColor("blue_accent", "500")};
`;

export const Pinned = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const DismissDesktop = styled.div`
  display: none;
  position: absolute;
  right: 12px;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${buildColor("red", "100")};
  cursor: pointer;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;

  &:hover {
    background-color: ${buildColor("red", "200")};
  }
`;

export const Card = styled.div`
  position: relative;
  height: inherit;
  min-height: 73px;
  transition: all 500ms ease;
  display: flex;
  background-color: ${({ viewed }) =>
    viewed ? buildColor("white", "100") : buildColor("blue_accent", "000")};
  border-bottom: 1px solid ${buildColor("blue", "000")};
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      height: 0;
      min-height: 0;
      overflow: hidden;
      border-bottom: 0;
    `}
  ${({ type, viewed }) =>
    (type === "ab-captioned-image" || type === "ab-image-only") &&
    css`
      border-bottom: 0;
      background-color: ${viewed
        ? buildColor("blue", "900")
        : buildColor("blue", "800")};

      ${ImageContainer} {
        width: 100%;
        margin: 0;
      }

      ${Title} {
        color: ${viewed
          ? buildColor("white", "100")
          : buildColor("blue_accent", "300")};
      }

      ${Description} {
        color: ${buildColor("white", "100")};
      }

      ${Link} {
        color: ${buildColor("blue_accent", "300")};
      }
    `}

  &:hover {
    ${({ isDesktop }) =>
      isDesktop &&
      css`
        background-color: ${buildColor("blue_accent", "100")};
      `}

    ${DismissDesktop} {
      display: flex;
    }

    ${({ type, viewed }) =>
      (type === "ab-captioned-image" || type === "ab-image-only") &&
      css`
        background-color: ${viewed
          ? buildColor("blue", "900")
          : buildColor("blue", "800")};

        ${Content} {
          background-color: ${buildColor("white", "10")};
        }
      `}
  }

  &:active {
    ${({ type }) =>
      type === "ab-captioned-image" || type === "ab-image-only"
        ? css`
            ${Content} {
              background-color: ${buildColor("white", "10")};
            }
          `
        : css`
            background-color: ${buildColor("blue_accent", "100")};
          `}
  }
`;

export const CardLink = styled.a`
  position: relative;
  height: inherit;
  min-height: 73px;
  transition: all 500ms ease;
  display: flex;
  background-color: ${({ viewed }) =>
    viewed ? buildColor("white", "100") : buildColor("blue_accent", "000")};
  border-bottom: 1px solid ${buildColor("blue", "000")};
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      height: 0;
      min-height: 0;
      overflow: hidden;
      border-bottom: 0;
    `}
  ${({ type, viewed }) =>
    (type === "ab-captioned-image" || type === "ab-image-only") &&
    css`
      border-bottom: 0;
      background-color: ${viewed
        ? buildColor("blue", "900")
        : buildColor("blue", "800")};

      ${ImageContainer} {
        width: 100%;
        margin: 0;
      }

      ${Title} {
        color: ${viewed
          ? buildColor("white", "100")
          : buildColor("blue_accent", "300")};
      }

      ${Description} {
        color: ${buildColor("white", "100")};
      }

      ${Link} {
        color: ${buildColor("blue_accent", "300")};
      }
    `}

  &:hover {
    ${({ isDesktop }) =>
      isDesktop &&
      css`
        background-color: ${buildColor("blue_accent", "100")};
      `}

    ${({ type, viewed }) =>
      (type === "ab-captioned-image" || type === "ab-image-only") &&
      css`
        background-color: ${viewed
          ? buildColor("blue", "900")
          : buildColor("blue", "800")};

        ${Content} {
          background-color: ${buildColor("white", "10")};
        }
      `}
  }

  &:active {
    background-color: ${buildColor("blue_accent", "100")};
  }
`;

export const CardLinkContainer = styled.div`
  position: relative;

  &:hover {
    ${({ pinned }) =>
      !pinned &&
      css`
        ${DismissDesktop} {
          display: flex;
        }
      `}
  }
`;
