// @flow
import axios from "axios";
import tvgConf from "@tvg/conf";

import type { TutorialListFromService } from "@tvg/types/OnBoarding";

/**
 * Gets the list of routes with available tutorials
 * @returns {Promise<TutorialListFromService>}
 */
const getTutorialList = (): Promise<TutorialListFromService[]> => {
  const conf = tvgConf();
  const url = `${conf.config().service.capi}/tutorial`;

  const requestOptions = {
    method: "GET",
    url,
    headers: {
      "content-type": "application/json",
      "x-tvg-context": conf.context(),
      "x-requested-with": "XMLHttpRequest"
    },
    params: {
      isMinimized: true,
      isActive: true
    },
    withCredentials: true
  };

  return axios(requestOptions)
    .then(({ data }) => data)
    .catch((err: Error): Error => err);
};

export default getTutorialList;
