export enum MediatorEventType {
  PAGE_VIEW = "PROMOS_PAGE_VIEW"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    siteVersion: string;
    productVersion: string;
    accountId: string;
    state: string;
    logged: string;
    registrationStatus: string;
    page: string;
    sport: string;
    privateBrowser: "Yes" | "No";
    graphVersion: "v1" | "v2";
  };
}
