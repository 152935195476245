import mediator from "@tvg/mediator";
import { get } from "lodash";

import { BaseEventProps } from "../../types";
import { track } from "../../amplitude";
import {
  EventData,
  EventProps,
  MediatorEventType,
  ModuleEventProps
} from "./types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.VIEWED, (data: EventData) =>
    track<EventProps>(EVENT_NAME.VIEWED, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.PLAY_SUCCESS, (data: EventData) =>
    track<EventProps>(EVENT_NAME.PLAY_SUCCESS, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.CLICK_PLAY, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CLICK_PLAY, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.PAUSE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.PAUSE, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.COMPLETE, (data: EventData) =>
    track<EventProps>(EVENT_NAME.COMPLETE, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.MUTE_UNMUTE, (data: EventData) =>
    track<EventProps>(get(data, "payload.gaEventAction"), {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.SIZE_CHANGE, (data: EventData) =>
    track<EventProps>(get(data, "payload.gaEventAction"), {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.SIZE_TILT, (data: EventData) =>
    track<EventProps>(get(data, "payload.gaEventAction"), {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider")
    })
  );

  mediator.base.subscribe(MediatorEventType.ERROR, (data: EventData) =>
    track<EventProps>(EVENT_NAME.ERROR, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [ModuleEventProps.ERROR_TYPE]: get(data, "payload.errorType")
    })
  );

  mediator.base.subscribe(MediatorEventType.LOAD_FAIL, (data: EventData) =>
    track<EventProps>(EVENT_NAME.LOAD_FAIL, {
      [BaseEventProps.EVENT_CATEGORY]: "video",
      [BaseEventProps.MODULE]: "video",
      [ModuleEventProps.VIDEO_TITLE]: get(data, "payload.videoTitle"),
      [ModuleEventProps.VIDEO_TYPE]: "education",
      [ModuleEventProps.VIDEO_PROVIDER]: get(data, "payload.videoProvider"),
      [ModuleEventProps.ERROR_TYPE]: get(data, "payload.errorType")
    })
  );
};
