import React from "react";

import { get } from "lodash";
import PropTypes from "prop-types";

import { Icon } from "@tvg/design-system";
import { events as AlchemerEvents } from "@urp/alchemer";
import style from "./style.css";

export const MyAccountMainMenu = ({
  goToSection,
  hasTalentPickTool,
  features,
  accountMenuTitles,
  sendFeedBack,
  supportEmail,
  menuHandlerClick
}) => {
  return (
    /* The <div> element has children <a> elements that allows keyboard interaction */
    /* eslint-disable-next-line */
    <div
      data-qa-label="my-account-links"
      className={style.links}
      onClick={menuHandlerClick}
    >
      <div
        data-qa-label="my-account-category-main-menu"
        className={style.category}
      >
        <h3
          data-qa-label="my-account-category-main-menu-title"
          className={style.title}
        >
          {accountMenuTitles ? accountMenuTitles.mainMenu : "Main Menu"}
        </h3>
        <a
          href="/my-bets"
          onClick={goToSection("/my-bets", "My Bets")}
          className={style.linkItem}
          data-qa-label="account-menu-my-bets"
        >
          <Icon
            name="bets"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>My Bets</span>
        </a>
        <a
          className={style.linkItem}
          href="/my-favorite-tracks"
          onClick={goToSection("/my-favorite-tracks", "Favorite Tracks")}
          data-qa-label="account-menu-my-favorite-tracks"
        >
          <Icon
            name="star"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>Favorite Tracks</span>
        </a>
        <a
          className={style.linkItem}
          href="/my-stable"
          onClick={goToSection("/my-stable", "Stable")}
          data-qa-label="account-menu-my-stable"
        >
          <Icon
            name="stable"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>Stable</span>
        </a>
      </div>
      <div
        className={style.category}
        data-qa-label="my-account-category-your-account"
      >
        <h3
          className={style.title}
          data-qa-label="my-account-category-your-account-title"
        >
          {accountMenuTitles ? accountMenuTitles.yourAccount : "Your Account"}
        </h3>
        <a
          className={style.linkItem}
          href="/my-account-summary"
          onClick={goToSection("/my-account-summary", "Account Summary")}
          data-qa-label="account-menu-my-account-summary"
        >
          <Icon
            name="user"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>Account Summary</span>
        </a>
        <a
          className={style.linkItem}
          href="/my-profile"
          onClick={goToSection("/my-profile", "Edit Info")}
          data-qa-label="account-menu-my-profile"
        >
          <Icon
            name="edit"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>Edit Info</span>
        </a>
        {get(features, "usePrefReact", false) && (
          <a
            className={style.linkItem}
            href="/preferences"
            onClick={goToSection("/preferences", "Preferences")}
            data-qa-label="account-menu-preferences"
          >
            <Icon
              name="settings"
              size="s"
              lineColor="blue_accent.500"
              backgroundColor="blue_accent.100"
            />
            <span>Preferences</span>
          </a>
        )}
        <a
          className={style.linkItem}
          href="/w2-g-reports"
          onClick={goToSection("/w2-g-reports", "W2-G Reports")}
          data-qa-label="account-menu-w2-g-reports"
        >
          <Icon
            name="file"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>W2-G Reports</span>
        </a>

        <a
          className={style.linkItem}
          href="/year-end-reports"
          onClick={goToSection("/year-end-reports", "Year End Reports")}
          data-qa-label="account-menu-year-end-reports"
        >
          <Icon
            name="calendar"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>Year End Reports</span>
        </a>
        <a
          href={supportEmail}
          className={style.linkItem}
          onClick={sendFeedBack}
          data-qa-label="account-menu-support-email"
        >
          <Icon
            name="customerSupport"
            size="s"
            lineColor="blue_accent.500"
            backgroundColor="blue_accent.100"
          />
          <span>Send Feedback</span>
        </a>
        {get(features, "showResponsibleGaming", false) && (
          <a
            className={style.linkItem}
            href="/responsible-gaming"
            onClick={() => {
              AlchemerEvents.selectResponsibleGaming();
              goToSection("/responsible-gaming", "Responsible Gaming");
            }}
            data-qa-label="account-menu-responsible-gaming"
          >
            <Icon
              name="lock"
              size="s"
              lineColor="blue_accent.500"
              backgroundColor="blue_accent.100"
            />
            <span>Responsible Gaming</span>
          </a>
        )}

        {hasTalentPickTool() && (
          <a
            className={style.linkItem}
            href="/talent-picks/"
            onClick={(e) => {
              e.preventDefault();
              window.open("/talent-picks/", "_blank");
            }}
            data-qa-label="account-menu-talent-picks"
          >
            <Icon
              name="tvgPicks"
              size="s"
              lineColor="blue_accent.500"
              backgroundColor="blue_accent.100"
            />
            <span>Talent Picks Tool</span>
          </a>
        )}
      </div>
    </div>
  );
};

MyAccountMainMenu.propTypes = {
  goToSection: PropTypes.func,
  hasTalentPickTool: PropTypes.func,
  features: PropTypes.shape({
    usePrefReact: PropTypes.bool,
    showResponsibleGaming: PropTypes.bool
  }),
  accountMenuTitles: PropTypes.shape({
    mainMenu: PropTypes.string,
    yourAccount: PropTypes.string
  }),
  sendFeedBack: PropTypes.func,
  supportEmail: PropTypes.string,
  menuHandlerClick: PropTypes.func,
  handleOptInNewProgramPage: PropTypes.func
};

MyAccountMainMenu.defaultProps = {
  goToSection: () => {},
  hasTalentPickTool: () => {},
  features: {},
  accountMenuTitles: {},
  sendFeedBack: () => {},
  supportEmail: "",
  menuHandlerClick: () => {},
  handleOptInNewProgramPage: () => {}
};
