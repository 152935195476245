// @flow

import React, { PureComponent, type Node } from "react";
import { Collapse } from "react-collapse";
import { bindAll, noop } from "lodash";
import type { UnaryFn } from "@tvg/types/Functional";
import Icon from "../../_static/Icons/index";

import buildColor from "../../_static/ColorPalette";
import { arrowDown } from "../../_static/Icons/icons";
import {
  RowContainer,
  Row,
  ExpandableHeader,
  IconContainer,
  ClickableArea,
  RowLineBreak
} from "./styled-components";

export type State = { isOpen: boolean };

export type Props = {
  /**
   * Header Component
   */
  header: Node,
  /**
   * Content component
   */
  children: Node,
  /**
   * Expandable Row
   */
  isExpandable: boolean,
  /**
   * Open/Close Row
   */
  isOpen: boolean,
  /**
   * Callback when expand.
   */
  onExpand: UnaryFn<boolean, mixed>,
  /**
   * Classname to style the component
   */
  className: string,
  /**
   * Line break for tracks separator
   */
  hasLineBreak: boolean,
  /**
   * Force Height of expandable row
   */
  forceHeight: boolean,
  /**
   * Track Code
   */
  trackCode: string
};

export default class ExpandableRow extends PureComponent<Props, State> {
  static defaultProps = {
    header: null,
    children: null,
    isExpandable: true,
    isOpen: false,
    onExpand: noop,
    className: "",
    hasLineBreak: false,
    forceHeight: false,
    trackCode: ""
  };

  constructor(props: Props): void {
    super(props);
    this.state = {
      isOpen: props.isOpen
    };
    bindAll(this, "onExpand");
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== this.props.isOpen
    ) {
      this.setState({
        isOpen: nextProps.isOpen
      });
    }
  }

  onExpand(): void {
    if (this.props.isExpandable) {
      this.props.onExpand(this.state.isOpen);
      this.setState((prevState) => ({
        isOpen: !prevState.isOpen
      }));
    }
  }

  render() {
    return (
      <RowContainer forceHeight={this.props.forceHeight}>
        <Row
          className={this.props.className}
          data-qa-label={`expandableRow-collapser${this.props.trackCode}`}
        >
          <ExpandableHeader onTouchStart={noop}>
            <ClickableArea onClick={this.onExpand}>
              {this.props.header}
            </ClickableArea>
            {this.props.isExpandable && (
              <IconContainer isOpen={this.state.isOpen}>
                <Icon
                  icon={arrowDown}
                  size={16}
                  color={buildColor("grey", "600")}
                  qaLabel="expandableRow-arrow-icon"
                />
              </IconContainer>
            )}
          </ExpandableHeader>
          {this.props.isExpandable && (
            <Collapse
              isOpened={this.state.isOpen}
              springConfig={{ stiffness: 200, damping: 25 }}
            >
              {this.props.children}
            </Collapse>
          )}
        </Row>
        {this.props.hasLineBreak && <RowLineBreak />}
      </RowContainer>
    );
  }
}
