export enum MediatorEventType {
  SOCIAL_CLICK = "SOCIAL_LINK_CLICK",
  NAVIGAGTION_CLICK = "RAF_NAVIGATION_CLICK",
  SITE = "RAF_SITE_CLICK"
}

export interface ReferFriendData {
  gaEventLabel: "string";
  module: "module";
  microApp: "microApp";
}
