import { get } from "lodash";

import mediator from "@tvg/mediator";
import {
  MediatorEventType,
  PromosRetCTAClickEventData,
  PromosAllCTAClickEventData,
  CTASiteClickEventData
} from "./types";
import { track } from "../../../amplitude";
import { BaseEventProps } from "../../../types";
import { EVENTS } from "./constants";
import { COMMON_EVENT_NAMES } from "../../../constants";
import { EVENTS_TO_EXCLUDE } from "../../../utils";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.PROMOS_RET_CTA_CLICK,
    (data: PromosRetCTAClickEventData) => {
      track<BaseEventProps>(EVENTS.OPT_IN, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(
          data,
          "payload.gaEventLabel",
          undefined
        ), // promo id
        [BaseEventProps.MODULE]: "promos",
        [BaseEventProps.TAG]: get(data, "payload.tag", undefined) // Outcome - Only on the optin attempt depending on the result of Sucess/Failure //
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.PROMOS_ALL_CTA_CLICK,
    (data: PromosAllCTAClickEventData) => {
      const eventAction = get(data, "payload.gaEventAction", undefined); // pass event name 'Join Now'
      if (EVENTS_TO_EXCLUDE.includes(eventAction.toLowerCase())) {
        return;
      }
      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(
          data,
          "payload.gaEventLabel",
          undefined
        ), // promo code
        [BaseEventProps.MODULE]: "promos",
        [BaseEventProps.TAG]: undefined
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.CTA_SITE_CLICK,
    (data: CTASiteClickEventData) => {
      const eventAction = get(data, "payload.gaEventAction", undefined); // pass event name 'Join Now'
      if (EVENTS_TO_EXCLUDE.includes(eventAction.toLowerCase())) {
        return;
      }
      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(
          data,
          "payload.gaEventLabel",
          undefined
        ), // Button label
        [BaseEventProps.MODULE]: get(data, "payload.module", undefined),
        [BaseEventProps.TAG]: undefined
      });
    }
  );
};
