import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  EDUCATION_CATEGORY = "Education Category",
  PAGINATION_NUMBER = "Pagination Number",
  TOTAL_PAGES = "Total Pages",
  ARTICLE_NAME = "Article Name"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  PILL_CLICKED = "EDUCATION_PILL_CLICKED",
  CAROUSEL_SELECTED = "EDUCATION_TUTORIAL_CAROUSEL_SELECTED",
  ARTICLE_SELECTED = "EDUCATIONAL_ARTICLE_SELECTED",
  ARTICLE_VIEWED = "EDUCATIONAL_ARTICLE_VIEWED"
}

export interface PillClickedData {
  type: MediatorEventType.PILL_CLICKED;
  payload: {
    eventLabel: string;
  };
}

export interface CarouselSelectedData {
  type: MediatorEventType.CAROUSEL_SELECTED;
  payload: {
    tutorialName: string;
  };
}

export interface ArticleSelectedViewedData {
  type: MediatorEventType.ARTICLE_SELECTED | MediatorEventType.ARTICLE_VIEWED;
  payload: {
    articleName: string;
    sectionTitle: string;
    eventLabel: string;
  };
}
