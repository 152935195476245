import styled, { css } from "styled-components";
import { fontNormal } from "../../_static/Typography";

export const MessageBoxContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ hasRoundCorners, borderColor }) =>
    hasRoundCorners
      ? css`
          border-radius: 4px;
          border: 1px solid ${borderColor};
        `
      : css`
          border-top: 1px solid ${borderColor};
          border-bottom: 1px solid ${borderColor};
        `}
`;

export const MessageBoxText = styled.div`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  color: ${({ color }) => color};
  margin: 0 12px;
`;

export const ArrowContainer = styled.div`
  display: inline-flex;
  margin-left: auto;
`;
