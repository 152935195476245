import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENTS = {
  SELECT: "Select",
  ALERTS: "Alerts",
  NAVIGATED_TO: COMMON_EVENT_NAMES.NAVIGATED,
  NEXT: "Next",
  BACK: "Back",
  CARD_CLICKED: "Talent Picks Card Clicked",
  ADD_TO_BETSLIP: "Add To Betslip",
  FILTERS_APPLIED: "Talent Picks Filter Applied",
  EMPTY_STATE_VIEWED: "Talent Picks Empty State Viewed",
  CTA_CLICKED: "Talent Picks CTA Clicked"
};

export const PAGES = {
  TP: "Talent Pick Page",
  HOMEPAGE: "Talent Pick Homepage",
  PP_TRACKMASTER: "PP Trackmaster Picks"
};

export const LINK_TEXT = {
  TALENT_NAME: "talent_name",
  ADD_TO_BETSLIP: "add_to_betslip",
  VIEW_RACE: "view_race"
} as const;

export const MODULE = {
  PICKS_PAGE: "fdtv_picks_page",
  HOME: "fdtv_home_placement",
  RACE_CARD: "fdtv_race_card"
} as const;

export const FILTER = {
  TRACK: "track_name",
  TALENT: "talent_name"
} as const;
