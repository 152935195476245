export enum MediatorEventType {
  PAGEVIEW = "PROMOS_PAGEVIEW"
}

export interface PromosPageData {
  type: MediatorEventType;
  payload: {
    productVersion: string;
    residenceState: string;
    loginStatus: string;
    registrationStatus: string;
    page: string;
  };
}
