import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindAll, get, attempt } from "lodash";
import classNames from "classnames";
import { connect } from "react-redux";
import * as mediator from "@tvg/mediator-classic/src";
import mediatorChannels from "@tvg/mediator";
import NotificationBox from "@tvg/atomic-ui/_molecule/NotificationBox";
import { events as AlchemerEvents } from "@urp/alchemer";

import MyAccountButtonLogoutSection from "../MyAccountButtonLogoutSection/index";
import MyAccountButtonUserSection from "../MyAccountButtonUserSection/index";
import { closeOverlay } from "../../../actions/accountButton";
import style from "./style.css";
import { MyAccountMainMenu } from "../MyAccountMainMenu";

const RolesMap = {
  TALENT: "TALENT",
  WAGEROPS: "WAGEROPS",
  ADMIN: "ADMIN"
};

const sendFeedBack = (event) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  AlchemerEvents.showSendFeedbackSurvey();
  this.props.dispatch(closeOverlay());
};

const menuHandlerClick = (event) => event.stopPropagation();
export class MyAccountButtonOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingWithdrawalsLength: 0
    };

    bindAll(this, ["goToSection", "hasTalentPickTool"]);

    mediatorChannels.base.subscribe(
      "PENDING_WITHDRAWALS_LENGTH",
      ({ payload: { withdrawalsLength = 0 } }) =>
        this.setState({ pendingWithdrawalsLength: withdrawalsLength })
    );
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.showPendingWithdrawalsV2 &&
      !prevProps.overlayOpen &&
      this.props.overlayOpen
    ) {
      mediatorChannels.base.dispatch({
        type: "REFETCH_PENDING_WITHDRAWALS_LENGTH",
        payload: {}
      });
    }
  };

  hasTalentPickTool() {
    return (
      get(this.props, "features.enableTalentPickApp", false) &&
      get(this.props, "user.accountRoles", []).some((role) => RolesMap[role])
    );
  }

  goToSection(sectionUrl, name) {
    return (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.props.dispatch(closeOverlay());
      mediator.dispatch("HEADER_DESKTOP_NAVIGATION", {
        event: name,
        url: `${get(window, "location.hostname")}${sectionUrl}`
      });
      mediator.dispatch("TVG4_NAVIGATION", { route: sectionUrl });
    };
  }

  render() {
    const classNamesContainer = classNames(
      style.overlayArea,
      style.accountPanelV2,
      this.props.className
    );

    return (
      <div className={classNamesContainer}>
        <section>
          <MyAccountButtonUserSection />
          <div
            id="MyAccountButtonUser"
            data-qa-label="my-account-button-user"
            className={style.scroll}
          >
            {this.props.showPendingWithdrawalsV2 &&
              !!this.state.pendingWithdrawalsLength && (
                <a
                  href="#pending-withdrawals"
                  onClick={() =>
                    mediatorChannels.base.dispatch({
                      type: "OPEN_PENDING_WITHDRAWALS_MODAL",
                      payload: {}
                    })
                  }
                >
                  <NotificationBox
                    text={`You have ${
                      this.state.pendingWithdrawalsLength
                    } pending ${
                      this.state.pendingWithdrawalsLength === 1
                        ? "withdrawal"
                        : "withdrawals"
                    }`}
                    variant="warning"
                    hasRoundCorners={false}
                    hasArrowRight={false}
                  />
                </a>
              )}

            <MyAccountMainMenu
              goToSection={this.goToSection}
              sendFeedBack={sendFeedBack}
              supportEmail={this.props.supportEmail}
              hasTalentPickTool={this.hasTalentPickTool}
              features={this.props.features}
              accountMenuTitles={this.props.accountMenuTitles}
              menuHandlerClick={menuHandlerClick}
            />

            <MyAccountButtonLogoutSection
              supportEmail={this.props.supportEmail}
              features={this.props.features}
              sendFeedBack={sendFeedBack}
            />
          </div>
        </section>
      </div>
    );
  }
}

/* eslint-disable */
MyAccountButtonOverlay.propTypes = {
  user: PropTypes.mixed,
  dispatch: PropTypes.func,
  className: PropTypes.string,
  features: PropTypes.mixed
};
/* eslint-enable */

MyAccountButtonOverlay.defaultProps = {
  user: {},
  dispatch: () => null,
  className: "",
  features: {}
};

export default connect((store) => {
  return {
    user: store.userData.user,
    overlayOpen: store.accountButton.overlayOpen,
    accountMenuTitles: attempt(JSON.parse, store.header.accountMenuTitles),
    features: store.header.features,
    supportEmail: store.header.supportEmail,
    showPendingWithdrawalsV2: get(
      store,
      "header.features.pendingWithdrawalsV2",
      false
    ),
    nextRaceLink: get(store, "nextRace.nextRaceLink", "")
  };
})(MyAccountButtonOverlay);
