import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  MediatorEventData,
  SortData,
  ModuleEventProps
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.FAVORITE_DATA_TYPE,
    (data: MediatorEventData) => {
      track<EventProps>(EVENT_NAME.FAVORITE_DATA_TYPE, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: data.payload?.type,
        [BaseEventProps.MODULE]: "Handicapping PP Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DATA_TYPE,
    (data: MediatorEventData) => {
      track<EventProps>(EVENT_NAME.DATA_TYPE, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: data.payload?.type,
        [BaseEventProps.MODULE]: "Handicapping PP Modal"
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.OPEN_MODAL, () => {
    track<EventProps>(EVENT_NAME.OPEN_MODAL, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "Handicapping PP Modal",
      [BaseEventProps.MODULE]: "Race Card"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.SORT_FAVORITE_DATA_TYPE,
    (data: MediatorEventData) => {
      track<EventProps>(EVENT_NAME.SORT_FAVORITE_DATA_TYPE, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: data.payload?.type,
        [BaseEventProps.MODULE]: "Sort Handicapping Modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SORT_DATA_TYPE,
    (data: SortData) => {
      track<EventProps>(EVENT_NAME.SORT_DATA_TYPE, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: data.payload?.type,
        [BaseEventProps.MODULE]: "runner_modifier",
        [BaseEventProps.RACE_NUMBER]: data.payload?.raceNumber,
        [BaseEventProps.TRACK_NAME]: data.payload?.trackName,
        [ModuleEventProps.SORT_TYPE]: "runner_modifier",
        [ModuleEventProps.SORT_NAME]: data.payload?.type,
        [ModuleEventProps.SORT_OPERATION]: data.payload?.order
      });
    }
  );
};
