import { COMMON_EVENT_NAMES } from "../../constants";

export const MODULE_NAME = "Registration";
export const EVENT_CATEGORY = "Registration";

export const AUTH_EVENTS = {
  MODAL: {
    LOAD: "Authentication Modal Loaded",
    CTA_CLICKED: "Authentication Modal CTA Clicked",
    OPEN: "Registration Modal Opened"
  }
};

export const FORM_EVENTS = {
  STEP: {
    COMPLETE_ATTEMPT: "Form Step Complete Attempt",
    SUCCESS: "Form Step Success"
  },
  REGISTRATION: {
    ATTEMPT: "Registration Attempt",
    COMPLETE: "Registration Finish",
    FAILURE: "Registration Failure",
    SIGN_UP_START: "Registration Sign Up Start",
    MODAL_OPEN: "Registration Modal Opened"
  }
};

export const OTHER_INTERACTIONS_EVENTS = {
  BACK_CLICKED: "Back Arrow Clicked",
  EDIT_FORM_OPEN: "Edit Form Opened",
  NAVIGATE: COMMON_EVENT_NAMES.NAVIGATED
};

export const PROMO_CODE_EVENTS = {
  FIELD_MODAL_OPENED: "Field Modal Opened",
  FIELD_MODAL_CLOSED: "Field Modal Closed",
  ATTEMPT: "Promo Code Attempt",
  SUCCESS: "Promo Code Success",
  REMOVED: "Promo Code Removed",
  EDITED: "Promo Code Edited"
};

export const TVG_PROVIDER_EVENTS = {
  FIELD_MODAL_OPENED: "Field Modal Opened",
  FIELD_MODAL_CLOSED: "Field Modal Closed",
  APPLIED: "TVG Provider Applied",
  REMOVED: "TVG Provider Removed"
};
