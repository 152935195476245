// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type LoginModalGTMEventsType =
  | "LOGIN_MODAL_START"
  | "LOGIN_MODAL_LOGIN_ATTEMPT"
  | "LOGIN_MODAL_SUCCESS"
  | "LOGIN_MODAL_LOGIN_ERROR"
  | "LOGIN_MODAL_FIELD_COMPLETE"
  | "LOGIN_MODAL_SELECT_STATE"
  | "LOGIN_MODAL_FORGOT_CREDENTIALS";

type LoginModalGTM = {
  type: LoginModalGTMEventsType,
  payload: {
    accountId?: string,
    error?: mixed,
    field?: string,
    state?: string
  }
};

export default () => {
  mediatorChannels.base.subscribe("LOGIN_MODAL_START", () => {
    pushToDataLayer({
      event: "Login",
      gaEventCategory: "Login",
      gaEventAction: "Login Start",
      gaEventLabel: undefined,
      module: "Modal",
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("LOGIN_MODAL_LOGIN_ATTEMPT", () => {
    pushToDataLayer({
      event: "login",
      gaEventCategory: "Login",
      gaEventAction: "Login Attempt",
      gaEventLabel: undefined,
      module: "Login",
      microApp: "non-MicroApp",
      accountId: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "LOGIN_MODAL_SUCCESS",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "login",
        gaEventCategory: "Login",
        gaEventAction: "Login Success",
        gaEventLabel: undefined,
        module: "Login",
        microApp: "non-Microapp",
        accountId: get(data, "payload.accountId"),
        loginStatus: "Logged In",
        registrationStatus: "Registered",
        balance: get(data, "payload.balance")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "LOGIN_MODAL_LOGIN_ERROR",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "login",
        gaEventCategory: "Login",
        gaEventAction: "Login Error",
        gaEventLabel: get(data, "payload.error"),
        module: "Login",
        microApp: "non-Microapp",
        accountId: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "LOGIN_MODAL_OPEN_CLOSE",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.open") ? "Open" : "Close",
        gaEventLabel: "Login Modal",
        tag: undefined,
        module: get(data, "payload.open") ? "Login" : "Login Modal",
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "LOGIN_MODAL_FIELD_COMPLETE",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "Login",
        gaEventCategory: "Login",
        gaEventAction: "Field Complete",
        gaEventLabel: get(data, "payload.field"),
        module: "Modal",
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "LOGIN_MODAL_SELECT_STATE",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "Login",
        gaEventCategory: "Login",
        gaEventAction: "Selected State",
        gaEventLabel: get(data, "payload.state"),
        module: "Modal",
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "LOGIN_MODAL_FORGOT_CREDENTIALS",
    (data: LoginModalGTM) => {
      pushToDataLayer({
        event: "login",
        gaEventCategory: "Login",
        gaEventAction: "Forgot Password Clicked",
        gaEventLabel: undefined,
        module: "Header"
      });
    }
  );
};
