import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import HeaderComp from "./containers/Header/index";

/* eslint-disable-next-line react/prefer-stateless-function */
class App extends Component {
  render() {
    return (
      <header>
        <TvgSafeAreaProvider>
          <Routes>
            <Route
              path="/racetracks/:trackAbbr/:trackName/*"
              element={<HeaderComp mode="standard" />}
            />
            <Route
              path="/greyhounds/:trackAbbr/:trackName/*"
              element={<HeaderComp mode="standard" />}
            />
            <Route
              path="/registration"
              element={<HeaderComp mode="hidden" />}
            />
            <Route
              path="/reset-credentials"
              element={<HeaderComp mode="recoverPassword" />}
            />
            <Route
              path="/credentials-reset"
              element={<HeaderComp mode="hidden" />}
            />
            <Route
              exact
              path="/nextrace"
              element={<HeaderComp mode="nextRace" />}
            />
            <Route
              path="/forgot-credentials"
              element={<HeaderComp mode="recoverPassword" />}
            />
            <Route
              path="/responsible-gaming"
              element={<HeaderComp mode="noLHNInHamburgerBtn" />}
            />
            <Route path="*" element={<HeaderComp mode="standard" />} />
          </Routes>
        </TvgSafeAreaProvider>
      </header>
    );
  }
}

export default App;
