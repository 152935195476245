import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  SuccessAndSumbitData,
  ErrorData
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(MediatorEventType.CANCEL, () => {
    track<EventProps>(EVENT_NAME.CANCEL, {
      [BaseEventProps.EVENT_CATEGORY]: "My Bets",
      [BaseEventProps.EVENT_LABEL]: "Cancel Bet",
      [BaseEventProps.MODULE]: "Race Bets",
      [BaseEventProps.LINK_TEXT]: "Cancel Bet"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.SUBMIT,
    (data: SuccessAndSumbitData) => {
      track<EventProps>(EVENT_NAME.CANCEL_BET_CTA, {
        [BaseEventProps.EVENT_LABEL]: "cancel_bet",
        [BaseEventProps.MODULE]: "Cancel Bet Modal"
      });
      track<EventProps>(EVENT_NAME.SUBMIT, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.MODULE]: "Race Bets",
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.BET_AMOUNT]: data.payload.betAmount,
        [BaseEventProps.BET_TYPE]: data.payload.betType
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.ERROR, (data: ErrorData) => {
    track<EventProps>(EVENT_NAME.ERROR, {
      [BaseEventProps.EVENT_CATEGORY]: "Bet",
      [BaseEventProps.MODULE]: "Race Bets",
      [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
      [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
      [BaseEventProps.BET_AMOUNT]: data.payload.betAmount,
      [BaseEventProps.BET_TYPE]: data.payload.betType,
      [BaseEventProps.ERROR_TYPE]: data.payload.error
    });
  });

  mediator.base.subscribe(
    MediatorEventType.SUCCESS,
    (data: SuccessAndSumbitData) => {
      track<EventProps>(EVENT_NAME.SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: "Bet",
        [BaseEventProps.MODULE]: "Race Bets",
        [BaseEventProps.RACE_NUMBER_SELECTION]: data.payload.raceNumber,
        [BaseEventProps.RACE_TRACK_SELECTION]: data.payload.trackName,
        [BaseEventProps.BET_AMOUNT]: data.payload.betAmount,
        [BaseEventProps.BET_TYPE]: data.payload.betType
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.KEEP_BET, () => {
    track<EventProps>(EVENT_NAME.CANCEL_BET_CTA, {
      [BaseEventProps.EVENT_CATEGORY]: "My Bets",
      [BaseEventProps.EVENT_LABEL]: "Keep Bet",
      [BaseEventProps.MODULE]: "Cancel Bet Modal"
    });
  });

  mediator.base.subscribe(MediatorEventType.MODAL_CLOSED, () => {
    track<EventProps>(EVENT_NAME.MODAL_CLOSED, {
      [BaseEventProps.EVENT_CATEGORY]: "My Bets",
      [BaseEventProps.MODULE]: "Cancel Bet Modal"
    });
  });
};
