import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.CLICK, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: get(data, "payload.promoName"),
      [BaseEventProps.MODULE]: "Promo widget",
      [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
    })
  );
};
