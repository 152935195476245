import mediator from "@tvg/mediator";
import type { User } from "@tvg/ts-types/Login";
import type { BrazeContentCard } from "./store/actions";

export const androidChangeUser = (user: User, accountNumber?: string) => {
  mediator.ios.dispatch({
    type: "BRAZE_CHANGE_USER",
    payload: {
      accountNumber: accountNumber || user.accountNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.emailAddress
    }
  });
};

export const androidBrazeInitialize = (params: unknown) => {
  mediator.ios.dispatch({
    type: "BRAZE_INITIALIZE",
    payload: params
  });
};

export const androidRequestContentCardsRefresh = () => {
  mediator.ios.dispatch({
    type: "BRAZE_REFRESH_CONTENT_CARDS"
  });
};

export const androidRemoveSubscription = (subscription: string) => {
  mediator.ios.dispatch({
    type: "BRAZE_REMOVE_SUBSCRIPTION",
    payload: subscription
  });
};

export const androidSubscribeToContentCards = () => {
  mediator.ios.dispatch({
    type: "BRAZE_CONTENT_CARDS_SUBSCRIPTION"
  });
};

export const androidReadContentCard = (cards: BrazeContentCard[]) => {
  mediator.ios.dispatch({
    type: "BRAZE_READ_CONTENT_CARDS",
    payload: { cards }
  });
};

export const androidClickContentCard = (card: BrazeContentCard) => {
  mediator.ios.dispatch({
    type: "BRAZE_CLICK_CONTENT_CARD",
    payload: card
  });
};

export const androidDismissContentCard = (card: BrazeContentCard) => {
  mediator.ios.dispatch({
    type: "BRAZE_DISMISS_CONTENT_CARD",
    payload: card
  });
};
