export enum MediatorEventType {
  PROMOS_RET_CTA_CLICK = "PROMOS_RET_CTA_CLICK",
  PROMOS_ALL_CTA_CLICK = "PROMOS_ALL_CTA_CLICK",
  CTA_SITE_CLICK = "CTA_SITE_CLICK"
}

export interface PromosRetCTAClickEventData {
  type: typeof MediatorEventType.PROMOS_RET_CTA_CLICK;
  payload: { gaEventLabel?: string; tag?: string };
}

export interface PromosAllCTAClickEventData {
  type: typeof MediatorEventType.PROMOS_ALL_CTA_CLICK;
  payload: { gaEventLabel?: string; gaEventAction?: string };
}

export interface CTASiteClickEventData {
  type: typeof MediatorEventType.CTA_SITE_CLICK;
  payload: {
    gaEventLabel?: string;
    gaEventAction?: string;
    module?: string;
    microApp?: string;
  };
}
