import mediator from "@tvg/mediator";
import { get } from "lodash";
import { COMMON_EVENT_NAMES } from "../../constants";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EventData, EventProps, MediatorEventType } from "./types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.SEEN, (data: EventData) =>
    track<EventProps>(EVENT_NAME.SEEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.onBoardingType", ""),
      [BaseEventProps.MODULE]: "racecard"
    })
  );
};
