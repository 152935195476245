import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK = "PROMO_WIDGET:CLICK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    promoName: string;
    destinationUrl: string;
  };
}
