import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { MediatorEventType, EventProps, LivePageData } from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.NAVIGATION,
    (data: LivePageData) => {
      track<EventProps>(EVENT_NAME.NAVIGATION, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.channel", "TVG"),
        [BaseEventProps.MODULE]: "TVGTV",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.MORE_NAVIGATION,
    (data: LivePageData) => {
      track<EventProps>(EVENT_NAME.MORE_NAVIGATION, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: "TVGTV",
        [BaseEventProps.MODULE]: "More",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl", "")
      });
    }
  );
};
