import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  OPEN_QUICK = "DEPOSITS_OPEN_QUICK"
}

export interface OpenQuickData {
  type: MediatorEventType.OPEN_QUICK;
  payload: {
    module: string;
  };
}
