import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EventData, MediatorEventType } from "./types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType, (data: EventData) =>
    track<BaseEventProps>(EVENT_NAME, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: data.payload.eventLabel || "",
      [BaseEventProps.MODULE]: data.payload.module || "",
      [BaseEventProps.LINK_URL]: data.payload.destinationUrl || ""
    })
  );
};
