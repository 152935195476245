import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  EXPAND: "Balance Details Expanded",
  COLLAPSE: "Balance Details Collapsed",
  VIEW_BALANCE_DETAILS: "Balance Details CTA Clicked",
  NAVIGATED: COMMON_EVENT_NAMES.NAVIGATED,
  PLAYABLE_BALANCE_MODAL_OPEN: "Playable Balance Information Opened",
  PLAYABLE_BALANCE_MODAL_CLOSE: "Playable Balance Information Closed"
};
