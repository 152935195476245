// @flow
import GeoComply from "../GeoComply";

const geoComply = new GeoComply("tvg4");

export default (
  payload: Payload,
  brand: string,
  fn: (p: Payload, o: *) => *
) => {
  const newfn = (geo: {}) => fn(payload, geo);

  if (brand === "4njbets") {
    return geoComply.getGeoPacket(payload.account).then(newfn);
  }

  return fn(payload, {});
};
