import React, { Component } from "react";
import _ from "lodash";
import * as mediator from "@tvg/mediator-classic/src";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import { watchLive } from "@tvg/atomic-ui/_static/Icons/icons";
import Play from "@tvg/ui-bootstrap/assets/svg/play.svg";
import style from "./style.css";

const goWatchLive = () => {
  mediator.dispatch("HEADER_DESKTOP_NAVIGATION", {
    event: "Watch TVG",
    url: `${_.get(window, "location.hostname")}/live`
  });
  mediator.dispatch("USER_ACTION", {
    userAction: "Navigation",
    url: "/live",
    module: "Header"
  });
};

export default class MenuItem extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div
        className={
          this.props.tvgHeaderV2FeatureToggle
            ? style.watchLiveV2
            : style.watchLive
        }
      >
        <a
          href="/live"
          data-qa-label="watchLiveButton"
          target="_blank"
          rel="noopener noreferrer"
          onClick={goWatchLive}
        >
          {this.props.tvgHeaderV2FeatureToggle ? (
            <Icon
              icon={watchLive}
              size={20}
              color="transparent"
              viewBoxSize={20}
            />
          ) : (
            <Play width="16" height="16" fill="#fff" />
          )}
          <span className={style.watchTvgLabel}>Watch FDTV</span>
        </a>
      </div>
    );
  }
}
