import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  PromoOnboardingData,
  ModuleEventProps
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.CTA_CLICK,
    (data: PromoOnboardingData) => {
      track<EventProps>(EVENT_NAME.CTA_CLICK, {
        [BaseEventProps.EVENT_CATEGORY]: "Promos",
        [BaseEventProps.EVENT_LABEL]: data?.payload?.link || "",
        [BaseEventProps.MODULE]: "Progress Bar",
        [ModuleEventProps.PROMO_ID]: data?.payload?.promoId,
        [BaseEventProps.PROMO_NAME]: data?.payload?.promoName,
        [BaseEventProps.TAG]: undefined
      });
    }
  );
};
