// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { buildText } from "../../_atom/Text";
import ExpandableRow from "../ExpandableRow";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import Button from "../../_atom/Buttons/button";
import { buildTextHighlighted, TYPES } from "../../_atom/TextHighlighted";
import {
  fontCondensedNormal,
  defaultSize,
  fontBold,
  fontMedium,
  fontNormal
} from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const AccountSection = styled.section`
  display: flex;
  flex-direction: column;
`;

export const AccountContainer = styled.div`
  flex: 1 1 auto;

  & > div:last-of-type > div {
    max-height: initial;
  }
`;

export const AccountLinkGroup = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  border-top: 1px solid ${buildColor("grey", "300")};
  border-bottom: 1px solid ${buildColor("grey", "300")};
  ${(props) =>
    props.device === "tablet" &&
    css`
      ${props.dontRoundTopBorders
        ? css`
            border-radius: 0 0 2px 2px;
          `
        : css`
            border-radius: 2px;
          `};
      overflow: hidden;
      border-left: 1px solid ${buildColor("grey", "300")};
      border-right: 1px solid ${buildColor("grey", "300")};
    `};

  &:not(:first-child) {
    margin-top: 8px;
  }

  > li {
    background-color: ${buildColor("white", "100")};
    height: 40px;
    list-style-type: none;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${buildColor("blue", "100")};
    }

    &:active {
      background-color: ${buildColor("blue", "000")};
    }
  }

  .disclaimer {
    height: auto;
    min-height: 40px;
  }
`;

const HighlightedTextStyles = buildTextHighlighted(
  TYPES.marketing,
  12,
  false,
  true
);

export const AccountHighlightedText = styled(HighlightedTextStyles)`
  margin-left: 10px;
  height: 16px;
  line-height: 16px;
  font-family: ${fontCondensedNormal};
  padding: 0 6px;
`;

const AccountLinkBaseStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding-left: 12px;
  padding-right: 12px;

  > svg {
    margin-right: 8px;
  }
`;

export const AccountLink = styled(Link).attrs({
  "data-qa-label": "account-link"
})`
  ${AccountLinkBaseStyles};
`;

export const AccountExternalLink = styled.a`
  ${AccountLinkBaseStyles};
  padding: 0 12px;
`;

export const AccountAppVersion = styled.div`
  ${AccountLinkBaseStyles};

  &:active {
    background-color: ${buildColor("white", "100")};
  }
`;

export const AccountLinkRow = styled.div`
  ${AccountLinkBaseStyles};
  justify-content: space-between;
  background-color: ${buildColor("blue", "000")};
  height: 40px;
`;

export const AccountExternalLinksWrapper = styled.div`
  ${AccountLinkBaseStyles};
  flex-direction: row;
  justify-content: space-between;
`;

export const IconGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  & > a {
    padding: 0 8px 0 0;
  }
`;

export const AccountLogoutSection = styled.div`
  padding: ${(props) => (props.device === "tablet" ? "8px 0" : "8px")};
  flex: 0 0 auto;
`;

const LinkTextStyles = styled(
  buildText({
    tag: "span",
    fontSize: 14,
    color: buildColor("grey", "900"),
    qaLabel: "account-link-text"
  })
)`
  font-family: ${fontMedium};
`;

export const LinkText = styled(LinkTextStyles)`
  line-height: 14px;
  height: 12px;
  display: inline-block;
`;

export const DisclaimerContainer = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const DisclaimerText = styled(
  buildText({
    tag: "span",
    fontSize: 12,
    color: buildColor("grey", "600"),
    qaLabel: "ct-disclaimer-text"
  })
)`
  font-family: ${fontNormal};
  line-height: 14px;
`;

export const PreferenceItem = buildText({
  tag: "span",
  fontSize: 12,
  color: buildColor("grey", "900")
});

export const AccountPreferencesSection = styled.ul`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${buildColor("blue", "100")};

  &:not(:first-child) {
    margin-top: 8px;
  }

  > li {
    display: flex;
    background-color: ${buildColor("blue", "000")};
    min-height: 40px;
    list-style-type: none;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${buildColor("blue", "100")};
    }
  }
`;

export const PreferencesTitleContainer = styled.div`
  ${AccountLinkBaseStyles};
  justify-content: flex-start;
  height: 40px;
`;

export const ExpandableBetPreferences = styled(ExpandableRow)`
  margin-top: 8px;
  border-top: 1px solid ${buildColor("grey", "300")};
  border-bottom: 1px solid ${buildColor("grey", "300")};
`;

export const ButtonSection = styled.div`
  background-color: ${buildColor("white", "100")};
  padding: ${(props) =>
    props.device === "tablet" ? "16px 0 16px" : "16px 12px 16px"};
  display: flex;
  flex-direction: column;

  & > a:not(:first-child) {
    margin-top: 8px;
    & > button {
      color: ${buildColor("red", "500")};
    }
  }
`;

export const LoginContainer = styled.div`
  padding: 12px;
  background-color: ${buildColor("blue", "700")};
`;

export const LoginTitle = styled(
  buildText({
    tag: "strong",
    fontSize: 18,
    color: buildColor("white", "100"),
    bold: true
  })
)`
  font-family: ${fontBold};
`;

export const LoginText = styled(
  buildText({
    tag: "p",
    fontSize: 14,
    color: buildColor("blue", "100")
  })
)`
  width: 100%;
  margin-top: 16px;
  line-height: 18px;
`;

const customButtonSharedStyled = css`
  height: 42px;
  padding: 0 24px;
  font-size: ${defaultSize};
  text-transform: capitalize;
  box-shadow: 0 2px 4px rgba(17, 43, 68, 0.06), 0 1px 2px rgba(17, 43, 68, 0.08);
  border: none;
  border-radius: 2px;
  font-family: ${fontMedium};
`;

export const ButtonLinkStyled = styled(ButtonLink)`
  ${customButtonSharedStyled};
`;

export const ButtonTransparent = styled(ButtonLink)`
  ${customButtonSharedStyled};
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.isSelected && !props.isDisabled
      ? buildColor("white", "20")
      : buildColor("white", "10")};
  color: ${buildColor("white", "100")};

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${(props) =>
        !props.isDisabled && buildColor("white", "20")};
    }
  }

  &:active {
    background-color: ${(props) =>
      !props.isDisabled && buildColor("white", "20")};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${(props) =>
        !props.isDisabled && buildColor("white", "20")};
    }
  }
`;

export const LoginButtons = styled.div`
  display: flex;
  margin-top: 20px;

  > a {
    box-shadow: 0 2px 4px rgba(17, 43, 68, 0.1);

    > svg {
      margin-right: 8px;
    }

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }
`;

export const SupportButton = styled(ButtonLink)`
  font-family: ${fontMedium};
`;

export const LogoutButton = styled(Button)`
  font-family: ${fontMedium};
  text-transform: capitalize;
`;

export const MessageBoxLink = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  ${({ device }) =>
    device !== "mobile" &&
    css`
      margin-top: 8px;
    `}
`;
