// @flow
import { attempt } from "lodash";

const RACE_FILTERS_KEY = "raceTypeFilters";
const REGION_FILTERS_KEY = "regionFilters";

const setRaceFilterPreference = (filters: string[]) =>
  attempt(() =>
    window.localStorage.setItem(RACE_FILTERS_KEY, filters.join(","))
  );

const setRegionFilterPreference = (filters: string[]) =>
  attempt(() =>
    window.localStorage.setItem(REGION_FILTERS_KEY, filters.join(","))
  );

const getUserRaceFilters = () => {
  if (typeof window !== "undefined") {
    const localStoredRaceFilters = attempt(() =>
      window.localStorage.getItem(RACE_FILTERS_KEY)
    );

    if (localStoredRaceFilters) {
      return localStoredRaceFilters.split(",");
    }
  }

  return [];
};

const getUserRegionFilters = () => {
  if (typeof window !== "undefined") {
    const localStoredRegions = attempt(
      () => window.localStorage.getItem(REGION_FILTERS_KEY),
      null
    );

    if (localStoredRegions) {
      return localStoredRegions.split(",");
    }
  }

  return [];
};

export default {
  setRaceFilterPreference,
  setRegionFilterPreference,
  getUserRaceFilters,
  getUserRegionFilters
};
