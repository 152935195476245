import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  WITHDRAWAL_ERROR: "Withdrawal Error",
  WITHDRAWAL_ATTEMPT: "Withdrawal Attempt",
  NEW_PAYMENT_TYPE_SELECTED: "New Payment Type Selected",
  SAVED_PAYMENT_TYPE_SELECTED: "Saved Payment Type Selected",
  WALLET_MODAL_OPENED: "Modal Opened",
  BACK_ARROW_CLICKED: "Back Arrow Clicked",
  PAYMENT_DRAW_OPENED: "Payment Draw Opened",
  PAYMENT_DRAW_CLOSED: "Payment Draw Closed",
  NAVIGATED_TO: COMMON_EVENT_NAMES.NAVIGATED,
  DEPOSIT_ATTEMPT: "Deposit Attempt",
  DEPOSIT_ERROR: "Deposit Error",
  DEPOSIT_FAILED: "Deposit Failed",
  DEPOSIT_SUCCESS: "Deposit Success",
  DEPOSIT_MODAL_CLOSED: "Modal Closed",
  DEPOSIT_BACK_ARROW_CLICKED: "Back Arrow Clicked",
  DEFAULT_AMOUNT_SELECTED: "Default Amount Selected",
  NEW_PAYMENT_METHOD_SUCCESS: "New Payment Method Success",
  WITHDRAWAL_MODAL_CLOSED: "Modal Closed",
  WITHDRAWAL_SUCCESS: "Withdrawal Success",
  WITHDRAWAL_FAILED: "Withdrawal Failed",
  WITHDRAWAL_MODAL_OPENED: "Modal Opened",
  WITHDRAWAL_ADDRESS_SELECTED: "Withdrawal Address Selected",
  CTA_CLICKED: "CTA Clicked",
  DEPOSIT_MODAL_OPENED: "Modal Opened",
  DEPOSIT_ADDRESS_SELECTED: "Deposit Address Selected",
  TAB_OPENED: "Tab Opened",
  PDF_DOWNLOADED: "PDF Downloaded",
  CHANGE_PAYMENT_METHOD_SELECTED: "Change Payment Method Selected",
  DELETE_PAYMENT_METHOD_CLICKED: "Delete Payment Method Clicked",
  PAYMENT_METHOD_DELETED: "Payment Method Deleted",
  DEPOSIT_START: "Deposit Start",
  WALLET_PAGE_VIEWED: "Wallet Page Viewed"
};

export const CUSTOMER_STATUS = {
  NEW: "New",
  RETURNING: "RETURNING"
};
