import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import {
  EventsProps,
  FormCompletionMediatorEventType,
  FormCompletion
} from "./types";
import { MODULE_NAME, EVENT_CATEGORY, FORM_EVENTS } from "./constants";

export default () => {
  // When a user clicks the continue button with intention of moving to the next step of the form
  mediator.base.subscribe(
    FormCompletionMediatorEventType.CLICK_CONTINUE_BUTTON,
    (data: FormCompletion) => {
      track<BaseEventProps>(FORM_EVENTS.STEP.COMPLETE_ATTEMPT, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks continue in the form and the form validates
  mediator.base.subscribe(
    FormCompletionMediatorEventType.FORM_STEP_SUCCESS,
    (data: FormCompletion) => {
      track<BaseEventProps>(FORM_EVENTS.STEP.SUCCESS, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.module", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks Finish Registration in the Review part of the form
  mediator.base.subscribe(
    FormCompletionMediatorEventType.REGISTRATION_ATTEMPT,
    (data: FormCompletion) => {
      track<BaseEventProps>(FORM_EVENTS.REGISTRATION.ATTEMPT, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user completes registration (verified or unverified) and an account id is returned
  mediator.base.subscribe(
    FormCompletionMediatorEventType.REGISTRATION_COMPLETE,
    (data: FormCompletion) => {
      track<EventsProps>(FORM_EVENTS.REGISTRATION.COMPLETE, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.verificationStatus")
          ? "Registration Success Verified"
          : "Registration Unverified",
        [BaseEventProps.MODULE]: MODULE_NAME,
        [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
        [BaseEventProps.REGISTRATION_STATUS]: get(
          data,
          "payload.verificationStatus"
        )
          ? "Registered"
          : "Unregistered",
        [BaseEventProps.RESIDENCE_STATE]: get(
          data,
          "payload.residenceState",
          ""
        ),
        [BaseEventProps.PROMO_CODE]: get(data, "payload.promoCode")
          ? get(data, "payload.promoCode")
          : undefined
      });
    }
  );

  // When registration fails due to: duplicate email, SSN failure (including already exists), invalid wagering age
  mediator.base.subscribe(
    FormCompletionMediatorEventType.REGISTRATION_FAILURE,
    (data: FormCompletion) => {
      track<BaseEventProps>(FORM_EVENTS.REGISTRATION.FAILURE, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.errorCode"),
        [BaseEventProps.MODULE]: MODULE_NAME
      });
    }
  );

  // When a user begins to type on the form
  mediator.base.subscribe(
    FormCompletionMediatorEventType.FORM_SIGN_UP_START,
    () => {
      track<BaseEventProps>(FORM_EVENTS.REGISTRATION.SIGN_UP_START, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: "Personal Details"
      });
    }
  );

  mediator.base.subscribe(
    FormCompletionMediatorEventType.REGISTRATION_MODAL_OPEN,
    (data: FormCompletion) => {
      track<BaseEventProps>(FORM_EVENTS.REGISTRATION.MODAL_OPEN, {
        [BaseEventProps.MODULE]: get(data, "payload.module", "join_bar")
      });
    }
  );
};
