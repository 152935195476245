import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  EVENTS,
  EVENT_CATEGORY,
  MODULE_FORGOT_CREDENTIALS,
  MODULE_RECOVER_EMAIL
} from "./constants";
import {
  CredentialsMediatorEventType,
  RecoverEmailMediatorEventType,
  LoginModalEventData
} from "./types";
import { BaseEventProps } from "../../types";

const subscribeForgotCredentialsEvents = () => {
  const defaultProps = {
    [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
    [BaseEventProps.MODULE]: MODULE_FORGOT_CREDENTIALS
  };
  // When a user fills and validates the email/account number field on the Forgot Credentials screen
  mediator.base.subscribe(CredentialsMediatorEventType.VALIDATED_FIELD, () => {
    track<BaseEventProps>(EVENTS.FIELD_COMPLETED, {
      ...defaultProps,
      [BaseEventProps.EVENT_LABEL]: "Email/Account Number"
    });
  });

  // When a user clicks the 'reset credentials' button
  mediator.base.subscribe(CredentialsMediatorEventType.RESET_BUTTON, () => {
    track<BaseEventProps>(EVENTS.RESET_SUBMIT_ATTEMPTED, defaultProps);
  });

  //  When a user clicks 'i don't know my login details'
  mediator.base.subscribe(CredentialsMediatorEventType.FORGOT_DETAILS, () => {
    track<BaseEventProps>(EVENTS.CTA_LINK_CLICKED, {
      ...defaultProps,
      [BaseEventProps.EVENT_LABEL]: "I don't know my Login Details"
    });
  });

  // When a user is presented with the 'please check your inbox' screen
  mediator.base.subscribe(CredentialsMediatorEventType.CHECK_SCREEN, () => {
    track<BaseEventProps>(EVENTS.RESET_SUBMIT_SUCCESS, defaultProps);
  });

  // When a user clicks the validation link in their email in order to reset their password and lands on the TVG site to resume credentials journey
  mediator.base.subscribe(CredentialsMediatorEventType.LINK_LANDED, () => {
    track<BaseEventProps>(EVENTS.RESET_CREDENTIALS_EMAIL_LINK_LANDED, {
      ...defaultProps,
      [BaseEventProps.EVENT_LABEL]: "Success/Link Expired"
    });
  });

  // When a user clicks 'resend a reset password email' on the Forgot Credentials screen
  mediator.base.subscribe(CredentialsMediatorEventType.RESEND_SCREEN, () => {
    track<BaseEventProps>(EVENTS.RESET_RE_SUBMITTED, defaultProps);
  });

  // When a user clicks 'resend a reset password email' on the Link Expired screen:
  mediator.base.subscribe(CredentialsMediatorEventType.LINK_EXPIRED, () => {
    track<BaseEventProps>(EVENTS.RESET_RE_SUBMITTED, {
      ...defaultProps,
      [BaseEventProps.MODULE]: "Link Expired"
    });
  });

  // When a user fills and validates the New Password field on the New Password screen:
  mediator.base.subscribe(
    CredentialsMediatorEventType.NEW_PW_PIN,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.FIELD_COMPLETED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`
      });
    }
  );

  // 7) When a user clicks 'cancel password reset' or 'cancel pin reset' :
  mediator.base.subscribe(
    CredentialsMediatorEventType.CANCEL_RESET,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.CANCEL_CREDENTIALS_RESET_CLICKED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`,
        [BaseEventProps.MODULE]: `${get(data, "payload.module")}` // pass value depending on journey
      });
    }
  );

  // When a user clicks 'update password' or 'update pin':
  mediator.base.subscribe(
    CredentialsMediatorEventType.UPDATE_CREDENTIAL,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.NEW_CREDENTIALS_SUBMITTED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`
      });
    }
  );

  // When a user updates their pin or password successfully:
  mediator.base.subscribe(
    CredentialsMediatorEventType.UPDATE_CREDENTIAL_SUCCESSFULLY,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.NEW_CREDENTIALS_UPDATED_SUCCESS, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`
      });
    }
  );

  // When a user clicks 'got the wrong email':
  mediator.base.subscribe(
    CredentialsMediatorEventType.WRONG_EMAIL_CTA_LINK_CLICK,
    () => {
      track<BaseEventProps>(EVENTS.CTA_LINK_CLICKED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: "Got The Wrong Email"
      });
    }
  );

  // When a user clicks a CTA on an Error screen (e.g too many attempts) for either credentials reset or email recovery:
  mediator.base.subscribe(
    CredentialsMediatorEventType.ERROR_PAGE_CTA,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.CTA_LINK_CLICKED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  // When a user clicks 'Return to Homepage' anywhere in the journey (except leave page modal):
  mediator.base.subscribe(
    CredentialsMediatorEventType.RETURN_TO_TVG_COM,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.CTA_LINK_CLICKED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `Return to Homepage`,
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );

  //  When a user attempts to submit their current credentials, but an error gets thrown (e.g wrong password):
  mediator.base.subscribe(
    CredentialsMediatorEventType.RESET_SUBMIT_ERROR,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.RESET_SUBMIT_ERROR, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}` // 'Date of Birth' or 'SSN',
      });
    }
  );

  //  When a user clicks 'contact customer service' after clicking a 'resend a reset email' button:
  mediator.base.subscribe(
    CredentialsMediatorEventType.CONTACT_CUSTOMER_SERVICE,
    () => {
      track<BaseEventProps>(EVENTS.CTA_LINK_CLICKED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: "Contact Customer Service",
        [BaseEventProps.MODULE]: "Reset Email Re-Sent"
      });
    }
  );

  // When a user closes a screen by clicking the 'x' button
  mediator.base.subscribe(
    CredentialsMediatorEventType.CLOSE_MODAL,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(`${get(data, "payload.action")}`, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`,
        [BaseEventProps.MODULE]: `${get(data, "payload.module")}`
      });
    }
  );

  // When a user clicks a back arrow to go back to the previous screen
  mediator.base.subscribe(
    CredentialsMediatorEventType.BACK_MODAL,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.BACK_ARROW_CLICKED, {
        ...defaultProps,
        [BaseEventProps.MODULE]: `${get(data, "payload.module")}`
      });
    }
  );

  // When a user clicks a CTA on the 'Leave Page?' Modal:

  mediator.base.subscribe(
    CredentialsMediatorEventType.LEAVE_PAGE_CTA_CLICK,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.CTA_LINK_CLICKED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`,
        [BaseEventProps.MODULE]: "Leave Page Modal"
      });
    }
  );

  // When a user clicks the validation link in their email in order to reset their password and lands on the TVG site to resume credentials journey:
  mediator.base.subscribe(
    CredentialsMediatorEventType.EMAIL_LINK_LANDED,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.RESET_CREDENTIALS_EMAIL_LINK_LANDED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`
      });
    }
  );
};

const subscribeRecoverEmailEvents = () => {
  const defaultProps = {
    [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
    [BaseEventProps.MODULE]: MODULE_RECOVER_EMAIL
  };
  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen
  mediator.base.subscribe(
    RecoverEmailMediatorEventType.RECOVER_EMAIL_SUBMIT,
    () => {
      track<BaseEventProps>(EVENTS.RECOVER_EMAIL_SUBMIT_ATTEMPTED, {
        ...defaultProps
      });
    }
  );

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen and an error gets thrown:
  mediator.base.subscribe(
    RecoverEmailMediatorEventType.RECOVER_EMAIL_ERROR,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.RECOVER_EMAIL_SUBMIT_ERROR, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.message")}` // e.g wrong email
      });
    }
  );

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen and fails (e.g too many attempts):
  mediator.base.subscribe(
    RecoverEmailMediatorEventType.RECOVER_EMAIL_FAILED,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.RECOVER_EMAIL_SUBMIT_FAILED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.message")}` // e.g too many attempts
      });
    }
  );

  // When a user clicks the 'Recover Email' button on the 'Recover Email Address' Screen and is Successful:
  mediator.base.subscribe(
    RecoverEmailMediatorEventType.RECOVER_EMAIL_SUBMIT_SUCCESS,
    () => {
      track<BaseEventProps>(EVENTS.RECOVER_EMAIL_SUBMIT_SUCCESS, {
        ...defaultProps
      });
    }
  );

  // When a user completes a field on the 'recover email address' screen:
  mediator.base.subscribe(
    RecoverEmailMediatorEventType.VALIDATED_FIELD,
    (data: LoginModalEventData) => {
      track<BaseEventProps>(EVENTS.FIELD_COMPLETED, {
        ...defaultProps,
        [BaseEventProps.EVENT_LABEL]: `${get(data, "payload.field")}`
      });
    }
  );
};

export default () => {
  subscribeForgotCredentialsEvents();
  subscribeRecoverEmailEvents();
};
