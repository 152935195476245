import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { TVGProviderMediatorEventType, TVGProviderEventData } from "./types";
import { EVENT_CATEGORY, TVG_PROVIDER_EVENTS } from "./constants";

export default () => {
  // When a user clicks "Select Provider" in the registration form
  mediator.base.subscribe(
    TVGProviderMediatorEventType.SELECT_PROVIDER,
    (data: TVGProviderEventData) => {
      track<BaseEventProps>(TVG_PROVIDER_EVENTS.FIELD_MODAL_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: "TVG Provider",
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks continue and applies a TVG provider to the form
  mediator.base.subscribe(
    TVGProviderMediatorEventType.APPLIED_PROVIDER,
    (data: TVGProviderEventData) => {
      track<BaseEventProps>(TVG_PROVIDER_EVENTS.APPLIED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.providerName", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks continue and removes a TVG provider from the form
  mediator.base.subscribe(
    TVGProviderMediatorEventType.REMOVE_PROVIDER,
    (data: TVGProviderEventData) => {
      track<BaseEventProps>(TVG_PROVIDER_EVENTS.REMOVED, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks to close the TV provider modal
  mediator.base.subscribe(TVGProviderMediatorEventType.CLOSE_MODAL, () => {
    track<BaseEventProps>(TVG_PROVIDER_EVENTS.FIELD_MODAL_CLOSED, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: "TVG Provider",
      [BaseEventProps.MODULE]: "TVG Provider"
    });
  });
};
