import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  CLICK_LINK = "FOOTER_LINK_CLICK"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    clickedLink: string;
  };
}
