import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME, LABELS } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.BALANCE_SHOWN, (data: EventData) =>
    track<EventProps>(
      get(data, "payload.isBalanceShown", "0") === "0"
        ? EVENT_NAME.HIDE
        : EVENT_NAME.SHOW,
      {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: "Balance",
        [BaseEventProps.MODULE]: "More"
      }
    )
  );

  mediator.base.subscribe(MediatorEventType.NAVIGATION, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: get(data, "payload.linkName"),
      [BaseEventProps.MODULE]: "More",
      [BaseEventProps.LINK_URL]: get(data, "payload.url")
    })
  );

  mediator.base.subscribe(MediatorEventType.OPEN_CONTENT, (data: EventData) =>
    track<EventProps>(`${get(data, "payload.itemOpened")} ${EVENT_NAME.OPEN}`, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.itemOpened"),
      [BaseEventProps.MODULE]: "More"
    })
  );

  mediator.base.subscribe(MediatorEventType.SWITCH_TOGGLE, (data: EventData) =>
    track<EventProps>(get(data, "payload.action"), {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: get(data, "payload.field"),
      [BaseEventProps.MODULE]: "More"
    })
  );

  mediator.base.subscribe(
    MediatorEventType.SUPPORT_TOGGLE,
    (data: EventData) => {
      const eventName = get(
        data,
        "payload.modalToggle",
        `${LABELS.SUPPORT_MODAL} ${EVENT_NAME.OPEN}`
      );

      if (eventName === "close") return;

      track<EventProps>(eventName, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: "support",
        [BaseEventProps.MODULE]: "support modal"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.WITHDRAW_DEPOSIT,
    (data: EventData) =>
      track<EventProps>(EVENT_NAME.NAVIGATED, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.eventLabel"),
        [BaseEventProps.MODULE]: "More",
        [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
      })
  );

  mediator.base.subscribe(MediatorEventType.NOTIFICATION_BTN, () =>
    track<EventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "TVG Inbox Notifications",
      [BaseEventProps.MODULE]: "More",
      [BaseEventProps.MENU]: "More"
    })
  );
};
