import React from "react";
import { Provider } from "react-redux";
import tvgConf from "@tvg/conf";
import { BrowserRouter } from "react-router-dom";
import LoginGTM from "@tvg/gtm/src/modules/LoginModal";
import BrazeGTM from "@tvg/gtm/src/modules/Braze";
import ForgotCredentialsGTM from "@tvg/gtm/src/modules/ForgotCredentials";
import { get } from "lodash";
import { TVGThemeProviderWeb, TvgConfProvider } from "@tvg/design-system";
import { FormationTokens } from "@tvg/design-system/web";
import { AppRegistry } from "react-native-web";
import configureStore from "./configureStore";
import App from "./pages";
import ServiceUrl from "./pages/factories/serviceUrl";
import getPublicPath from "./utils/getPublicPath";

LoginGTM();
ForgotCredentialsGTM();
BrazeGTM();

const publicPath = getPublicPath();

if (publicPath) {
  // @ts-ignore
  // eslint-disable-next-line
  __webpack_public_path__ = publicPath;
}

const preloadedState = window.__HDR_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle
delete window.__HDR_PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

const store = configureStore(preloadedState);
ServiceUrl.setHost();

const loadPerimeterXScript = get(
  preloadedState,
  "header.features.enablePerimeterx",
  false
);

// This script needs to load in client side to be able to load the px script
// This will be placed only 1 time even if user refreshes or navigates to a different route
// The idea is to load the px script in client without changing the tvgConf package
if (loadPerimeterXScript) {
  window.pxToken = tvgConf().config("perimeterxId");
  import("@tvg/perimeterx/perimeterx");
}

const Main = () => (
  <TvgConfProvider>
    <TVGThemeProviderWeb isFanduelTheme={false}>
      <FormationTokens />
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </TVGThemeProviderWeb>
  </TvgConfProvider>
);

const Render = () => {
  AppRegistry.registerComponent("App", () => Main);

  AppRegistry.runApplication("App", {
    initialProps: {},
    // @TODO check if we can improve the hydration
    // hydrate: true,
    rootTag: document.getElementById("hdr")
  });
};

export default Render();
