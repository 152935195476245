import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { COMMON_EVENT_NAMES } from "../../constants";
import { BaseEventProps } from "../../types";
import {
  MediatorEventType,
  EventProps,
  EmailReferralActionData
} from "./types";

export default () => {
  mediator.base.subscribe(MediatorEventType.PAGE_VIEW, () => {
    track<EventProps>(COMMON_EVENT_NAMES.PAGE_VIEWED, {});
  });

  mediator.base.subscribe(
    MediatorEventType.EMAIL_REFERRAL_ACTION,
    (data: EmailReferralActionData) => {
      track<EventProps>(get(data, "payload.action"), {
        [BaseEventProps.EVENT_CATEGORY]: get(data, "payload.category"),
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: get(data, "payload.module")
      });
    }
  );
};
