export enum MediatorEventType {
  PROMOS_FOOTER_ICON_CLICK = "PROMOS_FOOTER_ICON_CLICK",
  PROMOS_SLOT_PROMO_CLICK = "PROMOS_SLOT_PROMO_CLICK",
  PROMOS_CTA_CLICK = "PROMOS_CTA_CLICK",
  CTA_NAVIGATION_CLICK = "CTA_NAVIGATION_CLICK"
}

export interface MediatorEventData {
  type: typeof MediatorEventType;
  payload: {
    gaEventLabel?: string;
    module?: string;
    destinationUrl?: string;
    microApp?: string;
  };
}
