import { isEqual } from "lodash";
import { Dispatch } from "redux";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { PromoEligible } from "../types/promos";
import { startPromoOnboardingPoller } from "./promoOnboardingPoller";
import { fetchPromosEligible } from "./fetchPromosEligible";

export interface PromoOnboardingPollerProps {
  isLogged: boolean;
  accountNumber: string;
  promoOnboardingPollerTime: number;
  promoEligible: PromoEligible;
}

export const initPromoOnboardingPoller = (
  currentProps: Partial<PromoOnboardingPollerProps>,
  previusProps: Partial<PromoOnboardingPollerProps>,
  setState: UnaryFn<unknown, void>,
  dispatch: Dispatch
) => {
  if (
    !isEqual(currentProps.isLogged, previusProps.isLogged) ||
    !isEqual(currentProps.accountNumber, previusProps.accountNumber)
  ) {
    setState({ promoEligible: null });

    if (currentProps.isLogged && currentProps.accountNumber) {
      fetchPromosEligible(currentProps.accountNumber).then((promoEligible) => {
        setState({ promoEligible });
      });
    }
  }

  if (
    !isEqual(currentProps.isLogged, previusProps.isLogged) ||
    !isEqual(currentProps.accountNumber, previusProps.accountNumber) ||
    !isEqual(
      currentProps.promoOnboardingPollerTime,
      previusProps.promoOnboardingPollerTime
    ) ||
    !isEqual(currentProps.promoEligible, previusProps.promoEligible)
  ) {
    startPromoOnboardingPoller(
      dispatch,
      currentProps.promoOnboardingPollerTime || 60,
      currentProps.isLogged,
      currentProps.accountNumber,
      currentProps.promoEligible
    );
  }
};

export default initPromoOnboardingPoller;
