/* eslint-disable */
export class SubMenu {
  constructor(
    name,
    description,
    route,
    subQaLabel,
    options = "none",
    classes = "",
    icon,
    newIcon = false
  ) {
    this.name = name;
    this.description = description;
    this.route = route;
    this.subQaLabel = subQaLabel;
    this.classes = classes;
    this.options = options;
    this.icon = icon;
    this.newIcon = newIcon;
  }
}

export class Menu {
  constructor(
    name,
    route,
    options = {},
    classes = "",
    subMenus = [],
    qaLabel,
    icon
  ) {
    this.name = name;
    this.route = route;
    this.classes = classes;
    this.options = options;
    this.qaLabel = qaLabel;
    this.icon = icon;
    this.subMenus = subMenus.map(
      (subMenu) =>
        new SubMenu(
          subMenu.name,
          subMenu.description,
          subMenu.route,
          subMenu.subQaLabel,
          subMenu.options,
          subMenu.classes,
          subMenu.icon,
          subMenu.newIcon
        )
    );
  }
}
/* eslint-enable */
