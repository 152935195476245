import { cookieService } from "@tvg/utils/cookies";
import { isFDR } from "@tvg/utils/generalUtils";
import { get } from "lodash";
import { TVG5_COOKIE } from "./constants";

const clearLeftoverCookie = () => {
  document.cookie = `${TVG5_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
};

export const setTvg5Cookie = () => {
  cookieService(TVG5_COOKIE, true, "true");
  window.location.reload();
};

export const clearTvg5Cookie = () => {
  cookieService(TVG5_COOKIE, false, "", 1);

  // DESK sets a cookie with the domain .tvg.com
  // Cookies set manually have the domain www-qa.tvg.com
  // This leads to duplicated cookies so we make sure we clear any leftovers
  if (document.cookie.includes(TVG5_COOKIE)) {
    clearLeftoverCookie();
  }

  window.location.reload();
};

// This fn exists in @tvg/conf/src/utils but it is using .contains which doesnt
// exist on strings and its making the fn return a false when it shouldnt
// This fn is diong the same thing but using .includes instead
const isDev = () =>
  (typeof window !== "undefined" &&
    typeof get(window, "location.hostname.includes") === "function" &&
    window.location.hostname.includes("local")) ||
  (typeof process.env.ENVIRONMENT === "string" &&
    process.env.ENVIRONMENT === "dev");

// Execute experiment only when not in dev, we have an accountNumber and we are not in FDR
export const shouldExecuteExperiment = (accountNumber: string) =>
  !isDev() && accountNumber && !isFDR();
