import { useState } from "react";
import {
  Experiment,
  ExperimentClient,
  ExperimentConfig,
  ExperimentUser
} from "@amplitude/experiment-js-client";
import { Variant } from "./types";

/**
 * Base hook to instantiate experiments
 * @param deploymentKey Deployment key for the experiment we are fetching
 * @param withAnalytics Flag that decides if experiments is plugged in to Analytics or not
 * @returns experiment client, getVariant, setUserExperiment
 */
const useExperiments = (
  deploymentKey: string,
  config?: ExperimentConfig,
  withAnalytics: boolean = true
) => {
  const [experiment, setExperiment] = useState<ExperimentClient>(
    withAnalytics
      ? Experiment.initializeWithAmplitudeAnalytics(
          deploymentKey,
          // https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#configuration
          {
            automaticFetchOnAmplitudeIdentityChange: true,
            ...config
          }
        )
      : Experiment.initialize(deploymentKey, {})
  );

  /**
   *
   * @param user Fetch Experiment's data for a given user (recommended only for experiments not initialized with analytics)
   * @returns experiment client
   */
  const setUserExperiment = (user: ExperimentUser) =>
    experiment
      .fetch(user)
      .then((res) => {
        setExperiment(res);
        return res;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error("Experiments ->", e);
        return null;
      });

  /**
   * Gets the variant by the experiment key and it returns an undefined as a fallback
   * @param experimentKey key of experiment
   * @returns Variant or undefined
   */
  const getVariant = <V, P>(experimentKey: string): Variant<V, P> | undefined =>
    experiment.variant(experimentKey, undefined) as Variant<V, P> | undefined;

  return {
    experiment,
    getVariant,
    setUserExperiment
  };
};

export default useExperiments;
