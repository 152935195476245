// @flow
import mediator from "@tvg/mediator";
import type { RouterHistory } from "react-router-dom";
import { createStore, applyMiddleware, type Store } from "redux";
import React from "react";
import { bindAll, get, noop } from "lodash";
import type { NullaryFn } from "@tvg/types/Functional";
import LoginService from "@tvg/login-service";
import { CONSTANTS } from "@tvg/login-service/services/static";
import type {
  ResponseObject,
  Payload,
  ProfileResponse,
  IdResponse,
  LogoutResponse
} from "@tvg/login-service/src/types";
import { isFDR } from "@tvg/utils/generalUtils";

import { type MessageType } from "@tvg/atomic-ui/_templates/Login";
import tvgConf from "@tvg/conf";
import type { TvgConf } from "@tvg/conf/src/types";
import { formatDateToMMDDYYYYhhmm } from "@tvg/formatter/dates";

import Login from "./components/LoginComponent";
import reducers from "./reducers";

type GetLoginPayload = {
  mobile: boolean,
  callback: NullaryFn<mixed>,
  stateAbbr: string,
  hasSuccessMessage?: boolean,
  successMessage?: MessageType,
  user?: string,
  geopacketUsage?: boolean
};

export class TVGLogin {
  store: Store<*, *>;

  tvgConf: TvgConf;

  constructor() {
    this.tvgConf = tvgConf();
    // $FlowFixMe
    this.store = createStore(reducers, applyMiddleware());

    bindAll(this, [
      "triggerLogin",
      "triggerLogout",
      "validateSession",
      "watchSession",
      "getLoginModal"
    ]);

    // can keep it to validate session from TP tool
    mediator.base.subscribe(CONSTANTS["TVG_LOGIN:DO_LOGIN"], (data) =>
      this.triggerLogin(data.payload || {})
    );
    mediator.base.subscribe(CONSTANTS["TVG_LOGIN:DO_LOGOUT"], (data) =>
      this.triggerLogout(data.payload || {})
    );
    mediator.base.subscribe(CONSTANTS["TVG_LOGIN:VALIDATE_SESSION"], () =>
      this.validateSession()
    );
    this.watchSession();
    return this;
  }

  showRGException = (type: string, endDate: string) => {
    mediator.base.dispatch({
      type: "TVG_LOGIN:RG_EXCEPTION",
      payload: {
        type,
        endDate
      }
    });
  };

  triggerLogin(payload: Payload): Promise<ResponseObject> {
    return LoginService.doLogin(payload).then((response: ResponseObject) => {
      const type = response.error
        ? CONSTANTS.SET_SESSION_ERROR
        : CONSTANTS.SET_SESSION_SUCCESS;

      if (
        type === CONSTANTS.SET_SESSION_SUCCESS &&
        typeof window !== "undefined"
      ) {
        window._pxParam5 = get(
          response,
          "success.data.userDetails.accountNumber"
        );

        mediator.base.dispatch({
          type: "TVG_LOGIN:LOGIN_SUCCESS",
          userData: {
            ...get(response, "success.data.userDetails"),
            currentLocationByState: payload.stateAbbr
          }
        });
      }

      const loginCheck = {
        userLogin: !payload.notFromLogin,
        loginPin: !!Number(payload.pin)
      };

      const RGException = get(response, "error.responsibleGamingExclusion", {});

      this.store.dispatch({
        type,
        payload: {
          ...loginCheck,
          ...(response.error || response.success)
        }
      });

      if (RGException.type) {
        this.showRGException(
          RGException.type,
          formatDateToMMDDYYYYhhmm(RGException.endDate)
        );
      }

      return response;
    });
  }

  triggerLogout(payload: Payload): Promise<LogoutResponse> {
    return LoginService.doLogout(payload).then((response: LogoutResponse) => {
      const type = response.success ? CONSTANTS.CLEAR_SESSION : null;

      if (type) {
        this.store.dispatch({
          type,
          payload: {
            userLogout: true,
            ...(response.error || response.success)
          }
        });
      }

      return response;
    });
  }

  watchSession() {
    if (typeof window !== "undefined" && !isFDR()) {
      this.validateSession();
      return setInterval(() => this.validateSession(), 60000);
    }
    return true;
  }

  validateSession(logged: boolean = this.store.getState().logged) {
    return (
      !logged &&
      LoginService.validateSession().then(
        (response: IdResponse | ProfileResponse) => {
          if (response.success) {
            this.store.dispatch({
              type: CONSTANTS.SET_USER,
              payload: get(response, "success.data", {})
            });
          } else {
            this.store.dispatch({
              type: CONSTANTS.CLEAR_SESSION,
              payload: { userLogout: false }
            });
          }
        }
      )
    );
  }

  getLoginModal(
    payload: GetLoginPayload,
    closeModal: NullaryFn<void> = noop,
    history: RouterHistory
  ): React$Element<*> {
    return (
      <Login
        mobile={payload.mobile}
        callback={payload.callback}
        geopacketUsage={payload.geopacketUsage}
        triggerLogin={this.triggerLogin}
        stateAbbr={payload.stateAbbr}
        closeModal={closeModal}
        history={history}
        hasSuccessMessage={payload.hasSuccessMessage}
        successMessage={payload.successMessage}
        initialUsername={
          !payload.user && payload.successMessage
            ? payload.successMessage.user
            : payload.user
        }
      />
    );
  }
}

const instance = new TVGLogin();
export default instance;
