import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { MediatorEventType, PastPerformancesEventData } from "./types";
import { EVENTS, EVENT_CATEGORY, LABELS } from "./constants";

const generateEventTag = (payload: {
  runnerName: string;
  trackName: string;
  raceNumber: string;
}) => `${payload?.runnerName}/${payload?.trackName}/${payload?.raceNumber}`;

export default () => {
  mediator.base.subscribe(MediatorEventType.CLICK_PP, () => {
    track<BaseEventProps>(`${LABELS.INLINE_PP} ${EVENTS.SELECT}`, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: "Inline Past Performances",
      [BaseEventProps.MODULE]: LABELS.ALL_PP
    });
  });

  mediator.base.subscribe(MediatorEventType.CLICK_MORE_PAGE, () => {
    track<BaseEventProps>(`${LABELS.ALL_PP} ${EVENTS.OPEN}`, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: LABELS.ALL_PP,
      [BaseEventProps.MODULE]: "Inline PP"
    });
  });

  mediator.base.subscribe(MediatorEventType.SELECT_PP_MORE_PAGE, () => {
    track<BaseEventProps>(`${LABELS.INLINE_PP} ${EVENTS.SELECT}`, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: "Inline Past Performances",
      [BaseEventProps.MODULE]: LABELS.ALL_PP
    });
  });

  mediator.base.subscribe(MediatorEventType.CLOSE_ALL_PP, () => {
    track<BaseEventProps>(`${LABELS.ALL_PP} ${EVENTS.CLOSE}`, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: LABELS.ALL_PP,
      [BaseEventProps.MODULE]: LABELS.ALL_PP
    });
  });

  mediator.base.subscribe(MediatorEventType.CLOSE_SINGLE_PP, () => {
    track<BaseEventProps>(`${LABELS.PP} ${EVENTS.CLOSE}`, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: LABELS.PP,
      [BaseEventProps.MODULE]: LABELS.PP
    });
  });

  mediator.base.subscribe(
    MediatorEventType.TOGGLE_CONDITIONS,
    (data: PastPerformancesEventData) => {
      const eventAction = `Toggle ${data.payload.toggleState}`;

      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: "Matching Conditions",
        [BaseEventProps.MODULE]: "All PP Modal",
        [BaseEventProps.TAG]: `${get(data, "payload.matchingConditions")}`
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.BACK_TO_ALL,
    (data: PastPerformancesEventData) => {
      track<BaseEventProps>(EVENTS.BACK, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: undefined,
        [BaseEventProps.TAG]: generateEventTag(data.payload)
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.HORSE_SELECTOR,
    (data: PastPerformancesEventData) => {
      track<BaseEventProps>(EVENTS.OPEN, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: "Horse Past Performance Modal",
        [BaseEventProps.MODULE]: "All PP Modal",
        [BaseEventProps.TAG]: get(data, "payload.runnerIndex")
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.SEE_ALL_RUNNERS, () => {
    track<BaseEventProps>(EVENTS.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
      [BaseEventProps.EVENT_LABEL]: "See all runners",
      [BaseEventProps.MODULE]: "Full-Results"
    });
  });
};
