// @flow

import styled, { css } from "styled-components";
import Icon from "../../_static/Icons";

import { fontNormal, fontMedium, defaultSize } from "../../_static/Typography";
import { buildText } from "../../_atom/Text";
import buildColor from "../../_static/ColorPalette";

export const SelectWrapper = styled.div`
  position: relative;
`;

export const WarningMessage = styled.p`
  color: ${buildColor("orange", "600")};
  font-size: ${defaultSize};
  font-weight: normal;
  margin-top: 4px;
  font-family: ${fontNormal};
`;

export const SelectContainer = styled.fieldset`
  position: relative;
  height: auto;
  font-family: ${fontNormal};
`;

export const StatusIcon = styled(Icon)`
  margin-left: 3px;
  vertical-align: bottom;
  margin-right: 3px;
`;

export const SelectLabel = styled(
  buildText({
    tag: "label",
    bold: false,
    color: buildColor("grey", "900")
  })
)`
  font-size: 14px;
  display: block;
  position: relative;
  font-weight: 500;
  margin-bottom: 8px;
  font-family: ${fontMedium};
`;

export const IconWrapper = styled.span`
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 48%;
  transform: translateY(-50%);
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background-color: ${buildColor("blue_accent", "100")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "200")};
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 42px;
  background-color: transparent;
  border: 1px solid ${buildColor("grey", "100")};
  appearance: none;
  position: relative;
  z-index: 2;
  padding: 12px;
  border-radius: 2px;
  font-size: 14px;
  box-shadow: 0px 2px 2px rgba(17, 43, 68, 0.15);
  line-height: 18px;
  ${({ isError }) =>
    isError &&
    css`
      border: 2px solid ${buildColor("orange", "600")};
    `}
  margin-bottom: 2px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "700")};

  &:active,
  &:focus {
    outline: none;
  }

  &:active + .chevron-icon-wrapper {
    background-color: ${buildColor("blue_accent", "200")};
  }

  &:focus {
    border: solid 2px ${buildColor("blue_accent", "500")};
  }

  &::-ms-expand {
    display: none;
  }

  &.state-selected {
    color: ${buildColor("grey", "900")};
  }
`;
