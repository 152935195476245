// @flow
import type {
  SetFavoriteTrackActionType,
  RemoveFavoriteTrackActionType,
  SetUserFavoriteTracksWithIdActionType
} from "@tvg/shared-actions/UserActions";

type SetUserFavoriteTracks = {
  type: "SET_USER_FAVORITE_TRACKS",
  payload: {
    trackCode?: string,
    favoriteTracks: string[]
  }
};

type ClearUserFavoriteTracks = {
  type: "CLEAR_USER_FAVORITE_TRACKS"
};

type SetUserFavoriteTrack = {
  type: "SET_FAVORITE_TRACK",
  payload: {
    trackCode: string,
    favoriteId: number
  }
};

export type Favorites =
  | SetUserFavoriteTracks
  | SetUserFavoriteTrack
  | ClearUserFavoriteTracks
  | SetFavoriteTrackActionType
  | RemoveFavoriteTrackActionType
  | SetUserFavoriteTracksWithIdActionType;

export const setUserFavoriteTracks = (
  favoriteTracks: string[]
): SetUserFavoriteTracks => ({
  type: "SET_USER_FAVORITE_TRACKS",
  payload: { favoriteTracks }
});

export const clearUserFavoriteTracks = (): ClearUserFavoriteTracks => ({
  type: "CLEAR_USER_FAVORITE_TRACKS"
});

export default setUserFavoriteTracks;
