import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { MediatorEventType, RaceAlertsType } from "./types";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.TOGGLE, (data: RaceAlertsType) => {
    track<BaseEventProps>(
      get(data, "payload.toggledOn") ? "Toggle On" : "Toggle Off",
      {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel"),
        [BaseEventProps.MODULE]: get(data, "payload.module")
      }
    );
  });
};
