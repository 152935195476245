import * as amplitude from "@amplitude/analytics-browser";
import tvgConf from "@tvg/conf";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import type { UserProperties, AmplitudeInitArgs } from "./types";
import { URP_PAGE_CONFIG } from "./constants";
import useGlobalPropertiesPlugin from "./useGlobalPropertiesPlugin";
import {
  getAmpSessionIDCookie,
  setAmpSessionIDCookie,
  removeAmpSessionIDCookie,
  setAmpDeviceIDCookie
} from "./cookiesUtils";
import useXSellPlugin from "./useXSellPlugin";

export const setAmplitudeUserId = (accountId?: string) => {
  amplitude.setUserId(accountId);
};

export const setAmplitudeUserProperties = (
  userProperties: Partial<UserProperties>
) => {
  if (!userProperties) {
    return;
  }

  const identifyEvent = new amplitude.Identify();
  Object.entries(userProperties).forEach(([property, value]) => {
    identifyEvent.set(property, value);
  });

  amplitude.identify(identifyEvent);
};

export const setAmplitudeAnonymize = () => {
  removeAmpSessionIDCookie(); // clean previous session cookie value
  // update cookies
  setAmpSessionIDCookie();
  setAmpDeviceIDCookie();
};

export function track<T extends string>(
  eventName: string,
  properties?: Partial<
    Record<
      T,
      | string
      | number
      | boolean
      | undefined
      | Record<string, string>
      | Array<Record<string, string>>
    >
  >
) {
  amplitude.track(eventName, properties);
}

export default ({
  getGlobalProperties,
  pagesConfig = URP_PAGE_CONFIG,
  options = {
    minIdLength: 1,
    identityStorage: "localStorage"
  },
  extra
}: AmplitudeInitArgs) => {
  const { amplitudeAPI } = tvgConf().amplitudeConfig();
  amplitude.init(amplitudeAPI, "", {
    defaultTracking: {
      pageViews: false,
      attribution: {
        resetSessionOnNewCampaign: true
      }
    },
    ...options
  });

  const getGlobalPropsWithCookiesCheck = () => {
    // verify if amplitude cookie session is still accessible - not expired
    if (!getAmpSessionIDCookie()) {
      setAmpSessionIDCookie();
    }
    return getGlobalProperties();
  };

  amplitude.add(
    useGlobalPropertiesPlugin(
      getGlobalPropsWithCookiesCheck,
      pagesConfig,
      extra
    )
  );

  // delay execution - amplitude instance ready!
  setTimeout(() => {
    setAmpSessionIDCookie();
    setAmpDeviceIDCookie();
  });

  if (isXSell()) amplitude.add(useXSellPlugin());
};
