import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { processEventLabel } from "./utils";

export default () => {
  mediator.base.subscribe(MediatorEventType.CLICK, (data: EventData) => {
    const gaEventLabel = processEventLabel(data);

    return track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: gaEventLabel,
      [BaseEventProps.MODULE]: "upcoming races",
      [BaseEventProps.LINK_URL]: get(data.payload, "url", "")
    });
  });
};
