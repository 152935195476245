import { startCase } from "lodash";
import { BaseEventProps } from "../../types";
import {
  URP_PAGE_CONFIG,
  PAGE_NAME_KEYS,
  ALL_INFO_TRACK_DETAILS_REGEX
} from "../../constants";

export const getTrackName = () => {
  const pathName = window.location.pathname;
  const trackName = pathName.split("/").pop();
  const evalPath = URP_PAGE_CONFIG.find(
    (config) => config.page === PAGE_NAME_KEYS.TRACK_INFORMATION
  );

  if (
    (!ALL_INFO_TRACK_DETAILS_REGEX.test(pathName) &&
      !evalPath?.urlReg.test(pathName)) ||
    trackName === "racetracks"
  ) {
    return null;
  }

  return { [BaseEventProps.TRACK_NAME]: startCase(trackName) };
};
