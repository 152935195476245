export function nextRaceFulFilled(response) {
  return {
    type: "NEXT_RACE_FULFILLED",
    payload: response
  };
}

export function greyhoundRaceFulFilled(response) {
  return {
    type: "GREYHOUND_RACE_FULFILLED",
    payload: response
  };
}

export function nextGreyhoundRace(response) {
  return {
    type: "NEXT_GREYHOUND_RACE",
    payload: response
  };
}

export function noNextRaces(response) {
  return {
    type: "NO_NEXT_RACE",
    payload: response
  };
}

export function noNextGreyhounds(response) {
  return {
    type: "NO_NEXT_GREYHOUNDS",
    payload: response
  };
}

export function nextRaceRejected(err) {
  return {
    type: "NEXT_RACE_REJECTED",
    payload: err
  };
}

export function nextRacePending() {
  return {
    type: "NEXT_RACE_PENDING"
  };
}
