import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  SEEN = "PICK_BETS_ONBOARDING_SEEN"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    onBoardingType?: string;
    buttonCopy?: string;
  };
}
