import mediator from "@tvg/mediator";
import { get } from "lodash";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME, LABELS } from "./constants";
import Events from "./events";
import Navigation from "./navigation";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  Events();
  Navigation();

  mediator.base.subscribe(MediatorEventType.CLICK, (data: EventData) =>
    track<EventProps>(EVENT_NAME.NAVIGATED, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: get(data, "payload.isOptedIn")
        ? "Promo Signage - Opted In"
        : "Promo Signage - Promo",
      [BaseEventProps.MODULE]: get(data, "payload.module"),
      [BaseEventProps.LINK_URL]: get(data, "payload.destinationUrl")
    })
  );

  mediator.base.subscribe(MediatorEventType.OPEN, () =>
    track<EventProps>(`${LABELS.PROMOS} ${EVENT_NAME.OPEN}`, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: LABELS.PROMOS,
      [BaseEventProps.MODULE]: "Mobile Footer"
    })
  );
};
