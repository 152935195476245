import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  HIDE: "Hide",
  SHOW: "Show",
  OPEN: "Opened",
  NAVIGATED: COMMON_EVENT_NAMES.NAVIGATED
};

export const LABELS = {
  SUPPORT_MODAL: "Support Modal"
};
