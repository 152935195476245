export function triggerOverlayChange() {
  return {
    type: "OVERLAY_CHANGE"
  };
}
export function closeOverlay() {
  return {
    type: "CLOSE_OVERLAY"
  };
}
