export const EVENT_CATEGORY = "Site Click";

export const EVENTS = {
  SELECT: "Selected",
  OPEN: "Opened",
  CLOSE: "Closed",
  BACK: "Back"
};

export const LABELS = {
  ALL_PP: "All PP Modal",
  PP: "PP Modal",
  INLINE_PP: "Inline PP"
};
