import {
  loginHandler,
  logoutHandler,
  userTransformHandler
} from "../handlers/loginHandler";

export default function reducer(
  state = {
    logged: false,
    logging: false,
    user: null,
    error: "",
    baseValue: {
      username: "",
      password: "",
      state: null,
      rememberMe: false
    }
  },
  action
) {
  switch (action.type) {
    case "USER_LOGIN_PENDING": {
      return { ...state, logging: true, error: null };
    }
    case "USER_LOGIN_FULFILLED": {
      return {
        ...state,
        logged: true,
        error: null,
        user: userTransformHandler(loginHandler(action.payload))
      };
    }
    case "SESSION_FULFILLED": {
      return {
        ...state,
        logging: false,
        logged: true,
        error: null,
        user: userTransformHandler(action.payload.data)
      };
    }

    case "UPDATE_SESSION": {
      return {
        ...state,
        logging: false,
        logged: true,
        error: null,
        user: userTransformHandler(action.payload)
      };
    }
    case "SESSION_REJECTED": {
      return {
        ...state,
        logging: false,
        logged: false,
        error: null,
        user: null
      };
    }

    case "USER_LOGOUT_PENDING": {
      return { ...state, logging: true };
    }
    case "USER_LOGOUT_FULFILLED": {
      logoutHandler();
      return {
        ...state,
        logging: false,
        logged: false,
        error: null,
        user: null
      };
    }
    case "USER_LOGOUT_REJECTED": {
      return { ...state, logging: false, logged: false, error: "error" };
    }
    case "USER_LOGIN_SAVE_FORM_DATA": {
      return { ...state, baseValue: action.payload };
    }
    case "USER_LOGIN_FORM_ERROR": {
      return { ...state, logging: false, logged: false, error: action.payload };
    }
    default: {
      return state;
    }
  }
}
