import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  BANNER_TYPE = "Banner Type",
  BANNER_ID = "Banner Id",
  BANNER_NAME = "Banner Name",
  EDUCATION_CATEGORY = "Education Category",
  ARTICLE_NAME = "Article Name"
}

export type EventProps = BaseEventProps & ModuleEventProps;

export enum MediatorEventType {
  BANNER_CAROUSEL_VIEWED = "BANNER_CAROUSEL_VIEWED",
  BANNER_CAROUSEL_CLICKED = "BANNER_CAROUSEL_CLICKED",
  HOMEPAGE_TUTORIALS_LEARN_MORE = "HOMEPAGE_TUTORIALS_LEARN_MORE"
}

export type Banner = {
  bannerName: string;
  bannerType: "promo" | "tutorial";
  bannerId: string | number;
};

export type BannerCarouselViewedEvent = {
  type: MediatorEventType.BANNER_CAROUSEL_VIEWED;
  payload: Banner;
};

export type BannerCarouselClickEvent = {
  type: MediatorEventType.BANNER_CAROUSEL_CLICKED;
  payload: Banner & {
    linkUrl: string;
    linkText: string;
  };
};

export type HPTutorialsLearnMoreEvent = {
  type: MediatorEventType.HOMEPAGE_TUTORIALS_LEARN_MORE;
  payload: {
    article: string;
    subheading: string;
  };
};
