import formatCurrency from "@tvg/formatter/currency";
import { Balance, BalanceDisplayArgs } from "./types/balance";

export const PLACEHOLDER = {
  HIDDEN: "$••••",
  NULLISH: "$ -.--"
};

const checkValidBalance = (balance: Balance | undefined) => {
  const balanceType = typeof balance;

  return (
    balanceType === "number" ||
    (!!balance && balanceType === "string" && !Number.isNaN(+balance))
  );
};

export const computeBalanceDisplay = ({
  balance,
  nullishValueDisplay,
  isBalanceHidden,
  hiddenValueDisplay
}: BalanceDisplayArgs) => {
  if (isBalanceHidden) {
    return hiddenValueDisplay || PLACEHOLDER.HIDDEN;
  }

  return checkValidBalance(balance)
    ? // @ts-ignore
      formatCurrency(+balance)
    : nullishValueDisplay || PLACEHOLDER.NULLISH;
};
