// @flow
import { get } from "lodash";
import { reach } from "yup";
import type { EmailRecoverySchema } from "./validators";

export const handleOnTheFlyChange = async (
  isTouched: boolean,
  value: mixed,
  fieldName: string,
  schema: EmailRecoverySchema,
  formRef: any
) => {
  if (isTouched) {
    const fieldSchema = reach(schema, fieldName);
    try {
      await fieldSchema.validate(value, {
        abortEarly: true
      });
      formRef.current.setFieldError(fieldName, "");
    } catch (err) {
      formRef.current.setFieldError(
        fieldName,
        get(err, "errors[0]", "Please fill this field")
      );
    }
  }
};
