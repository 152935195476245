import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  RACE_STATUS = "Race Status",
  MTP = "Mtp",
  FDTV_TYPE = "FDTV Type"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  PAGE_VIEW = "LOAD:RACE_PAGE_VIEW"
}

// Payload type data
export interface PageViewData {
  type: MediatorEventType;
  payload: {
    accountId: string | null;
    event: string;
    loginStatus: string;
    page: string;
    privateBrowser: string;
    productVersion: string;
  };
}
