// @flow
import React, {
  // $FlowFixMe
  useCallback
} from "react";
import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { get, noop } from "lodash";
import SupportTemplate from "@tvg/atomic-ui/_templates/Support";
import { onExternalLinkCallback } from "@tvg/mobile-account";
import mediator from "@tvg/mediator";
import openExternalAppOnIOS from "@tvg/utils/mediatorUtils";
import type { NullaryFn } from "@tvg/types/Functional";
import { getEmail } from "@urp/store-selectors";

type Props = {
  emailUrl: string,
  liveChatUrl: string,
  hasLiveChat: boolean,
  isApp: boolean,
  useSalesforceSDK: boolean,
  user: {
    firstName: string,
    lastName: string,
    emailAddress: string
  },
  onOpenLiveChat: NullaryFn<void>
};

export const buttonEventGTM = (buttonType: string): void => {
  mediator.base.dispatch({
    type: "SUPPORT_MODAL_BUTTONS",
    payload: {
      buttonType
    }
  });
};

export const openRedirectEngineOnIOSCallback = (liveChatUrl: string): void =>
  openExternalAppOnIOS(liveChatUrl);

const SupportComp = ({
  isApp = false,
  liveChatUrl = "",
  emailUrl = "mailto:support@tvg.com",
  hasLiveChat = true,
  useSalesforceSDK = false,
  user = {
    firstName: "",
    lastName: "",
    emailAddress: ""
  },
  onOpenLiveChat = noop
}: Props) => {
  const iOSMessageUsCallback = useCallback(
    (e: Event) => {
      if (isApp) {
        if (useSalesforceSDK) {
          mediator.ios.dispatch({
            type: "LIVE_CHAT",
            payload: {
              liveChatFirstName: user.firstName,
              liveChatLastName: user.lastName,
              liveChatEmail: user.emailAddress
            }
          });
          onOpenLiveChat();
        } else {
          openExternalAppOnIOS(liveChatUrl);
        }
      } else {
        buttonEventGTM("live chat");
        if (liveChatUrl !== "") {
          onExternalLinkCallback(liveChatUrl, e);
        }
      }
    },
    [
      isApp,
      liveChatUrl,
      useSalesforceSDK,
      user.firstName,
      user.lastName,
      user.emailAddress
    ]
  );

  return (
    <SupportTemplate
      emailUrl={emailUrl}
      liveChatUrl={liveChatUrl}
      hasLiveChat={hasLiveChat}
      navigationCallBack={buttonEventGTM}
      onExternalLinkCallback={onExternalLinkCallback}
      iOSMessageUsCallback={iOSMessageUsCallback}
    />
  );
};

export default connect(
  (store) => ({
    hasLiveChat: get(
      store,
      "capi.featureToggles.showTVGLivePersonButton",
      true
    ),
    useSalesforceSDK: get(store, "capi.featureToggles.useSalesforceSDK", false),
    user: {
      firstName: get(store, "userData.user.firstName", ""),
      lastName: get(store, "userData.user.lastName", ""),
      emailAddress: getEmail(store)
    }
  }),
  (dispatch: Dispatch<*>) => ({ dispatch })
)(SupportComp);
