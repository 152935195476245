import { get } from "lodash";

export const getIsContentCardsOpen = (store, isDesktop) =>
  isDesktop
    ? store?.header?.brazeContentCardsOpen || false
    : store?.modal?.contentCardsOpen || false;

export const getIsLoginModalOpen = (store) =>
  get(store, "loginModal.loginOpen", false);

export const getIsOpenErrorModal = (store) =>
  get(store, "loginModal.isErrorModalOpen", false);

export const getIsAWLoginModalOpen = (store) =>
  get(store, "modal.accountWallet.isAWLoginModalOpen", false);
