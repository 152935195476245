// @flow

export type RevalidateLocationActions = {
  type:
    | "OPEN_LOCATION_REVALIDATE"
    | "SET_LOCATION_REVALIDATE_FAILURE"
    | "CLOSE_LOCATION_REVALIDATE"
};

export const openLocationRevalidate = (): RevalidateLocationActions => ({
  type: "OPEN_LOCATION_REVALIDATE"
});

export const setLocationRevalidateFailure = (): RevalidateLocationActions => ({
  type: "SET_LOCATION_REVALIDATE_FAILURE"
});

export const closeLocationRevalidate = (): RevalidateLocationActions => ({
  type: "CLOSE_LOCATION_REVALIDATE"
});
