/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium } from "../../_static/Typography";
import ButtonLink from "../../_atom/Buttons/buttonLink";
import Text from "../../_atom/Text";

export const Container = styled.section`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: ${buildColor("white", "100")};
  padding: 12px;
  overflow-y: scroll;
  ${(props) =>
    !props.isMobile &&
    css`
      padding: 20px;
    `};
  ${(props) =>
    props.device === "tablet" &&
    css`
      height: 0;
      min-height: 515px;
    `};
  ${(props) =>
    props.device === "desktop" &&
    css`
      height: 0;
      min-height: 450px;
    `};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageEmail = styled.div`
  margin-top: 20px;
  font-weight: bold;
  word-break: break-all;
`;

const linkStyles = css`
  text-decoration: none;
  color: ${buildColor("blue_accent", "500")};
  font-size: 14px;
  font-family: ${fontMedium};
  font-weight: 400;
`;

export const MessageBoxFooter = styled.div`
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

export const MessageNote = styled(Text)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  color: ${buildColor("grey", "900")};
  line-height: 18px;
  white-space: pre-line;
  ${({ breakLine }) =>
    breakLine &&
    css`
      align-items: center;
      flex-direction: column;
    `};

  /* CMS inserted anchor has inline styles so we need to use important */
  a {
    ${linkStyles};
    text-decoration: none !important;
    color: ${buildColor("blue_accent", "500")} !important;
  }

  ${({ isFooter }) =>
    isFooter &&
    css`
      display: flex;
      flex-direction: column;
      color: ${buildColor("grey", "800")};
      text-align: center;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    `};
`;

export const MessageLink = styled.a`
  ${linkStyles};
  padding-left: 4px;
`;

export const MessageLinkFooter = styled.a`
  ${linkStyles};
  width: 100%;
  text-align: center;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;

export const MessageEmailResent = styled.p`
  a {
    display: inline !important;
    vertical-align: top;
    font-family: ${fontMedium} !important;
    font-weight: normal !important;
  }
`;

export const CallToActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonLink = styled(ButtonLink)`
  font-family: ${fontMedium};
  span {
    font-family: ${fontNormal};
  }

  ${({ withMarginTop }) => withMarginTop && "margin-top: 10px;"}
`;
