import * as amplitude from "@amplitude/analytics-browser";

import { XSELL_PREFIX } from "./constants";

export default () => {
  const name = "xsell-properties-plugin";
  const type: amplitude.Types.EnrichmentPlugin<
    amplitude.Types.CoreClient,
    amplitude.Types.Config
  >["type"] = "enrichment";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let amplitudeConfig;

  const setup = async function (config: amplitude.Types.Config) {
    amplitudeConfig = config;
  };

  // The following function will "intercept" every event being triggered
  // and will append the "xsell property" to every event
  const execute = async function (event: amplitude.Types.Event) {
    event.event_type = `${XSELL_PREFIX} ${event.event_type}`;

    return event;
  };

  const plugin: amplitude.Types.EnrichmentPlugin = {
    name,
    setup,
    type,
    execute
  };

  return plugin;
};
