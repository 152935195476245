import { RacePanelLink } from "@tvg/ts-types/Race";
import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  SEE_NEXT = "TOP_RACES_SEE_NEXT_RACES",
  SEE_PREV = "TOP_RACES_SEE_PREVIOUS_RACES",
  SELECT_RUNNER = "TOP_RACES:SELECT_RUNNER",
  GO_TO_RACE = "HEADER_GO_TO_RACE"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    race: RacePanelLink;
    tag: string;
    url: string;
    runner: string;
  };
}
