// @flow
import { get } from "lodash";
import mediator from "@tvg/mediator";
import type {
  Props,
  LoginActiveFlow,
  LoginActiveFlowStatus
} from "@tvg/types/Login";
import type { Device } from "@tvg/types/Device";
import type { Product } from "@tvg/types/Product";
import {
  closeLoginModal,
  resetLoginFlow
} from "@tvg/sh-lib-account-actions/src/actions/modal";
import { LOGIN_ACTIVE_FLOWS } from "@tvg/sh-lib-account-actions/src/reducers/modalReducer";

export const buildModalProps = (
  loginActiveFlow: LoginActiveFlow,
  loginActiveFlowStatus: LoginActiveFlowStatus,
  baseProps: { [key: string]: * },
  props: Props,
  device: Device,
  product: Product
) => {
  const currentPath = `${get(props.history, "location.pathname")}${get(
    props.history,
    "location.search"
  )}`;

  const titleMap = {
    "forgot-credentials": "Forgot Credentials",
    "recover-email": "Recover email address"
  };
  const onClose = () => {
    props.history.push(currentPath);

    const payload = {
      action: device === "desktop" ? "Screen Closed" : "Modal Closed",
      field: titleMap[loginActiveFlow],
      module: titleMap[loginActiveFlow]
    };

    switch (loginActiveFlow) {
      case LOGIN_ACTIVE_FLOWS["forgot-credentials"]:
        mediator.base.dispatch({
          type: "FORGOT_CREDENTIALS_CLOSE_MODAL",
          payload: {
            ...payload,
            module: "Forgot Password"
          }
        });
        break;
      case LOGIN_ACTIVE_FLOWS["recover-email"]:
        mediator.base.dispatch({
          type: "FORGOT_CREDENTIALS_CLOSE_MODAL",
          payload: {
            ...payload
          }
        });
        break;
      default:
        // gtm event for close login modal
        mediator.base.dispatch({
          type: "LOGIN_MODAL_OPEN_CLOSE",
          payload: {
            open: false
          }
        });
    }
    props.dispatch(closeLoginModal());
  };

  const isOpen = get(props, "loginModal.loginOpen", false);

  switch (loginActiveFlow) {
    case LOGIN_ACTIVE_FLOWS["forgot-credentials"]:
      return {
        title: titleMap[loginActiveFlow],
        qaLabel: "modal-forgot-credentials",
        isOpen,
        onBack:
          loginActiveFlowStatus !== "success" &&
          loginActiveFlowStatus !== "failure" &&
          (() => {
            mediator.base.dispatch({
              type: "FORGOT_CREDENTIALS_BACK_MODAL",
              payload: {
                module: titleMap[loginActiveFlow]
              }
            });
            if (device !== "desktop")
              props.history.push(`${props.history.location.pathname}#login`);
            props.dispatch(resetLoginFlow());
          }),
        onClose,
        ...baseProps
      };
    case LOGIN_ACTIVE_FLOWS["recover-email"]:
      if (loginActiveFlowStatus === "failure")
        return {
          title: "Unsuccessful attempt",
          qaLabel: "modal-recover-email-failure",
          isOpen,
          onClose,
          ...baseProps
        };
      return {
        title: titleMap[loginActiveFlow],
        qaLabel: "modal-recover-email",
        isOpen,
        onBack:
          loginActiveFlowStatus !== "success" &&
          (() => {
            mediator.base.dispatch({
              type: "FORGOT_CREDENTIALS_BACK_MODAL",
              payload: {
                module: titleMap[loginActiveFlow]
              }
            });
            if (device !== "desktop") {
              props.history.push(
                `${props.history.location.pathname}#${LOGIN_ACTIVE_FLOWS["forgot-credentials"]}`
              );
            } else {
              mediator.base.dispatch({
                type: "OPEN_LOGIN_FLOW",
                payload: {
                  loginActiveFlow: LOGIN_ACTIVE_FLOWS["forgot-credentials"]
                }
              });
            }
          }),
        onClose,
        ...baseProps
      };
    default:
      return {
        title: "Login",
        qaLabel: "modal-login",
        useFakeInput: device === "tablet" && product === "ios2",
        isOpen,
        onClose,
        ...baseProps
      };
  }
};
