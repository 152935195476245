import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  SORT_TYPE = "Sort Type",
  SORT_NAME = "Sort Name",
  SORT_OPERATION = "Sort Operation"
}

export type EventProps = BaseEventProps | ModuleEventProps;

export enum MediatorEventType {
  FAVORITE_DATA_TYPE = "HANDICAPPING:SELECT_FAVORITE_DATA_TYPE",
  DATA_TYPE = "HANDICAPPING:SELECT_DATA_TYPE",
  OPEN_MODAL = "HANDICAPPING:OPEN_MODAL",
  SORT_FAVORITE_DATA_TYPE = "HANDICAPPING_SORT:SELECT_FAVORITE_DATA_TYPE",
  SORT_DATA_TYPE = "HANDICAPPING_SORT:SELECT_DATA_TYPE"
}

export interface MediatorEventData {
  type:
    | MediatorEventType.FAVORITE_DATA_TYPE
    | MediatorEventType.DATA_TYPE
    | MediatorEventType.SORT_FAVORITE_DATA_TYPE;
  payload: {
    type: string;
  };
}

export interface SortData {
  type: MediatorEventType.SORT_DATA_TYPE;
  payload: {
    type: string;
    trackName: string;
    raceNumber: string;
    order: "ascending" | "descending";
  };
}
