// @flow
import React from "react";
import type { NullaryFn } from "@tvg/types/Functional";
import { noop } from "lodash/fp";
import {
  ButtonLinkContainer,
  ButtonIconContainer,
  ButtonText,
  Counter
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import type { IconType } from "../../_static/Icons/Types.js.flow";

type Variant = "primary" | "primary-light" | "light" | "default";

type Props = {
  icon: IconType,
  text: string,
  url: string,
  isRouterLink?: boolean,
  variant?: Variant,
  onClick?: NullaryFn<mixed>,
  counter?: number,
  iconViewBoxSize?: number
};

const ButtonLink = (props: Props) => {
  const {
    icon,
    text,
    url,
    isRouterLink,
    variant,
    onClick,
    counter,
    iconViewBoxSize
  } = props;
  const buttonProps = isRouterLink
    ? { to: url, onClick }
    : { as: "a", href: url, onClick };

  return (
    <ButtonLinkContainer
      data-qa-label={`account-${text}-button`}
      {...buttonProps}
    >
      <ButtonIconContainer variant={variant}>
        {!!counter && (
          <Counter data-qa-label={`account-${text}-button-counter`}>
            {counter}
          </Counter>
        )}
        <Icon
          icon={icon}
          size={20}
          color={
            variant === "light"
              ? buildColor("grey", "900")
              : buildColor("white", "100")
          }
          viewBoxSize={iconViewBoxSize}
        />
      </ButtonIconContainer>
      <ButtonText variant={variant}>{text}</ButtonText>
    </ButtonLinkContainer>
  );
};

ButtonLink.defaultPros = {
  variant: "default",
  onClick: noop
};

export default ButtonLink;
