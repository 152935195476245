export enum CredentialsMediatorEventType {
  VALIDATED_FIELD = "FORGOT_CREDENTIALS_VALIDATED_FIELD",
  RESET_BUTTON = "FORGOT_CREDENTIALS_RESET_BUTTON",
  FORGOT_DETAILS = "FORGOT_CREDENTIALS_FORGOT_DETAILS",
  CHECK_SCREEN = "FORGOT_CREDENTIALS_CHECK_SCREEN",
  LINK_LANDED = "FORGOT_CREDENTIALS_LINK_LANDED",
  RESEND_SCREEN = "FORGOT_CREDENTIALS_RESEND_SCREEN",
  LINK_EXPIRED = "FORGOT_CREDENTIALS_LINK_EXPIRED",
  ERROR_PAGE_CTA = "FORGOT_CREDENTIALS_ERROR_PAGE_CTA",
  RETURN_TO_TVG_COM = "FORGOT_CREDENTIALS_RETURN_TO_TVG_COM",
  CANCEL_RESET = "FORGOT_CREDENTIALS_CANCEL_RESET",
  UPDATE_CREDENTIAL = "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL",
  UPDATE_CREDENTIAL_SUCCESSFULLY = "FORGOT_CREDENTIALS_UPDATE_CREDENTIAL_SUCCESSFULLY",
  WRONG_EMAIL_CTA_LINK_CLICK = "FORGOT_CREDENTIALS_WRONG_EMAIL_CTA_LINK_CLICK",
  RESET_SUBMIT_ERROR = "FORGOT_CREDENTIALS_RESET_SUBMIT_ERROR",
  CONTACT_CUSTOMER_SERVICE = "FORGOT_CREDENTIALS_CONTACT_CUSTOMER_SERVICE",
  CLOSE_MODAL = "FORGOT_CREDENTIALS_CLOSE_MODAL",
  BACK_MODAL = "FORGOT_CREDENTIALS_BACK_MODAL",
  LEAVE_PAGE_CTA_CLICK = "FORGOT_CREDENTIALS_LEAVE_PAGE_CTA_CLICK",
  NEW_PW_PIN = "FORGOT_CREDENTIALS_NEW_PWPIN",
  EMAIL_LINK_LANDED = "FORGOT_CREDENTIALS_EMAIL_LINK_LANDED"
}

export enum RecoverEmailMediatorEventType {
  RECOVER_EMAIL_SUBMIT = "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT",
  RECOVER_EMAIL_ERROR = "FORGOT_DETAILS_RECOVER_EMAIL_ERROR",
  RECOVER_EMAIL_FAILED = "FORGOT_DETAILS_RECOVER_EMAIL_FAILED",
  RECOVER_EMAIL_SUBMIT_SUCCESS = "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT_SUCCESS",
  VALIDATED_FIELD = "FORGOT_DETAILS_VALIDATED_FIELD"
}

export type LoginModalEventData = {
  type: CredentialsMediatorEventType | RecoverEmailMediatorEventType;
  payload: {
    accountId?: string;
    error?: unknown;
    field?: string;
    state?: string;
    module?: string;
    label?: string;
    message?: string;
  };
};
