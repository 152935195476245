import React from "react";
import {
  deposit,
  arrowDownward,
  email,
  wallet
} from "@tvg/atomic-ui/_static/Icons/icons";
import DetailedButtonLink from "@tvg/atomic-ui/_molecule/DetailedButtonLink";
import { ButtonsContainer } from "./styled-components";

export interface Props {
  showWalletButton: boolean;
  showInbox?: boolean;
  depositUrl: string;
  withdrawUrl: string;
  isExternalDeposit: boolean;
  enablePawsQuickDeposits?: boolean;
  depositOnClick?: () => void;
  quickDepositOnClick?: () => void;
  withdrawOnClick?: () => void;
  inboxCounter?: number;
  walletOnClick?: () => void;
  inboxOnClick?: () => void;
  hasQuickWithdraw: boolean;
  isSubWalletsAvailable?: boolean;
  isLightTheme?: boolean;
}

const AccountFooter = ({
  showInbox = true,
  showWalletButton,
  depositUrl,
  withdrawUrl,
  isExternalDeposit,
  inboxCounter,
  inboxOnClick,
  withdrawOnClick,
  depositOnClick,
  walletOnClick,
  hasQuickWithdraw,
  enablePawsQuickDeposits,
  isSubWalletsAvailable,
  isLightTheme = false
}: Props) => {
  const variant = isLightTheme ? "light" : "default";
  return (
    <ButtonsContainer isSubWalletsAvailable={isSubWalletsAvailable}>
      <DetailedButtonLink
        text="deposit"
        icon={deposit}
        variant={isLightTheme ? "primary-light" : "primary"}
        isRouterLink={enablePawsQuickDeposits || !isExternalDeposit}
        url={depositUrl}
        onClick={depositOnClick}
      />
      <DetailedButtonLink
        text="withdraw"
        url={withdrawUrl}
        icon={arrowDownward}
        isRouterLink={hasQuickWithdraw}
        variant={variant}
        onClick={withdrawOnClick}
      />
      {showWalletButton && (
        <DetailedButtonLink
          url="/wallet"
          icon={wallet}
          text="wallet"
          variant={variant}
          isRouterLink
          iconViewBoxSize={20}
          onClick={walletOnClick}
        />
      )}
      {showInbox && (
        <DetailedButtonLink
          isRouterLink
          url="#content-cards"
          icon={email}
          text="inbox"
          variant={variant}
          counter={inboxCounter}
          onClick={inboxOnClick}
        />
      )}
    </ButtonsContainer>
  );
};

export default AccountFooter;
