export interface BrazeContentCard {
  id: string;
  title: string;
  description: string;
  imageUrl?: string | null;
  linkText: string;
  url: string;
  clicked: boolean;
  dismissed: boolean;
  dismissible: boolean;
  pinned: boolean;
  viewed: boolean;
  sc?: string;
  Wc?: string;
  updated?: Date;
}

export enum CARD_SC {
  CONTROL_CARD = "ab-control-card",
  BANNER = "ab-image-only",
  CAPTIONED_IMG = "ab-captioned-image",
  CLASSIC_CARD = "ab-classic-card"
}
