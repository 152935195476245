import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindAll, get, attempt } from "lodash";
import classNames from "classnames";
import { connect } from "react-redux";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { events as AlchemerEvents } from "@urp/alchemer";
import UserAccount from "@tvg/atomic-ui/_organism/UserAccount";
import mediator from "@tvg/mediator";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { subWalletsContentDefault } from "@tvg/atomic-ui/_molecule/AccountSubWallets";
import { gtmUserAccountWalletIconsClick } from "@tvg/wallet/src/gtm";
import uwtService from "@tvg/api/uwt";
import { PreferencesService } from "../../../services/Preferences/Preferences";
import { closeOverlay } from "../../../actions/accountButton";
import { updateUserBalance } from "../../../actions/balance";
import style from "./style.css";

const getQueryParams = () =>
  typeof window !== "undefined" ? get(window, "location.search", "") : "";

const dipatchAction = (route) => {
  let event;
  switch (route) {
    case "/my-funds/deposit":
      event = "Deposit";
      break;
    case "/my-funds/withdraw":
      event = "Withdraw";
      break;
    case "/wallet":
      event = "Wallet";
      break;
    default:
      event = "Edit";
  }
  // TODO: switch back to this dispatch after updating vendors
  //
  // mediatorClassic.dispatch("HEADER_DESKTOP_NAVIGATION", {
  //   event,
  //   url: `${get(window, "location.hostname")}${route}`
  // });
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: event,
      module: "Header",
      tag: undefined,
      microApp: "Header",
      destinationUrl: `${get(window, "location.hostname")}${route}`
    });
  }
};

export class MyAccountButtonUser extends Component {
  constructor() {
    super();
    this.state = { withdrawFunds: 0 };
    bindAll(this, [
      "goTo",
      "getUserInfo",
      "toggleBalanceVisibility",
      "updateUserBalance"
    ]);
    mediatorClassic.subscribe(
      "ACCOUNT_BALANCE_CHANGED",
      this.updateUserBalance
    );
  }

  componentDidMount() {
    const userId = sessionStorage.getItem("userId");
    if (!this.props.showBalanceRequested && userId) {
      this.props.dispatch(
        PreferencesService.getUserPref("balance_is_shown", userId)
      );
    }

    if (
      this.getUserInfo().accountNumber &&
      this.props.isPawsSubWalletsAvailable
    ) {
      this.getWithdrawalFunds();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      get(this.props, "user.accountNumber") &&
      !sessionStorage.getItem("userId")
    ) {
      attempt(() => {
        sessionStorage.setItem("userId", this.props.user.accountNumber);
      });
    }
    if (!prevProps.overlayOpen && this.props.overlayOpen) {
      this.getWithdrawalFunds();
    }
  }

  componentWillUnmount() {
    mediatorClassic.unsubscribe(
      "ACCOUNT_BALANCE_CHANGED",
      this.updateUserBalance
    );
  }

  getUserInfo() {
    return this.props.user || {};
  }

  getWithdrawalFunds() {
    uwtService
      .getWithdrawalLimit(this.getUserInfo().accountNumber)
      .then(({ data }) => {
        this.setState({
          withdrawFunds: data.availableBalance
        });
      });
  }

  goTo(event, route) {
    event.preventDefault();
    event.stopPropagation();
    this.props.dispatch(closeOverlay());
    dipatchAction(route);
    mediatorClassic.dispatch("TVG4_NAVIGATION", { route });
  }

  toggleBalanceVisibility(e, toggle) {
    e.preventDefault();
    e.stopPropagation();
    const toggleCast = toggle ? "1" : "0";
    const userId = sessionStorage.getItem("userId");
    mediatorClassic.dispatch("HEADER_DESKTOP_SHOW_HIDE", {
      value: toggleCast,
      section: "Account Balance"
    });
    this.props.dispatch(
      PreferencesService.setUserPref("balance_is_shown", toggleCast, userId)
    );
  }

  openQuickDeposit = () => {
    mediator.base.dispatch({ type: "OPEN_QUICK_DEPOSIT" });
  };

  openQuickWithdrawal = () => {
    mediator.base.dispatch({ type: "OPEN_QUICK_WITHDRAWAL" });
  };

  handleClickUserSection = (event) => event.stopPropagation();

  updateUserBalance(data) {
    if (!data.noMediator) {
      mediatorClassic.dispatch("BALANCE_UPDATE", data.balance);
    }
    this.props.dispatch(updateUserBalance(data.balance));
  }

  render() {
    return (
      /* The <div> element has a child <button> element that allows keyboard interaction */
      /* eslint-disable-next-line */
      <div
        className={classNames(style.userSection, this.props.className)}
        onClick={this.handleClickUserSection}
      >
        <UserAccount
          accountNumber={this.getUserInfo().accountNumber}
          accountName={`${this.getUserInfo().firstName} ${
            this.getUserInfo().lastName
          }`}
          balance={this.props.balance && this.props.balance.balance}
          promoFunds={this.props.userPromoFunds}
          withdrawFunds={this.state.withdrawFunds}
          subWalletsContent={this.props.pawsSubWalletsContent}
          onClickHideBalance={(e) => {
            if (this.props.showBalanceDeposit) {
              this.toggleBalanceVisibility(e, false);
            } else {
              this.toggleBalanceVisibility(e, true);
            }
          }}
          withdrawUrl={
            this.props.hasQuickWithdraw
              ? `${getQueryParams()}#quick-withdraw`
              : "/my-funds/withdraw"
          }
          withdrawOnClick={(event) => {
            this.props.hasQuickWithdraw
              ? this.openQuickWithdrawal()
              : this.goTo(event, "/my-funds/withdraw");
            gtmUserAccountWalletIconsClick({
              destinationUrl: `${getQueryParams()}#quick-withdraw`,
              iconText: "withdraw"
            });
          }}
          depositUrl={
            this.props.enablePawsQuickDeposits
              ? `${getQueryParams()}#quick-deposit`
              : "/my-funds/deposit"
          }
          depositOnClick={(event) => {
            this.props.enablePawsQuickDeposits
              ? this.openQuickDeposit()
              : this.goTo(event, "/my-funds/deposit");
            gtmUserAccountWalletIconsClick({
              destinationUrl: `${getQueryParams()}#quick-deposit`,
              iconText: "deposit"
            });
          }}
          walletOnClick={(event) => {
            this.goTo(event, "/wallet");
            gtmUserAccountWalletIconsClick({
              destinationUrl: "/wallet",
              iconText: "wallet"
            });
            AlchemerEvents.selectWallet();
          }}
          hideBalance={!this.props.showBalanceDeposit}
          showWalletButton={this.props.accountPanelShowWallet}
          isExternalDeposit
          showUserInfo
          showBalanceInfo
          showInbox={false}
          hasQuickWithdraw={this.props.hasQuickWithdraw}
          enablePawsQuickDeposits={this.props.enablePawsQuickDeposits}
          isSubWalletsAvailable={this.props.isPawsSubWalletsAvailable}
          isLightTheme
        />
      </div>
    );
  }
}

MyAccountButtonUser.propTypes = {
  dispatch: PropTypes.func,
  showBalanceDeposit: PropTypes.bool,
  showBalanceRequested: PropTypes.bool,
  balance: PropTypes.shape({
    loading: PropTypes.bool,
    balance: PropTypes.number
  }),
  user: PropTypes.shape({ accountNumber: PropTypes.string }),
  className: PropTypes.string,
  hasQuickWithdraw: PropTypes.bool,
  pawsWalletAvailable: PropTypes.bool,
  isPawsSubWalletsAvailable: PropTypes.bool,
  userPromoFunds: PropTypes.number,
  pawsSubWalletsContent: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      value: PropTypes.string,
      tooltip: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string
      })
    })
  )
};

MyAccountButtonUser.defaultProps = {
  dispatch: () => null,
  user: {},
  balance: null,
  showBalanceDeposit: false,
  showBalanceRequested: false,
  className: "",
  hasQuickWithdraw: false,
  pawsWalletAvailable: false,
  isPawsSubWalletsAvailable: false,
  userPromoFunds: 0,
  pawsSubWalletsContent: []
};

export default connect((store) => ({
  user: store.userData.user,
  balance: store.balance,
  showBalanceDeposit: store.header.showBalanceDeposit,
  showBalanceRequested: store.header.showBalanceRequested,
  overlayOpen: store.accountButton.overlayOpen,
  accountPanelShowWallet: get(
    store,
    "header.features.accountPanelShowWallet",
    false
  ),
  hasQuickWithdraw: get(store, "header.features.pawsQuickWithdraw", false),
  enablePawsQuickDeposits: get(
    store,
    "header.features.pawsQuickDeposit",
    false
  ),
  pawsWalletAvailable: get(store, "header.features.pawsWalletAvailable", false),
  userPromoFunds: get(store, "userData.promoFunds", 0),
  pawsSubWalletsContent: parseCapiMessage(
    store,
    "header.pawsSubWalletsContent",
    subWalletsContentDefault
  ),
  isPawsSubWalletsAvailable: get(
    store,
    "header.features.pawsSubWalletsAvailable",
    false
  )
}))(MyAccountButtonUser);
