import styled from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const Container = styled.header<{ isLightTheme?: boolean }>`
  background-color: ${({ isLightTheme }) =>
    isLightTheme
      ? buildColor("blue_accent", "000")
      : buildColor("blue", "700")};
  position: relative;
`;
