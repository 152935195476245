export default function (
  state = {
    overlayOpen: false
  },
  action
) {
  switch (action.type) {
    case "OVERLAY_CHANGE": {
      return { ...state, overlayOpen: !state.overlayOpen };
    }
    case "CLOSE_OVERLAY": {
      return { ...state, overlayOpen: false };
    }
    default:
      return state;
  }
}
