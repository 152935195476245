import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  FIND_MORE = "ONBOARDINGS:FIND_OUT_MORE",
  NAVIGATE = "ONBOARDINGS:NAVIGATE_STEPS",
  COMPLETE = "ONBOARDINGS:COMPLETE"
}

export enum Orientation {
  NEXT = "Next",
  BACK = "Back"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    closingStage: string;
    tutorialName: string;
    orientation: Orientation;
    currentSlide: string;
    destination: string;
    label: string;
  };
}
