import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import classNames from "classnames";
import TVGLogin from "@tvg/login-classic";
import _, { get } from "lodash";
import Lock from "@tvg/ui-bootstrap/assets/svg/lock.svg";
import Button from "@tvg/ui-bootstrap/components/Buttons/";
import enhanceWithClickOutside from "react-click-outside";
import style from "./style.css";

export class LoginButton extends Component {
  constructor() {
    super();
    this.loginClassic = null;
    this.state = {
      isOpened: false
    };
    _.bindAll(this, ["handleClickOutside", "toggleOverlay"]);
  }

  componentWillUnmount() {
    this.setState({ isOpened: false });
  }

  toggleOverlay() {
    if (!this.state.isOpened) {
      mediatorClassic.dispatch("HEADER_DESKTOP_OPEN_SECTION", {
        section: "Login"
      });
    }

    mediator.base.dispatch({ type: "TVG_LOGIN:OPEN_LOGIN_MODAL" });
  }

  handleClickOutside() {
    this.setState({ isOpened: false });
  }

  render() {
    const loginStyle = classNames({
      [style.overlayArea]: true,
      [style.overlayAreaIn]: this.state.isOpened
    });

    const buttonStyle = classNames({
      [style.loginContainer]: true,
      [this.props.className]: true,
      [style.activeButton]: this.state.isOpened
    });

    const loginButtonContent = (
      <span className={style.innerFlexWrapper}>
        <Lock />
        Log in
      </span>
    );

    return (
      <div className={buttonStyle}>
        <Button
          type="secondaryAlt"
          onClick={this.toggleOverlay}
          content={loginButtonContent}
          qaLabel="openLogin"
        />
      </div>
    );
  }
}

LoginButton.propTypes = {
  className: PropTypes.string
};

LoginButton.defaultProps = {
  className: ""
};

export default connect((store) => ({
  loginData: store.login
}))(enhanceWithClickOutside(LoginButton));
