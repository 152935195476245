import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { InfoScreenMediatorEventType, InfoScreenEventData } from "./types";
import { EVENT_CATEGORY } from "./constants";

export default () => {
  mediator.base.subscribe(
    InfoScreenMediatorEventType.INFO_SCREEN_ERROR,
    (data: InfoScreenEventData) => {
      const eventAction = get(data, "payload.eventAction", "");

      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );

  mediator.base.subscribe(
    InfoScreenMediatorEventType.INFO_SCREEN_CTA_CLICK,
    (data: InfoScreenEventData) => {
      const eventAction = get(data, "payload.eventAction", "");

      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.eventLabel", ""),
        [BaseEventProps.MODULE]: get(data, "payload.module", "")
      });
    }
  );
};
