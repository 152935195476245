import mediator from "@tvg/mediator";
import { get } from "lodash";
import {
  EventData,
  EventProps,
  MediatorEventType,
  ModuleEventProps
} from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.OPEN, (data: EventData) =>
    track<EventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
      [BaseEventProps.EVENT_LABEL]: "Pending Withdrawals",
      [BaseEventProps.MODULE]: "More",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [ModuleEventProps.AMOUNT_OPTION]: "No"
    })
  );

  mediator.base.subscribe(MediatorEventType.CANCEL_SUCCESS, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CANCEL_SUCCESS, {
      [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
      [BaseEventProps.EVENT_LABEL]: "Pending Withdrawals",
      [BaseEventProps.EVENT_VALUE]: get(data, "payload.withdrawalAmount"),
      [BaseEventProps.MODULE]: "Cancel Withdraw",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [ModuleEventProps.AMOUNT_OPTION]: "No",
      [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.withdrawalType")
    })
  );

  mediator.base.subscribe(MediatorEventType.CANCEL_ERROR, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CANCEL_ERROR, {
      [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
      [BaseEventProps.EVENT_LABEL]: "Pending Withdrawals",
      [BaseEventProps.EVENT_VALUE]: get(data, "payload.withdrawalAmount"),
      [BaseEventProps.MODULE]: "Cancel Withdraw",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [ModuleEventProps.AMOUNT_OPTION]: "No",
      [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.withdrawalType")
    })
  );

  mediator.base.subscribe(MediatorEventType.CANCEL_ATTEMPT, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CANCEL_ATTEMPT, {
      [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
      [BaseEventProps.EVENT_LABEL]: "Pending Withdrawals",
      [BaseEventProps.EVENT_VALUE]: get(data, "payload.withdrawalAmount"),
      [BaseEventProps.MODULE]: "Cancel Withdraw",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [ModuleEventProps.AMOUNT_OPTION]: "No",
      [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.withdrawalType")
    })
  );

  mediator.base.subscribe(MediatorEventType.CALL_CS, (data: EventData) =>
    track<EventProps>(EVENT_NAME.CALL_CS, {
      [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
      [BaseEventProps.EVENT_LABEL]: "Pending Withdrawals",
      [BaseEventProps.EVENT_VALUE]: get(data, "payload.withdrawalAmount"),
      [BaseEventProps.MODULE]: "Cancel Withdraw",
      [ModuleEventProps.AMOUNT_OPTION]: "No",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.withdrawalType")
    })
  );

  mediator.base.subscribe(MediatorEventType.CANCEL_CLICK, (data: EventData) =>
    track<EventProps>(EVENT_NAME.DELETE, {
      [BaseEventProps.EVENT_CATEGORY]: "Withdrawal",
      [BaseEventProps.EVENT_LABEL]: "Pending Withdrawals",
      [BaseEventProps.EVENT_VALUE]: get(data, "payload.withdrawalAmount"),
      [BaseEventProps.MODULE]: "Pending Withdrawals",
      [ModuleEventProps.AMOUNT_OPTION]: "No",
      [BaseEventProps.ACCOUNT_ID]: get(data, "payload.accountId"),
      [ModuleEventProps.DEPOSIT_TYPE]: get(data, "payload.withdrawalType")
    })
  );
};
