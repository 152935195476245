import React, { useState, useMemo, useEffect } from "react";
import AccountSubWallets, {
  SubWalletContent,
  subWalletsContentDefault
} from "@tvg/atomic-ui/_molecule/AccountSubWallets";
import AccountHeader from "@tvg/atomic-ui/_molecule/AccountHeader";
import AccountFooter from "@tvg/atomic-ui/_molecule/AccountFooter";
import tvgConf from "@tvg/conf";
import { Container } from "./styled-components";

export interface Props {
  accountNumber: string;
  accountName: string;
  balance: number;
  promoFunds: number;
  withdrawFunds: number;
  onClickHideBalance: () => void;
  hideBalance: boolean;
  showWalletButton: boolean;
  showInbox?: boolean;
  showUserInfo?: boolean;
  showBalanceInfo?: boolean;
  depositUrl: string;
  withdrawUrl: string;
  isExternalDeposit: boolean;
  enablePawsQuickDeposits?: boolean;
  depositOnClick?: () => void;
  quickDepositOnClick?: () => void;
  withdrawOnClick?: () => void;
  inboxCounter?: number;
  walletOnClick?: () => void;
  inboxOnClick?: () => void;
  hasQuickWithdraw: boolean;
  shouldShowPromoOnboarding?: boolean;
  renderPromoOnboarding?: React.ReactNode | null;
  isSubWalletsAvailable: boolean;
  isLightTheme?: boolean;
  subWalletsContent: SubWalletContent[];
}

const UserAccount = ({
  accountNumber,
  accountName,
  balance,
  promoFunds = 0,
  withdrawFunds = 0,
  onClickHideBalance,
  hideBalance,
  showInbox = true,
  showWalletButton,
  showUserInfo = true,
  showBalanceInfo = true,
  depositUrl,
  withdrawUrl,
  isExternalDeposit,
  inboxCounter,
  inboxOnClick,
  withdrawOnClick,
  depositOnClick,
  walletOnClick,
  hasQuickWithdraw,
  enablePawsQuickDeposits,
  shouldShowPromoOnboarding = false,
  renderPromoOnboarding = null,
  isSubWalletsAvailable = false,
  isLightTheme = false,
  subWalletsContent
}: Props) => {
  const [showSubWallets, setShowSubWallets] = useState(false);

  useEffect(() => {
    const element = document.getElementById("MyAccountButtonUser");
    const allowScroll = element && tvgConf().device === "desktop";
    const onHandleDesktopScroll = () => setShowSubWallets(false);

    // On desktop when scrolling menus subwallets should hide automatically
    if (allowScroll) {
      element.addEventListener("scroll", onHandleDesktopScroll);
    }

    return () => {
      if (allowScroll) {
        element.removeEventListener("scroll", onHandleDesktopScroll);
      }
    };
  }, []);

  const data = useMemo(
    () =>
      (subWalletsContent ?? subWalletsContentDefault).map(
        (item: SubWalletContent) => {
          // This could be simplified but this way it is prepared for new subwallets
          if (item.value === "~withdrawFunds~") {
            return {
              ...item,
              value: withdrawFunds
            };
          }
          if (item.value === "~promoFunds~") {
            return {
              ...item,
              value: promoFunds
            };
          }
          return item;
        }
      ),
    [subWalletsContent, promoFunds, withdrawFunds]
  );

  return (
    <Container data-qa-label="account-header" isLightTheme={isLightTheme}>
      <AccountHeader
        showUserInfo={showUserInfo}
        accountNumber={accountNumber}
        accountName={accountName}
        showBalanceInfo={showBalanceInfo}
        hideBalance={hideBalance}
        onClickHideBalance={onClickHideBalance}
        balance={balance}
        isSubWalletsAvailable={isSubWalletsAvailable}
        onExpand={() => setShowSubWallets((prevState) => !prevState)}
        isExpended={showSubWallets}
        isLightTheme={isLightTheme}
      />

      {isSubWalletsAvailable && (
        <AccountSubWallets
          isLightTheme={isLightTheme}
          isExpanded={showSubWallets}
          hideBalance={hideBalance}
          data={data}
        />
      )}

      <AccountFooter
        isSubWalletsAvailable={isSubWalletsAvailable}
        showInbox={showInbox}
        showWalletButton={showWalletButton}
        depositUrl={depositUrl}
        withdrawUrl={withdrawUrl}
        isExternalDeposit={isExternalDeposit}
        inboxCounter={inboxCounter}
        inboxOnClick={inboxOnClick}
        withdrawOnClick={withdrawOnClick}
        depositOnClick={depositOnClick}
        walletOnClick={walletOnClick}
        hasQuickWithdraw={hasQuickWithdraw}
        enablePawsQuickDeposits={enablePawsQuickDeposits}
        isLightTheme={isLightTheme}
      />

      {shouldShowPromoOnboarding && renderPromoOnboarding}
    </Container>
  );
};

export default UserAccount;
