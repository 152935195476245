import tvgConf from "@tvg/conf";
import { User } from "@tvg/ts-types/Login";
import { get } from "lodash";
import type { Dispatch } from "redux";
import { dismissBrazeContentcard, BrazeContentCard } from "./store/actions";
import {
  androidChangeUser,
  androidBrazeInitialize,
  androidRequestContentCardsRefresh,
  androidRemoveSubscription,
  androidDismissContentCard,
  androidReadContentCard,
  androidClickContentCard
} from "./androidBrazeBridge";

export const hasDirectIntegration = () =>
  (tvgConf().product !== "androidwrapper" &&
    tvgConf().product !== "tvgandroid" &&
    !tvgConf().product.includes("fdrandroid")) ||
  (typeof window !== "undefined" &&
    !get(window, "__TVG_GLOBALS__.BRAZE_NATIVE", false)) ||
  tvgConf().product.includes("xsell");

export const brazeChangeUser = (user: User, overrideAccountId?: string) => {
  const accountNumber = overrideAccountId || user.accountNumber;

  if (hasDirectIntegration()) {
    if (window?.braze) {
      window.braze.changeUser(accountNumber);
      window.braze.getUser(accountNumber).setFirstName(user.firstName);
      window.braze.getUser(accountNumber).setLastName(user.lastName);
      window.braze.getUser(accountNumber).setEmail(user.emailAddress);
    }
  } else {
    androidChangeUser(user, accountNumber);
  }
};

export const isBrazeInitialized = () => {
  if (hasDirectIntegration()) {
    try {
      return !!window?.braze?.getUser();
    } catch (_) {
      return false;
    }
  }
  return true;
};

export const brazeInitialize = () => {
  const params = {
    minimumIntervalBetweenTriggerActionsInSeconds: 5,
    allowUserSuppliedJavascript: true,
    baseUrl: "sdk.iad-01.braze.com"
  };

  if (hasDirectIntegration()) {
    if (window?.braze) {
      try {
        window.braze.initialize(tvgConf().config("brazeId"), params);

        window.braze.automaticallyShowInAppMessages();
      } catch (e) {
        // Do nothing
      }
    }
  } else {
    androidBrazeInitialize(params);
  }
};

export const brazeContentCardsRefresh = () => {
  if (hasDirectIntegration()) {
    if (window.braze) {
      window.braze.requestContentCardsRefresh();
    }
  } else {
    androidRequestContentCardsRefresh();
  }
};

export const brazeRemoveSubscription = (subscription: string) => {
  if (hasDirectIntegration()) {
    if (window.braze) {
      window.braze.removeSubscription(subscription);
    }
  } else {
    androidRemoveSubscription(subscription);
  }
};

export const brazeReadContentCard = (
  cards: BrazeContentCard[],
  forContentCards: boolean
) => {
  if (hasDirectIntegration()) {
    if (window.braze) {
      window.braze.logCardImpressions(cards, forContentCards);
    }
  } else {
    androidReadContentCard(cards);
  }
};

export const brazeClickContentCard = (
  card: BrazeContentCard,
  forContentCards: boolean
) => {
  if (hasDirectIntegration()) {
    if (window.braze) {
      window.braze.logCardClick(card, forContentCards);
    }
  } else {
    androidClickContentCard(card);
  }
};

export const dismissContentCard = (
  card: BrazeContentCard,
  dispatch: Dispatch
) => {
  if (hasDirectIntegration()) {
    if (window?.braze) {
      window.braze.logCardDismissal(card);
      dispatch(dismissBrazeContentcard(card));
    }
  } else {
    androidDismissContentCard(card);
    setTimeout(() => {
      brazeContentCardsRefresh();
    }, 100);
  }
};
