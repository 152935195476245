export enum MediatorEventType {
  SEEN = "PREVIOUS_WINNERS:SEEN",
  SEE_ALL = "PREVIOUS_WINNERS:SEE_ALL",
  SELECT_WINNER = "PREVIOUS_WINNERS:SELECT_WINNER"
}

// Payload type data
export interface PreviousInfo {
  type: MediatorEventType;
  payload: {
    hasBiggest: boolean;
    hasPreviuos: boolean;
  };
}

export interface SelectWinner {
  type: MediatorEventType;
  payload: {
    winner: object;
    url: string;
  };
}
