import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { MediatorEventType, PreviousInfo, SelectWinner } from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.SEEN, (data: PreviousInfo) => {
    const hasBiggest = get(data, "payload.hasBiggest");
    const hasPrevious = get(data, "payload.hasPrevious");
    const gaEventLabel =
      (hasBiggest && hasPrevious && "biggest + previous") ||
      (hasBiggest && "biggest") ||
      "previous";
    track<BaseEventProps>(EVENT_NAME.SEEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: gaEventLabel,
      [BaseEventProps.MODULE]: "previous winners"
    });
  });

  mediator.base.subscribe(MediatorEventType.SEE_ALL, () => {
    track<BaseEventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: "see all",
      [BaseEventProps.MODULE]: "previous winners"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.SELECT_WINNER,
    (data: SelectWinner) => {
      track<BaseEventProps>(EVENT_NAME.NAVIGATE_TO, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: `${get(
          data,
          "payload.winner.currentRace.trackName"
        )} - R${get(data, "payload.winner.currentRace.raceNumber")} - ${get(
          data,
          "payload.winner.currentRace.mtp"
        )}`,
        [BaseEventProps.MODULE]: "previous winners",
        [BaseEventProps.LINK_URL]: get(data, "payload.url")
      });
    }
  );
};
