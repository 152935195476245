import {
  UserCustomProperties,
  setAmplitudeUserProperties,
  setAmplitudeUserId,
  setAmplitudeAnonymize as amplitudeSessionEnd,
  trackingInit,
  buildGlobalProperties
} from "@urp/amplitude";
import { Location } from "react-router";

interface GlobalProps {
  location: {
    pathname: string;
    search: string;
    hash: string;
  };
  user: {
    balance: number;
    accountNumber: number;
    isLogged: boolean;
    homeState: string;
  };
}

interface UserInfo {
  accountNumber: string;
  homeState: string;
}

const getAmplitudeGlobalProperties = (props: GlobalProps) => {
  const { location, user } = props;
  const { balance, isLogged } = user;

  return buildGlobalProperties({
    balance,
    isLogged,
    location: location as Location,
    pageTitle: document.title,
    jurisdiction: user.homeState
  });
};

const amplitudeSetLocation = (state: string) => {
  setAmplitudeUserProperties({
    [UserCustomProperties.USER_LOCATION]: state
  });
};

const amplitudeSessionStart = (props: UserInfo) => {
  setAmplitudeUserId(props?.accountNumber);
  amplitudeSetLocation(props?.homeState?.toLowerCase());
};

export {
  trackingInit,
  getAmplitudeGlobalProperties,
  amplitudeSessionStart,
  amplitudeSessionEnd
};
