import React, { useState, useCallback, useEffect } from "react";
import { Paragraph, TooltipDescriptive, Icon } from "@tvg/design-system";
import { useTransition, config, animated } from "@react-spring/web";
import formatCurrency from "@tvg/formatter/currency";
import tvgConf from "@tvg/conf";
import {
  SubWalletContainer,
  SubWalletRow,
  SubWalletTextContainer
} from "./styled-components";

export const subWalletsContentDefault = [
  {
    id: 1,
    text: "Funds available to withdraw",
    value: "~withdrawFunds~",
    tooltip: {
      title: "Why can’t I withdraw my whole balance?",
      description:
        "Funds deposited via ACH will take up to 5 business days to clear your checking account. You will not be able to withdraw these until this time has passed"
    }
  },
  {
    id: 2,
    text: "Promo Funds",
    value: "~promoFunds~"
  }
];

export interface SubWalletContent {
  id: number;
  text: string;
  value: number | string;
  tooltip?: {
    title: string;
    description: string;
  };
}

export interface Props {
  hideBalance: boolean;
  isExpanded: boolean;
  data: Array<SubWalletContent>;
  isLightTheme?: boolean;
}

// number of items * row height + paddingBottom + borders
const height = 2 * 50 + 20 + 3;

const AccountSubWallets = ({
  hideBalance,
  isExpanded,
  data,
  isLightTheme = false
}: Props) => {
  const [closeTooltip, setCloseTooltip] = useState(false);
  const transition = useTransition(isExpanded, {
    from: { height: 0, overflow: "hidden" },
    enter: { height, overflow: "hidden" },
    leave: { height: 0, overflow: "hidden" },
    config: { config: config.molasses, duration: 250 }
  });

  // This fixes the tooltip closing on desktop
  const onCloseTooltip = useCallback(() => {
    // force close by changing the state
    setCloseTooltip(true);

    // reset the state to be able to open the tooltip again
    setTimeout(() => setCloseTooltip(false), 0);
  }, []);

  useEffect(() => {
    const isDesktop = tvgConf().device === "desktop";

    // On desktop close the tooltip when scrolling on the page
    if (isDesktop) {
      document.addEventListener("scroll", onCloseTooltip);
    }

    return () => {
      if (isDesktop) {
        document.removeEventListener("scroll", onCloseTooltip);
      }
    };
  }, []);

  return transition(
    (styles, item) =>
      item && (
        <animated.div style={styles}>
          <SubWalletContainer isLightTheme={isLightTheme}>
            {data.map(
              (
                { id, text, value, tooltip }: SubWalletContent,
                index: number
              ) => (
                <SubWalletRow
                  data-qa-label={`sub-wallet-row-${index}`}
                  key={id}
                  isLightTheme={isLightTheme}
                >
                  <SubWalletTextContainer>
                    <Paragraph
                      fontFamily="condensedRegular"
                      textTransform="uppercase"
                      color={isLightTheme ? "grey.800" : "blue_accent.200"}
                      qaLabel={`sub-wallet-text-${index}`}
                      mr="space-2"
                    >
                      {text}
                    </Paragraph>
                    {tooltip && (
                      <div onBlur={onCloseTooltip}>
                        <TooltipDescriptive
                          placement="bottom-mid"
                          isDarkMode={!isLightTheme}
                          header={tooltip.title}
                          body={tooltip.description}
                          qaLabel={`sub-wallet-tooltip-${index}`}
                          forceClose={closeTooltip}
                        >
                          <Icon
                            lineColor={
                              isLightTheme
                                ? "blue_accent.500"
                                : "blue_accent.200"
                            }
                            backgroundColor={
                              isLightTheme ? "blue_accent.100" : "blue.800"
                            }
                            name="info"
                            size="s"
                            qaLabel="tooltip-icon"
                          />
                        </TooltipDescriptive>
                      </div>
                    )}
                  </SubWalletTextContainer>
                  <Paragraph
                    color={isLightTheme ? "grey.900" : "white.900"}
                    qaLabel={`sub-wallet-value-${index}`}
                    ml="space-2"
                  >
                    {hideBalance ? `$••••` : formatCurrency(+value)}
                  </Paragraph>
                </SubWalletRow>
              )
            )}
          </SubWalletContainer>
        </animated.div>
      )
  );
};

export default AccountSubWallets;
