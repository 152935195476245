import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import Icon from "@tvg/atomic-ui/_static/Icons";
import * as allIcons from "@tvg/atomic-ui/_static/Icons/icons";
import * as mediator from "@tvg/mediator-classic/src";
import ArrowDown from "@tvg/ui-bootstrap/assets/svg/down-arrow.svg";
import ArrowUp from "@tvg/ui-bootstrap/assets/svg/arrow-up.svg";
import SubMenu from "../SubMenu/index";
import SubMenuItem from "../SubMenuItem/index";
import style from "./style.css";

const MENU_ITEM_CLASSES = {
  sideMenuButton: style.sideMenuButton
};

const getStyleClasses = (classes) =>
  classes
    ? classes
        .split(" ")
        .map((itemClass) => MENU_ITEM_CLASSES[itemClass] || "")
        .join(" ")
    : "";

export default class MenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    _.bindAll(this, ["openMenu", "closeMenu"]);
    this.subMenuClosedClass = false;
  }

  setClassNames() {
    return this.state.subMenuClosedClass
      ? `${style.subMenuList} ${style.clearSubMenu}`
      : `${style.subMenuList}`;
  }

  closeMenu() {
    this.setState({ subMenuClosedClass: true });
    setTimeout(() => this.setState({ subMenuClosedClass: false }), 400);
    this.props.closeMenu(false);
    mediator.dispatch("OPEN_SIDE_MENU", { open: false });
  }

  openMenu(e, route, name, menu, options) {
    e.preventDefault();
    e.stopPropagation();

    const module =
      this.props.menuToggled && !this.props.isFixedHeader
        ? "Hamburger"
        : "Header";

    mediator.dispatch("HEADER_DESKTOP_MENU_NAVIGATION", {
      url:
        route && route.indexOf("http") === -1
          ? `${_.get(window, "location.hostname")}${route}`
          : route,
      event: name,
      menu,
      module,
      additionalParams: { tag: undefined }
    });

    if (route && (route.indexOf("http") > -1 || route.indexOf("mailto") > -1)) {
      if (
        options === "newWindow" ||
        (options && options.target === "newWindow")
      ) {
        window.open(route, "_blank");
        return;
      }

      window.location.href = route;
      return;
    }

    mediator.dispatch("TVG4_NAVIGATION", { route });
    this.closeMenu();
  }

  render() {
    const {
      menuItemName,
      menuItemRoute,
      menuItemClasses,
      menuItemOptions,
      subMenus,
      menuItemQaLabel,
      menuItemIcon,
      newHeaderExperienceMenus
    } = this.props;
    const genClassesString = () =>
      this.props.isFixedHeader
        ? style.menuItemHeader
        : `${style.menuItem} ${getStyleClasses(menuItemClasses)}`;
    return (
      <li className={genClassesString()}>
        <a
          href={menuItemRoute}
          className={style.menuItemLink}
          data-qa-label={menuItemQaLabel}
          onClick={(event) =>
            this.openMenu(
              event,
              this.props.menuItemRoute,
              this.props.menuItemName,
              this.props.menuItemName,
              this.props.menuItemOptions
            )
          }
        >
          {!this.props.isFixedHeader && menuItemIcon ? (
            <i>
              <Icon
                icon={allIcons[menuItemIcon] || allIcons.question}
                color={buildColor("blue", "100")}
                size={16}
              />
              <span>{menuItemName}</span>
            </i>
          ) : (
            <span>{menuItemName}</span>
          )}
          {subMenus.length > 0 && (
            <Fragment>
              <i className={style.arrowDown}>
                <ArrowDown width="16" height="16" fill="#d6e3f0" />
              </i>
              <i className={style.arrowUp}>
                <ArrowUp width="16" height="16" fill="#fff" />
              </i>
            </Fragment>
          )}
        </a>
        {subMenus.length > 0 &&
          (newHeaderExperienceMenus ? (
            <SubMenuItem
              menuName={menuItemName}
              subMenus={subMenus}
              className={this.setClassNames()}
              openMenuMethod={this.openMenu}
              newIcon={menuItemOptions.newIcon}
            />
          ) : (
            <SubMenu
              menuName={menuItemName}
              subMenus={subMenus}
              className={this.setClassNames()}
              openMenuMethod={this.openMenu}
              newIcon={menuItemOptions.newIcon}
            />
          ))}
      </li>
    );
  }
}

MenuItem.propTypes = {
  menuItemName: PropTypes.string,
  closeMenu: PropTypes.func,
  menuToggled: PropTypes.bool,
  menuItemRoute: PropTypes.string,
  subMenus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      route: PropTypes.string
    })
  ),
  menuItemClasses: PropTypes.string,
  menuItemOptions: PropTypes.oneOfType([
    PropTypes.shape({
      newIcon: PropTypes.bool
    }),
    PropTypes.string
  ]),
  menuItemQaLabel: PropTypes.string,
  isFixedHeader: PropTypes.bool
};

MenuItem.defaultProps = {
  closeMenu: () => null,
  menuItemName: "",
  menuItemRoute: "",
  subMenus: [],
  menuItemClasses: "",
  menuItemOptions: {},
  menuToggled: false,
  menuItemQaLabel: "",
  isFixedHeader: false
};
