import { ENV_KEYS } from "../../constants";
import useExperiments from "../../useExperiments";
import { EXPERIMENT_KEY } from "./constants";
import { Payload, VariantEnum } from "./types";
import { parseOption } from "./utils";

export const useTalentPickNewOption = () => {
  const { getVariant } = useExperiments(ENV_KEYS.fdr_fe.web);
  const variant = getVariant<VariantEnum, Payload | Payload[]>(EXPERIMENT_KEY);

  const racingLinks =
    variant?.value === VariantEnum.SECTION &&
    variant.payload &&
    Array.isArray(variant.payload)
      ? variant.payload.map((item: Payload) => parseOption(item))
      : null;

  const talentPicksOption =
    variant?.value === VariantEnum.OPTION &&
    variant.payload &&
    !Array.isArray(variant.payload)
      ? parseOption(variant.payload)
      : null;

  const headerLinks =
    variant?.value === VariantEnum.HOME &&
    variant.payload &&
    Array.isArray(variant.payload)
      ? variant.payload
      : null;

  return { racingLinks, talentPicksOption, headerLinks };
};
