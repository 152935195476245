// @flow
import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const Switch = styled.label`
  width: 46px;
  height: 24px;
  background-color: ${(props) =>
    props.$on ? buildColor("blue_accent", "500") : buildColor("grey", "300")};
  border-radius: 12px;
  display: flex;
  padding: 3px;
  position: relative;
  cursor: pointer;
  transition: background-color ease 0.3s;

  > input {
    display: none;
  }

  &::before {
    ${(props) =>
      props.$on
        ? css`
            left: 0;
            content: "ON";
            padding: 3px 5px;
          `
        : css`
            right: 0;
            content: "OFF";
            padding: 3px;
          `};
    color: ${buildColor("white", "100")};
    font-size: 10px;
    font-weight: bold;
    position: absolute;
  }
`;

export const Slider = styled.span`
  width: 18px;
  height: 18px;
  background-color: ${buildColor("white", "100")};
  border-radius: 50%;
  position: absolute;
  transition: left ease 0.3s;
  left: ${(props) => (props.$on ? "25px" : "3px")};
`;
