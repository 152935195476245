// @flow
// $FlowFixMe
import React, { useEffect, useRef, useState } from "react";
import { noop } from "lodash";
import { useField } from "@unform/core";
import { type Props as InputProps } from "react-input-mask";
import type { NullaryFn, BinaryFn, UnaryFn } from "@tvg/types/Functional";
import { useDebouncedStringDiff } from "@tvg/custom-hooks";
import Icon from "../../_static/Icons";
import buildColor from "../../_static/ColorPalette";
import { warning } from "../../_static/Icons/icons";
import {
  Container,
  Label,
  OptionalLabel,
  InputMaskStyled,
  ErrorText,
  IconWrapper,
  InputWrapper
} from "../InputV2/styled-components";

type Props = {
  /**
   * Name (ID) for the input. This needs to be unique in order to get, set values within unform api
   */
  name: string,
  /**
   * Label for input
   */
  label: string,
  /**
   * Label if want to tell that is optional
   */
  optionalLabel?: string,
  /**
   * the QA label for automation
   */
  qaLabel: string,
  /**
   * Type of input
   */
  type?: *,
  /**
   * Input mode
   */
  inputMode?: *,
  /**
   * Use mono font family
   */
  isMono?: boolean,
  /**
   * Input Placeholder
   */
  placeholder?: string,
  /**
   * Custom handler
   */
  customHandleChange?: BinaryFn<string, string, void>,
  /**
   * Custom handler on blur
   */
  customHandleOnBlur?: UnaryFn<string, Promise<void>>,
  /**
   * Pass an icon to be rendered in the right
   */
  icon?: any,
  /**
   * Pass an handler for the icon
   */
  handleIconClick?: NullaryFn<void>,
  /**
   * Letter Spacing
   */
  letterSpacing?: number,
  /**
   * Amount in px for margin top
   */
  marginTop?: number,
  /**
   * Amount in px for margin bottom
   */
  marginBottom?: number,
  /**
   * Align center input text
   */
  alignCenter?: boolean,
  /**
   * Function to create a debounced call when changing input value
   */
  debouncedValueCallback?: UnaryFn<string, any>,
  /**
   * Toggle to control the debounced call when changing input value execution
   */
  hasPreloadedInputs?: boolean
};

const InputMask = ({
  name,
  label,
  optionalLabel,
  qaLabel,
  type,
  inputMode,
  isMono,
  placeholder,
  customHandleChange,
  customHandleOnBlur,
  icon,
  handleIconClick,
  letterSpacing,
  marginTop,
  marginBottom,
  alignCenter,
  mask,
  maskChar,
  alwaysShowMask,
  debouncedValueCallback,
  hasPreloadedInputs,
  ...inputProps
}: Props & InputProps) => {
  const [trackOfInputValue, setTrackOfInputValue] = useState("");
  const inputRef = useRef(null);

  useDebouncedStringDiff(
    trackOfInputValue,
    debouncedValueCallback,
    hasPreloadedInputs
  );

  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      setValue(ref: *, value: string = "") {
        if (ref && typeof ref.setInputValue && typeof value === "string") {
          ref.setInputValue(value);
          setTrackOfInputValue(value);
        }
      },
      clearValue(ref: *) {
        if (ref) {
          ref.setInputValue("");
          setTrackOfInputValue("");
        }
      }
    });
  }, [fieldName, registerField]);

  return (
    <Container
      data-qa-label={qaLabel}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      <Label htmlFor={fieldName}>
        {label}
        {optionalLabel && <OptionalLabel>({optionalLabel})</OptionalLabel>}
      </Label>
      <InputWrapper>
        <InputMaskStyled
          id={fieldName}
          data-qa-label={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          type={type}
          $isMono={isMono}
          inputMode={inputMode}
          onBlur={(event) => {
            const { value } = event.target;
            if (typeof customHandleOnBlur === "function") {
              customHandleOnBlur(value);
            }
          }}
          onChange={(event) => {
            const { value } = event.target;

            if (typeof customHandleChange === "function") {
              customHandleChange(value, fieldName);
            }

            setTrackOfInputValue(value);
          }}
          error={error}
          mask={mask}
          maskChar={maskChar}
          placeholder={placeholder}
          alwaysShowMask={alwaysShowMask}
          letterSpacing={letterSpacing}
          $alignCenter={alignCenter}
          {...inputProps}
        />
        {icon && (
          <IconWrapper onClick={handleIconClick} type="button">
            {icon}
          </IconWrapper>
        )}
      </InputWrapper>
      {error && (
        <ErrorText>
          <Icon icon={warning} color={buildColor("orange", "600")} />
          {error}
        </ErrorText>
      )}
    </Container>
  );
};

InputMask.defaultProps = {
  optionalLabel: "",
  type: "text",
  inputMode: "",
  placeholder: "",
  customHandleChange: noop,
  icon: null,
  handleIconClick: noop,
  letterSpacing: 0,
  marginTop: 0,
  marginBottom: 0,
  alignCenter: false
};

export default InputMask;
