import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { MediatorEventType, ResultsChangeTabType } from "./types";
import { EVENT_NAME } from "./constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.CHANGE_TAB_RESULTS,
    (data: ResultsChangeTabType): void => {
      track<BaseEventProps>(EVENT_NAME.RACE_CARD_TAB_OPENED, {
        [BaseEventProps.EVENT_CATEGORY]: "Program",
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.textLabel"),
        [BaseEventProps.MODULE]: "Race Card Tabs",
        [BaseEventProps.RACE_NUMBER]: get(data, "payload.raceNumber"),
        [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName")
      });
    }
  );
};
