import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  BALANCE_SHOWN = "MORE_PAGE:BALANCE_SHOWN",
  NAVIGATION = "MORE_NAVIGATION",
  OPEN_CONTENT = "MORE_OPEN_CONTENT",
  SWITCH_TOGGLE = "MORE_SWITCH_TOGGLE",
  SUPPORT_TOGGLE = "SUPPORT_MODAL_TOGGLE",
  WITHDRAW_DEPOSIT = "MORE_PAGE:WITHDRAWAL_AND_DEPOSIT_BUTTON",
  NOTIFICATION_BTN = "MORE_PAGE:HEADER_SECTION_NOTIFICATIONS_BUTTON"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    isBalanceShown: string;
    linkName: string;
    menu: string;
    url: string;
    itemOpened: string;
    destinationMenu: string;
    module: string;
    action: string;
    field: string;
    isExpanded: boolean;
    modalToggle: string;
    buttonType: string;
    destinationUrl: string;
    eventLabel: string;
  };
}
