import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  ModuleEventProps,
  EventProps,
  BetPreferenceData,
  PageViewPayloadData,
  DefaultBetTypeSelectedData,
  TogglePreferenceData,
  DefaultBetAmountData,
  CustomBetAmountData
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.SAVE_CHANGES,
    (data: BetPreferenceData) => {
      track<EventProps>(EVENT_NAME.SAVE_CHANGES, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: {
          [BaseEventProps.BET_TYPE]: data.payload.betType,
          [ModuleEventProps.BET_AMOUNT_OPTION]: data.payload.betAmountOption
        },
        [BaseEventProps.MODULE]: "Default bet"
      });
    }
  );

  mediator.base.subscribe(MediatorEventType.NAVIGATE_MORE_PAGE, () => {
    track<EventProps>(EVENT_NAME.NAVIGATE_MORE_PAGE, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: "More Menu",
      [BaseEventProps.MODULE]: "Default bet",
      [BaseEventProps.LINK_URL]: "/account"
    });
  });

  mediator.base.subscribe(
    MediatorEventType.PAGE_VIEW,
    ({ payload: { module } }: PageViewPayloadData) => {
      track<EventProps>(EVENT_NAME.PAGE_VIEW, {
        [BaseEventProps.MODULE]: module
      });
    }
  );
  mediator.base.subscribe(
    MediatorEventType.TOGGLE_PREFERENCE,
    ({ payload: { module, setting, itemSelected } }: TogglePreferenceData) => {
      track<EventProps>(EVENT_NAME.PREFERENCE_TOGGLE, {
        [BaseEventProps.MODULE]: module,
        [ModuleEventProps.SETTING]: setting,
        [ModuleEventProps.ITEM_SELECTED]: itemSelected
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.DEFAULT_BET_TYPE_SELECTED,
    ({ payload: { defaultBetTypeSelected } }: DefaultBetTypeSelectedData) => {
      track<EventProps>(EVENT_NAME.FDR_ACCOUNT_DEFAULT_BET_TYPE_SELECTED, {
        [BaseEventProps.MODULE]: "betting",
        [BaseEventProps.LINK_TEXT]: defaultBetTypeSelected
      });
    }
  );
  mediator.base.subscribe(
    MediatorEventType.DEFAULT_BET_AMOUNT,
    ({ payload: { itemSelected } }: DefaultBetAmountData) => {
      track<EventProps>(EVENT_NAME.FDR_ACCOUNT_DEFAULT_BET_AMOUNT, {
        [BaseEventProps.MODULE]: "betting",
        [ModuleEventProps.ITEM_SELECTED]: itemSelected
      });
    }
  );
  mediator.base.subscribe(
    MediatorEventType.CUSTOM_BET_AMOUNT,
    ({ payload: { betType, fieldInput } }: CustomBetAmountData) => {
      track<EventProps>(EVENT_NAME.FDR_ACCOUNT_CUSTOM_BET_AMOUNT, {
        [BaseEventProps.MODULE]: "betting",
        [BaseEventProps.BET_TYPE]: betType,
        [ModuleEventProps.FIELD_INPUT]: fieldInput
      });
    }
  );
};
