import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { MediatorEventType, EventProps, NavigateData, OpenData } from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(MediatorEventType.NAVIGATE, (data: NavigateData) => {
    track<EventProps>(EVENT_NAME.NAVIGATE, {
      [BaseEventProps.EVENT_CATEGORY]: "Navigation",
      [BaseEventProps.LINK_TEXT]: "Tutorials",
      [BaseEventProps.MODULE]: "More",
      [BaseEventProps.LINK_URL]: data?.payload?.destination
    });
  });

  mediator.base.subscribe(MediatorEventType.OPEN, (data: OpenData) => {
    track<EventProps>(EVENT_NAME.OPEN, {
      [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
      [BaseEventProps.EVENT_LABEL]: data?.payload?.tutorialName,
      [BaseEventProps.MODULE]: "Tutorials"
    });
  });
};
