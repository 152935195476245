export enum VariantEnum {
  HOME = "home-header-section",
  SECTION = "account-panel-section",
  OPTION = "account-panel-option"
}

export interface Payload {
  to?: string;
  label?: string;
  qaLabel?: string;
  iconName?: string;
}
