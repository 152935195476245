// @flow
import React from "react";
import { noop } from "lodash";
import type { BinaryFn, NullaryFn, UnaryFn } from "@tvg/types/Functional";

import AccountMenuLink from "../../_molecule/AccountMenuLink";
import { chat, email, arrowRight } from "../../_static/Icons/icons";
import {
  SupportSection,
  SupportContainer,
  HeaderText
} from "./styled-components";
import buildColor from "../../_static/ColorPalette";

export type Props = $Shape<{
  emailUrl: string,
  liveChatUrl: string,
  hasLiveChat: boolean,
  onExternalLinkCallback: BinaryFn<string, Event, void>,
  navigationCallBack: UnaryFn<string, void>,
  iOSMessageUsCallback: NullaryFn<void>
}>;

const Support = ({
  emailUrl = "mailto:racing@fanduel.com",
  liveChatUrl = "",
  hasLiveChat = true,
  navigationCallBack = noop,
  onExternalLinkCallback = noop,
  iOSMessageUsCallback = noop
}: Props) => (
  <SupportSection data-qa-label="support">
    {hasLiveChat && (
      <SupportContainer data-qa-label="support-container-liveChat" isLiveChat>
        <HeaderText data-qa-label="live-chat-title">
          Direct Messaging
        </HeaderText>
        <AccountMenuLink
          qaLabel="support-liveChat-btn"
          to={liveChatUrl}
          onClick={iOSMessageUsCallback}
          linkText="Message Us"
          leftIcon={{ icon: chat, size: 17 }}
          hide
          isExternalLink
          leftIconQaLabel="live-chat-icon"
          rightIcon={{
            icon: arrowRight,
            color: buildColor("grey", "800"),
            size: 24
          }}
        />
      </SupportContainer>
    )}
    <SupportContainer data-qa-label="support-container-orBy" removeBottomBorder>
      {hasLiveChat && (
        <HeaderText data-qa-label="or-by-title">Or By</HeaderText>
      )}
      <AccountMenuLink
        qaLabel="support-email-btn"
        to={emailUrl}
        onClick={(e: Event) => {
          onExternalLinkCallback(emailUrl, e);
          navigationCallBack("email");
        }}
        linkText="E-mail"
        leftIcon={{ icon: email, size: 17 }}
        isExternalLink
        leftIconQaLabel="email-icon"
        rightIcon={{
          icon: arrowRight,
          color: buildColor("grey", "800"),
          size: 24
        }}
      />
    </SupportContainer>
  </SupportSection>
);

export default Support;
