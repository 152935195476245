import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { MediatorEventType, PicksAlerts } from "./types";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.USER_ACTIONS,
    (data: PicksAlerts) => {
      track<BaseEventProps>(get(data, "payload.action"), {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.MODULE]: "TVG Picks Alerts"
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.TOGGLE_CLICK,
    (data: PicksAlerts) => {
      track<BaseEventProps>(`Toggle ${get(data, "payload.toggleStatus")}`, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.EVENT_LABEL]: `${get(
          data,
          "payload.entityType"
        )} Alerts`,
        [BaseEventProps.MODULE]: "TVG Picks Alerts",
        [BaseEventProps.TAG]: get(data, "payload.name")
      });
    }
  );
};
