import mediator from "@tvg/mediator";
import { EventData, EventProps, MediatorEventType } from "./types";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.CLICK_LINK,
    (data: EventData): void =>
      track<EventProps>(EVENT_NAME.CLICK_LINK, {
        [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
        [BaseEventProps.LINK_TEXT]: data.payload.clickedLink,
        [BaseEventProps.MODULE]: "Footer"
      })
  );
};
