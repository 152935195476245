import { get } from "lodash";

import mediator from "@tvg/mediator";
import { track } from "../../amplitude";
import { BaseEventProps } from "../../types";
import { InputRelatedEventData, InputRelatedMediatorEventType } from "./types";
import { EVENT_CATEGORY } from "./constants";

export default () => {
  mediator.base.subscribe(
    InputRelatedMediatorEventType.FIELD_FILL_AND_VALIDATED,
    (data: InputRelatedEventData) => {
      const eventAction = get(data, "payload.isEditing")
        ? "Edit Reg Field Fills"
        : "Reg Field Fills";

      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: get(data, "payload.isEditing")
          ? `Edit ${get(data, "payload.module")}`
          : get(data, "payload.module")
      });
    }
  );

  mediator.base.subscribe(
    InputRelatedMediatorEventType.FIELD_ERROR,
    (data: InputRelatedEventData) => {
      const eventAction = get(data, "payload.isEditing")
        ? "Edit Registration Error"
        : "Registration Error";

      track<BaseEventProps>(eventAction, {
        [BaseEventProps.EVENT_CATEGORY]: EVENT_CATEGORY,
        [BaseEventProps.EVENT_LABEL]: get(data, "payload.label"),
        [BaseEventProps.MODULE]: get(data, "payload.isEditing")
          ? `Edit ${get(data, "payload.module")}`
          : get(data, "payload.module")
      });
    }
  );
};
