// @flow
import React, { Component, Fragment, type Node } from "react";
import { connect } from "react-redux";
import mediator from "@tvg/mediator";
import { noop, bindAll, get, attempt } from "lodash";
import type { Device } from "@tvg/types/Device";
import tvgConf from "@tvg/conf";
import type { NullaryFn, UnaryFn } from "@tvg/types/Functional";
import type { ResponseObject, Payload } from "@tvg/login-service/src/types";
import { buildRedirectMessage } from "@tvg/login-service/services/helper";
import LoginTemplate, {
  type MessageType
} from "@tvg/atomic-ui/_templates/Login";
import type { LoginFieldStatus } from "@tvg/atomic-ui/_molecule/LoginInput";
import type { SelectFieldStatus } from "@tvg/atomic-ui/_molecule/LoginSelect";
import { TextLink } from "@tvg/atomic-ui/_organism/LoginForm/styled-components";
import Modal from "@tvg/atomic-ui/_templates/ModalV2";
import LoginNotification from "@tvg/atomic-ui/_atom/LoginNotifications";
import { errors } from "@tvg/login-service/services/static";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import { isTvg5 } from "@tvg/utils/generalUtils";
import isMobile from "@tvg/utils/mobileUtils";
import { formatDateToMMDDYYYYhhmm } from "@tvg/formatter/dates";
// $FlowFixMe
import sendToAppsFlyer, { AppsFlyerEvents } from "@tvg/utils/appsflyerUtils";
import useFakeInput from "@tvg/utils/useFakeInput";
import queryString from "query-string";
import { LOGIN_ACTIVE_FLOWS } from "@tvg/sh-lib-account-actions/src/reducers/modalReducer";
import uwt from "@tvg/api/uwt";
import { getBalance } from "@urp/store-selectors";

import gtmService from "../../gtmService";

type Props = {
  history: {
    push: UnaryFn<string, void>
  },
  mobile: boolean,
  triggerLogin: UnaryFn<Payload, Promise<ResponseObject>>,
  closeModal: NullaryFn<void>,
  callback: NullaryFn<mixed>,
  closeDropdownCallback?: () => void,
  stateAbbr: string,
  errorTitleCapi: string,
  robotMessage: string,
  initialUsername?: string,
  hasSuccessMessage?: boolean,
  successMessage?: MessageType,
  device: Device,
  userBalance: number,
  geopacketUsage: boolean
};

type State = {
  cmsMsgs: { [string]: string },
  isRedirectModalopen: boolean,
  isLoginError: boolean,
  errorTitle: string,
  errorMessage: Node,
  targetDomain: string,
  usernameStatus: LoginFieldStatus,
  username: string,
  passwordStatus: LoginFieldStatus,
  password: string,
  stateStatus: SelectFieldStatus,
  state: string,
  isProcessing: boolean,
  isHumanChallengeEnabled: boolean,
  passwordPlaceholder: string
};

export const redirectIOSApp = (target: string) => {
  mediator.ios.dispatch({
    type: "OPEN_EXTERNAL_APP",
    payload: { openExternalApp: target }
  });
};

export const getCustomerBrand = (targetDomain: string = ""): string =>
  targetDomain.split("-")[0] === "www" ? "tvg" : targetDomain.split("-")[0];

export const createRedirectAppLink = (redirectUrl: string): string => {
  if (redirectUrl.includes("pabets")) {
    return "pa://";
  }
  if (redirectUrl.includes("4njbets")) {
    return "nj://";
  }
  return "tvg://";
};

// adds user query string to be used in credentials recovery
const createPathWithUser = (username: string | null) => {
  const forgotCredentialsHash = "#forgot-credentials";
  const queryParams =
    typeof window !== "undefined" ? window.location.search : "";
  if (!username) return `${queryParams}${forgotCredentialsHash}`;
  const params = queryString.parse(queryParams);
  const paramsWithUser = {
    user: username,
    ...params
  };
  const newQueryParams = queryString.stringify(paramsWithUser);
  return `?${newQueryParams}${forgotCredentialsHash}`;
};

const isAccountNumber = (username) => /^\d+$/.test(username);

export class LoginSignup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cmsMsgs: {},
      isRedirectModalopen: false,
      isLoginError: false,
      isProcessing: false,
      errorTitle: "",
      errorMessage: null,
      targetDomain: "",
      usernameStatus: null,
      username: this.props.initialUsername || "",
      passwordStatus: null,
      password: "",
      stateStatus: null,
      state:
        this.props.stateAbbr !== "" && this.props.stateAbbr
          ? this.props.stateAbbr
          : "",
      isHumanChallengeEnabled: false,
      passwordPlaceholder: "Password or PIN",
      geopacketUsage: false
    };

    bindAll(this, [
      "updateState",
      "updateLoadingHandler",
      "tryToLogin",
      "getCMSMsgs",
      "onBlurHandler",
      "validateForm",
      "renderRedirectModal",
      "enterKeyHandler",
      "closeRedirectModal",
      "doLogin",
      "updatePasswordPlaceholder"
    ]);

    this.getCMSMsgs();

    const unsubscribe = () => {
      mediator.base.unsubscribe(
        "TVG_LOGIN:DO_LOGIN_FORM",
        subscriber // eslint-disable-line
      );
      mediator.base.unsubscribe(
        "TVG_LOGIN:TOUCH_ID_ERROR",
        errorSubscriber // eslint-disable-line
      );
    };

    const subscriber = (data) => {
      this.doLogin(data.payload);
      unsubscribe();
    };

    const errorSubscriber = () => {
      unsubscribe();
    };

    mediator.base.subscribe("TVG_LOGIN:DO_LOGIN_FORM", subscriber);
    mediator.base.subscribe("TVG_LOGIN:TOUCH_ID_ERROR", errorSubscriber);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("passedHC", () => {
        this.tryToLogin();
        this.setState({ isHumanChallengeEnabled: false });
      });
      window.addEventListener("enableHC", () => {
        this.setState({ isHumanChallengeEnabled: true });
      });
    }
  }

  componentWillUnmount(): void {
    if (typeof window !== "undefined") {
      window.removeEventListener("passedHC", () => {
        this.setState({ isHumanChallengeEnabled: false });
      });
      window.removeEventListener("enableHC", () => {
        this.setState({ isHumanChallengeEnabled: true });
      });
    }
  }

  onBlurHandler(field: string) {
    if (field !== "state") {
      gtmService({
        type: "input-complete",
        field
      });
    }

    this.updateState(
      `${field}Status`,
      this.validateInput(field, this.state) ? null : "error"
    );
  }

  // Get the cms messages in this.cmsMsgs
  getCMSMsgs() {
    tvgConf()
      .getMessages(["Login"])
      .then((response) =>
        this.setState({
          cmsMsgs: response
        })
      )
      .catch(() => {
        this.setState({
          cmsMsgs: {}
        });
      });
  }

  changeLink = () => {
    if (tvgConf().device !== "desktop" || isTvg5()) {
      this.props.closeModal();
      this.props.history.push("/registration");
    } else {
      window.location.assign("/registration");
    }
  };

  validateInput = (field: string, state: State): boolean => {
    switch (field) {
      case "username":
        return /^([A-Za-z0-9]+){1,20}/.test(state[field]);
      case "password":
        if (state[field].length && state[field].length < 21) {
          return /^[\\S]*/.test(state[field]);
        }
        return false;
      case "state":
        return state[field] !== "";
      default:
        return false;
    }
  };

  redirectToPRF = (e: Event) => {
    mediator.base.dispatch({ type: "LOGIN_MODAL_FORGOT_CREDENTIALS" });

    if (tvgConf().device === "desktop") {
      e.preventDefault();
      e.stopPropagation();

      mediator.base.dispatch({
        type: "OPEN_LOGIN_FLOW",
        payload: {
          loginActiveFlow: LOGIN_ACTIVE_FLOWS["forgot-credentials"]
        }
      });
    } else if (tvgConf().device === "tablet") {
      this.props.closeModal();
    }
  };

  getMessageNode = (errorMessage: string) => {
    const hyperLinkRegex = /<a href="\/forgot-credentials"[^>]*>([^<]+)<\/a>/;
    const matches = errorMessage.match(hyperLinkRegex);

    if (matches) {
      const [hyperLink, content] = matches;
      // $FlowFixMe
      const matchesIndex = matches.index;

      return (
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: errorMessage.substring(0, matchesIndex)
            }}
          />
          <TextLink
            data-qa-label="forgotCredentialsLink"
            to={createPathWithUser(this.state.username)}
            onClick={this.redirectToPRF}
          >
            {content}
          </TextLink>
          <span
            dangerouslySetInnerHTML={{
              __html: errorMessage.substring(matchesIndex + hyperLink.length)
            }}
          />
        </div>
      );
    }

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: errorMessage
        }}
        data-qa-label="messageBox-message"
      />
    );
  };

  doLogin(loginData: Payload) {
    this.updateLoadingHandler(true);

    this.props.triggerLogin(loginData).then((response) => {
      this.updateLoadingHandler(false);
      if (response.error) {
        gtmService({
          type: "error",
          error: get(response.error, "message")
        });

        if (get(response, "error.redirectUrl")) {
          this.updateLoadingHandler(false);
          this.setState({
            isRedirectModalopen: true,
            targetDomain: get(response, "error.redirectUrl")
          });
          return;
        }

        const errorMessage = get(response, "error.message");
        const capiTitles = attempt(() => JSON.parse(this.props.errorTitleCapi));
        let errorTitle = get(capiTitles, errorMessage, "Login Failed");

        let htmlError = get(
          // next line has a linting bug saying that we are doing a setState
          // eslint-disable-next-line
          this.state.cmsMsgs,
          errorMessage,
          "An error occurred and we could not log you in at this time. Please <a target='_blank' href='https://support.tvg.com/s/'>contact our customer service</a> for assistance."
        );

        if (errors.ACCOUNT_IN_TIMEOUT === errorMessage) {
          const endDate = get(
            response,
            "error.responsibleGamingExclusion.endDate"
          );
          htmlError = replaceCAPIVariables(htmlError, {
            exclusionTime: formatDateToMMDDYYYYhhmm(endDate)
          });
        } else if (
          errors.ACCOUNT_SUSPENDED === errorMessage ||
          errorMessage.ACCOUNT_SELF_EXCLUDE === errorMessage
        ) {
          const motive = get(
            response,
            "error.responsibleGamingExclusion.type",
            ""
          );
          htmlError = replaceCAPIVariables(htmlError, {
            motive: motive.toLowerCase()
          });
        }

        if (errors.INVALID_CREDENTIALS === errorMessage) {
          const remainingTries = get(response, "error.remainingTries");
          if (remainingTries > 0) {
            const triesMessageParsed = attempt(() =>
              JSON.parse(get(this.state.cmsMsgs, "loginTriesMessage", ""))
            );

            htmlError = triesMessageParsed[remainingTries];
          } else if (remainingTries === 0) {
            errorTitle = get(
              capiTitles,
              "loginAttemptsAccountLocked",
              "Login Failed"
            );
            htmlError = get(
              this.state.cmsMsgs,
              "loginAttemptsAccountLocked",
              ""
            );
          }
        }

        this.setState({
          isLoginError: true,
          errorTitle,
          errorMessage: this.getMessageNode(htmlError)
        });
      }

      if (response.success) {
        const accountId = get(
          response.success,
          "data.userDetails.accountNumber"
        );
        uwt
          .getBalance(accountId, false)
          .then((res) => {
            const balance = get(res, "data.balance", undefined);
            gtmService({
              type: "success",
              accountId,
              balance:
                typeof balance === "number" ? balance.toFixed(2) : balance
            });
          })
          .catch(() => {
            gtmService({
              type: "success",
              accountId,
              balance: undefined
            });
          });
        this.props.closeModal();

        if (isMobile(tvgConf().product)) {
          // Send AppsFlyer login event for iOS app
          // $FlowFixMe
          sendToAppsFlyer({
            key: AppsFlyerEvents.AfLogin,
            values: {
              accountId: get(
                response.success,
                "data.userDetails.accountNumber",
                0
              ).toString()
            }
          });

          mediator.ios.dispatch({
            type: "PUSHWOOSH_EVENTS",
            payload: {
              pushwooshTags: {
                HasLoggedInOnce:
                  attempt(() => localStorage.getItem("userLoginOnce")) || false,
                StateOfRegistration: get(
                  response.success,
                  "data.userDetails.homeAddress.state",
                  ""
                ),
                FirstName: get(
                  response.success,
                  "data.userDetails.firstName",
                  ""
                ),
                AccountNumber: get(
                  response.success,
                  "data.userDetails.accountNumber",
                  0
                )
              }
            }
          });
        }
      }
    });
  }

  tryToLogin() {
    // Hack for iOSApp in tablet when closing amount modal to fix the layout issues with the keyboard
    if (tvgConf().product === "ios2" && tvgConf().device === "tablet") {
      setTimeout(() => {
        useFakeInput();
      }, 10);
    }

    if (this.validateForm()) {
      const loginData = {
        account: this.state.username,
        pin: this.state.password,
        stateAbbr: this.state.state,
        callback: this.props.callback,
        geopacketUsage: this.props.geopacketUsage
      };
      this.doLogin(loginData);
    }
  }

  closeRedirectModal() {
    this.setState({
      isRedirectModalopen: false
    });
  }

  enterKeyHandler(e: SyntheticKeyboardEvent<*>): void {
    if (e.charCode === 13) {
      e.preventDefault();
      e.stopPropagation();

      const password = document.getElementById("passwordLogin");
      const stateSelector = document.getElementById("stateSelector");
      switch (get(e, "target.id")) {
        case "textLogin":
          if (password) {
            password.focus();
          }
          break;
        case "passwordLogin":
          if (stateSelector) {
            stateSelector.focus();
          } else {
            this.tryToLogin();
          }
          break;
        case "stateSelector":
        default:
          if (stateSelector) {
            stateSelector.blur();
          }
          this.tryToLogin();
          break;
      }
    }
  }

  updateLoadingHandler(value: boolean): void {
    this.setState({
      isProcessing: value
    });
  }

  updateState(field: string, value: string | null) {
    const isFirstTouch = attempt(() => localStorage.getItem("isFirstTouch"));

    if (!isFirstTouch) {
      attempt(() => localStorage.setItem("isFirstTouch", "true"));
      gtmService({
        type: "login-start"
      });
    }

    if (field === "state" && value !== null) {
      gtmService({
        type: "state-selected",
        state: value
      });
    }

    const dataToUpdate = {};
    dataToUpdate[field] = value;

    const newState = { ...this.state, ...dataToUpdate };

    this.setState({
      ...newState
    });

    this.updatePasswordPlaceholder();
  }

  updatePasswordPlaceholder() {
    if (isAccountNumber(this.state.username)) {
      this.setState({
        passwordPlaceholder: "Password or PIN"
      });
    } else {
      this.setState({
        passwordPlaceholder: "Password"
      });
    }
  }

  validateForm(): boolean {
    const valuesToCheck = ["username", "password", "state"];
    const wrongValues = [];

    valuesToCheck.forEach(
      (item) => this.state[item] === "" && wrongValues.push(item)
    );

    if (wrongValues.length) {
      const newState = {};

      wrongValues.forEach((item) => {
        newState[`${item}Status`] = "error";
      });

      this.setState(newState);
      return false;
    }
    return true;
  }

  isWagerpad =
    window !== undefined && window.location.pathname.includes("wagerpad");

  renderRedirectModal() {
    const { redirectMessage, redirectTitle } = buildRedirectMessage(
      this.state.targetDomain,
      get(this.state, "cmsMsgs.loginRedirectionMessage", "{}")
    );
    const isApp = isMobile(tvgConf().product);
    const isDeviceMobile = tvgConf().device === "mobile";

    const redirectProps = isApp
      ? {
          type: "appStore",
          callback: () =>
            redirectIOSApp(createRedirectAppLink(this.state.targetDomain)),
          targetDomain: redirectTitle
        }
      : {
          type: "redirect",
          targetDomain: this.state.targetDomain
        };

    return (
      <Modal
        title={redirectTitle}
        isOpen={this.state.isRedirectModalopen}
        animation="fade"
        shouldBeClosable={false}
        onClose={this.closeRedirectModal}
        isFullWidth={isDeviceMobile}
        isFluidWidth={!isDeviceMobile}
        isFullHeight={false}
        offsetLeft={12}
        offsetRight={12}
        qaLabel="login-redirectModal"
        layerOffset={10000}
      >
        {() => (
          <LoginNotification
            redirectMessage={redirectMessage}
            targetDomain={this.state.targetDomain}
            {...redirectProps}
          />
        )}
      </Modal>
    );
  }

  render() {
    return (
      <Fragment>
        <LoginTemplate
          passwordPlaceholder={this.state.passwordPlaceholder}
          mobile={this.props.mobile}
          onBlurHandler={this.onBlurHandler}
          isProcessing={this.state.isProcessing}
          isLoginError={
            this.state.isLoginError && !this.state.isHumanChallengeEnabled
          }
          redirectToPRF={this.redirectToPRF}
          errorTitle={this.state.errorTitle}
          errorMessage={this.state.errorMessage}
          robotMessage={this.props.robotMessage}
          usernameStatus={this.state.usernameStatus}
          usernameCallback={this.updateState}
          username={this.state.username}
          passwordStatus={this.state.passwordStatus}
          passwordCallback={this.updateState}
          password={this.state.password}
          selectCallback={this.updateState}
          stateSelectorStatus={this.state.stateStatus}
          signUpCallback={this.changeLink}
          onLoginCallback={this.tryToLogin}
          states={tvgConf().getStates()}
          noStateSelector={isMobile(tvgConf().product)}
          closeModal={this.props.closeModal}
          enterKeyHandler={(e) => this.enterKeyHandler(e)}
          awareMessage={get(this.state, "cmsMsgs.LoginAware")}
          // hasAwareMessageMarginTop={!this.props.initialUsername} FIX FR-1379
          isHumanChallengeEnabled={this.state.isHumanChallengeEnabled}
          recoverLink={createPathWithUser(this.state.username)}
          hasSuccessMessage={this.props.hasSuccessMessage}
          successMessage={this.props.successMessage}
          device={tvgConf().device}
          isWagerpad={this.isWagerpad}
        />
        {this.renderRedirectModal()}
      </Fragment>
    );
  }
}

// $FlowFixMe
LoginSignup.defaultProps = {
  closeDropdownCallback: noop,
  hasSuccessMessage: false,
  successMessage: {
    title: "",
    message: ""
  },
  device: "mobile"
};

export default connect((store) => ({
  errorTitleCapi: get(
    store,
    "capi.messages.loginErrorTitles",
    get(store, "header.loginErrorTitles", false)
  ),
  robotMessage: get(
    store,
    "capi.messages.robotMessage",
    get(store, "header.robotMessage", "")
  ),
  userBalance: getBalance(store)
}))(LoginSignup);
