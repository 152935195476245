import styled, { css } from "styled-components";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";

export const SubWalletContainer = styled.div<{ isLightTheme?: boolean }>`
  padding-left: 12px;
  ${({ isLightTheme }) =>
    isLightTheme
      ? css`
          background-color: ${buildColor("blue_accent", "100")};
          border-bottom: 1px solid ${buildColor("blue", "100")};
          border-top: 1px solid ${buildColor("blue", "100")};
        `
      : css`
          background-color: ${buildColor("blue", "800")};
          border-bottom: 1px solid ${buildColor("white", "10")};
          border-top: 1px solid ${buildColor("white", "10")};
        `}
`;

export const SubWalletRow = styled.div<{ isLightTheme?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-right: 12px;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ isLightTheme }) =>
        isLightTheme ? buildColor("blue", "100") : buildColor("white", "10")};
  }
`;

export const SubWalletTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
