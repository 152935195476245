import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  EVENT = "GTM_EVENT"
}

export enum PayloadEvent {
  ATTEMPT = "ATTEMPT",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  PROMPT = "PROMPT",
  YES_PROMPT = "YES_PROMPT",
  SKIP_PROMPT = "SKIP_PROMPT",
  NO_PROMPT = "NO_PROMPT"
}

export enum PayloadKey {
  TOUCH_ID = "TOUCH_ID",
  FACE_ID = "FACE_ID"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    message: string;
    gtm: {
      [PayloadKey.TOUCH_ID]?: PayloadEvent;
      [PayloadKey.FACE_ID]?: PayloadEvent;
    };
  };
}
