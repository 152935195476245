import { BaseEventProps } from "../../types";

export type EventProps = BaseEventProps;

export enum MediatorEventType {
  NAVIGATION = "HOMEPAGE_TRACK_LIST_NAVIGATION"
}

export interface EventData {
  type: MediatorEventType;
  payload: {
    trackName: string;
    number: string;
    mtp: number;
    url: string;
  };
}
